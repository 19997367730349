import { Pagination } from "@components/pagination";
import {
    useGetListApplyProcessPaginateQuery,
    useUpdateApplyProcessStatusMutation,
} from "@redux/queries/admin/admin.apply.process";
import { Form, FormInstance, Modal, Select, Table, Tag, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import FilterApplyProcess from "./components/FilterApplyProcess";
import HandleForm from "./components/HanleForm";
import { listApplyProcessStatus } from "./apply-process.const";
import { Link } from "react-router-dom";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { EditOutlined } from "@ant-design/icons";
import ApplyDetail from "./components/ApplyDetail";

const { Text } = Typography;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form
            form={form}
            component={false}
        >
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: any;
    record: any;
    handleSave: () => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const form = useContext(EditableContext)!;

    const [updateApplyProcessStatus, result] = useUpdateApplyProcessStatusMutation();

    const { showToast } = useToast();

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            const status = values.status;
            const result = await updateApplyProcessStatus({
                id: record.id,
                status: values.status,
            });

            if ("error" in result) {
                showToast({ ...TOAST_UPDATE_ERROR });
            }

            if ("data" in result) {
                showToast({ ...TOAST_UPDATE_SUCCESS });
            }
            handleSave();
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Select
                    onChange={save}
                    onBlur={save}
                >
                    {listApplyProcessStatus.map((item) => (
                        <Select.Option
                            key={item.value}
                            value={item.value}
                        >
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const ApplyProcess: React.FunctionComponent = () => {
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [detailId, setDetailID] = useState<string | null>(null);

    const handleViewDetail = (id: string) => {
        setDetailID(id);
        setOpenDrawer(true);
    };

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching, refetch } = useGetListApplyProcessPaginateQuery(query as any);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const defaultColumns = [
        {
            title: <div className="text-title-table">Đơn apply</div>,
            dataIndex: "applyStep",
            key: "applyStep",
            render: (applyStep: any) => {
                const userName = applyStep?.apply?.user?.firstName + " " + applyStep?.apply?.user?.lastName;
                return <Link to={`/admin/apply/edit/${applyStep?.apply?.id}`}>{userName}</Link>;
            },
        },
        {
            title: <div className="text-title-table">Tiêu đề</div>,
            dataIndex: "title",
            key: "title",
        },
        {
            title: <div className="text-title-table">Mô tả</div>,
            dataIndex: "description",
            key: "description",
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            dataIndex: "status",
            key: "status",
            render: (status: string) => {
                const statusItem = listApplyProcessStatus.find((item) => item.value === status);
                return (
                    <Tag color={statusItem?.color}>
                        {statusItem?.label}
                        <EditOutlined />
                    </Tag>
                );
            },
            editable: true,
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-gray"
                            onClick={() => handleViewDetail(record.applyStep?.apply?.id)}
                        >
                            <i className="bi-eye"></i>
                        </button>
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: () => {
                    refetch();
                },
            }),
        };
    });

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterApplyProcess
                                        data={filter}
                                        onSearch={(d: any) => setFilter(d)}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    components={components}
                                    columns={columns as any}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
                <ApplyDetail
                    openDrawer={openDrawer}
                    setOpenDrawer={(v: boolean) => setOpenDrawer(v)}
                    detailId={detailId}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default ApplyProcess;
