import { useRegisterMutation } from "@redux/queries/auth.api";
import { history } from "@utils/helper-router";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import AlertPage from "./components/AlertPage";
import Container from "./components/Container";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { authSelector } from "@redux/slices/auth.slice";
import { useSelector } from "react-redux";
const RegisterForm: FunctionComponent = (props) => {
    const { t } = useTranslation();

    const { referralCode } = useSelector(authSelector);

    const [doRegister, result] = useRegisterMutation();
    const recaptchaRef = useRef<any>(null);

    useEffect(() => {
        if (referralCode) {
            formik.setFieldValue("ref", referralCode);
        }
    }, [referralCode]);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            username: "",
            gender: "Male",
            birthday: "",
            password: "",
            confirmPass: "",
            ref: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().max(15, "Tên không được quá 15 kí tự").required("Không được bỏ trống Tên"),
            lastName: Yup.string().max(20, "Họ không được quá 20 kí tự").required("Không được bỏ trống Họ"),
            email: Yup.string().email("Email không đúng định dạng").required("Không được bỏ trống Email"),
            username: Yup.string().required("Không được bỏ trống tên đăng nhập"),
            birthday: Yup.date()
                .max(new Date(), "Ngày sinh phải là ngày trong quá khứ")
                .required("Không được bỏ trống Ngày sinh"),
            password: Yup.string().required("Không được bỏ trống Mật khẩu").min(8, "Tối thiểu 8 kí tự"),
            confirmPass: Yup.string()
                .oneOf([Yup.ref("password"), null as any], "Nhập lại mật khẩu không khớp")
                .required("Không được bỏ trống xác nhận mật khẩu"),
        }),
        onSubmit: async (values) => {
            await recaptchaRef.current.execute();
            const recaptchaValue = recaptchaRef && recaptchaRef.current && recaptchaRef.current.getValue();

            const result: any = await doRegister({
                ...values,
                birthday: new Date(values.birthday).toISOString(),
                recaptcha: recaptchaValue,
            });
        },
    });

    if (result.data) {
        return (
            <AlertPage
                title={t("page_register_success_em", "Gửi email thành công!")}
                description={t(
                    "page_register_alert_des",
                    "Chúng tôi đã gửi một thông báo về email của bạn. Vui lòng check lại email và làm theo hướng dẫn. Chúc bạn vui vẻ!"
                )}
                button={{
                    navigateTo: "/",
                    text: t("page_register_backhome", "Quay về Trang chủ"),
                }}
            />
        );
    }

    const errorMessage = () => {
        switch ((result?.error as any)?.errorCode) {
            case 1019:
                return "Tên đăng nhập đã tồn tại";
            case 1004:
                return "Email đã đăng ký tài khoản";
            default:
                break;
        }
    };

    return (
        <Container title={t("page_register_tit", "Đăng ký")}>
            <form
                id="register"
                className="pd-sign w-100"
                onSubmit={formik.handleSubmit}
            >
                <hgroup className="sign-title">
                    <h1>Đăng ký tài khoản</h1>
                    <h3>Nhập thông tin đầy đủ của bạn</h3>
                </hgroup>

                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="John Doe"
                                    id="username"
                                    name="username"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.username}
                                />
                                <label>{t("page_register_username", "Tên đăng nhập")}</label>
                                <i className="bi bi-person" />
                                {formik.touched.username && formik.errors.username ? (
                                    // @ts-ignore
                                    <p className="text-red">{formik?.errors?.username}</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <div className="form-floating">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="example@site.com"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />

                                <label>Email của bạn</label>
                                <i className="bi-envelope" />
                            </div>
                            {formik.touched.email && formik.errors.email ? (
                                // @ts-ignore
                                <p className="text-red">{formik?.errors?.email}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="example@site.com"
                                    id="lastName"
                                    name="lastName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                />

                                <label>{t("page_register_lastname", "Họ của bạn")}</label>
                            </div>
                            {formik.touched.lastName && formik.errors.lastName ? (
                                // @ts-ignore
                                <p className="text-red">{formik?.errors?.lastName}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="example@site.com"
                                    id="firstName"
                                    name="firstName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                />

                                <label>{t("page_register_firstname", "Tên")}</label>
                            </div>
                            {formik.touched.firstName && formik.errors.firstName ? (
                                // @ts-ignore
                                <p className="text-red">{formik?.errors?.firstName}</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <div className="form-floating">
                                <select
                                    id="gener"
                                    name="gender"
                                    className="form-select"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.gender}
                                >
                                    <option value={"Male"}>{t("page_register_gender_male", "Nam")}</option>
                                    <option value={"Female"}>{t("page_register_gender_female", "Nữ")}</option>
                                    <option value={"Other"}>{t("page_register_gender_other", "Khác")}</option>
                                </select>

                                <label>{t("page_register_gender", "Giới tính")}</label>
                            </div>
                            {formik.touched.gender && formik.errors.gender ? (
                                // @ts-ignore
                                <p className="text-red">{formik?.errors?.gender}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <div className="form-floating">
                                <input
                                    name="birthday"
                                    id="birthday"
                                    type="date"
                                    max={new Date().toISOString().split("T")[0]}
                                    className="form-control position-relative"
                                    placeholder="example@site.com"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.birthday}
                                />

                                <label>{t("page_register_birthday", "Ngày sinh")}</label>
                                <i className="bi bi-calendar" />
                            </div>
                            {formik.touched.birthday && formik.errors.birthday ? (
                                // @ts-ignore
                                <p className="text-red">{formik?.errors?.birthday}</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nhập mã giới thiệu (nếu có)"
                            id="ref"
                            name="ref"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ref}
                        />

                        <label>Mã giới thiệu</label>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-floating">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className="form-control"
                            placeholder="Tối thiểu 8 ký tự"
                        />

                        <label>{t("page_register_pass", "Mật khẩu")}</label>
                        <i className="bi-key" />
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                        // @ts-ignore
                        <p className="text-red">{formik?.errors?.password}</p>
                    ) : null}
                </div>
                <div className="form-group">
                    <div className="form-floating">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Tối thiểu 8 ký tự"
                            id="confirmPass"
                            name="confirmPass"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPass}
                        />

                        <label>{t("page_register_repass", "Nhập lại mật khẩu")}</label>
                        <i className="bi-key" />
                    </div>
                    {formik.touched.confirmPass && formik.errors.confirmPass ? (
                        // @ts-ignore
                        <p className="text-red">{formik?.errors?.confirmPass}</p>
                    ) : null}
                </div>

                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY as any}
                    ref={recaptchaRef}
                    size="invisible"
                />
                <div className="mirror mirror-child">
                    {(result?.error as any)?.message && <p className="text-red">{errorMessage()}</p>}
                    <button
                        className="btn-submit btn-blue"
                        type="submit"
                        disabled={result.isLoading}
                    >
                        {result.isLoading && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <span className="ps-2">{t("page_register_redirect", "Đăng ký ngay!")}</span>
                    </button>
                </div>
                <p className="dont-account">
                    {t("page_register_hasacc", "Bạn đã có tài khoản?")}{" "}
                    <b>
                        <Link
                            to="/login"
                            className="cursor-pointer"
                        >
                            {t("page_register_login", "Đăng nhập")}
                        </Link>
                    </b>
                </p>
            </form>
        </Container>
    );
};

export default RegisterForm;
