import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useDeleteMultiMajorsMutation,
    useDeleteMajorMutation,
    useGetListMajorPaginateQuery,
    useGetListMajorQuery,
} from "@redux/queries/admin/admin.majors";
import { Button, Modal, Table, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterMajors from "./components/FilterMajors";
import HandleForm from "./components/HandleForm";
import { schoolLogo } from "@consts";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";

const { Text } = Typography;

const Majors: React.FunctionComponent = () => {
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const { data: list_major, isLoading: isLoadingMajor, error: errorMajor } = useGetListMajorQuery({});
    const { data: list_train, isLoading: isLoadingTrain, error: error } = useGetListTrainingQuery();

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching } = useGetListMajorPaginateQuery(query as any);

    const [deleteMajors, isLoadingDelete] = useDeleteMajorMutation();
    const [deleteMultiMajors, isLoadingMultiDelete] = useDeleteMultiMajorsMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteMajors({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiMajors({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Code</div>,
            key: "code",
            dataIndex: "code",
        },
        {
            title: <div className="text-title-table" >Thumbnail</div>,
            key: "thumbnail",
            dataIndex: "thumbnail",
            render: (logo: string) => {
                return (
                    <img
                        src={logo ? process.env.REACT_APP_CDN + logo : schoolLogo}
                        alt="logo"
                        height={50}
                    />
                );
            },
        },
        {
            title: <div className="text-title-table">Tên ngành học</div>,
            key: "languageTitleVn",
            dataIndex: "language",
            render: (language: any) => {
                return language && language[0].title;
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Anh</div>,
            key: "languageTitleEn",
            dataIndex: "language",
            render: (language: any) => {
                return language && language[1].title;
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Trung</div>,
            key: "languageTitleCn",
            dataIndex: "language",
            render: (language: any) => {
                return language && language[2].title;
            },
        },
        {
            title: <div className="text-title-table">Ngành học cha</div>,
            key: "parentId",
            dataIndex: "parentId",
            render: (parentId: any) => {
                const major = list_major?.find((o: any) => o.id === parentId);
                return parentId ? major?.language[0]?.title : <Text type="secondary">Không có</Text>;
            },
        },
        {
            title: <div className="text-title-table">Hệ đào tạo</div>,
            key: "trainingId",
            dataIndex: "trainingId",
            render: (trainingId: any) => {
                return list_train?.find((item: any) => item.id === trainingId)?.language[0].title;
            },
        },

        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterMajors
                                        data={filter}
                                        onSearch={(v: any) => setFilter(v)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                    expandable={{
                                        expandIconColumnIndex: -1,
                                    }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default Majors;
