import { Button, Form, Input, Modal, Select } from "antd";
import { useCreateSchoolFaqMutation, useUpdateSchoolFaqMutation } from "@redux/queries/admin/admin.school.faq";
import { useEffect } from "react";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import JoditEditor from "jodit-react";
import { editorConfig } from "@utils/editConfig";
import DOMPurify from "dompurify";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createSchoolFaq, isLoadingCreate] = useCreateSchoolFaqMutation();
    const [updateSchoolFaq, isLoadingUpdate] = useUpdateSchoolFaqMutation();
    const { showToast } = useToast();
    const { data: list_school, isLoading, isFetching } = useGetListSchoolQuery();

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue({
                schoolId: dataEdit.school.id,
                title: dataEdit.title,
                question: dataEdit.question,
                answer: dataEdit.answer,
            });
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updateSchoolFaq({
                  id: dataEdit.id,
                  ...values,
              })
            : await createSchoolFaq(values);
        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update School FAQ" : "Add School FAQ"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={900}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="schoolId"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {list_school &&
                                        list_school.map((o: any, i: any) => {
                                            return (
                                                <Select.Option
                                                    key={o.id}
                                                    value={o.id}
                                                >
                                                    {o.language[0].name}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <label>Trường</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="title"
                                className="form-floating"
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Tiêu đề</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="question"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Không được để trống",
                                    },
                                ]}
                            >
                                <JoditEditor
                                    className="text-editor"
                                    value={dataEdit?.question}
                                    config={editorConfig as any}
                                />
                            </Form.Item>
                            <label>Câu hỏi</label>
                        </div>
                    </div>
                    {dataEdit && (
                        <div className="form-group">
                            <div className="form-floating">
                                <Form.Item
                                    name="answer"
                                    className="form-floating"
                                >
                                    <JoditEditor
                                        className="text-editor"
                                        value={dataEdit.answer}
                                        config={editorConfig as any}
                                    />
                                </Form.Item>
                                <label>Câu trả lời</label>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
