import { useGetListMajorQuery } from "@redux/queries/admin/admin.majors";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterSpecialized: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    const { data: list_major, isLoading: isLoadingMajor, error: errorMajor } = useGetListMajorQuery({
        child: '1'
    });

    const { data: list_major_parent} = useGetListMajorQuery({
        parent: '1'
    });

    const { data: list_training, isLoading: isLoadingTraining, error: errorTraining } = useGetListTrainingQuery();

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        {/* col 1 */}
                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="title"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Tiêu đề</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="majorsParent"
                                            className="form-floating"
                                        >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)
                                                    ?.toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {list_major_parent &&
                                                list_major_parent.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                        </Form.Item>
                                        <label>Nhóm ngành</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="code"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Code</label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="trainings"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                loading={isLoadingTraining}
                                                bordered={false}
                                                mode="multiple"
                                                allowClear
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {list_training &&
                                                    list_training.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Hệ đào tạo</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="majors"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                mode="multiple"
                                                allowClear
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {list_major &&
                                                    list_major.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].title + ' - ' + o.code}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Ngành</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterSpecialized;
