import { LoadingOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import { useLazyGetPaginateSpecializedsQuery } from "@redux/queries/user/public.api";
import { authSelector } from "@redux/slices/auth.slice";
import { Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SearchForm from "./components/SearchForm";
import SpecializedItem from "./components/SpecializedItem";

const SIZE = 12;
const openProTool = process.env.REACT_APP_FREE_PRO_TOOL;

const SpecializedUser: FC = () => {
    const [triggerSearchSpecialize, resultSpecializes] = useLazyGetPaginateSpecializedsQuery();
    const [searchString, setSearchString] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(SIZE);
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);
    const activeProTool = openProTool || isLogin;

    useEffect(() => {
        const params: any = {
            limit: limit,
            page: page,
            title: searchString,
        };

        if (activeProTool) {
            triggerSearchSpecialize(params);
        }
    }, [page, searchString, limit]);

    return (
        <section id="content-main">
            <Spin
                spinning={!activeProTool}
                indicator={
                    <LoadingOutlined
                        style={{
                            opacity: 0,
                        }}
                        spin
                    />
                }
            >
                <div className="box-component bg-filter">
                    <div className="area-button-mobile">
                        <button
                            type="button"
                            className="btn-blue btn-filter"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                        >
                            <i className="bi-filter"></i> Lọc
                        </button>
                    </div>
                    <div className="body-component filter-form">
                        <h5>Tra cứu chuyên ngành</h5>
                        <SearchForm
                            onSearch={({ searchInput, sortBy }) => {
                                setSearchString(searchInput);
                                setPage(1);
                            }}
                        />
                        <p className="result-p">
                            Chúng tôi tìm thấy <b>{resultSpecializes.data?.meta.totalItems}</b> kết quả cho bộ lọc của
                            bạn.
                            {/* với từ khóa <b>"Trường An Nam"</b>. */}
                        </p>
                    </div>
                </div>
            </Spin>

            <div className="frame-box">
                {activeProTool ? (
                    <div className="order-b">
                        <Spin spinning={resultSpecializes.isLoading || resultSpecializes.isFetching}>
                            <div className="row">
                                {resultSpecializes?.data?.items.map((e, i) => (
                                    <SpecializedItem
                                        data={e}
                                        key={i}
                                    />
                                ))}
                            </div>
                        </Spin>
                        <div className="row">
                            <Pagination
                                onSizeChange={(size) => {
                                    setLimit(size), setPage(1);
                                }}
                                total={resultSpecializes.data?.meta.totalItems || 0}
                                showSize={true}
                                totalPage={resultSpecializes.data?.meta.totalPages || 0}
                                onChangePage={setPage}
                                defaultCurrentPage={1}
                                listSize={[12, 24, 50, 100]}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="m-auto w-50">
                        <h4 className="box-valid-login">Vui Lòng đăng nhập để sử dụng tính năng!</h4>
                    </div>
                )}
            </div>
        </section>
    );
};

export default SpecializedUser;
