import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/report";

export const dashBoardReportApi = createApi({
    reducerPath: "dashBoardReportApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Report"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getCommonReport: builder.query<any, void>({
                query: (query) => ({
                    url: `${BASE}/common`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
        };
    },
});

export const { useGetCommonReportQuery } = dashBoardReportApi;
