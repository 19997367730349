import { LoadingOutlined } from "@ant-design/icons";
import logoSchool from "@assets/images/logo-school.png";
import { Pagination } from "@components/pagination";
import { useLazyServiceSearchQuery, useUserSchoolLikeQuery } from "@redux/queries/user/authaction.api";
import { authSelector } from "@redux/slices/auth.slice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { linkCDN } from "@utils/common";
import { Spin } from "antd";
import { formatDistanceToNowStrict } from "date-fns";
import { FormikProvider, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import FilterServices from "./components/FilterServices";
import SearchSchoolForm, { SORT_BY } from "./components/SearchServiceForm";
import vi from "date-fns/locale/vi";
import { Link } from "react-router-dom";
const SIZE = 12;
const openProTool = process.env.REACT_APP_FREE_PRO_TOOL;

const colors = ["text-box-green", "text-box-red", "text-box-blue"];

//localize
const LANGUAGE: Record<string, string> = {
    EN: "Tiếng Anh",
    CN: "Tiếng Trung",
};
const ListServices: React.FunctionComponent = () => {
    const [triggerSearchService, resultServices] = useLazyServiceSearchQuery();
    const [filter, setFilter] = useState<ServiceFilter>();
    const [searchString, setSearchString] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(SIZE);
    const [sortBy, setSortBy] = useState<SORT_BY>(SORT_BY.rank);
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);
    const activeProTool = openProTool || isLogin;

    const paramsServices = useMemo(() => {
        const params: any = {};
        const listServices = resultServices?.data?.items || [];
        const ids: any = listServices?.map((e) => e.id);
        ids?.map((e: any, i: number) => {
            params[`schoolIds[${i}]`] = e;
        });

        if (ids.length === 0) {
            return undefined;
        }
        return params;
    }, [resultServices?.data]);

    // const { data: likeData } = useUserSchoolLikeQuery(
    //     isLogin ? (paramsServices ? paramsServices : skipToken) : skipToken
    // );

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {},
    });

    const handleChangeFilter = (values: ServiceFilter) => {
        setPage(1);
        setFilter(values);
    };

    useEffect(() => {
        const params: any = {
            limit: limit,
            page: page,
            title: searchString,
            sortBy: sortBy,
            yearFrom: filter?.year_from,
            yearTo: filter?.year_to,
        };

        filter?.area.map((e, i) => {
            params[`areaIds[${i}]`] = e;
        });
        filter?.training.map((e, i) => {
            params[`trainingIds[${i}]`] = e;
        });
        filter?.languageSupport.map((e, i) => {
            params[`languageSupports[${i}]`] = e;
        });
        filter?.scholarshipType.map((e, i) => {
            params[`scholarshipTypeIds[${i}]`] = e;
        });
        filter?.school_project.map((e, i) => {
            params[`projects[${i}]`] = e;
        });
        filter?.school_type.map((e, i) => {
            params[`scholarshipTypeIds[${i}]`] = e;
        });
        filter?.major.map((e, i) => {
            params[`majors[${i}]`] = e;
        });
        filter?.specialized.map((e, i) => {
            params[`specializes[${i}]`] = e;
        });

        if (activeProTool) {
            triggerSearchService(params);
        }
    }, [filter, page, searchString, sortBy, limit, activeProTool]);

    return (
        <section id="content-main">
            <Spin
                spinning={!activeProTool}
                indicator={
                    <LoadingOutlined
                        style={{
                            opacity: 0,
                        }}
                        spin
                    />
                }
            >
                <div className="box-component bg-filter">
                    <div className="area-button-mobile">
                        <button
                            type="button"
                            className="btn-blue btn-filter"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                        >
                            <i className="bi-filter"></i> Lọc
                        </button>
                    </div>
                    <div className="body-component filter-form">
                        <h5>Tra cứu thông tin tuyển sinh</h5>
                        <SearchSchoolForm
                            onSearch={({ searchInput, sortBy }) => {
                                setSearchString(searchInput);
                                setSortBy(sortBy);
                                setPage(1);
                            }}
                        />
                        <p className="result-p">
                            Chúng tôi tìm thấy <b>{resultServices.data?.meta.totalItems}</b> kết quả cho bộ lọc của bạn.
                            {/* với từ khóa <b>"Trường An Nam"</b>. */}
                        </p>
                    </div>
                </div>
            </Spin>

            <div className="frame-box">
                {activeProTool ? (
                    <div className="content-left order-b">
                        <Spin spinning={resultServices.isLoading || resultServices.isFetching}>
                            <div className="row">
                                <div>
                                    {resultServices?.data?.items &&
                                        resultServices?.data?.items?.length > 0 &&
                                        resultServices?.data?.items?.map((item, index) => {
                                            return (
                                                <div
                                                    className="box-filter-item suggest-item bg-suggest"
                                                    key={index}
                                                >
                                                    <div className="row align-items">
                                                        <div className="col-10 col-sm-10">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-4 col-md-3">
                                                                    <div className="box-apply">
                                                                        <img
                                                                            style={{ width: "80px", height: "80px" }}
                                                                            src={linkCDN(
                                                                                item?.school?.logo,
                                                                                logoSchool
                                                                            )}
                                                                            alt="icon-default"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-8 col-md-9">
                                                                    <h5>
                                                                        <Link
                                                                            to={`/pro-tool/service/${item.id}`}
                                                                            className="text-black"
                                                                        >
                                                                            {" "}
                                                                            {item?.language?.[0]?.title ||
                                                                                "(Chưa có tên)"}
                                                                        </Link>
                                                                    </h5>
                                                                    <ul className="mt-2">
                                                                        <li className="h6">
                                                                            Trường: {item?.school?.language[0]?.name}
                                                                        </li>
                                                                        <li className="h6">
                                                                            Học bổng:{" "}
                                                                            {item?.scholarship?.language?.[0]?.title}
                                                                        </li>
                                                                        <li className="h6">
                                                                            Hạn Apply:{" "}
                                                                            {moment(item?.expireApply).format(
                                                                                "DD/MM/YYYY"
                                                                            )}
                                                                        </li>

                                                                        {item?.expireApply &&
                                                                        new Date(item?.expireApply).getTime() >
                                                                            Date.now() ? (
                                                                            <li className="h6">
                                                                                Còn{" "}
                                                                                {Math.ceil(
                                                                                    (new Date(
                                                                                        item?.expireApply
                                                                                    ).getTime() -
                                                                                        new Date().getTime()) /
                                                                                        (1000 * 3600 * 24)
                                                                                )}{" "}
                                                                                ngày để apply
                                                                            </li>
                                                                        ) : (
                                                                            <li className="text-danger h6">
                                                                                Đã hết hạn apply
                                                                            </li>
                                                                        )}
                                                                    </ul>

                                                                    <div className="group-tags">
                                                                        <span
                                                                            className={colors[2]}
                                                                            key={index}
                                                                        >
                                                                            {item?.training?.language[0]?.title}
                                                                        </span>{" "}
                                                                        {item?.languageSupports?.map(
                                                                            (language, index) => {
                                                                                const colorIndex = index % 3;

                                                                                return (
                                                                                    <span
                                                                                        className={colors[colorIndex]}
                                                                                        key={index}
                                                                                    >
                                                                                        {LANGUAGE[language]}
                                                                                    </span>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-2">
                                                            <div className="body-filter-item">
                                                                <ul className="suggest-li">
                                                                    <li>
                                                                        <Link to={`/pro-tool/service/${item.id}`}>
                                                                            Chi tiết
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </Spin>
                        <div className="row">
                            <Pagination
                                onSizeChange={(size) => {
                                    setLimit(size), setPage(1);
                                }}
                                total={resultServices.data?.meta.totalItems || 0}
                                showSize={true}
                                totalPage={resultServices.data?.meta.totalPages || 0}
                                onChangePage={setPage}
                                defaultCurrentPage={1}
                                listSize={[12, 24, 50, 100]}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="content-left order-b">
                        <h4 className="box-valid-login">Vui Lòng đăng nhập để sử dụng tính năng!</h4>
                    </div>
                )}

                <div
                    className="offcanvas offcanvas-end"
                    tabIndex={1}
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="content-right order-a">
                        <Spin
                            spinning={!activeProTool}
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        opacity: 0,
                                    }}
                                    spin
                                />
                            }
                        >
                            <FormikProvider value={formik}>
                                <FilterServices onChange={handleChangeFilter} />
                            </FormikProvider>
                        </Spin>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </section>
    );
};

export default ListServices;
