import React from "react";

interface VideoUI {
    thumbnail: string;
    url: string;
}

interface VideoItemProps {
    item: VideoUI;
    onClick: () => void;
}

const VideoItem: React.FunctionComponent<VideoItemProps> = (props) => {
    const { thumbnail, url } = props.item;
    return (
        <div
            className="col-12 col-sm-12 col-md-12 pd-12"
            onClick={props.onClick}
        >
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modal-video"
            >
                <iframe
                    src={url}
                    title="video"
                    allowFullScreen
                ></iframe>
            </button>
        </div>
    );
};

export default VideoItem;
