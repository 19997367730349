import NumberFormat from "@components/NumberFormat";
import { HSKK_MAX, HSK_MAX } from "@pages/admin/users/user.const";
import { useGetListAreaQuery } from "@redux/queries/admin/admin.area";
import { useGetListScholarshipTypeQuery } from "@redux/queries/admin/admin.scholarship.type";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { divideNumber, multipleNumber } from "@utils/common";
import { FC, memo, useMemo } from "react";

interface ICompleteUserInfo {
    apply: ApplyWelcome | undefined;
    rank: RankWelcome | undefined;
    user: User | undefined;
}

const CompleteUserInfo: FC<ICompleteUserInfo> = ({ apply, rank, user }) => {
    const { applySameTraining, applySameArea, applySameCity } = apply || {};
    const { rankProfile, rankProvince, rankCity } = rank || {};
    const { metadataInit, profile, score, firstName, lastName, username } = user || {};
    const { Gpa, Hsk, Hskk, ielts } = profile || {};
    const { aspirationApply, profileInformation } = metadataInit || {};
    const { training, scholarshipType, area } = aspirationApply || {};
    const { certificate } = profileInformation || {};

    const { data: listTrainings = [] } = useGetListTrainingQuery();
    const { data: listScholarshipTypes = [] } = useGetListScholarshipTypeQuery();
    const { data: listAreas = [] } = useGetListAreaQuery();

    const certificatePoint = useMemo(() => {
        if (!certificate) return 0;

        if (certificate === 0) return 0;
        else if (certificate === 1) return 1.6;
        else if (certificate === 2) return 2;
        else if (certificate === 3) return 2.4;
        else if (certificate === 4) return 3.2;
        else if (certificate >= 5) return 4;
        return 0;
    }, [certificate]);

    const displayUserName = firstName || lastName ? `${firstName} ${lastName}` : username;
    const percentGpa = Number(multipleNumber(divideNumber(Gpa?.mark, Gpa?.coefficients), 1e2));
    const percentHsk = Number(multipleNumber(divideNumber(Hsk?.mark, HSK_MAX), 1e2));
    const percentHskk = Number(multipleNumber(divideNumber(Hskk?.mark, HSKK_MAX), 1e2));
    const percentIelts = Number(multipleNumber(divideNumber(ielts, 9), 1e2));
    const certificatePercent = (certificatePoint / 4) * 100;

    const listTrainingNames = training?.map((item) => {
        const training = listTrainings.find((training) => training.id === item);
        return training?.language?.[0]?.title;
    });

    const listScholarshipTypeNames = scholarshipType?.map((item) => {
        const scholarshipType = listScholarshipTypes.find((scholarshipType) => scholarshipType.id === item);
        return scholarshipType?.language?.[0]?.title;
    });

    const listAreaNames = area?.map((item) => {
        const area = listAreas.find((area) => area.id === item);
        return area?.language?.[0]?.title;
    });

    return (
        <div className="box-component box-profile-complete">
            <div className="row">
                <div className="col-12 col-md-3 order-a">
                    <aside className="box-aside-part">
                        <p id="number-main">{score}</p>
                        <h6 className="text-name">{displayUserName}</h6>

                        <div className="box-progress">
                            <p>
                                <b>
                                    <NumberFormat
                                        decimalScale={0}
                                        value={percentGpa}
                                        suffix="%"
                                    />
                                </b>
                                <b>
                                    GPA: {Gpa?.mark} / {Gpa?.coefficients}
                                </b>
                            </p>
                            <div className="clearfix"></div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow={percentGpa}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{ width: `${percentGpa}%` }}
                                ></div>
                            </div>
                        </div>

                        <div className="box-progress">
                            <p>
                                <b>
                                    <NumberFormat
                                        decimalScale={0}
                                        value={percentHsk}
                                        suffix="%"
                                    />
                                </b>
                                <b>
                                    HSK: {Hsk?.mark || 0} - {HSK_MAX}
                                </b>
                            </p>
                            <div className="clearfix"></div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow={percentHsk}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{ width: `${percentHsk}%` }}
                                ></div>
                            </div>
                        </div>

                        <div className="box-progress">
                            <p>
                                <b>
                                    <NumberFormat
                                        decimalScale={0}
                                        value={percentHskk}
                                        suffix="%"
                                    />
                                </b>
                                <b>
                                    HSKK: {Hskk?.mark || 0} - {HSKK_MAX}
                                </b>
                            </p>
                            <div className="clearfix"></div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow={percentHskk}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{ width: `${percentHskk}%` }}
                                ></div>
                            </div>
                        </div>

                        <div className="box-progress">
                            <p>
                                <b>
                                    <NumberFormat
                                        decimalScale={0}
                                        value={percentIelts}
                                        suffix="%"
                                    />
                                </b>
                                <b>
                                    <NumberFormat
                                        fixedDecimalScale
                                        decimalScale={1}
                                        value={ielts}
                                        prefix="IELTS: "
                                    />
                                </b>
                            </p>
                            <div className="clearfix"></div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow={percentIelts}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{ width: `${percentIelts}%` }}
                                ></div>
                            </div>
                        </div>

                        <div className="box-progress">
                            <p>
                                <b>
                                    <NumberFormat
                                        decimalScale={0}
                                        value={certificatePercent}
                                        suffix="%"
                                    />
                                </b>
                                <b>
                                    <NumberFormat
                                        decimalScale={1}
                                        value={certificate}
                                        prefix="Hoạt động: "
                                    />
                                </b>
                            </p>
                            <div className="clearfix"></div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow={certificatePercent}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{ width: `${certificatePercent}%` }}
                                ></div>
                            </div>
                        </div>
                    </aside>
                </div>
                <div className="col-12 col-md-9 order-b">
                    <p className="sub-p">
                        {listScholarshipTypeNames?.map((item, index) => (
                            <span
                                key={index}
                                className="text-box-green"
                            >
                                {item}
                            </span>
                        ))}

                        {listTrainingNames?.map((item, index) => (
                            <span
                                key={index}
                                className="text-box-gold"
                            >
                                {item}
                            </span>
                        ))}

                        {listAreaNames?.map((item, index) => (
                            <span
                                key={index}
                                className="text-box-blue"
                            >
                                {item}
                            </span>
                        ))}
                    </p>

                    <div className="main-profile-info">
                        <h3>Thông tin hồ sơ</h3>
                        <h2>{score}</h2>
                        {/* //TODO group star */}
                        {/* <div className="group-star">
                            <i className="bi-star-fill active"></i>
                            <i className="bi-star-fill active"></i>
                            <i className="bi-star-fill active"></i>
                            <i className="bi-star-fill"></i>
                            <i className="bi-star-fill"></i>
                        </div> */}

                        <div className="row box-range-p">
                            <div className="col-4">
                                <p>
                                    <b className="text-green">#{rankProfile}</b>
                                    Xếp hạng hồ sơ
                                </p>
                            </div>
                            <div className="col-4">
                                <p>
                                    <b className="text-gold">#{rankProvince}</b>
                                    Xếp hạng Tỉnh
                                </p>
                            </div>
                            <div className="col-4">
                                <p>
                                    <b className="text-red">#{rankCity}</b>
                                    Xếp hạng Thành phố
                                </p>
                            </div>
                        </div>

                        <div className="row box-range-p mt-4">
                            <div className="col-4">
                                <div className="border-radius bg-green">
                                    <p className="box-fill-p">
                                        Apply cùng hệ
                                        <b>{applySameTraining}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="border-radius  bg-gold">
                                    <p className="box-fill-p">
                                        Apply cùng khu vực
                                        <b>{applySameArea}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="border-radius bg-red">
                                    <p className="box-fill-p">
                                        Apply cùng Thành phố
                                        <b>{applySameCity}</b>
                                    </p>
                                </div>
                            </div>
                            {/* <div className="col-12 col-sm-4">
                                <div className="bg-blue">
                                    <p className="box-fill-p">
                                        Apply cùng hệ
                                        <b>300</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4">
                                <p className="bg-dark box-fill-p">
                                    Apply cùng khu vực
                                    <b>68</b>
                                </p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="bg-warning">
                                    <p className="box-fill-p">
                                        Apply cùng Thành phố
                                        <b>15</b>
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(CompleteUserInfo);
