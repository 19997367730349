import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useDeleteMultiRoleMutation,
    useDeleteRoleMutation,
    useGetListRolePaginateQuery,
} from "@redux/queries/admin/admin.role";
import { Avatar, Button, Modal, Space, Table, Tag, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Filter from "./components/Filter";
import HandleForm from "./components/HanleForm";
import Title from "antd/es/typography/Title";
import { listUserRole } from "../users/user.const";

const { Text } = Typography;

const Role: React.FunctionComponent = () => {
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching } = useGetListRolePaginateQuery(query as any);

    const [deleteRole, isLoadingDelete] = useDeleteRoleMutation();
    const [deleteMultiRole, isLoadingMultiDelete] = useDeleteMultiRoleMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteRole({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiRole({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Tên</div>,
            key: "name",
            dataIndex: "name",
        },
        {
            title: <div className="text-title-table">Mô tả</div>,
            key: "description",
            dataIndex: "description",
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const permissionColumns = [
        {
            title: <div className="text-title-table">Mục</div>,
            key: "resource",
            dataIndex: "resource",
        },
        {
            title: <div className="text-title-table">Mô tả</div>,
            key: "description",
            dataIndex: "description",
        },
    ];

    const userColumns = [
        {
            title: <div className="text-title-table">Tên</div>,
            key: "firstName",
            dataIndex: "firstName",
            render: (firstName: any, record: any) => {
                return (
                    <Space>
                        <Avatar src={record?.avatar}>{record?.firstName?.charAt(0)}</Avatar>
                        <Text>{record?.firstName + " - " + record?.lastName || ""}</Text>
                    </Space>
                );
            },
        },
        {
            title: <div className="text-title-table">Ngày sinh</div>,
            key: "birthday",
            dataIndex: "birthday",
            render: (birthday: any) => {
                return birthday ? moment(birthday).format("DD/MM/YYYY") : "";
            },
        },
        {
            title: <div className="text-title-table">Tuổi</div>,
            key: "birthday",
            dataIndex: "birthday",
            render: (birthday: any) => {
                return birthday ? moment().diff(birthday, "years") : "";
            },
        },
        {
            title: <div className="text-title-table">Mã</div>,
            key: "code",
            dataIndex: "code",
        },
        {
            title: <div className="text-title-table">Email</div>,
            key: "email",
            dataIndex: "email",
        },
        {
            title: <div className="text-title-table">Số điện thoại</div>,
            key: "phone",
            dataIndex: "phone"
        },
        {
            title: <div className="text-title-table">Role</div>,
            key: "role",
            dataIndex: "user",
            render: (role: any) => {
                const userRole = listUserRole?.find((o: any) => o.value === role);
                return <Tag color={userRole?.color}>{userRole?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };

    console.log("data", data?.items);
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <Filter
                                        data={filter}
                                        onSearch={(v: any) => setFilter(v)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                    expandable={{
                                        expandedRowRender: (e) => {
                                            return (
                                                <>
                                                    <Title level={5}>Quyền</Title>
                                                    <Table
                                                        columns={permissionColumns}
                                                        dataSource={(e?.permissions as any) || []}
                                                        pagination={false}
                                                        loading={isLoading || isFetching}
                                                        scroll={{ x: 1500 }}
                                                    />
                                                    <Title level={5}>Nhân viên</Title>
                                                    <Table
                                                        columns={userColumns}
                                                        dataSource={(e?.users as any) || []}
                                                        pagination={false}
                                                        loading={isLoading || isFetching}
                                                        scroll={{ x: 1500 }}
                                                    />
                                                </>
                                            );
                                        },
                                    }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default Role;
