import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

const BASE = "/api/scholarship/type";

export const adminScholarshipTypeApi = createApi({
    reducerPath: "adminScholarshipTypeApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["ScholarshipType"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListScholarshipType: builder.query<Array<IScholarshipType>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListScholarshipTypePaginate: builder.query<
                IResponseDataAdmin<IScholarshipType>,
                { page: number; limit: number }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: {
                        page: query.page,
                        limit: query.limit,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "ScholarshipType" as const, id })),
                              { type: "ScholarshipType", id: "ScholarshipType-LIST" },
                          ]
                        : [{ type: "ScholarshipType", id: "ScholarshipType-LIST" }],
            }),
            createScholarshipType: builder.mutation<any, IAdminScholarshipTypeInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: {
                        language: params.language,
                        lastPointPass: params.lastPointPass,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ScholarshipType"],
            }),
            updateScholarshipType: builder.mutation<any, IAdminScholarshipTypeInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        language: params.language,
                        lastPointPass: params.lastPointPass,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ScholarshipType"],
            }),
            deleteScholarshipType: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ScholarshipType"],
            }),
            deleteMultiScholarshipType: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ScholarshipType"],
            }),
        };
    },
});

export const {
    useGetListScholarshipTypeQuery,
    useGetListScholarshipTypePaginateQuery,
    useLazyGetListScholarshipTypePaginateQuery,
    useCreateScholarshipTypeMutation,
    useUpdateScholarshipTypeMutation,
    useDeleteScholarshipTypeMutation,
    useDeleteMultiScholarshipTypeMutation,
} = adminScholarshipTypeApi;
