import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import ShortPost from "@pages/pro-tool/schools/DetailSchool/components/TabRelatedPost/ShortPost";
import {
    useDeleteMultiSchoolNewsMutation,
    useDeleteSchoolNewsMutation,
    useGetListSchoolNewsPaginateQuery,
    useGetSchoolNewsByIdQuery,
} from "@redux/queries/admin/admin.school.news";
import { history } from "@utils/helper-router";
import { Button, Divider, Modal, Table, Tabs, Tag, Typography } from "antd";
import Title from "antd/es/typography/Title";
import "jodit";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import FilterSchoolNews from "./components/FilterSchoolNews";

const { Text } = Typography;

const listStatusNews = [
    {
        value: 0,
        label: "INACTIVE",
    },
    {
        value: 1,
        label: "ACTIVE",
    },
];

const SchoolNews: React.FunctionComponent = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [detailId, setDetailID] = useState<string | null>(null);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});
    const [activeKey, setActiveKey] = useState("titleVi");

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching } = useGetListSchoolNewsPaginateQuery(query as any);

    const { data: detail } = useGetSchoolNewsByIdQuery({
        id: detailId || "",
    });

    const [deleteSchoolNews, isLoadingDelete] = useDeleteSchoolNewsMutation();
    const [deleteMultiSchoolNews, isLoadingMultiDelete] = useDeleteMultiSchoolNewsMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteSchoolNews({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiSchoolNews({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Trường</div>,
            key: "schoolId",
            dataIndex: "school",
            render: (school: IAdminSchool, record: any) => {
                return school?.language[0]?.name || "";
            },
        },
        {
            title: <div className="text-title-table">Danh mục</div>,
            key: "categoryId",
            dataIndex: "category",
            render: (category: ISchoolCategory, record: any) => {
                return category?.language[0]?.title || "";
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Việt</div>,
            dataIndex: "language",
            key: "titleVn",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "vi");
                return lang?.title;
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Anh</div>,
            dataIndex: "language",
            key: "titleEn",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "en");
                return lang?.title;
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Trung</div>,
            dataIndex: "language",
            key: "titleCn",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "cn");
                return lang?.title;
            },
        },
        {
            title: <div className="text-title-table">Tags</div>,
            dataIndex: "tags",
            key: "tags",
            render: (tags: string[]) => {
                return tags?.map((item: string, index) => <Tag key={index}>{item}</Tag>);
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            dataIndex: "status",
            key: "status",
            render: (status: number) => {
                const statusItem = listStatusNews.find((item) => item.value === status);
                return <Tag color={statusItem?.value === 1 ? "green" : "red"}>{statusItem?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-gray"
                            onClick={() => handleViewDetail(record.id)}
                        >
                            <i className="bi-eye"></i>
                        </button>
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                history.navigate(`/admin/school-news/edit/${record.id}`);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterSchoolNews
                                        data={filter}
                                        onSearch={(value: any) => setFilter(value)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => history.navigate("/admin/school-news/add")}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                    expandable={{
                                        expandedRowRender: (e) => {
                                            return (
                                                <ShortPost
                                                    id={e?.id}
                                                    category={
                                                        e?.category?.language?.find((item: any) => item.lang === "vi")
                                                            ?.title || ""
                                                    }
                                                    title={
                                                        e?.language?.find((item: any) => item.lang === "vi")?.title ||
                                                        ""
                                                    }
                                                    username={
                                                        (e?.creator?.firstName + e?.creator?.firstName).length === 0
                                                            ? e?.creator?.userName
                                                            : e?.creator?.firstName + e?.creator?.firstName
                                                    }
                                                    avatar={""}
                                                    time={e.createdAt}
                                                    content={
                                                        e?.language?.find((item: any) => item.lang === "vi")
                                                            ?.shortContent || ""
                                                    }
                                                    key={e.id}
                                                    like={e?.like}
                                                    comment={e?.like}
                                                    save={e?.like}
                                                    thumbnail={e?.thumbnail}
                                                />
                                            );
                                        },
                                    }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {contextHolder}
            <Modal
                title="Xem trước tin tức"
                open={!!detailId}
                onCancel={() => {
                    setDetailID(null);
                    setActiveKey("titleVi");
                }}
                footer={null}
                width={800}
            >
                <Tabs
                    onChange={onChange}
                    activeKey={activeKey}
                    items={[
                        {
                            label: "Tiếng Việt",
                            children: (
                                <div className="news-detail">
                                    <Title level={5}>{detail?.language[0]?.title}</Title>
                                    <Divider />
                                    <Title level={5}>{detail?.language[0]?.shortContent}</Title>
                                    <Divider />
                                    <ReactQuill
                                        className="text-editor"
                                        value={detail?.language[0]?.content}
                                        readOnly={true}
                                    />
                                </div>
                            ),
                            key: "titleVi",
                            closable: false,
                        },
                        {
                            label: "English ",
                            children: (
                                <div className="news-detail">
                                    <Title level={5}>{detail?.language[1]?.title}</Title>
                                    <Divider />
                                    <Title level={5}>{detail?.language[0]?.shortContent}</Title>
                                    <Divider />
                                    <ReactQuill
                                        className="text-editor"
                                        value={detail?.language[1]?.content}
                                        readOnly={true}
                                    />
                                </div>
                            ),
                            key: "titleEn",
                            closable: false,
                        },
                        {
                            label: "中国人",
                            children: (
                                <div className="news-detail">
                                    <Title level={5}>{detail?.language[2]?.title}</Title>
                                    <Divider />
                                    <Title level={5}>{detail?.language[0]?.shortContent}</Title>
                                    <Divider />
                                    <ReactQuill
                                        className="text-editor"
                                        value={detail?.language[2]?.content}
                                        readOnly={true}
                                    />
                                </div>
                            ),
                            key: "titleCn",
                            closable: false,
                        },
                    ]}
                />
            </Modal>
        </div>
    );
};

export default SchoolNews;
