export enum DepositStatus {
    PENDING = 'PENDING',
    CANCEL = 'CANCEL',
    REJECT = 'REJECT',
    DONE = 'DONE',
}

export const DepositAmount = [100000, 200000, 500000, 1000000, 1500000, 2000000];

export const listDepositStatus = [
    { value: DepositStatus.PENDING, label: 'Pending', color: 'yellow'},
    { value: DepositStatus.CANCEL, label: 'Cancel', color: 'red'},
    { value: DepositStatus.REJECT, label: 'Reject', color: 'orange'},
    { value: DepositStatus.DONE, label: 'Done', color: 'green'},
];