import { Pagination } from "@components/pagination";
import { useListBlogQuery } from "@redux/queries/user/blog.api";
import { Spin } from "antd";
import { FC, useState } from "react";
import Post from "./Post";
import Blog from "@pages/dashboard/UserDashboard/components/Blog";
import { authSelector } from "@redux/slices/auth.slice";
import { useSelector } from "react-redux";
import Nodata from "@components/Nodata";

const LIMIT = 6;

interface ListPostProps {
    handleClickEditBlog?: (blog: UserBlog | SchoolNews) => void;
}

const ListPost: FC<ListPostProps> = ({ handleClickEditBlog }) => {
    const { user } = useSelector(authSelector);

    const [page, setPage] = useState(1);
    const { data, isLoading } = useListBlogQuery({
        page: page,
        limit: LIMIT,
    });
    const userName = user?.firstName ? `${user?.firstName} ${user?.lastName}` : user?.username;

    return (
        <Spin spinning={isLoading}>
            <div className="row profile_private">
                {data?.meta?.totalItems && data?.meta?.totalItems > 0 ? (
                    data?.items.map((item, i) => (
                        <Post
                            displayEditDelete
                            handleClickEditBlog={handleClickEditBlog}
                            data={item}
                            title={item?.title}
                            shortContent={item?.shortContent}
                            key={i}
                            link={`/blogs/${item?.id}`}
                        />
                        // <div
                        //     className="col-12 col-lg-6 col-xxl-4"
                        //     key={i}
                        // >
                        //     <Blog
                        //         viewMode="card"
                        //         title={item?.title}
                        //         shortContent={item?.shortContent}
                        //         linkType={"blogs"}
                        //         id={item?.id}
                        //         createdAt={item?.createdAt}
                        //         slug={item?.slug}
                        //         thumbnail={item?.thumbnail}
                        //         view={item?.view}
                        //         like={item?.like}
                        //         userName={userName || ""}
                        //         avatar={user?.avatar}
                        //     />
                        // </div>
                    ))
                ) : (
                    <Nodata />
                )}

                {(data?.meta?.totalItems || 0) > 0 && (
                    <div className="d-flex justify-content-center mt-4">
                        <Pagination
                            defaultSize={LIMIT}
                            onSizeChange={(size) => {}}
                            total={data?.meta.totalItems || 0}
                            showSize={false}
                            totalPage={data?.meta.totalPages || 0}
                            onChangePage={setPage}
                            defaultCurrentPage={1}
                            listSize={[12, 24, 50, 100]}
                        />
                    </div>
                )}
            </div>
        </Spin>
    );
};

export default ListPost;
