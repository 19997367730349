import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useCreateApplyMutation,
    useCreatePaymentMutation,
    useLazyGetApplyByIdQuery,
    useUpdateApplyMutation,
} from "@redux/queries/admin/admin.apply";
import { useCreateApplyProcessMutation, useUpdateApplyProcessMutation } from "@redux/queries/admin/admin.apply.process";
import { useLazyGetListSchoolScholarshipTrainingQuery } from "@redux/queries/admin/admin.school.scholarship.training";
import { useLazyScholarshipBySchoolQuery } from "@redux/queries/user/public.api";
import { history } from "@utils/helper-router";
import { Button, Card, Col, Form, Modal, Row, Space, Tag, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContractTab from "../components/ContractTab";
import GeneralTab from "../components/GeneralTab";
import StepTab from "../components/StepTab";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import { useGetAllContractUsersQuery } from "@redux/queries/user/contractUser.api";
import ListContract from "../components/ListContract";
import { typeContractUser } from "../apply.enum";

export interface FormSchoolValues {
    title_vi?: string;
    title_en?: string;
    title_cn?: string;
    linkDetail?: string;
    rank?: number;
    code: string;
    projectIds?: string;
    schoolTypeId?: string;
}

const Create: React.FunctionComponent = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [createApply, result] = useCreateApplyMutation();
    const [updateApply, resultUpdate] = useUpdateApplyMutation();
    const [createApplyProcess, isLoadingCreate] = useCreateApplyProcessMutation();
    const [updateApplyProcess, isLoadingUpdate] = useUpdateApplyProcessMutation();
    const { showToast } = useToast();
    const location = useLocation();
    const id = location.pathname.split("/")[4];

    const [listContract, setListContract] = useState([]);
    const [radio, setRadio] = useState<number>(1);
    const [userId, setUserId] = useState();
    const [schoolServiceTrainingId, setSchoolServiceTrainingId] = useState();

    // console.log(userId);
    // console.log(schoolServiceTrainingId);

    const onChange = (e: RadioChangeEvent) => {
        setRadio(e.target.value);
    };

    const [getListSchoolScholarshipTraining, { data: dataSchoolScholarshipTraining }] =
        useLazyGetListSchoolScholarshipTrainingQuery();
    const [getApplyById, { data: dataEdit, isLoading }] = useLazyGetApplyByIdQuery();
    const [createPayment] = useCreatePaymentMutation();
    const [getSchoolScholarship, { data }] = useLazyScholarshipBySchoolQuery();

    const { data: listContractUser } = useGetAllContractUsersQuery({
        userId: userId,
        schoolServiceTrainingId: schoolServiceTrainingId,
    });

    useEffect(() => {
        if (id) {
            getApplyById({ id });
        }
    }, [id]);

    useEffect(() => {
        if (dataEdit) {
            const payments = dataEdit.contract?.payments?.map((item: any) => {
                return {
                    amount: item.amount,
                    date: item.date,
                    expiredAt: item.expiredAt,
                    note: item.note,
                    status: item.status,
                    id: item.id,
                };
            });
            form.setFieldsValue({
                ...dataEdit,
                payments,
                schoolId: dataEdit.school.id,
                schoolScholarshipTrainingId: dataEdit.schoolScholarshipTraining.id,
                contractMethod: dataEdit?.contract?.contractMethod,
                address: dataEdit?.contract?.address,
                aspirations: dataEdit?.contract?.aspirations,
                scholarshipTypes: dataEdit?.contract?.scholarshipTypes,
                hskPlace: dataEdit?.contract?.hskPlace,
                contact: dataEdit?.contract?.contact,
                discount: dataEdit?.contract?.discount,
                schoolServiceId: dataEdit?.contract?.schoolService?.id,
                userId: dataEdit?.user?.id,
                trainingId: dataEdit?.schoolScholarshipTraining?.training?.id,
                scholarshipId: dataEdit?.schoolScholarshipTraining?.schoolScholarship?.scholarshipId,
                schoolServiceTrainingId: (dataEdit?.contract as any)?.contract?.schoolServiceTrainingId,
                grantIds: (dataEdit as any)?.userGrants?.map((item: any) => item?.grantId),
            });
        }
    }, [dataEdit]);

    const [form] = Form.useForm();

    const handleSubmit = async () => {
        let value: any;
        try {
            value = await form.validateFields();
        } catch (errorInfo) {
            return;
        }

        const { data: schoolScholarshipTraining } = await getListSchoolScholarshipTraining({
            page: 1,
            limit: 10,
            schoolId: value.schoolId,
            scholarshipId: value.scholarshipId,
            trainingId: value.trainingId,
        });

        const schoolScholarshipTrainingItem =
            schoolScholarshipTraining &&
            schoolScholarshipTraining.find((item: any) => {
                return (
                    item.school.id === value.schoolId &&
                    item.training.id === value.trainingId &&
                    item.schoolScholarship.scholarshipId === value.scholarshipId
                );
            });

        const listCreateApplyProcess = [] as any[];
        const listUpdateApplyProcess = [] as any[];
        Object.keys(value.steps || {}).forEach((key) => {
            const applyStepId = key;
            const { processes } = value.steps[key];
            processes.forEach((item: any) => {
                const { applyTaskId, expiredAt, id } = item;
                if (applyTaskId) {
                    if (id) {
                        listUpdateApplyProcess.push({
                            ...item,
                            applyStepId,
                            applyTaskId,
                            expiredAt: moment(expiredAt).toISOString(),
                            grants: item.grants.map((grant: any) => {
                                return {
                                    ...grant,
                                    expiredAt: moment(grant.expiredAt).toISOString(),
                                };
                            }),
                        });
                    } else {
                        listCreateApplyProcess.push({
                            ...item,
                            applyStepId,
                            applyTaskId,
                            expiredAt: moment(expiredAt).toISOString(),
                            grants: item.grants.map((grant: any) => {
                                return {
                                    ...grant,
                                    expiredAt: moment(grant.expiredAt).toISOString(),
                                };
                            }),
                        });
                    }
                }
            });
        });
        await Promise.all(
            listCreateApplyProcess.map((item: any) => {
                return createApplyProcess({
                    ...item,
                });
            })
        );

        await Promise.all(
            listUpdateApplyProcess.map((item: any) => {
                return updateApplyProcess({
                    ...item,
                });
            })
        );

        const payments = value.payments?.map((item: any) => {
            return {
                ...item,
                amount: Number(item.amount),
                date: moment(item.date).toISOString(),
                expiredAt: moment(item.expiredAt).toISOString(),
                note: item.note,
            };
        });
        if (dataEdit && dataEdit.id) {
            const listCreatePayment = payments && payments?.filter((item: any) => !item.id);
            await Promise.all(
                listCreatePayment
                    ? listCreatePayment?.map((item: any) => {
                          return createPayment({
                              ...item,
                              applyId: dataEdit.id,
                          });
                      })
                    : []
            );
        }
        const contract =
            radio === typeContractUser.NEWCONTRACT
                ? _.pickBy(
                      {
                          schoolServiceTrainingId: value.schoolServiceTrainingId,
                          discount: value.discount ? Number(value.discount) : null,
                          contractMethod: value.contractMethod,
                          address: value.address,
                          aspirations: value.aspirations,
                          scholarshipTypes: value.scholarshipTypes,
                          hskPlace: value.hskPlace,
                          contact: value.contact,
                          payments,
                      },
                      (item) => item !== undefined && item !== null && item !== ""
                  )
                : radio === typeContractUser.RENEWCONTRACT
                ? _.pickBy(
                      {
                          contractId: listContract,
                        //   schoolServiceTrainingId: value.schoolServiceTrainingId,
                          discount: value.discount ? Number(value.discount) : null,
                          contractMethod: value.contractMethod,
                          address: value.address,
                          aspirations: value.aspirations,
                          scholarshipTypes: value.scholarshipTypes,
                          hskPlace: value.hskPlace,
                          contact: value.contact,
                          payments,
                      },
                      (item) => item !== undefined && item !== null
                  )
                : undefined;

        if (contract === undefined) {
            TOAST_UPDATE_ERROR.message = "Bạn chưa tạo hợp đồng!";
            showToast({ ...TOAST_UPDATE_ERROR });
            return;
        }

        const data = _.pickBy(
            {
                userId: value.userId,
                schoolScholarshipTrainingId: schoolScholarshipTrainingItem.id,
                specializeds: value.specializeds,
                year: Number(value.year),
                period: Number(value.period),
                majorId: value.majorId,
                schoolId: value.schoolId,
                storageUri: value.storageUri,
                createdBy: value.createdBy,
                contract,
                grantIds: value.grantIds,
            },
            (item) => item !== undefined && item !== null && item !== ""
        );

        const result = id
            ? await updateApply({
                  ...data,
                  id,
              } as any)
            : await createApply(data as any);
        if ("error" in result) {
            id ? showToast({ ...TOAST_UPDATE_ERROR }) : showToast({ ...TOAST_CREATE_ERROR });
        }

        if ("data" in result) {
            id ? showToast({ ...TOAST_UPDATE_SUCCESS }) : showToast({ ...TOAST_CREATE_SUCCESS });
            history.navigate("/admin/apply");
        }
    };

    return (
        <>
            {contextHolder}
            <div className="container-fluid padding0">
                <span className="screen-darken"></span>
                <main>
                    <div>
                        <section id="content-main">
                            <Form
                                form={form}
                                scrollToFirstError
                            >
                                <div className="box-component">
                                    <div>
                                        <Card
                                            title="Thông tin chung"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                            loading={isLoading}
                                            extra={
                                                <Space>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        Submit
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            history.navigate("/admin/apply");
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Space>
                                            }
                                        >
                                            <GeneralTab
                                                form={form}
                                                dataEdit={dataEdit}
                                                setUserId={(idUser: any) => setUserId(idUser)}
                                                setSchoolServiceTrainingId={(idSchool: any) =>
                                                    setSchoolServiceTrainingId(idSchool)
                                                }
                                            />
                                        </Card>
                                    </div>
                                    <div>
                                        <Card
                                            title={`Thông tin hợp đồng`}
                                            extra={
                                                (dataEdit?.contract as any)?.contract?.contractNumber && (
                                                    <Tag color="red">
                                                        Mã hợp đồng:
                                                        {(dataEdit?.contract as any)?.contract?.contractNumber}
                                                    </Tag>
                                                )
                                            }
                                            bordered={false}
                                            style={{ width: "100%" }}
                                            loading={isLoading}
                                        >
                                            {dataEdit ? (
                                                <ContractTab
                                                    form={form}
                                                    modal={modal}
                                                />
                                            ) : (
                                                <>
                                                    <Radio.Group
                                                        onChange={onChange}
                                                        value={radio}
                                                        className="gap-5 d-flex justify-content-center mb-4"
                                                    >
                                                        <Radio value={typeContractUser.NEWCONTRACT}>
                                                            <Typography.Title
                                                                level={5}
                                                                className="mb-0"
                                                            >
                                                                Tạo hợp đồng mới
                                                            </Typography.Title>
                                                        </Radio>
                                                        <Radio value={typeContractUser.RENEWCONTRACT}>
                                                            <Typography.Title
                                                                level={5}
                                                                className="mb-0"
                                                            >
                                                                Sử dụng hợp đồng đã có
                                                            </Typography.Title>
                                                        </Radio>
                                                    </Radio.Group>
                                                    {radio === typeContractUser.NEWCONTRACT ? (
                                                        <ContractTab
                                                            form={form}
                                                            modal={modal}
                                                        />
                                                    ) : radio === typeContractUser.RENEWCONTRACT ? (
                                                        <>
                                                            <ListContract
                                                                data={listContractUser}
                                                                setListContract={(data: any) => setListContract(data)}
                                                            />
                                                            <ContractTab
                                                                form={form}
                                                                modal={modal}
                                                            />
                                                        </>
                                                    ) : null}
                                                </>
                                            )}
                                        </Card>
                                    </div>
                                    {dataEdit && (
                                        <div>
                                            <Card
                                                title="Quy trình xử lý hồ sơ"
                                                bordered={false}
                                                style={{ width: "100%" }}
                                                loading={isLoading}
                                            >
                                                <StepTab
                                                    form={form}
                                                    data={dataEdit?.applySteps}
                                                />
                                            </Card>
                                        </div>
                                    )}
                                    <Row
                                        justify="center"
                                        gutter={2}
                                    >
                                        <Space>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        history.navigate("/admin/apply");
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Form.Item>
                                        </Space>
                                    </Row>
                                </div>
                            </Form>
                        </section>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Create;
