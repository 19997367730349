import { FC, memo } from "react";
import Img01 from "@assets/images/img-01.jpg";
import { linkCDN } from "@utils/common";
import { Link } from "react-router-dom";

interface CardNewsProps {
    news: SchoolNews;
}

const CardNews: FC<CardNewsProps> = ({ news }) => {
    const { language, id, thumbnail, like, category, creator } = news || {};
    const userName = creator?.firstName ? `${creator?.firstName} ${creator?.lastName}` : creator?.userName;

    return (
        <div className="card card-article article-mobile">
            <div className="row">
                <div className="col-5 col-md-4">
                    <figure className="snap">
                        <img
                            className="img-fluid"
                            src={linkCDN(thumbnail, Img01)}
                        />
                        <figcaption>
                            <h5>Xem chi tiết</h5>
                        </figcaption>
                        <Link
                            title="Xem chi tiết"
                            to={`/news/${id}`}
                        ></Link>
                    </figure>
                </div>
                <div className="col-7 col-md-8">
                    <div className="card-body">
                        <h6>
                            <span>{category?.language?.[0]?.title}</span>
                        </h6>
                        <h5>
                            <Link to={`/news/${id}`}>{language?.[0]?.title}</Link>
                        </h5>
                        <ul>
                            <li>
                                <i className="bi-person"></i> {userName}
                            </li>
                            <li>
                                <i className="bi-hand-thumbs-up"></i> {like} Likes
                            </li>
                        </ul>
                        <p>{language?.[0]?.shortContent}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(CardNews);
