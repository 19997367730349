export const listTypePayment = [
    {
        value: "INCOME",
        label: "Thu nhập",
        color: "green",
    },
    {
        value: "EXPENSE",
        label: "Chi phí",
        color: "red",
    },
];

export const listSourcePayment = [
    {
        value: "DEPOSIT",
        label: "Nạp tiền",
        color: "green",
    },
    {
        value: "CONTRACT",
        label: "Hợp đồng",
        color: "blue",
    },
    {
        value: "WITHDRAW",
        label: "Rút tiền",
        color: "orange",
    },
    {
        value: "REFUND",
        label: "Hoàn tiền",
        color: "red",
    },
    {
        value: "TRANSFER",
        label: "Chuyển khoản",
        color: "violet",
    },
    {
        value: "OTHER",
        label: "Khác",
        color: "purple",
    },
];

export const listFundPayment = [
    {
        value: "CASH",
        label: "Tiền mặt",
        color: "green",
    },
    {
        value: "ONLINE",
        label: "Chuyển khoản",
        color: "blue",
    },
];

export const listStatusPayment = [
    {
        value: "PENDING",
        label: "Chờ xử lý",
        color: "orange",
    },
    {
        value: "DONE",
        label: "Thành công",
        color: "green",
    },
    {
        value: "REJECTED",
        label: "Từ chối",
        color: "red",
    },
];