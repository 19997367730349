import { HSK, HSKK } from "@consts";

export const purposeOfUse = [
    {
        id: 1,
        language: [
            {
                lang: "vi",
                content: "Tìm thông tin học bổng",
            },
            {
                lang: "en",
                content: "Find scholarship information",
            },
            {
                lang: "cn",
                content: "寻找奖学金信息",
            },
        ],
    },
    {
        id: 2,
        language: [
            {
                lang: "vi",
                content: "Tìm thông tin trường học",
            },
            {
                lang: "en",
                content: "Find school information",
            },
            {
                lang: "cn",
                content: "寻找学校信息",
            },
        ],
    },
    {
        id: 3,
        language: [
            {
                lang: "vi",
                content: "Tìm thông tin chuyên ngành",
            },
            {
                lang: "en",
                content: "Find information about majors",
            },
            {
                lang: "cn",
                content: "寻找专业信息",
            },
        ],
    },
    {
        id: 4,
        language: [
            {
                lang: "vi",
                content: "Học hỏi kinh nghiệm",
            },
            {
                lang: "en",
                content: "Learn experience",
            },
            {
                lang: "cn",
                content: "学习经验",
            },
        ],
    },
    {
        id: 5,
        language: [
            {
                lang: "vi",
                content: "Xem cho biết",
            },
            {
                lang: "en",
                content: "See for yourself",
            },
            {
                lang: "cn",
                content: "看看自己",
            },
        ],
    },
];

export const initWelcomeUser: InputWelcomeUser = {
    purposeOfUseId: null,
    profileInformation: null,
    aspirationApply: null,
};

export const HSKOptions = [
    {
        label: HSK.HSK1,
        value: HSK.HSK1,
    },
    {
        label: HSK.HSK2,
        value: HSK.HSK2,
    },
    {
        label: HSK.HSK3,
        value: HSK.HSK3,
    },
    {
        label: HSK.HSK4,
        value: HSK.HSK4,
    },
    {
        label: HSK.HSK5,
        value: HSK.HSK5,
    },
    {
        label: HSK.HSK6,
        value: HSK.HSK6,
    },
];

export const HSKKOptions = [
    {
        label: "BEGINNER",
        value: HSKK.HSKK_BEGINNER,
    },
    {
        label: "INTERMEDIATE",
        value: HSKK.HSKK_INTERMEDIATE,
    },
    {
        label: "ADVANCED",
        value: HSKK.HSKK_ADVANCED,
    },
];

export const GPACoefficientsOptions = [
    {
        label: 4,
        value: 4,
    },
    {
        label: 10,
        value: 10,
    },
];
