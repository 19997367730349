import { Button, Form, Input, Modal, Select } from "antd";
import { useCreateSchoolReviewMutation, useUpdateSchoolReviewMutation } from "@redux/queries/admin/admin.school.review";
import { useEffect } from "react";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createSchoolReview, isLoadingCreate] = useCreateSchoolReviewMutation();
    const [updateSchoolReview, isLoadingUpdate] = useUpdateSchoolReviewMutation();
    const { showToast } = useToast();
    const { data: list_school, isLoading, isFetching } = useGetListSchoolQuery();

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue({
                schoolId: dataEdit.school.id,
                ...dataEdit
            });
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updateSchoolReview({
                  id: dataEdit.id,
                  ...values,
                  rating: parseInt(values.rating),
              })
            : await createSchoolReview({
                  ...values,
                  rating: parseInt(values.rating),
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update School Review" : "Add School Review"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="schoolId"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {list_school &&
                                        list_school.map((o: any, i: any) => {
                                            return (
                                                <Select.Option
                                                    key={o.id}
                                                    value={o.id}
                                                >
                                                    {o.language[0].name}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <label>Trường</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="rating"
                                className="form-floating"
                                rules={[
                                    {
                                        pattern: /^[1-5]$/,
                                        message: "Chỉ nhập số từ 1 đến 5",
                                    },
                                    {
                                        required: true,
                                        message: "Không được để trống",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Rating</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="content"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Không được để trống",
                                    },
                                ]}
                            >
                                <Input.TextArea style={{ height: 100 }} />
                            </Form.Item>
                            <label>Nội dung</label>
                        </div>
                    </div>
                    <Form.List
                        name="tags"
                        initialValue={[""]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div
                                        key={field.key}
                                        className="space-medias-tab"
                                    >
                                        <div
                                            className="form-group"
                                            style={{
                                                width: "90%",
                                                display: "inline-block",
                                            }}
                                        >
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name]}
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="no-icon form-floating"
                                                        style={{ width: "100%" }}
                                                    />
                                                </Form.Item>
                                                <label>Tag</label>
                                            </div>
                                        </div>
                                        {fields?.length > 0 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                style={{
                                                    width: "10%",
                                                    display: "inline-block",
                                                }}
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{ width: "100%" }}
                                        icon={<PlusOutlined />}
                                    >
                                        Thêm tag
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
