import Nodata from "@components/Nodata";
import { GROUP_TAB } from "@consts";
import ModalApply from "@pages/pro-tool/schools/components/ModalApply";
import { useUserApplyQuery } from "@redux/queries/user/user.api";
import { updateActiveTab } from "@redux/slices/action.slice";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import renderHTML from "react-render-html";
import StatisticsChart from "../StatisticsChart";
import ModalImage, { FormModalImageRef } from "../TabImage/ModalImage";
import { authSelector } from "@redux/slices/auth.slice";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface TabGeneralProps {
    schoolInfo: Array<{
        key: string;
        value: string;
    }>;
    images: Array<SchoolDetailImage>;
    video: Array<SchoolDetailImage>;
    scholarship: Array<SchoolDetailScholarship>;
    introduce: string;
    specializeds: Array<SchoolDetailSpecialized>;
    id?: string;
    view720?: string | null;
    statistics: SchoolStatistic[];
}

const MAX_IMAGE_COUNT = 4;
const TabGeneral: React.FunctionComponent<TabGeneralProps> = (props) => {
    const { schoolInfo, images, video, scholarship, introduce, specializeds, id, view720, statistics } = props;
    const dispatch = useDispatch();
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);

    const { data: userApplies } = useUserApplyQuery(!isLogin ? skipToken : undefined);
    const [selectedImage, setSelectedImage] = useState<string>("");
    const ref = useRef<FormModalImageRef>(null);
    const [currentApply, setCurrentApply] = useState<IApplyScholarShip>();
    const [open, setOpen] = useState(false);
    const [itemSchoolinfo, setItemSchoolinfo] = useState(3);

    const groupKeys: any = {
        student: "Số lượng sinh viên",
        international_student: "Số lượng sinh viên quốc tế",
        year_of_school_establishment: "Năm thành lập",
        school_level: "Cấp học",
        school_type: "Loại hình",
        number_of_majors: "Số lượng ngành học",
        number_of_lecturers: "Số lượng giảng viên",
        acreage: "Diện tích (m2)",
        cityLevel: "Cấp thành phố",
        is_central_city: "Thành phố trực thuộc TW",
        area: "Khu vực",
        economic_zone: "Đặc khu kinh tế",
        provinceLevel: "Cấp tỉnh",
        numberOfScholarshipTypes: "Số loại học bổng",
        tuition_fee_university: "Học phí hệ Đại học",
        tuition_fee_master: "Học phí hệ Thạc sĩ",
        dormitory_fee: "Phí kí túc xá",
        living_expense_fee: "Sinh hoạt phí",
        major_focus: "Ngành trọng điểm",
        dining_hall_number: "Số nhà ăn",
    };

    const goToTab = (tab: string) => {
        dispatch(updateActiveTab(tab));
    };

    const checkApplied = useCallback(
        (scholarshipId: string, sholarshipTypeId: string, trainingId: string) => {
            console.log(userApplies);
        },
        [userApplies]
    );

    useEffect(() => {
        const handleChangeWindowWidth = () => {
            if (window.innerWidth <= 575) {
                setItemSchoolinfo(1);
            }
            if (575 < window.innerWidth && window.innerWidth <= 768) {
                setItemSchoolinfo(2);
            }
            if (768 < window.innerWidth) {
                setItemSchoolinfo(3);
            }
        };

        window.addEventListener("resize", handleChangeWindowWidth);
        window.addEventListener("load", handleChangeWindowWidth);

        return () => {
            window.removeEventListener("resize", handleChangeWindowWidth);
            window.removeEventListener("load", handleChangeWindowWidth);
        };
    }, []);

    const newSchoolInfo =
        schoolInfo?.length % itemSchoolinfo === 0
            ? schoolInfo
            : schoolInfo?.concat(
                  Array(itemSchoolinfo - (schoolInfo?.length % itemSchoolinfo)).fill({ key: "", value: "" })
              );

    return (
        <>
            {/* BEGIN: Element 5 - Information School 2 */}
            {view720 && (
                <div className="box-item-element infor-school p-0">
                    <div
                        className="title-element"
                        style={{ padding: "20px 20px 0" }}
                    >
                        <h5>Toàn cảnh trường</h5>
                        <hr />
                    </div>
                    <iframe
                        allowFullScreen
                        className="rounded-bottom"
                        style={{ height: "30vw", minHeight: "300px" }}
                        src={view720}
                        width="100%"
                    ></iframe>
                </div>
            )}

            <div className="box-item-element infor-school">
                <div className="title-element">
                    <h5>Thông tin trường</h5>
                    <hr />
                </div>
                <div className="row gx-2 row-attributes">
                    {schoolInfo?.length === 0 && <Nodata />}
                    {newSchoolInfo.map((e, id) => (
                        <div
                            className="list-attributes col-12 col-sm-6 col-md-4"
                            key={id}
                        >
                            <div className="row g-2 item-attributes d-flex align-items-center justify-content-between">
                                <span className="col-6">{groupKeys[e.key]}</span>
                                <span className="col-6 value">{e.value}</span>
                                {/* <span>{groupKeys[e.key]}</span> <span className="value">{e.value}</span> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* END: Element 5 - Information School 2 */}
            {/* BEGIN: Element 5 - Information School 2 */}

            <div className="box-item-element infor-school">
                <div className="title-element">
                    <h5>Hình ảnh</h5>
                    <hr />
                </div>
                <div className="body-element">
                    <div className="row list-image">
                        {images?.length === 0 && <Nodata />}
                        {images
                            .filter((e, i) => i < MAX_IMAGE_COUNT)
                            .map((img, id) => (
                                <div
                                    className="col-6 col-sm-4 col-lg-3 d-flex"
                                    key={id}
                                >
                                    <img
                                        onClick={() => {
                                            setSelectedImage(img.url);
                                            ref.current?.openModal();
                                        }}
                                        className="img-fluid"
                                        src={`${process.env.REACT_APP_CDN}${img.url}`}
                                        alt={img.name || "studyinchina"}
                                    />
                                </div>
                            ))}
                    </div>
                    <ModalImage
                        image={selectedImage}
                        ref={ref}
                    />
                </div>
                <div className="footer-element">
                    {images.length > 0 && (
                        <div
                            className="more"
                            onClick={() => goToTab(GROUP_TAB.IMAGE)}
                        >
                            Xem tất cả
                        </div>
                    )}
                </div>
            </div>
            {/* END: Element 5 - Information School 2 */}
            {/* BEGIN: Element 5 - Information School 2 */}
            <div className="box-item-element infor-school">
                <div className="title-element">
                    <h5>Giới thiệu</h5>
                    <hr />
                </div>
                <div className="body-element render-content-html">
                    {introduce.length === 0 ? <Nodata /> : renderHTML(introduce || "")}
                </div>
            </div>

            <div className="box-item-element infor-school">
                <div className="title-element">
                    <h5>Các loại học bổng của trường</h5>
                    <hr />
                </div>
                <div className="body-element">
                    <div className="table-responsive">
                        {scholarship.length === 0 ? (
                            <Nodata />
                        ) : (
                            <table className="table scholarship">
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th className="text-start">Loại học bổng</th>
                                        <th className="text-start">Học bổng</th>
                                        <th>Hệ tuyển sinh</th>
                                        <th>Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scholarship.map((e, id) => {
                                        return (
                                            <tr key={id}>
                                                <td>{id + 1}.</td>
                                                <td className="text-start">
                                                    {e?.scholarshipType?.language?.find((e) => e.lang === "vi")?.title}
                                                </td>
                                                <td className="text-start">
                                                    {e?.scholarship?.language?.find((e) => e.lang === "vi")?.title}
                                                </td>
                                                <td>
                                                    {e?.trainings.map((ele, id) => (
                                                        <>
                                                            {ele?.language?.find((e) => e.lang === "vi")?.title} <br />
                                                        </>
                                                    ))}
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn-blue"
                                                        onClick={() => {
                                                            setCurrentApply({
                                                                scholarshipTypeId: e.scholarshipType.id,
                                                                scholarshipId: e.scholarship.id,
                                                                trainingId: e.trainings?.[0]?.id,
                                                            });
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        Apply
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                        <ModalApply
                            open={open}
                            onClose={() => setOpen(false)}
                            schoolId={id || ""}
                            trainingId={currentApply?.trainingId}
                            scholarshipId={currentApply?.scholarshipId}
                            scholarshipTypeId={currentApply?.scholarshipTypeId}
                        />
                    </div>
                </div>
                <div className="footer-element">
                    {scholarship.length > 0 && (
                        <div
                            className="more"
                            onClick={() => goToTab(GROUP_TAB.SCHOLARSHIP)}
                        >
                            Xem tất cả
                        </div>
                    )}
                </div>
            </div>
            <div className="box-item-element infor-school">
                <div className="title-element">
                    <h5>Thống kê số lượng người apply</h5>
                    <hr />
                    {statistics && statistics.length > 0 ? <StatisticsChart chartParams={statistics} /> : <Nodata />}
                </div>
                <div className="body-element"></div>
            </div>
            <div className="box-item-element infor-school">
                <div className="title-element">
                    <h5>Chuyên ngành đào tạo</h5>
                    <hr />
                </div>
                <div className="body-element">
                    <div className="table-responsive">
                        {specializeds.length === 0 ? (
                            <Nodata />
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Mã ngành</th>
                                        <th className="text-start">Tên ngành</th>
                                        <th>Hệ đào tạo</th>
                                        <th>Xếp loại</th>
                                        <th>Xếp hạng</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {specializeds.map((e, index) => (
                                        <tr key={e.id}>
                                            <td>{index + 1}.</td>
                                            <td>{e?.specialized?.code}</td>
                                            <td className="text-start">
                                                {e?.specialized?.language?.find((lang) => lang?.lang === "vi")?.title}
                                            </td>
                                            <td>{e?.specialized?.training?.language[0]?.title}</td>
                                            <td>{e?.rankAlphabet || "--"}</td>
                                            <td>{e?.rank || "--"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <div className="footer-element">
                    {specializeds.length > 0 && (
                        <div
                            className="more"
                            onClick={() => goToTab(GROUP_TAB.MAJOR)}
                        >
                            Xem tất cả
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default TabGeneral;
