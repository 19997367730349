import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/payment/method";

export const adminPaymentMethodApi = createApi({
    reducerPath: "adminPaymentMethodApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["PaymentMethod"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListPaymentMethod: builder.query<Array<any>, any>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "PaymentMethod" as const, id })),
                              { type: "PaymentMethod", id: "PaymentMethod-LIST" },
                          ]
                        : [{ type: "PaymentMethod", id: "PaymentMethod-LIST" }],
            }),
            getListPaymentMethodPaginate: builder.query<
                IResponseDataAdmin<any>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "PaymentMethod" as const, id })),
                              { type: "PaymentMethod", id: "PaymentMethod-LIST" },
                          ]
                        : [{ type: "PaymentMethod", id: "PaymentMethod-LIST" }],
            }),
            createPaymentMethod: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["PaymentMethod"],
            }),
            updatePaymentMethod: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"])
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["PaymentMethod"],
            }),
            deletePaymentMethod: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["PaymentMethod"],
            }),
            deleteMultiPaymentMethod: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["PaymentMethod"],
            }),
        };
    },
});

export const {
    useGetListPaymentMethodQuery,
    useGetListPaymentMethodPaginateQuery,
    useLazyGetListPaymentMethodPaginateQuery,
    useCreatePaymentMethodMutation,
    useUpdatePaymentMethodMutation,
    useDeletePaymentMethodMutation,
    useDeleteMultiPaymentMethodMutation,
} = adminPaymentMethodApi;
