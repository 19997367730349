export const listTypeNotification = [
  {
    label: 'Email',
    value: 'EMAIL',
    color: 'blue',
  },
  {
    label: 'SMS',
    value: 'SMS',
    color: 'green',
  },
  {
    label: 'Telegram',
    value: 'TELEGRAM',
    color: 'purple',
  },
  {
    label: 'In-app',
    value: 'APP',
    color: 'orange',
  },
  {
    label: 'System',
    value: 'SYSTEM',
    color: 'red',
  },
  {
    label: 'Websocket',
    value: 'WSS',
    color: 'cyan',
  }
];