import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

export const notificationApi = createApi({
    reducerPath: "notification",
    tagTypes: ["User-Notification"],
    keepUnusedDataFor: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getNotificationByUser: builder.query<
                ResponseWithPaginateFormat<UserNotification>,
                InputGetNotificationByUser
            >({
                query: (params) => ({
                    url: "/api/user/notification/paginate",
                    params: { ...params, type: "SYSTEM" },
                }),
                providesTags: ["User-Notification"],
            }),
            readAllUserNotification: builder.mutation<Response<boolean>, void>({
                query: () => ({
                    url: "/api/user/notification/read-all",
                    method: "PATCH",
                }),
                invalidatesTags: ["User-Notification"],
            }),
            readUserNotification: builder.mutation<Response<boolean>, { id: string }>({
                query: (body) => ({
                    url: `/api/user/notification/${body.id}`,
                    method: "GET",
                }),
                invalidatesTags: ["User-Notification"],
            }),
        };
    },
});

export const {
    useGetNotificationByUserQuery,
    useLazyGetNotificationByUserQuery,
    useReadAllUserNotificationMutation,
    useReadUserNotificationMutation,
} = notificationApi;
