import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useDeleteMultiSchoolSpecializedMutation,
    useDeleteSchoolSpecializedMutation,
    useGetListSchoolSpecializedPaginateQuery,
} from "@redux/queries/admin/admin.school.specialized";
import { Button, Modal, Table, Tag, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterSchoolSpecialized from "./components/FilterSchoolSpecialized";

const { Text } = Typography;

const SchoolSpecialized: React.FunctionComponent = () => {
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching } = useGetListSchoolSpecializedPaginateQuery(query as any);
    console.log("data", data);

    const [deleteSchoolSpecialized, isLoadingDelete] = useDeleteSchoolSpecializedMutation();
    const [deleteMultiSchoolSpecialized, isLoadingMultiDelete] = useDeleteMultiSchoolSpecializedMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteSchoolSpecialized({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiSchoolSpecialized({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Trường</div>,
            key: "schoolId",
            dataIndex: "school",
            render: (school: IAdminSchool, record: any) => {
                return school?.language[0]?.name || "";
            },
        },
        {
            title: <div className="text-title-table">Ngành học</div>,
            key: "majorId",
            dataIndex: "major",
            render: (major: IMajor, record: any) => {
                return major?.language[0]?.title || "";
            },
        },
        {
            title: <div className="text-title-table">Chuyên ngành</div>,
            key: "specialized",
            dataIndex: "specialized",
            render: (specialized: ISpecialized, record: any) => {
                return <Tag key={specialized.id}>{specialized.language[0]?.title}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterSchoolSpecialized
                                        data={filter}
                                        onSearch={(v: any) => setFilter(v)}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    // rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {contextHolder}
        </div>
    );
};

export default SchoolSpecialized;
