import { schoolLogo } from "@consts";
import React from "react";
import AdvideForm from "./AdviceForm";
import Map from "./Map";
import { Link } from "react-router-dom";

interface AsideInfoProps {
    schoolName: string;
    address: string;
    phoneNumber: string;
    website: string;
    logo: string;
    email: string;
    map?: {
        lat: number;
        lng: number;
    };
}
const AsideInfo: React.FunctionComponent<AsideInfoProps> = (props) => {
    const { schoolName, address, phoneNumber, website, logo, email, map } = props;
    const logoDefault = "images/logo-school.png?v=1.0.0";

    return (
        <>
            {/* BEGIN: Element 1 - Information School */}
            <div className="box-item-element infor-school">
                <div className="title-element">
                    <h5>Thông tin liên hệ</h5>
                    <hr />
                </div>
                <div className="body-element">
                    <div className="logo-school">
                        <img
                            src={logo ? `${process.env.REACT_APP_CDN}${logo}` : schoolLogo}
                            alt="logo-school"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = schoolLogo;
                            }}
                        />
                        <h5>
                            <strong>{schoolName}</strong>
                            {/* Phòng hợp tác Quốc Tế */}
                        </h5>
                        <div className="clearfix" />
                    </div>
                    <ul className="address-element">
                        {address && (
                            <li>
                                <i className="bi-pin-map" /> {address}
                            </li>
                        )}
                        {phoneNumber && (
                            <li>
                                <i className="bi-telephone" /> {phoneNumber}
                            </li>
                        )}
                        {email && (
                            <li>
                                <i className="bi-envelope" /> {email}
                            </li>
                        )}
                        {website && (
                            <li>
                                <i className="bi-globe" />{" "}
                                <Link
                                    className="text-decoration-underline"
                                    target="_blank"
                                    to={website}
                                    rel="noreferrer"
                                >
                                    {website}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            {/* END: Element 1 - Information School  */}
            {/* BEGIN: Element 2 - Google Map */}
            {map && map?.lat && map?.lng && (
                <Map
                    lat={map?.lat}
                    lng={map?.lng}
                />
            )}
            <AdvideForm />
            {/* BEGIN: Element 4 - School Recommended */}
            {/* <div className="box-item-element infor-school">
                <div className="title-element">
                    <h5>Trường đề xuất</h5>
                    <hr />
                </div>
                <div className="body-element">
                    <div className="logo-school">
                        <img
                            src="images/logo-school.png?v=1.0.0"
                            alt="logo-school"
                        />
                        <h5>
                            <strong>Đại học An Huy</strong>
                            Phòng hợp tác Quốc Tế
                        </h5>
                        <div className="clearfix" />
                    </div>
                    <div className="logo-school">
                        <img
                            src="images/logo-school.png?v=1.0.0"
                            alt="logo-school"
                        />
                        <h5>
                            <strong>Đại học Đại Nam</strong>
                            Phòng hợp tác Quốc Tế
                        </h5>
                        <div className="clearfix" />
                    </div>
                    <div className="logo-school">
                        <img
                            src="images/logo-school.png?v=1.0.0"
                            alt="logo-school"
                        />
                        <h5>
                            <strong>Đại học Kinh doanh - Công nghệ</strong>
                            Phòng hợp tác Quốc Tế
                        </h5>
                        <div className="clearfix" />
                    </div>
                    <div className="logo-school">
                        <img
                            src="images/logo-school.png?v=1.0.0"
                            alt="logo-school"
                        />
                        <h5>
                            <strong>Đại học Hồng Bàng</strong>
                            Phòng hợp tác Quốc Tế
                        </h5>
                        <div className="clearfix" />
                    </div>
                    <div className="logo-school">
                        <img
                            src="images/logo-school.png?v=1.0.0"
                            alt="logo-school"
                        />
                        <h5>
                            <strong>Đại học Giao thông Trùng Khánh</strong>
                            Phòng hợp tác Quốc Tế
                        </h5>
                        <div className="clearfix" />
                    </div>
                </div>
            </div> */}
            {/* END: Element 4 - School Recommended */}
        </>
    );
};

export default AsideInfo;
