import { useUserApplyQuery } from "@redux/queries/user/user.api";
import { Spin, Tabs, TabsProps } from "antd";
import { FC, useState } from "react";
import TabApplySchool from "./TabApplySchool";
import ScholarshipOfferModal from "./ScholarshipOfferModal";
import Nodata from "@components/Nodata";

interface TabApplyProcessProps {}

const TabApplyProcess: FC<TabApplyProcessProps> = () => {
    const { data: userApplies = [], isLoading, refetch } = useUserApplyQuery();

    const [showModal, setShowModal] = useState(false);
    const [applySelected, setApplySelected] = useState<ApplyProcess | null>(null);

    const onChangeTab = (key: string) => {
        console.log("onChangeTab", key);
    };

    const listApplies: TabsProps["items"] = userApplies?.map((apply) => {
        return {
            key: apply.id,
            label: <div className="h6">{apply?.school?.language?.[0]?.name}</div>,
            children: (
                <TabApplySchool
                    apply={apply}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setApplySelected={setApplySelected}
                    refetchListApplies={refetch}
                />
            ),
        };
    });

    return (
        <div
            className={`tab-pane fade ${location.hash === "#process-apply-tab" ? "show active" : ""}`}
            id="process-apply"
            role="tabpanel"
            aria-labelledby="process-apply-tab"
        >
            <div className="box-item-element">
                <div className="title-element">
                    <h5>Danh sách Apply</h5>
                    <hr />
                </div>
                <Spin spinning={isLoading}>
                    {listApplies?.length > 0 ? (
                        <div className="body-element">
                            <Tabs
                                defaultActiveKey="1"
                                items={listApplies}
                                onChange={onChangeTab}
                            />
                        </div>
                    ) : (
                        <Nodata />
                    )}
                </Spin>
            </div>
            <ScholarshipOfferModal
                open={showModal}
                applySelected={applySelected}
                onCancel={() => setShowModal(false)}
                refetchListApplies={refetch}
            />
        </div>
    );
};

export default TabApplyProcess;
