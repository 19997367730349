import React, { useEffect, useState, useImperativeHandle } from "react";
import { FieldInputProps } from "formik";
import { debounce, random } from "lodash";

interface FilterYearProps {
    title: string;
    onChange: (value: { yearFrom: number; yearTo: number }) => void;
    field: FieldInputProps<string>;
    form: any;
}

export const YEAR_MIN = 2020;
export const YEAR_MAX = 2026;

export interface FormRef {
    resetFields: () => void;
}

const defaultYear = {
    yearFrom: YEAR_MIN,
    yearTo: YEAR_MAX,
};

const FilterYearCmp: React.ForwardRefRenderFunction<FormRef, FilterYearProps> = (props, ref) => {
    const id = props.field.name;
    const { title, onChange } = props;
    const [key, setKey] = useState<number>(0);
    const [filterYear, setFilterYear] = useState<{
        yearFrom: number;
        yearTo: number;
    }>(defaultYear);

    useImperativeHandle(ref, () => ({
        resetFields: () => {
            setFilterYear(defaultYear);
            onChange(defaultYear);
            setKey(random(100000));
        },
    }));

    const handleChangeYear = (e: React.ChangeEvent<HTMLInputElement>, type: "yearFrom" | "yearTo") => {
        const totalMilestones = YEAR_MAX - YEAR_MIN;
        const value = e.target.value;

        const year = YEAR_MIN + Math.round((totalMilestones * parseInt(value, 10)) / 100);
        const newData = {
            yearFrom: filterYear.yearFrom,
            yearTo: filterYear.yearTo,
        };
        if (type === "yearFrom") {
            newData.yearFrom = year == 0 ? 1 : year;
        } else {
            newData.yearTo = year == 0 ? 1 : year;
        }

        setFilterYear(newData);
        onChange(newData);
    };

    useEffect(() => {
        /* ************* Js for Range Input ************* */
        const multiRange = document.querySelector(".multi-range");
        if (multiRange) {
            const inputs = multiRange.querySelectorAll("input");
            const lowerInput: any = inputs[0];
            const upperInput: any = inputs[1];
            const range: any = multiRange.querySelector(".range");

            const updateRange = () => {
                const lowerValue = parseInt(lowerInput.value, 0);
                const upperValue = parseInt(upperInput.value, 10);

                if (upperValue < lowerValue + 4) {
                    lowerInput.value = upperValue - 4;
                    if (lowerValue === lowerInput.min) {
                        upperInput.value = 4;
                    }
                }

                range.style.left = `${lowerValue}%`;
                range.style.width = `${upperValue - lowerValue}%`;
            };
            lowerInput?.addEventListener("input", updateRange);
            upperInput?.addEventListener("input", updateRange);
            updateRange();
        }
    }, [key]);

    return (
        <div
            className="group-item-filter"
            key={key}
        >
            <button
                className="btn btn-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#content-00"
                aria-expanded="false"
                aria-controls="collapseList"
            >
                {title} <i className="bi-chevron-down" />
            </button>
            <div
                className="collapse show"
                id="content-00"
            >
                <div className="item-collapse">
                    <div className="range_container">
                        <div
                            className="multi-range"
                            data-lower={0}
                            data-upper={80}
                        >
                            <input
                                onChange={debounce((e) => handleChangeYear(e, "yearFrom"), 300)}
                                type="range"
                                min={0}
                                max={100}
                                defaultValue={1}
                            />
                            <input
                                onChange={debounce((e) => handleChangeYear(e, "yearTo"), 300)}
                                type="range"
                                min={0}
                                max={100}
                                defaultValue={100}
                            />
                            <div className="track" />
                            <div className="range" />
                        </div>
                    </div>
                    {/* BEGIN: Infor Range (display behind input="range") */}
                    <div className="row infor-min-max">
                        <div className="col-12 col-sm-6">
                            <fieldset>
                                <legend>Min</legend>
                                <p>{filterYear.yearFrom}</p>
                            </fieldset>
                        </div>
                        <div className="col-12 col-sm-6">
                            <fieldset>
                                <legend>Max</legend>
                                <p>{filterYear.yearTo}</p>
                            </fieldset>
                        </div>
                    </div>
                    {/* END: Infor Range (display behind input="range") */}
                </div>
            </div>
        </div>
    );
};

export default React.forwardRef(FilterYearCmp);
