import { useGetAllStatisticApplyScholarshipQuery } from "@redux/queries/dashboard/dashboard.apply";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface IProps {
    query?: any;
}

const ApexChart = (props: IProps) => {
    const { data } = useGetAllStatisticApplyScholarshipQuery(props.query ? props.query : {});

    const [listYear, setListYear] = useState<any>([]);
    const [listDataChart, setListDataChart] = useState<any>([]);
    useEffect(() => {
        if (data) {
            const minYear = _.minBy(data, "year") as any;
            const maxYear = _.maxBy(data, "year") as any;

            const listYear = [];
            for (let i = minYear?.year; i <= maxYear?.year; i++) {
                listYear.push(i);
            }

            setListYear(listYear);
            const uniqueScholarship = _.uniqBy(data, "scholarship.id");
            const listSeries = listYear.map((year: number) => {
                return [
                    {
                        name: "Passed",
                        data: uniqueScholarship.map((item: any) => {
                            const findItem = data.find((data: any) => {
                                return data.scholarship.id === item.scholarship.id && data.year === year;
                            });
                            return findItem ? findItem.passed : 0;
                        }),
                        color: "#00B746",
                    },
                    {
                        name: "Pending",
                        data: uniqueScholarship.map((item: any) => {
                            const findItem = data.find((data: any) => {
                                return data.scholarship.id === item.scholarship.id && data.year === year;
                            });
                            return findItem ? findItem.total - findItem.passed : 0;
                        }),
                        color: "#775DD0",
                    },
                ];
            });

            const listDataChart = listSeries.map((series: any) => {
                return {
                    series: series as any,
                    options: {
                        chart: {
                            type: "bar",
                            stacked: true,
                            parentHeightOffset: 10,
                            height: "auto",
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                                columnWidth: "100%",
                                barHeight: "80%",
                                dataLabels: {
                                  total: {
                                    enabled: true,
                                    offsetX: 0,
                                    style: {
                                      fontSize: '13px',
                                      fontWeight: 900
                                    }
                                  }
                                }
                            },
                        },
                        xaxis: {
                            type: "category",
                            categories: uniqueScholarship.map((item: any) => item.scholarship.language[0].title),
                            labels: {
                                show: true,
                                rotate: 0,
                                style: {
                                    width: 400,
                                    fontSize: "12px",
                                    colors: ["#000000"],
                                },
                            },
                        },
                        yaxis: {
                            title: {
                                text: undefined,
                            },
                            labels: {
                                maxWidth: 1000,
                                show: true,
                                rotate: 0,
                            },
                        },
                        tooltip: {
                            y: {
                              show: true,
                            },
                        },
                        fill: {
                            opacity: 1,
                        },
                    },
                };
            });

            setListDataChart(listDataChart);
        }
    }, [data]);

    return (
        <div>
            <div className="row">
                {listYear?.map((item: number, index: number) => {
                    return (
                        <div
                            className="col-12 col-lg-6 d-flex"
                            key={item}
                        >
                            <div className="box-component">
                                <div className="title-component">
                                    <h4>Apply Theo Học bổng {item}</h4>
                                    <div className="clearfix" />
                                </div>
                                <div className="body-component">
                                    <div id="chart">
                                        <ReactApexChart
                                            options={listDataChart[index]?.options as any}
                                            series={listDataChart[index]?.series}
                                            type="bar"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ApexChart;
