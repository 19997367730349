import Nodata from "@components/Nodata";
import { Pagination } from "@components/pagination";
import ModalApply from "@pages/pro-tool/schools/components/ModalApply";
import { usePaginateScholarshipBySchoolIdQuery } from "@redux/queries/user/public.api";
import React, { useState } from "react";

const LIMIT = 12;

const TabScholarship: React.FunctionComponent<{ id?: string }> = ({ id }) => {
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [currentApply, setCurrentApply] = useState<IApplyScholarShip>();

    const { data } = usePaginateScholarshipBySchoolIdQuery({
        id: id as any,
        page,
        limit: LIMIT,
    });

    return (
        <>
            <div className="box-component">
                <div className="body-component">
                    <div className="title-element">
                        <h5>Các loại học bổng</h5>
                        <hr />
                    </div>
                    <div className="table-responsive">
                        {data?.items?.length === 0 ? (
                            <Nodata />
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th className="text-start">Loại học bổng</th>
                                        <th className="text-start">Học bổng</th>
                                        <th>Hệ tuyển sinh</th>
                                        <th>Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.items?.map((e, id) => (
                                        <tr key={e?.id}>
                                            <td>{id + LIMIT * (page - 1) + 1}.</td>
                                            <td className="text-start">
                                                {e?.scholarshipType?.language?.find((e) => e.lang === "vi")?.title}
                                            </td>
                                            <td className="text-start">
                                                {e?.scholarship?.language?.find((e) => e.lang === "vi")?.title}
                                            </td>
                                            <td>
                                                {e?.trainings?.map((ele, id) => (
                                                    <>
                                                        {ele?.language?.find((e) => e.lang === "vi")?.title} <br />
                                                    </>
                                                ))}
                                            </td>
                                            <td>
                                                <a
                                                    className="btn-blue cursor-pointer"
                                                    onClick={() => {
                                                        setCurrentApply({
                                                            scholarshipTypeId: e.scholarshipType.id,
                                                            scholarshipId: e.scholarship.id,
                                                            trainingId: e.trainings?.[0]?.id,
                                                        });
                                                        setOpen(true);
                                                    }}
                                                >
                                                    Apply
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {(data?.meta?.totalItems || 0) > 0 && (
                            <div className="d-flex justify-content-center mt-4">
                                <Pagination
                                    defaultSize={LIMIT}
                                    onSizeChange={(size) => {}}
                                    total={data?.meta.totalItems || 0}
                                    showSize={false}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={setPage}
                                    defaultCurrentPage={1}
                                    listSize={[12, 24, 50, 100]}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ModalApply
                open={open}
                onClose={() => setOpen(false)}
                schoolId={id || ""}
                trainingId={currentApply?.trainingId}
                scholarshipId={currentApply?.scholarshipId}
                scholarshipTypeId={currentApply?.scholarshipTypeId}
            />
        </>
    );
};

export default TabScholarship;
