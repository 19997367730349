import React from "react";

const TermOfUse = () => (
    <div>
        <h3>Introduction to Scholarships for Studying in China</h3>
        <p>
            Welcome to our platform, your gateway to a world of educational opportunities in China. These terms of use
            govern your access and utilization of our website (https://studyinchina.io), mobile application, and other
            digital platforms (collectively referred to as "our Platforms").
        </p>
        <p>
            Before proceeding, we kindly request that you carefully review and understand these Terms of Use. By
            accessing or using our Platforms, you signify your acceptance of these terms. If you do not agree with them,
            please refrain from further use of our Platforms.
        </p>
        <h5>About Us</h5>
        <p>
            We are dedicated to making the dream of studying in China a reality. Our mission is to provide valuable
            information and resources about scholarships and educational opportunities in China. As you embark on this
            educational journey, we want you to know more about us.
        </p>
        <p>
            We are an International Educational Services Provider committed to offering comprehensive information and
            support for students interested in studying in China. Our services encompass a range of resources, including
            our website (https://studyinchina.io), to facilitate your pursuit of academic excellence.
        </p>
        <h5>Terms of Use Updates</h5>
        <p>
            We continually strive to improve and enhance your user experience. As part of this commitment, we may make
            updates to these Terms of Use. We encourage you to check this page regularly to stay informed about any
            changes. These terms are binding on you and any updates or amendments will take immediate effect upon
            posting. By using our Platforms, you acknowledge and accept these changes.
        </p>
        <h5>Access to Our Platforms</h5>
        <p>
            We offer free access to our Platforms, but we cannot guarantee uninterrupted availability. We may, at our
            discretion, suspend, withdraw, or modify our Platforms, in part or in full, without notice. You are
            responsible for ensuring access to our Platforms and compliance with these Terms of Use.
        </p>
        <h5>User Responsibilities</h5>
        <p>
            Our Platforms are intended for users who are at least 18 years old. Persons under 18 are not authorized to
            use our Platforms. By accessing and using our Platforms, you certify that you meet this age requirement.
        </p>
        <p>
            We kindly request that you do not use our Platforms for any illegal or unauthorized purpose. Automated
            access, except for specific purposes such as building public tools and bots that facilitate transparency and
            analysis, is not allowed. Unauthorized use, circumvention of security features, and attempts to interfere
            with our Platforms' normal operation are strictly prohibited.
        </p>
        <h5>Privacy Policy</h5>
        <p>
            In addition to these Terms of Use, our Privacy Policy outlines how we handle personal data. It applies to
            your use of our Platforms, and we encourage you to review it for a comprehensive understanding of how we
            process your data.
        </p>
        <h5>Intellectual Property</h5>
        <p>
            We are the owners or licensees of the intellectual property on our Platforms, including source codes,
            databases, software, designs, content, and more. All trademarks, logos, and service marks on our Platforms
            are protected by applicable laws. You may use content from our Platforms for personal, non-commercial
            purposes, provided you maintain the intellectual property notices intact and unchanged.
        </p>
        <h5>Prohibited Use</h5>
        <p>
            You may only use our Platforms for their intended purpose. Unauthorized use for commercial endeavors is not
            allowed, except when expressly permitted in a contract with us. Specifically, you must not engage in
            activities such as making unauthorized use of our Platforms, circumventing security features, disrupting our
            Platforms' operation, or attempting to bypass measures designed to prevent or restrict access.
        </p>
        <h5>Accounts and Passwords</h5>
        <p>
            You must create and play on only one account within a 24-hour period. Earning rewards and assets should
            occur on a single account, and you may not earn rewards on two or more accounts. Your username, password,
            and other registration information must be kept confidential. We reserve the right to disable or change a
            username if it is inappropriate or violates our guidelines.
        </p>
        <p>
            If you suspect that someone other than you knows your password, please notify us immediately. You are
            responsible for ensuring the accuracy of the information you provide during registration.
        </p>
        <h5>Termination and Suspension</h5>
        <p>
            We reserve the right to terminate, block, or suspend your access to our Platforms if we believe you have
            violated these Terms of Use. This may occur with or without notice. We also have the authority to modify or
            discontinue our Platforms, and such changes will be binding immediately.
        </p>
        <h5>No Reliance on Information</h5>
        <p>
            The content on our Platforms is provided for general information. You should seek professional advice before
            taking any action based on the content. We make reasonable efforts to ensure the accuracy and completeness
            of the information, but we do not guarantee it.
        </p>
        <h5>Limitation of Liability</h5>
        <p>
            We exclude all conditions, warranties, or other terms that may apply to our Platforms. We will not be liable
            for any loss or damage arising from the use of our Platforms, whether direct or indirect. This includes loss
            of profits, business interruption, and other consequential damages.
        </p>
        <h5>Assumption of Risks</h5>
        <p>We kindly request that you acknowledge the following:</p>
        <ul>
            <li>Prices of scholarships and educational opportunities can be volatile.</li>
            <li>You are responsible for paying relevant taxes.</li>
            <li>We do not store or transmit scholarships or assets, and all transactions occur on the blockchain.</li>
            <li>
                There are risks associated with the internet and digital assets, including hardware and software issues.
            </li>
        </ul>
        <h5>Contact Us</h5>
        <p>
            If you have any questions or comments about these Terms of Use, please reach out to us at
            contact@yourwebsite.com. For inquiries related to your personal data, we can also provide information upon
            request.
        </p>
        <p>
            Thank you for choosing our Platforms as your source of information on scholarships for studying in China. We
            are here to support your educational journey.
        </p>
    </div>
);

export default TermOfUse;
