import { listTrainingLanguage, schoolLogo } from "@consts";
import { Checkbox, Col, Divider, Modal, Row, Space, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDeleteSchoolServiceMutation } from "@redux/queries/admin/admin.school.service";
import HandleForm from "./HanleForm";
import { Link } from "react-router-dom";
import Title from "antd/es/typography/Title";
import { listLanguageSupports, listSchoolServiceType } from "../school-service.const";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";

interface Props {
    item: any;
    selectedKeys: string[];
    setSelectedKeys: (id: string[]) => void;
}

const SchoolServiceItem = ({ item, selectedKeys, setSelectedKeys }: Props) => {
    const { data: list_specialized, isLoading, isFetching, error: errorSpecialized } = useGetListSpecializedQuery();

    const [dataTable, setDataTable] = useState<any>([]);
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    useEffect(() => {
        if (item) {
            const list = item.services?.map((service: any) => {
                return {
                    ...service,
                    scholarship: service?.scholarship?.language[0]?.title,
                    // training: service?.schoolServiceTrainings?.map((o: any) => o.training),
                    // specializeds: service?.specializeds?.map((o: any) => o.language[0]?.title).join(", "),
                    // trainingLanguage: service?.schoolServiceTrainings?.map((o: any) => o.trainingLanguage),
                    // language: service?.schoolServiceTrainings?.map((o: any) => o.required?.language),
                    // gpa: service?.schoolServiceTrainings?.map((o: any) => o.required?.gpa),
                    // amount: service?.schoolServiceTrainings?.map((o: any) => o.amount),
                    // total: service?.schoolServiceTrainings?.map((o: any) => o.total),
                    // apply: service?.schoolServiceTrainings?.map((o: any) => o.apply),
                    // expireApply: service?.schoolServiceTrainings?.map((o: any) => o.expireApply),
                    // supported: service?.schoolServiceTrainings?.map((o: any) => o.supported),
                    // type: service?.schoolServiceTrainings?.map((o: any) => o.type),
                    id: service?.id,
                    // languageContent: service?.language,
                };
            });
            setDataTable(list);
        }
    }, [item]);

    const [deleteSchoolService, isLoadingDelete] = useDeleteSchoolServiceMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteSchoolService({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const columns = [
        {
            title: "Học bổng",
            dataIndex: "scholarship",
            key: "scholarship",
            width: 120,
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const columnChildren = [
        {
            title: <div className="text-title-table"></div>,
            width: 5,
            dataIndex: "note",
            key: "note",
            render: (text: any, record: any) => {
                return text ? (
                    <Tooltip
                        title={text}
                        placement="right"
                    >
                        <i className="bi-info-circle"></i>
                    </Tooltip>
                ) : null;
            },
        },
        {
            title: "Hệ đào tạo",
            dataIndex: "training",
            key: "training",
            width: 100,
            render: (record: any) => {
                return <div className="">{record?.language[0].title}</div>;
            },
        },
        {
            title: "Chuyên ngành",
            dataIndex: "majorSpecializationIds",
            key: "majorSpecializationIds",
            render: (major: any) => {
                const majorNames = major?.map((id: any) => list_specialized?.find((item: any) => item.id === id));

                const languageTitles = majorNames?.map((item: any) => item.language[0]?.title);

                const titlesString = languageTitles.join(", ");

                return <div className="">{titlesString}</div>;
            },
            width: 150,
        },
        {
            title: "Đào tạo",
            dataIndex: "trainingLanguage",
            render: (trainingLanguage: any) => {
                const trainingLanguageName = listTrainingLanguage.find((o: any) => o.value === trainingLanguage);
                return <div className="">{trainingLanguageName?.label}</div>;
            },

            width: 100,
        },
        {
            title: "Ngoại ngữ",
            dataIndex: "languageSupports",
            render: (languageSupports: any) => {
                const languageSupportsNames = languageSupports?.map((language: any, index: number) => {
                    const typeLanguageSupports = listLanguageSupports.find((o: any) => o.value === language);
                    return (
                        <div
                            className=""
                            key={index}
                        >
                            {typeLanguageSupports?.label}{" "}
                        </div>
                    );
                });
                return <div className="">{languageSupportsNames}</div>;
            },

            width: 120,
        },
        {
            title: "GPA",
            dataIndex: "required",
            render: (required: any) => {
                return <div className="">{required?.gpa}</div>;
            },
            width: 50,
        },
        {
            title: "Phí dịch vụ",
            dataIndex: "amount",
            key: "amount",
            width: 100,
        },
        {
            title: "Chỉ tiêu",
            dataIndex: "total",
            key: "total",
            width: 100,
        },
        {
            title: "Đã tuyển",
            dataIndex: "apply",
            key: "apply",
            width: 100,
        },
        {
            title: "Ngày hết hạn",
            dataIndex: "expireApply",
            render: (expireApply: any) => {
                return <div className="">{moment(expireApply).format("DD/MM/YYYY")}</div>;
            },
            width: 150,
        },
        {
            title: "Hỗ trợ",
            dataIndex: "supported",
            render: (supported: any) => {
                return (
                    <Checkbox
                        className="w-100"
                        checked={supported}
                        disabled
                    />
                );
            },

            width: 70,
        },
        {
            title: "Phân loại",
            dataIndex: "type",
            render: (type: any) => {
                const typeItem = listSchoolServiceType.find((o: any) => o.value === type);
                return <Tag color={typeItem?.color}>{typeItem?.label}</Tag>;
            },
            width: 70,
        },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedKeys(newSelectedRowKeys as string[]);
    };

    const rowSelection = {
        selectedKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <div style={{ padding: "30px", border: "1px solid #c7c5c5", borderRadius: "12px", marginBottom: "5px" }}>
                <div style={{ display: "flex" }}>
                    {/* <Checkbox
                        checked={selected === item?.id}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelected(item?.id);
                            } else {
                                setSelected(null);
                            }
                        }}
                    /> */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "0 30px",
                            width: "30%",
                        }}
                    >
                        <img
                            src={item?.logo ? process.env.REACT_APP_CDN + item?.logo : schoolLogo}
                            height={50}
                            width={50}
                            style={{ marginBottom: "8px", borderRadius: "50%" }}
                        />
                        Số người đang apply:{" "}
                        {item?.services.reduce(
                            (a: any, b: any) =>
                                a + b?.schoolServiceTrainings.reduce((c: any, d: any) => c + d?.apply, 0),
                            0
                        )}
                    </div>

                    <Row style={{ width: "100%" }}>
                        <Col md={12}>
                            <div>
                                <div style={{ color: "red", fontWeight: 600 }}>{item?.language[0]?.name}</div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div>
                                <div style={{ fontWeight: 600, marginBottom: "8px" }}>
                                    Thành phố: <span style={{ fontWeight: 400 }}>{item?.area?.language[0]?.title}</span>
                                </div>
                                <div style={{ fontWeight: 600, marginBottom: "8px" }}>
                                    Rank: <span style={{ fontWeight: 400 }}>{item?.rank}</span>
                                </div>
                                <div style={{ fontWeight: 600 }}>
                                    Chi tiết:{" "}
                                    <Link
                                        to={`/schools/${item?.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        tại đây
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <Space align="start">
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(item);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i
                                className="bi-pencil-square"
                                style={{ fontSize: "20px" }}
                            ></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(item?.id)}
                        >
                            <i
                                className="bi-trash"
                                style={{ fontSize: "20px" }}
                            ></i>
                        </button>
                    </Space> */}
                </div>

                <Divider />

                <Table
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={dataTable}
                    loading={isLoading || isFetching}
                    pagination={false}
                    scroll={{ x: 300 }}
                    rowKey="id"
                    expandable={{
                        expandedRowRender: (e) => {
                            return (
                                <div className="">
                                    <Title level={5}>Thông tin hệ đào tạo</Title>
                                    <Table
                                        columns={columnChildren}
                                        dataSource={(e?.schoolServiceTrainings as any) || []}
                                        pagination={false}
                                        loading={isLoading || isFetching}
                                        scroll={{ x: 1500 }}
                                    />
                                </div>
                            );
                        },
                    }}
                />
            </div>
            <HandleForm
                openHandleForm={openHandleForm}
                setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                dataEdit={dataEdit}
                setDataEdit={(d: any) => setDataEdit(d)}
            />
            {contextHolder}
        </>
    );
};

export default SchoolServiceItem;
