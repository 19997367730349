import React, { useRef, useState } from "react";
import school_01 from "@assets/images/school-01.jpg";
import { useParams } from "react-router-dom";
import { usePaginateMediaBySchoolIdQuery } from "@redux/queries/user/public.api";
import { Pagination } from "@components/pagination";
import ModalImage, { FormModalImageRef } from "./ModalImage";
import Nodata from "@components/Nodata";

const LIMIT = 12;

const TabImage: React.FunctionComponent<{ id?: string }> = ({ id }) => {
    const [page, setPage] = useState(1);
    const { data } = usePaginateMediaBySchoolIdQuery({
        id: id as any,
        page,
        limit: LIMIT,
    });
    const [selectedImage, setSelectedImage] = useState<string>("");
    const ref = useRef<FormModalImageRef>(null);

    return (
        <>
            {/* BEGIN: Content Image */}
            <div className="box-component">
                <div className="body-component">
                    <div className="title-element">
                        <h5>Hình ảnh</h5>
                        <hr />
                    </div>
                    <div className="row list-image-item">
                        {data?.items?.length === 0 && <Nodata />}
                        {data?.items?.map((img) => (
                            <div
                                onClick={() => setSelectedImage(img?.url)}
                                key={img.id}
                                className="col-12 col-sm-6 col-md-4 col-lg-3 pd-4 d-flex"
                            >
                                <img
                                    className="img-fluid hover-shadow cursor"
                                    src={img.url ? `${process.env.REACT_APP_CDN}${img.url}` : ""}
                                    onClick={() => {
                                        ref.current?.openModal();
                                    }}
                                    alt="image"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <Pagination
                            defaultSize={LIMIT}
                            onSizeChange={(size) => {}}
                            total={data?.meta.totalItems || 0}
                            showSize={false}
                            totalPage={data?.meta.totalPages || 0}
                            onChangePage={setPage}
                            defaultCurrentPage={1}
                            listSize={[12, 24, 50, 100]}
                        />
                    </div>
                </div>
            </div>
            <ModalImage
                image={selectedImage}
                ref={ref}
            />
            {/* END: Content Image */}
        </>
    );
};

export default TabImage;
