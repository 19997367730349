import { schoolBg, schoolLogo } from "@consts";
import React from "react";
import Action from "./Action";
import Navbar from "./Navbar";

import img00 from "@assets/images/school-05.jpg";
import img01 from "@assets/images/bg-login.jpg";
import img02 from "@assets/images/background.jpg";
import img03 from "@assets/images/image-01.jpg";
import img04 from "@assets/images/img-01.jpg";
import img05 from "@assets/images/item-02.jpg";
import img06 from "@assets/images/school-01.jpg";

interface OverviewProps {
    name: string;
    background: string;
    logo: string;
    views: number;
    favourite: number;
    like: number;
    onRefresh: () => void;
    id?: string;
}

const Overview: React.FunctionComponent<OverviewProps> = (props) => {
    const defaultlogo = "images/logo-school.png?v=1.0.0";
    const { name, background, logo, views, favourite, like, onRefresh, id } = props;
    return (
        <div className="head-infor">
            {/* BEGIN: Multimedia */}
            <div className="multimedia-list-img">
                <img
                    src={background ? `${process.env.REACT_APP_CDN}${background}` : schoolBg}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = schoolBg;
                    }}
                    alt="studyinchina"
                    className="w-100"
                />
            </div>
            {/* END: Multimedia */}
            {/* BEGIN: Title Information School */}
            <div className="bg-title-infor">
                <div className="row">
                    <div className="col-12 col-md-7 col-lg-9">
                        <div className="logo-school">
                            <img
                                src={logo ? `${process.env.REACT_APP_CDN}/${logo}` : schoolLogo}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = schoolLogo;
                                }}
                                alt="logo-school"
                            />
                            <h5>
                                <strong>{name}</strong>
                                {/* Phòng hợp tác Quốc Tế */}
                            </h5>
                            <div className="clearfix" />
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-3">
                        <Action
                            views={views}
                            favourite={favourite}
                            like={like}
                            onRefresh={onRefresh}
                            id={id}
                        />
                    </div>
                </div>
                <Navbar />
            </div>
            {/* END: Title Information School */}
        </div>
    );
};

export default Overview;
