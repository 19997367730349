import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import queryString from "query-string";

const BASE = "/api/city";

export const adminCityApi = createApi({
    reducerPath: "adminCityApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["City"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListCity: builder.query<Array<ICity>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListCityPaginate: builder.query<
                IResponseDataAdmin<ICity>,
                { page: number; limit: number; title?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate?` + queryString.stringify(query, { arrayFormat: "bracket" }),
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "City" as const, id })),
                              { type: "City", id: "City-LIST" },
                          ]
                        : [{ type: "City", id: "City-LIST" }],
            }),
            createCity: builder.mutation<any, IAdminCityInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["City"],
            }),
            updateCity: builder.mutation<any, IAdminCityInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["City"],
            }),
            deleteCity: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["City"],
            }),
            deleteMultiCity: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["City"],
            }),
        };
    },
});

export const {
    useGetListCityQuery,
    useGetListCityPaginateQuery,
    useLazyGetListCityPaginateQuery,
    useCreateCityMutation,
    useUpdateCityMutation,
    useDeleteCityMutation,
    useDeleteMultiCityMutation,
} = adminCityApi;
