export enum TypeActivity {
    ADD_REVIEW = "ADD_REVIEW",
    USER_APPLY = "USER_APPLY",
    CREATE_USER = "CREATE_USER",
    ADD_SCHOOL_SERVICE = "ADD_SCHOOL_SERVICE",
    ADD_TICKET = "ADD_TICKET",
    REJECT_DEPOSIT = "REJECT_DEPOSIT",
    CONFIRM_DEPOSIT = "CONFIRM_DEPOSIT",
    ADD_DEPOSIT = "ADD_DEPOSIT",
    USER_ADD_BLOG = "USER_ADD_BLOG",
}

export const listTypeActivity = [
    { value: TypeActivity.ADD_REVIEW, icon: "bi bi-person-lines-fill", color: "pink" },
    { value: TypeActivity.USER_APPLY, icon: "bi bi-person-video2", color: "orange" },
    { value: TypeActivity.CREATE_USER, icon: "bi bi-person-fill-add", color: "gray" },
    { value: TypeActivity.ADD_SCHOOL_SERVICE, icon: "bi bi-house-add-fill", color: "purple" },
    { value: TypeActivity.ADD_TICKET, icon: "bi bi-ticket-detailed-fill", color: "yellow" },
    { value: TypeActivity.REJECT_DEPOSIT, icon: "bi bi-terminal-x", color: "red" },
    { value: TypeActivity.CONFIRM_DEPOSIT, icon: "bi bi-terminal-plus", color: "cyan" },
    { value: TypeActivity.ADD_DEPOSIT, icon: "bi bi-cash-coin", color: "blue" },
    { value: TypeActivity.USER_ADD_BLOG, icon: "bi bi-layout-text-window-reverse", color: "violet" },
];
