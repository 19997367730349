import { useGetAllStatisticApplyQuery } from "@redux/queries/dashboard/dashboard.apply";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface IProps {
    query?: any;
}

const ColumnChart = (props: IProps) => {
    const { data } = useGetAllStatisticApplyQuery(props.query ? props.query : {});

    useEffect(() => {
        if (data) {
            const cloneData = data.map((item: any) => {
                return {
                    ...item,
                    formatMonth: item.month < 10 ? `0${item.month}` : `${item.month}`,
                };
            });
            const minMonth = _.minBy(cloneData, (item: any) => {
                return Number(`${item.year}${item.formatMonth}`);
            });
            const maxMonth = _.maxBy(cloneData, (item: any) => {
                return Number(`${item.year}${item.formatMonth}`);
            });

            let month = {
                year: minMonth.year,
                formatMonth: minMonth.formatMonth,
            };
            const listMouth = [month];

            while (Number(`${month.year}${month.formatMonth}`) < Number(`${maxMonth.year}${maxMonth.formatMonth}`)) {
                if (month.formatMonth === "12") {
                    month = {
                        year: month.year + 1,
                        formatMonth: "01",
                    };
                } else {
                    month = {
                        year: month.year,
                        formatMonth:
                            Number(month.formatMonth) + 1 < 10
                                ? `0${Number(month.formatMonth) + 1}`
                                : `${Number(month.formatMonth) + 1}`,
                    };
                }
                listMouth.push(month);
            }

            const list = listMouth.map((item) => {
                const data = cloneData.find((data: any) => {
                    return data.year === item.year && data.formatMonth === item.formatMonth;
                });
                return {
                    year: item.year,
                    month: item.formatMonth,
                    passed: data ? data.passed : 0,
                    failed: data ? data.failed : 0,
                    rest: data ? data.total - data.passed - data.failed : 0,
                };
            });

            const series = [
                {
                    name: "Passed",
                    data: list.map((item) => item.passed),
                    color: "#00B746",
                },
                {
                    name: "Failed",
                    data: list.map((item) => item.failed),
                    color: "#FF4560",
                },
                {
                    name: "Pending",
                    data: list.map((item) => item.rest),
                    color: "#775DD0",
                },
            ];

            const xaxis = {
                type: "category" as any,
                categories: listMouth.map((item: any) => `${item.formatMonth}/${item.year}`),
            };

            setChartData({
                series: series as any,
                options: {
                    chart: {
                        type: "bar" as any,
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: true,
                        },
                        zoom: {
                            enabled: true,
                        },
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: "bottom",
                                    offsetX: -10,
                                    offsetY: 0,
                                },
                            },
                        },
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 10,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    style: {
                                        fontSize: "13px",
                                        fontWeight: 900,
                                    },
                                },
                            },
                        },
                    },
                    xaxis: xaxis as any,
                    legend: {
                        position: "right" as any,
                        offsetY: 40,
                    },
                    fill: {
                        opacity: 1,
                    },
                },
            });
        }
    }, [data]);

    const [chartData, setChartData] = useState({
        series: [
            {
                name: "Passed",
                data: [],
                color: "#00B746",
            },
            {
                name: "Failed",
                data: [],
                color: "#FF4560",
            },
            {
                name: "Pending",
                data: [],
                color: "#775DD0",
            },
        ],
        options: {
            chart: {
                type: "bar" as any,
                height: 350,
                stacked: true,
                toolbar: {
                    show: true,
                },
                zoom: {
                    enabled: true,
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: "bottom",
                            offsetX: -10,
                            offsetY: 0,
                        },
                    },
                },
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: "13px",
                                fontWeight: 900,
                            },
                        },
                    },
                },
            },
            xaxis: {
                type: "category" as any,
                categories: [],
            },
            legend: {
                position: "right" as any,
                offsetY: 40,
            },
            fill: {
                opacity: 1,
            },
        },
    });

    return (
        <div>
            <div id="chart">
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height={350}
                />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default ColumnChart;
