import welcomeIcon from "@assets/images/welcome.jpg";
import { authSelector } from "@redux/slices/auth.slice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ListSchoolNews from "./components/ListSchoolNews";
import ListUserBlogs from "./components/ListUserBlogs";
import Statistics from "./components/Statistics";

const UserDashboard = () => {
    const { user } = useSelector(authSelector);

    return (
        <main>
            <section id="content-main">
                <div id="box-welcome">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <article>
                                <h2>{user?.firstName ? user?.firstName : "Bạn"} thân mến!</h2>
                                <p className="mb-2">
                                    Sutdyinchina.io cung cấp rất nhiều công cụ hỗ trợ hữu ích, giúp bạn dễ dàng tìm kiếm
                                    và so sánh thông tin học bổng cũng như thông tin các trường đại học Trung Quốc. Hãy
                                    đăng ký tài khoản ngay hôm nay để khám phá những điều thú vị trên hệ thống bạn nhé.
                                </p>
                                <p className="mt-0 mb-2"> Trân trọng!</p>
                                {/* <Link to="#">
                                    <button
                                        type="button"
                                        className="btn btn-blue"
                                    >
                                        Learn More
                                    </button>
                                </Link> */}
                            </article>
                        </div>
                        <div className="col-12 col-sm-6">
                            <img
                                className="img-fluid"
                                src={welcomeIcon}
                                alt="welcome-icon"
                            />
                        </div>
                    </div>
                </div>

                <Statistics />

                {/* <div className="row">
                    <div className="col-12 col-md-6 d-flex">
                        <Overview />
                    </div>
                    <div className="col-12 col-md-6 d-flex">
                        <UpcomingContent />
                    </div>
                </div> */}
                {/* <KnowledgeBase /> */}

                <ListSchoolNews />

                <ListUserBlogs />
            </section>
        </main>
    );
};

export default UserDashboard;
