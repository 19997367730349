import React, { useState } from "react";
import FaqItem from "./FaqItem";
import { useParams } from "react-router-dom";
import { usePaginateFaqBySchoolIdQuery, usePaginateNewsBySchoolIdQuery } from "@redux/queries/user/public.api";
import { Pagination } from "@components/pagination";
import Nodata from "@components/Nodata";

const LIMIT = 12;

const TabFaq: React.FunctionComponent<{ id?: string }> = ({ id }) => {
    const [page, setPage] = useState(1);
    const { data } = usePaginateFaqBySchoolIdQuery({
        id: id as any,
        page,
        limit: LIMIT,
    });

    return (
        <>
            <div className="title-element">
                <h5>Câu hỏi thường gặp</h5>
                <hr />
            </div>

            <div
                className="accordion"
                id="faq"
            >
                {data?.meta?.totalItems === 0 ? (
                    <Nodata />
                ) : (
                    <>
                        {data?.items?.map((e, index) => (
                            <FaqItem
                                key={e.id}
                                item={{
                                    title: e.title,
                                    question: e.question,
                                    answer: e.answer,
                                    index: index,
                                }}
                            />
                        ))}
                        {(data?.meta?.totalItems || 0) > 0 && (
                            <div className="d-flex justify-content-center mt-4">
                                <Pagination
                                    defaultSize={LIMIT}
                                    onSizeChange={(size) => {}}
                                    total={data?.meta.totalItems || 0}
                                    showSize={false}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={setPage}
                                    defaultCurrentPage={1}
                                    listSize={[12, 24, 50, 100]}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default TabFaq;
