import { schoolLogo } from "@consts";
import { Card, Radio, Table, Typography } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";

interface Props {
    data: any;
    setListContract: (data: any) => void;
}

const ListContract = (props: Props) => {
    return (
        <div className="">
            {props.data ? (
                <Radio.Group
                    options={props.data?.map((o: any) => ({
                        label: (
                            <Card
                                bordered={false}
                                // onClick={() => props.setListContract(o.id)}
                            >
                                <Meta
                                    avatar={
                                        <img
                                            src={o.thumbnail ? process.env.REACT_APP_CDN + o.thumbnail : schoolLogo}
                                            alt="logo"
                                            height={50}
                                        />
                                    }
                                    title={`Mã hợp đồng: ${o.contractNumber}`}
                                    description={o?.id}
                                />
                            </Card>
                        ),
                        value: o.id,
                    }))}
                    onChange={(e) => props.setListContract(e.target.value)}
                ></Radio.Group>
            ) : (
                ""
            )}
        </div>
    );
};

export default ListContract;
