import { SchoolAttributeLabels } from "@pages/admin/school/schoolAttribute.enum";
import { Drawer, Descriptions, Empty, Space, Typography, Card, Tag, Divider } from "antd";
import Title from "antd/es/typography/Title";
import _ from "lodash";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLazyGetApplyByIdQuery } from "@redux/queries/admin/admin.apply";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";
import styled from "styled-components";
import { useGetListApplyTaskQuery } from "@redux/queries/admin/admin.apply.task";
import DatePicker from "react-multi-date-picker";
import { useGetListUserGrantQuery } from "@redux/queries/admin/admin.user";

const { Text } = Typography;

interface IProps {
    detailId: any;
    openDrawer: boolean;
    setOpenDrawer: (open: boolean) => void;
}

const WrapDescriptions = styled(Descriptions)`
    tbody {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }
    .ant-descriptions-row {
        max-width: 230px;
    }
    .ant-card.ant-card-bordered.ant-card-small {
        max-height: 380px;
    }
`;

const ApplyDetail = (props: IProps) => {
    const { detailId, openDrawer, setOpenDrawer } = props;

    const [getApplyById, { data: detail }] = useLazyGetApplyByIdQuery();
    const { data: list_apply_task } = useGetListApplyTaskQuery();
    const { data: list_user_grant } = useGetListUserGrantQuery();
    const { data: list_specialized } = useGetListSpecializedQuery();

    const applyPaymentStatusColor = {
        PENDING: "yellow",
        PAID: "green",
    };

    const applyStepStatusColor = {
        PROCESSING: "blue",
        DONE: "green",
    };

    useEffect(() => {
        if (detailId) {
            getApplyById({ id: detailId });
        }
    }, [detailId]);

    return (
        <div>
            <Drawer
                title="Thông tin chi tiết"
                placement={"right"}
                closable={false}
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                width={600}
                extra={<CloseOutlined onClick={() => setOpenDrawer(false)} />}
            >
                {detail && (
                    <>
                        <Descriptions
                            column={1}
                            title="Thông tin chung"
                            items={[
                                {
                                    key: "name",
                                    label: "Tên khách hàng",
                                    children: detail?.user?.firstName + " " + detail?.user?.lastName,
                                },
                                {
                                    key: "school",
                                    label: "Tên trường",
                                    children: detail?.school?.language[0]?.name,
                                },
                                {
                                    key: "scholarship",
                                    label: "Học bổng",
                                    children:
                                        detail?.schoolScholarshipTraining?.schoolScholarship?.scholarship?.language[0]
                                            .title,
                                },
                                {
                                    key: "training",
                                    label: "Hệ đào tạo",
                                    children: detail?.schoolScholarshipTraining?.training?.language[0]?.title,
                                },
                                {
                                    key: "major",
                                    label: "Ngành học",
                                    children: detail?.major?.language[0]?.title,
                                },
                                {
                                    key: "year",
                                    label: "Năm",
                                    children: detail?.year,
                                },
                                {
                                    key: "period",
                                    label: "Giai đoạn",
                                    children: detail?.period,
                                },
                                {
                                    key: "storageUri",
                                    label: "Uri Lưu trữ",
                                    children: detail?.storageUri,
                                },
                                {
                                    key: "specializeds",
                                    label: "Chuyên ngành",
                                    children: (detail as any)?.specializeds
                                        ?.map((o: any) => {
                                            const titles: string[] = [];
                                            list_specialized?.forEach((item: any) => {
                                                if (item?.id === o) {
                                                    titles.push(item?.language[0]?.title);
                                                }
                                            });
                                            return titles;
                                        })
                                        .join(", "),
                                },
                            ]}
                        />
                        {!(detail as any)?.contract ? (
                            <>
                                <Title level={5}>Thông tin hợp đồng</Title>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Không có thông tin hợp đồng"
                                />
                            </>
                        ) : (
                            <>
                                <Descriptions
                                    column={1}
                                    title="Thông tin hợp đồng"
                                    items={[
                                        {
                                            key: "contractMethod",
                                            label: "Phương thức hợp đồng",
                                            children: detail?.contract?.contractMethod,
                                        },
                                        {
                                            key: "Discount",
                                            label: "Discount",
                                            children: detail?.contract?.discount,
                                        },
                                        {
                                            key: "hskPlace",
                                            label: "hskPlace",
                                            children: detail?.contract?.hskPlace,
                                        },
                                        {
                                            key: "contact",
                                            label: "Thông tin liên hệ",
                                            children: detail?.contract?.contact,
                                        },
                                        {
                                            key: "address",
                                            label: "Địa chỉ",
                                            children: detail?.contract?.address,
                                        },
                                        {
                                            key: "scholarshipTypes",
                                            label: "Loại học bổng",
                                            children: "",
                                        },
                                        {
                                            key: "aspirations",
                                            label: "Nguyện vọng",
                                            children: "",
                                        },
                                    ]}
                                />
                                <WrapDescriptions
                                    column={1}
                                    title="Thông tin thanh toán"
                                    className=""
                                    items={detail?.contract?.payments?.map((o: any, index: number) => {
                                        return {
                                            key: o.id,
                                            children: (
                                                <Card
                                                    size="small"
                                                    title={
                                                        <Space>
                                                            <Text>Thanh toán {index + 1}</Text>
                                                            {_.get(o, "status") && (
                                                                <Tag
                                                                    color={_.get(
                                                                        applyPaymentStatusColor,
                                                                        _.get(o, "status")
                                                                    )}
                                                                >
                                                                    {_.get(o, "status")}
                                                                </Tag>
                                                            )}
                                                        </Space>
                                                    }
                                                >
                                                    <div className="form-group">
                                                        <label className="ant-descriptions-item-label">Số tiền</label>
                                                        <span className="">{o?.amount}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="ant-descriptions-item-label">Ngày</label>
                                                        <span>{o?.date?.split("T")[0]}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="ant-descriptions-item-label">
                                                            Ngày hết hạn
                                                        </label>
                                                        <span>{o?.expiredAt?.split("T")[0]}</span>
                                                    </div>

                                                    <div className="form-group d-flex">
                                                        <label className="ant-descriptions-item-label">Note</label>
                                                        <span
                                                            className="text-truncate d-block"
                                                            style={o?.note && { maxWidth: 150 + "px" }}
                                                        >
                                                            {o?.note}
                                                        </span>
                                                    </div>
                                                </Card>
                                            ),
                                        };
                                    })}
                                />
                                <Divider />
                                <Title level={5}>Quy trình xử lý hồ sơ</Title>
                                <WrapDescriptions
                                    column={1}
                                    title="Xử lý hồ sơ"
                                    className=""
                                    items={detail?.applySteps
                                        ?.filter((o: any) => o?.order === 1)?.[0]
                                        ?.applyProcess?.map((o: any, index: number) => {
                                            return {
                                                key: o.id,
                                                children: (
                                                    <Card
                                                        size="small"
                                                        title={
                                                            <Space>
                                                                <Text>Process {index + 1}</Text>
                                                                {_.get(o, "status") && (
                                                                    <Tag
                                                                        color={_.get(
                                                                            applyStepStatusColor,
                                                                            _.get(o, "status")
                                                                        )}
                                                                    >
                                                                        {_.get(o, "status")}
                                                                    </Tag>
                                                                )}
                                                            </Space>
                                                        }
                                                    >
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Công việc
                                                            </label>
                                                            <span className="">
                                                                {list_apply_task?.map((item) => {
                                                                    return item?.id === o?.applyTaskId
                                                                        ? item?.name
                                                                        : "";
                                                                })}
                                                            </span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Tiêu đề
                                                            </label>
                                                            <span>{o?.title}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">Mô tả</label>
                                                            <span>{o?.description}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Ngày hết hạn
                                                            </label>
                                                            <span>{o?.createdAt?.split("T")[0]}</span>
                                                        </div>
                                                        <div className="">
                                                            <Text> Gán quyền </Text>
                                                            <div className="form-group d-flex">
                                                                <label className="ant-descriptions-item-label">
                                                                    Người xử lý
                                                                </label>
                                                                <span>
                                                                    {list_user_grant?.map((item: any) => {
                                                                        return item?.id === o?.applyGrant[0]?.userId
                                                                            ? `${item?.firstName || ""} ${
                                                                                  item?.lastName || ""
                                                                              } - ${item?.code || ""}`
                                                                            : "";
                                                                    })}
                                                                </span>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="ant-descriptions-item-label">
                                                                    Ngày hết hạn
                                                                </label>
                                                                <span>
                                                                    {list_user_grant?.map((item: any) => {
                                                                        return item?.id === o?.applyGrant[0]?.userId
                                                                            ? o?.applyGrant[0]?.expiredAt?.split("T")[0]
                                                                            : "";
                                                                    })}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                ),
                                            };
                                        })}
                                />

                                <WrapDescriptions
                                    column={1}
                                    title="Gửi hồ sơ"
                                    className=""
                                    items={detail?.applySteps
                                        ?.filter((o: any) => o?.order === 2)?.[0]
                                        ?.applyProcess?.map((o: any, index: number) => {
                                            return {
                                                key: o.id,
                                                children: (
                                                    <Card
                                                        size="small"
                                                        title={
                                                            <Space>
                                                                <Text>Process {index + 1}</Text>
                                                                {_.get(o, "status") && (
                                                                    <Tag
                                                                        color={_.get(
                                                                            applyStepStatusColor,
                                                                            _.get(o, "status")
                                                                        )}
                                                                    >
                                                                        {_.get(o, "status")}
                                                                    </Tag>
                                                                )}
                                                            </Space>
                                                        }
                                                    >
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Công việc
                                                            </label>
                                                            <span className="">
                                                                {list_apply_task?.map((item) => {
                                                                    return item?.id === o?.applyTaskId
                                                                        ? item?.name
                                                                        : "";
                                                                })}
                                                            </span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Tiêu đề
                                                            </label>
                                                            <span>{o?.title}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">Mô tả</label>
                                                            <span>{o?.description}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Ngày hết hạn
                                                            </label>
                                                            <span>{o?.expiredAt?.split("T")[0]}</span>
                                                        </div>
                                                        <div className="">
                                                            <Text> Gán quyền </Text>
                                                            <div className="form-group d-flex">
                                                                <label className="ant-descriptions-item-label">
                                                                    Người xử lý
                                                                </label>
                                                                <span>
                                                                    {list_user_grant?.map((item: any) => {
                                                                        return item?.id === o?.applyGrant[0]?.userId
                                                                            ? `${item?.firstName || ""} ${
                                                                                  item?.lastName || ""
                                                                              } - ${item?.code || ""}`
                                                                            : "";
                                                                    })}
                                                                </span>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="ant-descriptions-item-label">
                                                                    Ngày hết hạn
                                                                </label>
                                                                <span>
                                                                    {list_user_grant?.map((item: any) => {
                                                                        return item?.id === o?.applyGrant[0]?.userId
                                                                            ? o?.applyGrant[0]?.expiredAt?.split("T")[0]
                                                                            : "";
                                                                    })}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                ),
                                            };
                                        })}
                                />
                                <WrapDescriptions
                                    column={1}
                                    title="Gửi kết quả"
                                    className=""
                                    items={detail?.applySteps
                                        ?.filter((o: any) => o?.order === 3)?.[0]
                                        ?.applyProcess?.map((o: any, index: number) => {
                                            return {
                                                key: o.id,
                                                children: (
                                                    <Card
                                                        size="small"
                                                        title={
                                                            <Space>
                                                                <Text>Process {index + 1}</Text>
                                                                {_.get(o, "status") && (
                                                                    <Tag
                                                                        color={_.get(
                                                                            applyStepStatusColor,
                                                                            _.get(o, "status")
                                                                        )}
                                                                    >
                                                                        {_.get(o, "status")}
                                                                    </Tag>
                                                                )}
                                                            </Space>
                                                        }
                                                    >
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Công việc
                                                            </label>
                                                            <span className="">
                                                                {list_apply_task?.map((item) => {
                                                                    return item?.id === o?.applyTaskId
                                                                        ? item?.name
                                                                        : "";
                                                                })}
                                                            </span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Tiêu đề
                                                            </label>
                                                            <span>{o?.title}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">Mô tả</label>
                                                            <span>{o?.description}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="ant-descriptions-item-label">
                                                                Ngày hết hạn
                                                            </label>
                                                            <span>{o?.expiredAt?.split("T")[0]}</span>
                                                        </div>
                                                        <div className="">
                                                            <Text> Gán quyền </Text>
                                                            <div className="form-group d-flex">
                                                                <label className="ant-descriptions-item-label">
                                                                    Người xử lý
                                                                </label>
                                                                <span>
                                                                    {list_user_grant?.map((item: any) => {
                                                                        return item?.id === o?.applyGrant[0]?.userId
                                                                            ? `${item?.firstName || ""} ${
                                                                                  item?.lastName || ""
                                                                              } - ${item?.code || ""}`
                                                                            : "";
                                                                    })}
                                                                </span>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="ant-descriptions-item-label">
                                                                    Ngày hết hạn
                                                                </label>
                                                                <span>
                                                                    {list_user_grant?.map((item: any) => {
                                                                        return item?.id === o?.applyGrant[0]?.userId
                                                                            ? o?.applyGrant[0]?.expiredAt?.split("T")[0]
                                                                            : "";
                                                                    })}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                ),
                                            };
                                        })}
                                />
                            </>
                        )}          
                    </>
                )}
            </Drawer>
        </div>
    );
};

export default ApplyDetail;
