import { useListBlogQuery } from "@redux/queries/user/blog.api";
import { authSelector } from "@redux/slices/auth.slice";
import { FC } from "react";
import { useSelector } from "react-redux";
import Blog from "./Blog";
import { Spin } from "antd";

const PAGE = 1;
const LIMIT = 8;

const ListUserBlogs: FC = () => {
    const { user } = useSelector(authSelector);
    const { data, isLoading } = useListBlogQuery({
        page: PAGE,
        limit: LIMIT,
    });
    const { items: listBlogs } = data || {};

    const userName =
        (user?.firstName || user?.lastName ? user?.firstName + " " + user?.lastName : user?.username) || "Unnamed";

    return (
        <>
            <h5 className="title-section">Bài viết của bạn gần đây</h5>

            <Spin spinning={isLoading}>
                <div className="row">
                    {listBlogs && listBlogs?.length > 0 ? (
                        listBlogs?.map(({ id, createdAt, title, shortContent, slug, thumbnail, view, like }, index) => (
                            <div
                                className="col-12 col-sm-6 col-xl-4 col-xxl-3 d-flex"
                                key={index}
                            >
                                <Blog
                                    id={id}
                                    createdAt={createdAt}
                                    title={title}
                                    shortContent={shortContent}
                                    slug={slug}
                                    thumbnail={thumbnail}
                                    view={view}
                                    like={like}
                                    userName={userName}
                                    avatar={user?.avatar}
                                />
                            </div>
                        ))
                    ) : (
                        <div className="min-h-400px"></div>
                    )}
                </div>
            </Spin>
        </>
    );
};

export default ListUserBlogs;
