import { Button, Col, Form, Input, Modal, Row, Select, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { Pagination } from "@components/pagination";
import _ from "lodash";
import { useGetListSchoolServicePaginateQuery } from "@redux/queries/admin/admin.school.service";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { useGetListScholarshipQuery } from "@redux/queries/admin/admin.scholarship";
import { listTrainingLanguage } from "@consts";

interface SearchSchoolServiceProps {
    openSearchSchoolService: boolean;
    setOpenSearchSchoolService: (visible: boolean) => void;
    selectedSchoolService?: any;
    setSelectedSchoolService?: (data: any) => void;
}

const SearchSchoolService = ({
    openSearchSchoolService,
    setOpenSearchSchoolService,
    selectedSchoolService,
    setSelectedSchoolService,
}: SearchSchoolServiceProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedSchoolService) {
            setSelectedRowKeys([selectedSchoolService]);
        }
    }, [selectedSchoolService]);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const { data, isLoading, isFetching } = useGetListSchoolServicePaginateQuery(query as any);

    const onFinish = async (values: any) => {
        const newQuery = _.pickBy(
            { ...query, ...values, page: 1 },
            (item: any) => item !== "" && item !== null && item !== undefined && item !== "-1" && item
        );
        setQuery(newQuery);
    };

    const { data: list_school, isLoading: isLoadingSchool, isFetching: isFetchingSchool } = useGetListSchoolQuery();
    const { data: list_train, isLoading: isLoadingTrain, error: error } = useGetListTrainingQuery();
    const {
        data: list_scholarship,
        isLoading: isLoadingScholarship,
        error: errorScholarship,
    } = useGetListScholarshipQuery();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [query]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        type: "radio" as any,
    };

    const columns = [
        {
            title: <div className="text-title-table">Trường</div>,
            key: "school",
            dataIndex: "school",
            render: (text: string, record: any) => {
                return record?.school?.language[0]?.name;
            },
        },
        {
            title: <div className="text-title-table">Học bổng</div>,
            key: "schoolScholarship",
            dataIndex: "schoolScholarship",
            render: (text: string, record: any) => {
                return record?.scholarship?.language[0]?.title;
            },
        },
        {
            title: <div className="text-title-table">Hệ đào tạo</div>,
            key: "training",
            dataIndex: "training",
            render: (text: string, record: any) => {
                return record?.training?.language[0]?.title;
            },
        },
        {
            title: <div className="text-title-table">Tổng số</div>,
            key: "total",
            dataIndex: "total",
        },
        {
            title: <div className="text-title-table">Đã tuyển</div>,
            key: "apply",
            dataIndex: "apply",
        },
    ];

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openSearchSchoolService}
                onCancel={() => {
                    form.resetFields();
                    setOpenSearchSchoolService(false);
                }}
                title={"Search School Service"}
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        disabled={selectedRowKeys.length === 0}
                        onClick={() => {
                            setSelectedSchoolService && setSelectedSchoolService(selectedRowKeys[0]);
                            form.resetFields();
                            setOpenSearchSchoolService(false);
                        }}
                    >
                        Xác nhận
                    </Button>,
                ]}
                width={1000}
            >
                <Row>
                    <Col span={8}>
                        <div className="body-component">
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name={"scholarshipId"}
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            allowClear
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                            showSearch
                                        >
                                            {list_scholarship &&
                                                list_scholarship.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Học bổng</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="trainingId"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                        >
                                            {list_train &&
                                                list_train.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Hệ đào tạo</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="trainingLanguage"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                        >
                                            {listTrainingLanguage.map((o: any, i: any) => {
                                                return (
                                                    <Select.Option
                                                        key={o.value}
                                                        value={o.value}
                                                    >
                                                        {o.label}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <label>Chương trình đào tạo</label>
                                </div>
                            </div>
                            <Row justify="end">
                                <Space>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            onClick={() => {
                                                form.submit();
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            onClick={() => {
                                                form.resetFields();
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Row>
                        </div>
                    </Col>
                    <Col span={16}>
                        <Table
                            rowSelection={rowSelection}
                            rowKey="id"
                            columns={columns}
                            dataSource={(data?.items as any) || []}
                            pagination={false}
                            loading={isLoading || isFetching}
                        />
                        <Pagination
                            onSizeChange={(size) => setQuery({ ...query, limit: size })}
                            total={data?.meta.totalItems || 0}
                            showSize={true}
                            totalPage={data?.meta.totalPages || 0}
                            onChangePage={(page) => setQuery({ ...query, page: page })}
                            defaultCurrentPage={query.page}
                        />
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default SearchSchoolService;
