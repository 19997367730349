import { useForgotPassMutation } from "@redux/queries/auth.api";
import { history } from "@utils/helper-router";
import { useFormik } from "formik";
import React, { useMemo, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import AlertPage from "./components/AlertPage";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FogotPass: React.FunctionComponent = () => {
    const [doForgotPass, result] = useForgotPassMutation();
    const recaptchaRef = useRef<any>(null);
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Địa chỉ email không hợp lệ").required("Không được để trống"),
        }),
        onSubmit: async (values: any) => {
            await recaptchaRef.current.execute();
            const recaptchaValue = recaptchaRef && recaptchaRef.current && recaptchaRef.current.getValue();

            const result: any = await doForgotPass({
                ...values,
                recaptcha: recaptchaValue,
            });
        },
    });

    if (result.data) {
        return (
            <AlertPage
                title="Gửi email thành công!"
                description="Chúng tôi đã gửi một thông báo về email của bạn. Vui lòng check lại email và làm theo hướng dẫn.
                Chúc bạn vui vẻ!"
                button={{
                    navigateTo: "/",
                    text: "Quay về Trang chủ",
                }}
            />
        );
    }

    const errorMessage = () => {
        switch ((result?.error as any)?.errorCode) {
            case 1015:
                return (
                    <>
                        Chúng tôi đã gửi mail thay đổi mật khẩu tới bạn trước đó.
                        <br />
                        Vui lòng kiểm tra lại email.
                    </>
                );
            case 404:
                return "Email chưa đăng ký tài khoản";
            default:
                break;
        }
    };

    return (
        <div className="login-auth">
            <div className="ocean">
                <div className="wave" />
                <div className="wave" />
            </div>
            <div className="header-login container">
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <Link
                            className="logo-header"
                            to="/"
                        >
                            <img
                                src="images/logo.png"
                                alt="logo-riba"
                            />
                        </Link>
                    </div>
                    <div className="col-12 col-sm-8">
                        <p className="dont-account">
                            {t("cmp_forgotPass_tit", "Bạn chưa có tài khoản?")}{" "}
                            <Link
                                to="/register"
                                className="btn-green"
                            >
                                {t("page_forgot_redirect", "Đăng ký ngay!")}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

            <section className="inline-block">
                <form
                    onSubmit={formik.handleSubmit}
                    id="signin"
                >
                    <div className="title-sign">
                        <h1>{t("page_forgot_tit", "Bạn quên mật khẩu?")}</h1>
                        <p>
                            {t(
                                "page_forgot_desc",
                                "Trường hợp bạn quên mật khẩu, xin vui lòng nhập email của bạn. Chúng tôi sẽ gửi một thông báo về email của bạn."
                            )}
                        </p>
                    </div>
                    <div className="body-sign">
                        <div className="form-group">
                            <div className="form-floating">
                                <input
                                    id="email"
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    type="text"
                                    className="form-control"
                                    placeholder="example@site.com"
                                />
                                <label>{t("page_forgot_email", "Email hoặc Tên đăng nhập")}</label>
                                <i className="bi-envelope" />

                                {(result?.error as any)?.message && (
                                    <p className="text-red text-center">{errorMessage()}</p>
                                )}
                            </div>
                            {formik.touched.email && formik.errors.email ? (
                                // @ts-ignore
                                <p className="text-red">{formik?.errors?.email}</p>
                            ) : null}
                        </div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY as any}
                            ref={recaptchaRef}
                            size="invisible"
                        />

                        <div className="mirror mirror-child">
                            <button
                                className="btn-submit btn-blue"
                                type="submit"
                                disabled={result.isLoading}
                            >
                                {result.isLoading && (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                <span className="ps-2">{t("page_forgotPass_send", "Gửi cho tôi")}</span>
                            </button>
                        </div>
                    </div>
                </form>
                <p className="copyright-sign">{t("page_forgotpass_inc", "Bản quyền © 2023 thuộc Studychina, Inc.")}</p>
            </section>
        </div>
    );
};

export default FogotPass;
