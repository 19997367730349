import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import queryString from "query-string";

const BASE = "/api/specialized";

export const adminSpecializedApi = createApi({
    reducerPath: "adminSpecializedApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Specialized"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSpecialized: builder.query<Array<ISpecialized>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListSpecializedPaginate: builder.query<
                IResponseDataAdmin<ISpecialized>,
                { page: number; limit: number; title?: string; code?: string }
            >({
                query: (query) => {
                    const queryObject: any = {
                        url: `${BASE}/paginate?` + queryString.stringify(query, {arrayFormat: 'bracket'}),
                        method: "GET",
                    };
                    return queryObject;
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Specialized" as const, id })),
                              { type: "Specialized", id: "Specialized-LIST" },
                          ]
                        : [{ type: "Specialized", id: "Specialized-LIST" }],
            }),
            createSpecialized: builder.mutation<any, IAdminSpecializedInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: {
                        ...params,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Specialized"],
            }),
            updateSpecialized: builder.mutation<any, IAdminSpecializedInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        ...params,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Specialized"],
            }),
            deleteSpecialized: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Specialized"],
            }),
            deleteMultiSpecialized: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Specialized"],
            }),
        };
    },
});

export const {
    useGetListSpecializedQuery,
    useGetListSpecializedPaginateQuery,
    useLazyGetListSpecializedPaginateQuery,
    useCreateSpecializedMutation,
    useUpdateSpecializedMutation,
    useDeleteSpecializedMutation,
    useDeleteMultiSpecializedMutation,
} = adminSpecializedApi;
