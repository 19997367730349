import { GROUP_TAB } from "@consts";
import { actionSelector, updateActiveTab } from "@redux/slices/action.slice";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const Navbar: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { activeTab } = useSelector(actionSelector);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleTabClick = (tab: string) => {
        dispatch(updateActiveTab(tab));
        setDropdownOpen(false); // Close the dropdown after selecting a tab
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <ul className="justify-content-center nav-list-title">
            <li className={`nav-item ${activeTab === GROUP_TAB.GENERAL ? "active" : ""}`}>
                <button
                    className="nav-link"
                    onClick={() => handleTabClick(GROUP_TAB.GENERAL)}
                >
                    <i className="bi-house-door" /> Tổng quan
                </button>
            </li>
            <li className={`nav-item ${activeTab === GROUP_TAB.IMAGE ? "active" : ""}`}>
                <button
                    className="nav-link"
                    onClick={() => handleTabClick(GROUP_TAB.IMAGE)}
                >
                    <i className="bi-card-image" /> Ảnh
                </button>
            </li>
            <li className={`nav-item ${activeTab === GROUP_TAB.VIDEO ? "active" : ""}`}>
                <button
                    className="nav-link"
                    onClick={() => handleTabClick(GROUP_TAB.VIDEO)}
                >
                    <i className="bi-camera-video" /> Video
                </button>
            </li>
            <li className={`nav-item ${activeTab === GROUP_TAB.MAJOR ? "active" : ""}`}>
                <button
                    className="nav-link"
                    onClick={() => handleTabClick(GROUP_TAB.MAJOR)}
                >
                    <i className="bi-journal-bookmark-fill" /> Chuyên ngành
                </button>
            </li>
            <li className={`nav-item ${activeTab === GROUP_TAB.SCHOLARSHIP ? "active" : ""}`}>
                <button
                    className="nav-link"
                    onClick={() => handleTabClick(GROUP_TAB.SCHOLARSHIP)}
                >
                    <i className="bi-award" /> Học bổng
                </button>
            </li>
            <li className={`nav-item ${activeTab === GROUP_TAB.REVIEW ? "active" : ""}`}>
                <button
                    className="nav-link"
                    onClick={() => handleTabClick(GROUP_TAB.REVIEW)}
                >
                    <i className="bi-hand-thumbs-up" /> Review
                </button>
            </li>
            <li className={`nav-item ${activeTab === GROUP_TAB.RELATED_POST ? "active" : ""}`}>
                <button
                    className="nav-link"
                    onClick={() => handleTabClick(GROUP_TAB.RELATED_POST)}
                >
                    <i className="bi-pencil-square" /> Bài viết liên quan
                </button>
            </li>
            <li className={`nav-item ${activeTab === GROUP_TAB.FAQ ? "active" : ""}`}>
                <button
                    className="nav-link"
                    onClick={() => handleTabClick(GROUP_TAB.FAQ)}
                >
                    <i className="bi-question-circle" /> Hỏi &amp; Đáp
                </button>
            </li>
        </ul>
    );
};

export default Navbar;
