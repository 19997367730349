import Img01 from "@assets/images/item-01.jpg";
import NumberFormat from "@components/NumberFormat";
import { linkCDN } from "@utils/common";
import { format } from "date-fns";
import { FC } from "react";
import renderHTML from "react-render-html";

interface INewsContent {
    data: ISchoolNews | undefined;
}

const NewsContent: FC<INewsContent> = ({ data }) => {
    const { createdAt, creator, thumbnail, view, status, language } = data || {};

    const userName = creator?.firstName ? `${creator?.firstName} ${creator?.lastName}` : creator?.username;

    return (
        <div className="box-component">
            <div className="body-component">
                <article className="detail-content">
                    <img
                        className="img-fluid"
                        src={linkCDN(thumbnail, Img01)}
                        alt="img-item"
                    />
                    <ul className="infor-out-detail">
                        <li>
                            <i className="bi-calendar2-minus"></i>{" "}
                            {createdAt && format(new Date(createdAt), "MMMM dd, yyyy")}
                        </li>
                        <li>
                            <i className="bi-person"></i> {userName}
                        </li>
                        <li>
                            <i className="bi-eye-fill"></i>{" "}
                            <b>
                                <NumberFormat value={view} />
                            </b>{" "}
                            Views
                        </li>
                    </ul>
                    <h1>{language?.[0]?.title}</h1>
                    <p className="summary">{language?.[0]?.shortContent}</p>

                    <p className="content pe-1 render-content-html">
                        {language?.[0]?.content && renderHTML(language?.[0]?.content)}
                    </p>
                </article>
            </div>
        </div>
    );
};

export default NewsContent;
