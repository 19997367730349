import ModalCustome from "@components/modal/Modal";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS } from "@consts";
import { useCreateProjectMutation } from "@redux/queries/admin/admin.project";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

const AddProject: React.FunctionComponent = () => {
    const [createProject, result] = useCreateProjectMutation();
    const [open, setOpen] = useState<boolean>(false);
    const closeModal = () => setOpen(false);
    const openModal = () => {
        formik.resetForm();
        setOpen(true);
    };

    const { showToast } = useToast();

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            description: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Không được để trống"),
            description: Yup.string().required("Không được để trống"),
        }),
        onSubmit: async (values) => {
            if (values.id === "") {
                const result = await createProject({
                    name: values.name,
                    description: values.description,
                });
                if ("error" in result) {
                    showToast({ ...TOAST_CREATE_ERROR });
                }

                if ("data" in result) {
                    formik.resetForm();
                    closeModal();
                    showToast({ ...TOAST_CREATE_SUCCESS });
                }
                // show error use toast
            }
        },
    });

    return (
        <>
            <button
                type="button"
                className="btn-blue"
                onClick={openModal}
            >
                Add New
            </button>
            <ModalCustome
                open={open}
                onClose={closeModal}
                title="Add Project"
                footer={[
                    <button
                        key="1"
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                        className="btn-blue"
                    >
                        {result.isLoading && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <span className="ps-2">Submit</span>
                    </button>,
                    <button
                        key="2"
                        type="button"
                        onClick={closeModal}
                        className="text-box-gray"
                    >
                        Close
                    </button>,
                ]}
            >
                <form>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            <label>Tên dự án</label>
                        </div>
                        {formik.touched.name && formik.errors.name ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.name}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                            />
                            <label>Mô Tả</label>
                        </div>
                        {formik.touched.description && formik.errors.description ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.description}</p>
                        ) : null}
                    </div>
                </form>
            </ModalCustome>
        </>
    );
};

export default AddProject;
