import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import { Button, Col, Collapse, Form, Input, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useGetListUserQuery } from "@redux/queries/admin/admin.user";
import SearchUser from "./ModalSearchUser";
import SearchSchoolScholarshipTraining from "./ModalSearchSchoolScholarshipTraining";
import { useGetListMajorQuery } from "@redux/queries/admin/admin.majors";
import { useGetListSchoolScholarshipTrainingPaginateQuery } from "@redux/queries/admin/admin.school.scholarship.training";
import { listApplyStatus } from "../apply.enum";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterApply: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const { data: list_school, isLoading, isFetching } = useGetListSchoolQuery();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    const { data: list_user, isLoading: isLoadingUser, isFetching: isFetchingUser } = useGetListUserQuery();
    const { data: list_major, isLoading: isLoadingMajor, error: errorMajor } = useGetListMajorQuery({});
    const {
        data: list_specialized,
        isLoading: isLoadingSpecialized,
        error: errorSpecialized,
    } = useGetListSpecializedQuery();
    const [openSearchUser, setOpenSearchUser] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [openSearchSchoolScholarshipTraining, setOpenSearchSchoolScholarshipTraining] = useState<boolean>(false);
    const [selectedSchoolScholarshipTraining, setSelectedSchoolScholarshipTraining] = useState<any>(null);

    useEffect(() => {
        if (selectedUser) {
            form.setFieldsValue({ userId: selectedUser });
        }
    }, [selectedUser]);

    useEffect(() => {
        if (selectedSchoolScholarshipTraining) {
            form.setFieldsValue({ schoolScholarshipTrainingId: selectedSchoolScholarshipTraining });
        }
    }, [selectedSchoolScholarshipTraining]);

    const { data: list_school_scholarship_training } = useGetListSchoolScholarshipTrainingPaginateQuery({
        page: 1,
        limit: 100,
    });

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Collapse
                        bordered={false}
                        ghost
                    >
                        <Collapse.Panel
                            header="Tìm kiếm nâng cao"
                            key="1"
                        >
                            <Row>
                                {/* col 1 */}
                                <Col span={12}>
                                    <div className="body-component">
                                        <div className="form-group h-50">
                                            <div
                                                className="form-group"
                                                style={{
                                                    width: "80%",
                                                    display: "inline-block",
                                                }}
                                            >
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="userId"
                                                        className="form-floating"
                                                    >
                                                        <Select
                                                            className="form-control form-floating"
                                                            bordered={false}
                                                            placeholder=""
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                (option?.children as any)
                                                                    ?.toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={(v: string) => {
                                                                setSelectedUser(v);
                                                            }}
                                                        >
                                                            {list_user &&
                                                                list_user.map((o: any, i: any) => {
                                                                    return (
                                                                        <Select.Option
                                                                            key={o.id}
                                                                            value={o.id}
                                                                        >
                                                                            {`${o.firstName || ""} ${
                                                                                o.lastName || ""
                                                                            } - ${o.code || ""}`}
                                                                        </Select.Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </Form.Item>
                                                    <label>Khách hàng</label>
                                                </div>
                                            </div>
                                            <Button
                                                type="primary"
                                                style={{
                                                    height: "calc(2.6rem + 5px)",
                                                    width: "20%",
                                                    display: "inline-block",
                                                }}
                                                icon={<SearchOutlined />}
                                                onClick={() => setOpenSearchUser(true)}
                                            >
                                                Search
                                            </Button>
                                        </div>
                                        <div className="form-group h-50">
                                            <div
                                                className="form-group"
                                                style={{
                                                    width: "80%",
                                                    display: "inline-block",
                                                }}
                                            >
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="schoolScholarshipTrainingId"
                                                        className="form-floating"
                                                    >
                                                        <Select
                                                            className="form-control form-floating"
                                                            bordered={false}
                                                            placeholder=""
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                (option?.children as any)
                                                                    ?.toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={(v: string) => {
                                                                setSelectedSchoolScholarshipTraining(v);
                                                            }}
                                                        >
                                                            {list_school_scholarship_training &&
                                                                list_school_scholarship_training?.items?.map(
                                                                    (o: any, i: any) => {
                                                                        const schoolName =
                                                                            o.school?.language[0]?.name || "";
                                                                        const scholarshipName =
                                                                            o.schoolScholarship?.scholarship
                                                                                ?.language[0]?.title || "";
                                                                        const trainingName =
                                                                            o.training?.language[0]?.title || "";
                                                                        const name = `${schoolName} - ${scholarshipName} - ${trainingName}`;
                                                                        return (
                                                                            <Select.Option
                                                                                key={o.id}
                                                                                value={o.id}
                                                                            >
                                                                                {name}
                                                                            </Select.Option>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </Form.Item>
                                                    <label>Trường - Học bổng - Hệ đào tạo</label>
                                                </div>
                                            </div>
                                            <Button
                                                type="primary"
                                                style={{
                                                    height: "calc(2.6rem + 5px)",
                                                    width: "20%",
                                                    display: "inline-block",
                                                }}
                                                icon={<SearchOutlined />}
                                                onClick={() => setOpenSearchSchoolScholarshipTraining(true)}
                                            >
                                                Search
                                            </Button>
                                        </div>
                                        <Row>
                                            <Col span={12}>
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name="year"
                                                            className="form-floating"
                                                            rules={[
                                                                {
                                                                    pattern: /^[0-9]*$/,
                                                                    message: "Phải nhập số",
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                type="text"
                                                                className="no-icon form-floating"
                                                            />
                                                        </Form.Item>
                                                        <label>Năm</label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col
                                                span={11}
                                                offset={1}
                                            >
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name="period"
                                                            className="form-floating"
                                                            rules={[
                                                                {
                                                                    pattern: /^[0-9]*$/,
                                                                    message: "Phải nhập số",
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                type="text"
                                                                className="no-icon form-floating"
                                                            />
                                                        </Form.Item>
                                                        <label>Giai đoạn</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>

                                {/* col 2 */}
                                <Col span={6}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="schoolId"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        showSearch
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {list_school &&
                                                            list_school.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].name}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Trường</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="passPoint"
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: "Phải nhập số",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Điểm đỗ</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={6}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={"majorId"}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                    >
                                                        {list_major &&
                                                            list_major.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Ngành học</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="createdBy"
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Tạo bởi</label>
                                            </div>
                                        </div>
                                        <div className="form-floating">
                                            <Form.Item
                                                name="specializeds"
                                                className="form-floating"
                                            >
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {list_specialized &&
                                                        list_specialized.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Chuyên ngành</label>
                                        </div>
                                        <Row justify="end">
                                            <Space>
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        Search
                                                    </Button>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => {
                                                            form.resetFields();
                                                        }}
                                                    >
                                                        Clear
                                                    </Button>
                                                </Form.Item>
                                            </Space>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </Form>
                <SearchUser
                    openSearchUser={openSearchUser}
                    setOpenSearchUser={(v: boolean) => setOpenSearchUser(v)}
                    selectedUser={selectedUser}
                    setSelectedUser={(v: any) => setSelectedUser(v)}
                />
                <SearchSchoolScholarshipTraining
                    openSearchSchoolScholarshipTraining={openSearchSchoolScholarshipTraining}
                    setOpenSearchSchoolScholarshipTraining={(v: boolean) => setOpenSearchSchoolScholarshipTraining(v)}
                    selectedSchoolScholarshipTraining={selectedSchoolScholarshipTraining}
                    setSelectedSchoolScholarshipTraining={(v: any) => setSelectedSchoolScholarshipTraining(v)}
                />
            </div>
        </>
    );
};

export default FilterApply;
