import bellIcon from "@assets/images/icons/bell.svg";
import {
    useReadAllUserNotificationMutation,
    useReadUserNotificationMutation,
    useGetNotificationByUserQuery,
} from "@redux/queries/user/notification.api";
import { useUser } from "@redux/slices/auth.slice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { format } from "date-fns";
import { FC, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import { Spin } from "antd";

const Notifications: FC = () => {
    const [page, setPage] = useState(1);
    const user = useUser();
    const [triggerReadAll, resultReadAll] = useReadAllUserNotificationMutation();
    const [triggerReadNoti, resultReadNoti] = useReadUserNotificationMutation();
    const { data, isLoading } = useGetNotificationByUserQuery(
        user?.id
            ? {
                  page: page,
                  limit: 5,
                  userId: user?.id || "",
              }
            : skipToken
    );

    const { items, meta, unread = 0 } = data || {};

    const handleClickNotification = (id: string) => () => {
        if (id) {
            triggerReadNoti({ id });
        }
    };

    return (
        <div
            // id="contact-alert"
            className="btn-group"
        >
            <button
                type="button"
                className="btn dropdown-toggle btn-head-top me-3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <img
                    src={bellIcon}
                    alt=""
                />
                {unread > 0 && <span className="badge">{unread}</span>}
            </button>

            <div className="dropdown-menu dropdown-menu-end scrollbar mh-450">
                {items?.map((item, index) => (
                    <Fragment key={index}>
                        <Link
                            to="/user/profile#notice-tab"
                            title=""
                            onClick={handleClickNotification(item?.id)}
                        >
                            <div className="d-flex align-items-center justify-content-between">
                                <span>{item?.language?.[0]?.title}</span>
                                {!item?.isRead && <span className="dot-icon"></span>}
                            </div>
                            <time>{item?.createdAt && format(new Date(item?.createdAt), "dd/MM/yyyy HH:mm:ss")}</time>
                            <small className="text-truncate">
                                {item?.language?.[0]?.content && renderHTML(item?.language?.[0]?.content)}
                            </small>
                        </Link>
                        <hr />
                    </Fragment>
                ))}

                <div className="button-a text-center">
                    <Link to="/user/profile#notice-tab">Xem tất cả thông báo</Link>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
