import { useFormik } from "formik";
import React from "react";

export enum SORT_BY {
    createdAt = "createdAt",
    rank = "rank",
    like = "like",
    favorite = "favorite",
    view = "view",
}

interface SearchServiceFormProps {
    onSearch: (value: { searchInput: string; sortBy: SORT_BY }) => void;
}

const SearchServiceForm: React.FunctionComponent<SearchServiceFormProps> = (props) => {
    const formik = useFormik({
        initialValues: {
            searchInput: "",
            sortBy: SORT_BY.rank,
        },
        onSubmit: async (values) => {
            props.onSearch(values);
        },
    });

    return (
        <form
            className="d-flex justify-content-center"
            onSubmit={formik.handleSubmit}
        >
            <div className="rel-icon me-2">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Tìm kiếm..."
                    id="searchInput"
                    name="searchInput"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.searchInput}
                />
                <i className="bi-search" />
            </div>
            <button
                className="btn-blue"
                type="submit"
            >
                Tìm kiếm
            </button>
        </form>
    );
};

export default SearchServiceForm;
