import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import { Advice } from "@pages/pro-tool/schools/DetailSchool/components/Overview/AdviceForm";

export const authActionApi = createApi({
    reducerPath: "authAction",
    tagTypes: ["AuthAction", "Suggest-School", "Public", "School"],
    keepUnusedDataFor: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            paginateSuggestSchool: builder.query<ResponseWithPaginateFormat<ShortSchool>, InputSearchSchool>({
                query: (params) => {
                    return {
                        url: `/api/school/suggest`,
                        params: params,
                    };
                },
                providesTags: ["Suggest-School"],
            }),
            schoolSearch: builder.query<ResponseWithPaginateFormat<ShortSchool>, InputSearchSchool>({
                query: (params) => {
                    return {
                        url: `/api/school/paginate`,
                        params: params,
                    };
                },
                providesTags: ["School"],
            }),
            userSchoolLike: builder.query<Array<string>, any>({
                query: (params) => {
                    return {
                        url: `/api/user/school/like`,
                        params: params,
                    };
                },
                keepUnusedDataFor: 0,
                providesTags: ["Suggest-School"],
            }),
            like: builder.mutation<any, { schoolId: string }>({
                query: (params) => ({
                    url: `/api/school/like/${params.schoolId}`,
                    method: "POST",
                    body: {
                        id: params.schoolId,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Suggest-School", "School"],
            }),
            unlike: builder.mutation<any, { schoolId: string }>({
                query: (params) => ({
                    url: `/api/school/unlike/${params.schoolId}`,
                    method: "POST",
                    body: {
                        id: params.schoolId,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Suggest-School", "School"],
            }),
            userSchoolfavorite: builder.query<Array<string>, any>({
                query: (params) => {
                    return {
                        url: `/api/user/school/favorite`,
                        params: params,
                    };
                },
                keepUnusedDataFor: 0,
                providesTags: ["Suggest-School"],
            }),
            favorite: builder.mutation<any, { schoolId: string }>({
                query: (params) => ({
                    url: `/api/school/favorite/${params.schoolId}`,
                    method: "POST",
                    body: {
                        id: params.schoolId,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Suggest-School"],
            }),
            unfavorite: builder.mutation<any, { schoolId: string }>({
                query: (params) => ({
                    url: `/api/school/unfavorite/${params.schoolId}`,
                    method: "POST",
                    body: {
                        id: params.schoolId,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Suggest-School"],
            }),
            consultation: builder.mutation<any, Advice>({
                query: (params) => ({
                    url: `/api/consultation`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),

            serviceSearch: builder.query<ResponseWithPaginateFormat<ShortService>, InputSearchSchool>({
                query: (params) => {
                    return {
                        url: `/api/school/service/v2/paginate`,
                        params: params,
                    };
                },
            }),
        };
    },
});

export const {
    useLazySchoolSearchQuery,
    useLazyServiceSearchQuery,
    useServiceSearchQuery,
    usePaginateSuggestSchoolQuery,
    useLazyPaginateSuggestSchoolQuery,
    useUserSchoolLikeQuery,
    useLikeMutation,
    useUnlikeMutation,
    useUserSchoolfavoriteQuery,
    useFavoriteMutation,
    useUnfavoriteMutation,
    useConsultationMutation,
} = authActionApi;
