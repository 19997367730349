import React, { useState, useEffect, useMemo } from "react";
import SearchSchoolForm, { SORT_BY } from "@pages/pro-tool/schools/ListSchool/components/SearchSchoolForm";
import SchoolItem from "@pages/pro-tool/schools/ListSchool/components/SchoolItem";
import FilterSchools from "@pages/pro-tool/schools/ListSchool/components/FilterSchools";
import { FormikProvider, useFormik } from "formik";
import { Pagination } from "@components/pagination";
import { useLazyPaginateSuggestSchoolQuery, useUserSchoolLikeQuery } from "@redux/queries/user/authaction.api";
import { useSelector } from "react-redux";
import { authSelector } from "@redux/slices/auth.slice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const SIZE = 12;

const Schools: React.FunctionComponent = () => {
    const [triggerSearchSchool, resultSchools] = useLazyPaginateSuggestSchoolQuery();
    const [filter, setFilter] = useState<SchoolFilter>();
    const [searchString, setSearchString] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(SIZE);
    const [sortBy, setSortBy] = useState<SORT_BY>(SORT_BY.rank);
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);

    const paramsSchool = useMemo(() => {
        const params: any = {};
        const listSchools = resultSchools?.data?.items || [];
        const ids: any = listSchools?.map((e) => e.id);
        ids?.map((e: any, i: number) => {
            params[`schoolIds[${i}]`] = e;
        });

        if (ids.length === 0) {
            return undefined;
        }
        return params;
    }, [resultSchools?.data]);

    const { data: likeData } = useUserSchoolLikeQuery(isLogin ? (paramsSchool ? paramsSchool : skipToken) : skipToken);

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {},
    });

    const handleChangeFilter = (values: SchoolFilter) => {
        setPage(1);
        setFilter(values);
    };

    useEffect(() => {
        const params: any = {
            limit: limit,
            page: page,
            s: searchString,
            sortBy: sortBy,
            rankFrom: filter?.rank_from,
            rankTo: filter?.rank_to,
        };

        filter?.area.map((e, i) => {
            params[`areas[${i}]`] = e;
        });
        filter?.city.map((e, i) => {
            params[`cities[${i}]`] = e;
        });
        filter?.school_project.map((e, i) => {
            params[`projects[${i}]`] = e;
        });
        filter?.school_type.map((e, i) => {
            params[`types[${i}]`] = e;
        });
        filter?.major.map((e, i) => {
            params[`majors[${i}]`] = e;
        });
        filter?.specialized.map((e, i) => {
            params[`specializes[${i}]`] = e;
        });

        if (isLogin) {
            triggerSearchSchool(params);
        }
    }, [filter, page, searchString, sortBy, limit, isLogin]);

    return (
        <section id="content-main">
            <Spin
                spinning={!isLogin}
                indicator={
                    <LoadingOutlined
                        style={{
                            opacity: 0,
                        }}
                        spin
                    />
                }
            >
                <div className="box-component bg-filter">
                    <div className="area-button-mobile">
                        <button
                            type="button"
                            className="btn-blue btn-filter"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                        >
                            <i className="bi-filter"></i> Lọc
                        </button>
                    </div>
                    <div className="body-component filter-form">
                        <h5>Đề Xuất Trường</h5>
                        <SearchSchoolForm
                            onSearch={({ searchInput, sortBy }) => {
                                setSearchString(searchInput);
                                setSortBy(sortBy);
                                setPage(1);
                            }}
                        />
                        <p className="result-p">
                            Chúng tôi tìm thấy <b>{resultSchools.data?.meta.totalItems}</b> kết quả cho bộ lọc của bạn.
                            {/* với từ khóa <b>"Trường An Nam"</b>. */}
                        </p>
                    </div>
                </div>
            </Spin>
            <div className="frame-box">
                {isLogin ? (
                    <div className="content-left order-b">
                        {
                            <Spin spinning={resultSchools.isLoading || resultSchools.isFetching}>
                                <div className="row">
                                    {resultSchools.data?.items?.map((e, i) => (
                                        <SchoolItem
                                            item={{
                                                id: e.id,
                                                background: e.background,
                                                views: e.view,
                                                rank: e.rank,
                                                rate: e.rate,
                                                logo: e?.logo ? process.env.REACT_APP_CDN + e.logo : "",
                                                name: e?.language?.find((e) => e.lang === "vi")?.name,
                                                saved: 0,
                                                like: e.likeTotal,
                                                area: e.area,
                                                code: e.code,
                                                project: e.projects.map((e) => e.name),
                                                liked: likeData?.includes(e.id),
                                                slug: e.slug,
                                                supportApply: e.supportApply,
                                            }}
                                            key={i}
                                        />
                                    ))}
                                </div>
                            </Spin>
                        }
                        <div className="row">
                            <Pagination
                                onSizeChange={(size) => {
                                    setLimit(size), setPage(1);
                                }}
                                total={resultSchools.data?.meta.totalItems || 0}
                                showSize={true}
                                totalPage={resultSchools.data?.meta.totalPages || 0}
                                onChangePage={setPage}
                                defaultCurrentPage={1}
                                listSize={[12, 24, 50, 100]}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="content-left order-b">
                        <h4 className="box-valid-login">Vui Lòng đăng nhập để sử dụng tính năng!</h4>
                    </div>
                )}

                <div
                    className="offcanvas offcanvas-end"
                    tabIndex={1}
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="content-right order-a">
                        <Spin
                            spinning={!isLogin}
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        opacity: 0,
                                    }}
                                    spin
                                />
                            }
                        >
                            <FormikProvider value={formik}>
                                <FilterSchools onChange={handleChangeFilter} />
                            </FormikProvider>
                        </Spin>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </section>
    );
};

export default Schools;
