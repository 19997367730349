import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useCreateApplyTaskMutation, useUpdateApplyTaskMutation } from "@redux/queries/admin/admin.apply.task";
import { validateNoSpaces } from "@utils/common";
import { Button, Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { UserRoleGrantList } from "../apply-task.const";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createApplyTask, isLoadingCreate] = useCreateApplyTaskMutation();
    const [updateApplyTask, isLoadingUpdate] = useUpdateApplyTaskMutation();
    const { showToast } = useToast();

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue(dataEdit);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updateApplyTask({
                  id: dataEdit.id,
                  ...values,
              })
            : await createApplyTask({
                  ...values,
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Apply Task" : "Add Apply Task"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="name"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                    {
                                        validator: (rule, value, callback) =>
                                            validateNoSpaces(rule, value, callback, "vi"),
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Tên</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="description"
                                className="form-floating"
                            >
                                <Input.TextArea
                                    style={{ height: 100 }}
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Mô tả</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="public"
                                className="form-floating"
                                initialValue={false}
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    placeholder=""
                                    options={[
                                        {
                                            label: "Công khai",
                                            value: true,
                                        },
                                        {
                                            label: "Tạm ẩn",
                                            value: false,
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <label>Công khai</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="roles"
                                className="form-floating"
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    placeholder=""
                                    mode="multiple"
                                    options={UserRoleGrantList}
                                />
                            </Form.Item>
                            <label>Roles</label>
                        </div>
                    </div>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
