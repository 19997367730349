import { useSchoolNewsHomeMutation } from "@redux/queries/user/intro.api";
import { FC, useEffect } from "react";
import Blog from "./Blog";
import { Spin } from "antd";

const PAGE = 1;
const LIMIT = 8;

const ListSchoolNews: FC = () => {
    const [fetch, { data, isLoading }] = useSchoolNewsHomeMutation();

    useEffect(() => {
        fetch({ page: PAGE, limit: LIMIT });
    }, []);

    const { items: listSchoolNews } = data || {};

    return (
        <>
            <h5 className="title-section">Bài viết mới nhất</h5>
            <Spin spinning={isLoading}>
                <div className="row">
                    {listSchoolNews && listSchoolNews?.length > 0 ? (
                        listSchoolNews?.map(
                            ({ id, createdAt, language, slug, thumbnail, view, like, creator }, index) => (
                                <div
                                    className="col-12 col-sm-6 col-xl-4 col-xxl-3 d-flex"
                                    key={index}
                                >
                                    <Blog
                                        linkType="news"
                                        id={id}
                                        createdAt={createdAt}
                                        title={language?.[0]?.title}
                                        shortContent={language?.[0]?.shortContent}
                                        slug={slug}
                                        thumbnail={thumbnail}
                                        view={view}
                                        like={like}
                                        userName={
                                            creator?.firstName
                                                ? creator?.firstName + " " + creator?.lastName
                                                : creator?.userName
                                        }
                                        avatar={creator?.avatar}
                                    />
                                </div>
                            )
                        )
                    ) : (
                        <div className="min-h-400px"></div>
                    )}
                </div>
            </Spin>
        </>
    );
};

export default ListSchoolNews;
