import { Col, Form, Input, Row } from "antd";

const ContactTab: React.FunctionComponent = () => {
    return (
        <>
            <div
                className="tab-pane fade show"
                id="contact-tab-pane"
                role="tabpanel"
                aria-labelledby="contact-tab"
                tabIndex={0}
            >
                <Row>
                    <Col span={12}>
                        <div className="row">
                            {/* col 1 */}
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="address"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Địa chỉ</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="phone"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Số điện thoại</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="email"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "email",
                                                        message: "Sai định dạng email",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Email</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="website"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Website</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="websiteInternationalInstitute"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Website Viên quốc tế</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="row">
                            {/* col 1 */}
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="facebook"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Facebook</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="youtube"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Youtube</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="instagram"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Instagram</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="twitter"
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            type: "url",
                                                            message: "Sai định dạng url",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Twitter</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="linkedin"
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            type: "url",
                                                            message: "Sai định dạng url",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Linkedin</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ContactTab;
