import React, { useState } from "react";
// import FullPost from "./FullPost";
import ShortPost from "./ShortPost";
import { usePaginateNewsBySchoolIdQuery } from "@redux/queries/user/public.api";
import { Pagination } from "@components/pagination";
import Nodata from "@components/Nodata";

const LIMIT = 12;

const TabRelatedPost: React.FunctionComponent<{ id?: string }> = ({ id }) => {
    const [page, setPage] = useState(1);
    const { data } = usePaginateNewsBySchoolIdQuery({
        id: id as any,
        page,
        limit: LIMIT,
    });

    return (
        <>
            {/* BEGIN: Content Relate Post */}
            <div className="box-component">
                <div className="body-component">
                    <div className="title-element">
                        <h5>Bài viết liên quan</h5>
                        <hr />
                    </div>

                    {data?.meta?.totalItems === 0 ? (
                        <Nodata />
                    ) : (
                        <>
                            {data?.items?.map((blog) => (
                                <ShortPost
                                    id={blog?.id}
                                    category={blog?.category?.language?.find((e) => e.lang === "vi")?.title || ""}
                                    title={blog?.language?.find((e) => e.lang === "vi")?.title || ""}
                                    username={blog?.creator?.firstName + " " + blog?.creator?.lastName}
                                    avatar={""}
                                    time={blog?.createdAt}
                                    content={blog?.language?.find((e) => e.lang === "vi")?.shortContent || ""}
                                    key={blog?.id}
                                    like={blog?.like}
                                    comment={blog?.like}
                                    save={blog?.like}
                                    thumbnail={blog?.thumbnail}
                                />
                            ))}
                            {(data?.meta?.totalItems || 0) > 0 && (
                                <div className="d-flex justify-content-center mt-4">
                                    <Pagination
                                        defaultSize={LIMIT}
                                        onSizeChange={(size) => {}}
                                        total={data?.meta.totalItems || 0}
                                        showSize={false}
                                        totalPage={data?.meta.totalPages || 0}
                                        onChangePage={setPage}
                                        defaultCurrentPage={1}
                                        listSize={[12, 24, 50, 100]}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default TabRelatedPost;
