import { history } from "@utils/helper-router";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
interface AlertPageProp {
    title: string;
    description: string;
    button: {
        navigateTo: string;
        text: string;
    };
}

const AlertPage: React.FunctionComponent<AlertPageProp> = (props) => {
    const { t } = useTranslation();
    const { title, description, button } = props;

    return (
        <div className="login-auth">
            <div className="ocean">
                <div className="wave" />
                <div className="wave" />
            </div>
            <div className="header-login container">
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <Link
                            className="logo-header"
                            to="/"
                        >
                            <img
                                src="images/logo.png"
                                alt="logo-riba"
                            />
                        </Link>
                    </div>
                    <div className="col-12 col-sm-8">
                        <p className="dont-account">
                            {t("cmp_not_has_acc", "Bạn chưa có tài khoản?")}{" "}
                            <Link
                                to="/register"
                                className="btn-green"
                            >
                                {t("cmp_register", "Đăng ký ngay!")}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <section className="inline-block">
                <form
                    id="signin"
                    method="POST"
                    action="index.html"
                >
                    <div className="title-sign">
                        <h1 className="text-green">{title}</h1>
                        <p>{description}</p>
                    </div>
                    <div className="body-sign">
                        <div className="mirror mirror-child">
                            <button
                                className="btn-submit btn-blue"
                                type="submit"
                                onClick={() => history.navigate(button.navigateTo)}
                            >
                                {button.text}
                            </button>
                        </div>
                    </div>
                </form>
                <p className="copyright-sign">{t("cmp_alert_inc", "Bản quyền © 2023 thuộc Studychina, Inc.")}</p>
            </section>
        </div>
    );
};

export default AlertPage;
