export enum ApplyGrantStatus {
    PENDING = "PENDING",
    PROCESSING = "PROCESSING",
    DONE = "DONE",
    PASSED_CHECKING = "PASSED_CHECKING",
    CANCELLED = "CANCELLED",
}

export const listApplyGrantStatus = [
    { value: ApplyGrantStatus.PENDING, label: "Pending" },
    { value: ApplyGrantStatus.PROCESSING, label: "Processing" },
    { value: ApplyGrantStatus.DONE, label: "Done" },
    { value: ApplyGrantStatus.PASSED_CHECKING, label: "Passed Checking" },
];
