import defaultAvatar from "@assets/images/default.png";
import chinaFlagIcon from "@assets/images/flag/chinese.png";
import englishFlagIcon from "@assets/images/flag/english.png";
import japanFlagIcon from "@assets/images/flag/japan.png";
import koreaFlagIcon from "@assets/images/flag/korean.png";
import russiaFlagIcon from "@assets/images/flag/russia.png";
import vietnamFlagIcon from "@assets/images/flag/vietnam.png";
import menuBar from "@assets/images/icons/menu-bar.svg";
import { showNavbar } from "@assets/js/script";
import { textUserRole } from "@consts";
import { ApplyStepStatus } from "@pages/admin/apply-step/apply-step.const";
import { applyStatusOptions } from "@pages/user/profile/components/TabApplySchool";
import { useUserApplyQuery } from "@redux/queries/user/user.api";
import { authSelector, logout } from "@redux/slices/auth.slice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Tooltip } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Notifications from "./Notifications";
import { linkCDN } from "@utils/common";

const Header = () => {
    const navigate = useNavigate();

    const { accessToken, type, user } = useSelector(authSelector);
    const dispatch = useDispatch();
    const isLogin = !!(accessToken && type && user);

    const { data: userApplies = [] } = useUserApplyQuery(!isLogin ? skipToken : undefined);

    const handleLogout = () => {
        dispatch(logout());
    };

    useEffect(() => {
        const darkenScreen = (yesno: boolean) => {
            const screenDarken = document.querySelector(".screen-darken");
            if (screenDarken) {
                if (yesno) {
                    screenDarken.classList.add("active");
                } else {
                    screenDarken.classList.remove("active");
                }
            }
        };

        const closeOffcanvas = () => {
            darkenScreen(false);
            const mobileOffcanvas = document.querySelector(".mobile-offcanvas.show");
            if (mobileOffcanvas) {
                mobileOffcanvas.classList.remove("show");
            }
            document.body.classList.remove("offcanvas-active");
        };

        const showOffcanvas = (offcanvasId: any) => {
            darkenScreen(true);
            const offcanvasElement = document.getElementById(offcanvasId);
            if (offcanvasElement) {
                offcanvasElement.classList.add("show");
                document.body.classList.add("offcanvas-active");
            }
        };

        document.querySelectorAll("[data-trigger]").forEach((everyElement) => {
            let offcanvasId = everyElement.getAttribute("data-trigger");
            if (offcanvasId) {
                everyElement.addEventListener("click", (e) => {
                    e.preventDefault();
                    showOffcanvas(offcanvasId);
                });
            }
        });

        document.querySelectorAll(".btn-close").forEach((everyButton) => {
            everyButton.addEventListener("click", (e) => {
                e.preventDefault();
                closeOffcanvas();
            });
        });

        const screenDarken = document.querySelector(".screen-darken");
        if (screenDarken) {
            screenDarken.addEventListener("click", (event) => {
                closeOffcanvas();
            });
        }

        return () => {};
    }, []); // Empty dependency array ensures the effect runs once after the initial render

    return (
        <section
            id="header-top"
            className="d-flex justify-content-between"
        >
            <div className="side-left">
                <span
                    onClick={showNavbar}
                    className="bi-icon"
                >
                    <img
                        src={menuBar}
                        alt="icon-menu"
                    />
                </span>

                <div className="ms-4 d-flex align-items-center justify-content-center list-applies-header gap-2">
                    {userApplies.length > 0 &&
                        userApplies?.map((apply, index) => {
                            const indexStep = apply?.applySteps?.findIndex(
                                (step: any) => step?.status === ApplyStepStatus.PROCESSING
                            );

                            const isSuccess = indexStep === -1;
                            const currentStep = apply?.applySteps?.[indexStep];

                            return apply?.contract ? (
                                <Tooltip
                                    trigger={["hover"]}
                                    title={
                                        <>
                                            {apply?.school?.language?.[0]?.name}
                                            <br />
                                            {!isSuccess ? (
                                                <b>
                                                    (Bước: {indexStep + 1} - {currentStep?.step?.name})
                                                </b>
                                            ) : (
                                                <b>Apply hoàn tất</b>
                                            )}
                                        </>
                                    }
                                    key={index}
                                >
                                    <div
                                        className="text-truncate-container cursor-pointer"
                                        onClick={() => navigate("/user/profile#process-apply-tab")}
                                    >
                                        <div
                                            className={`truncate-2 apply-item btn-outline-primary ${applyStatusOptions[
                                                currentStep?.status || ApplyStepStatus.DONE
                                            ]?.className}`}
                                        >
                                            {apply?.school?.language?.[0]?.name}
                                            <br />
                                            {!isSuccess ? (
                                                <b>
                                                    (Bước: {indexStep + 1} - {currentStep?.step?.name})
                                                </b>
                                            ) : (
                                                <b>Apply hoàn tất</b>
                                            )}
                                        </div>
                                    </div>
                                </Tooltip>
                            ) : null;
                        })}
                </div>

                {/* <span className="circle-icon">
                    <img
                        src={calendar}
                        alt="icon-calendar"
                    />
                </span>
                <span className="circle-icon">
                    <img
                        src={editIcon}
                        alt="icon-edit"
                    />
                </span>
                <form
                    className="search-text"
                    method="GET"
                    action=""
                >
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                    />
                    <img
                        src={searchIcon}
                        alt="icon-search"
                    />
                </form> */}
            </div>

            <div className="side-right">
                <div className="group-link">
                    {isLogin && <Notifications />}
                    {/* <button
                        type="button"
                        className="btn-head-top"
                    >
                        <img
                            src={shoppingCartIcon}
                            alt="icon-cart"
                        />
                    </button>
                    <button
                        type="button"
                        className="btn-head-top"
                    >
                        <img
                            src={msgIcon}
                            alt="icon-message"
                        />
                    </button> */}

                    <div className="btn-group group-language">
                        <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <img
                                src={chinaFlagIcon}
                                alt="flag_cn"
                            />{" "}
                            简体中文
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                >
                                    <img
                                        src={englishFlagIcon}
                                        alt="flag_en"
                                    />{" "}
                                    English
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                >
                                    <img
                                        src={chinaFlagIcon}
                                        alt="flag_cn"
                                    />{" "}
                                    简体中文
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                >
                                    <img
                                        src={russiaFlagIcon}
                                        alt="flag_ru"
                                    />{" "}
                                    Русский
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                >
                                    <img
                                        src={koreaFlagIcon}
                                        alt="flag_kr"
                                    />{" "}
                                    우리말
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                >
                                    <img
                                        src={japanFlagIcon}
                                        alt="flag_jp"
                                    />{" "}
                                    日本語
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                >
                                    <img
                                        src={vietnamFlagIcon}
                                        alt="flag_vi"
                                    />{" "}
                                    Tiếng Việt
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                {isLogin ? (
                    <div
                        id="info-user"
                        className="btn-group"
                    >
                        <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>
                                {user?.username ?? "Unnamed"}
                                <small>{user?.role ? textUserRole[user?.role] : "Unknown"}</small>
                            </span>
                            <img
                                className="p-0 object-fit-cover"
                                src={linkCDN(user?.avatar, defaultAvatar)}
                                alt="avatar"
                            />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <Link
                                    to="/user/profile"
                                    className="dropdown-item"
                                >
                                    <i className="bi-journal-text" /> Hồ sơ cá nhân
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="dropdown-item cursor-pointer"
                                    onClick={handleLogout}
                                >
                                    <i className="bi-power" /> Thoát tài khoản
                                </Link>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div id="popup-login">
                        <Link to="/login">
                            <button
                                type="button"
                                className="btn btn-blue"
                            >
                                Đăng nhập
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </section>
    );
};
export default Header;
