import defaultAvatar from "@assets/images/default.png";
import { formatNumber, linkCDN } from "@utils/common";
import { format } from "date-fns";
import { FC, memo } from "react";
import { Link } from "react-router-dom";
import Img01 from "@assets/images/img-01.jpg";

interface IBlogProps {
    id: string;
    createdAt: string;
    title: string;
    shortContent: string;
    slug: string;
    thumbnail: string;
    view: number;
    like: number;
    userName: string;
    linkType?: string;
    viewMode?: string;
    avatar?: string | null;
}

const Blog: FC<IBlogProps> = ({
    id,
    createdAt,
    title,
    shortContent,
    slug,
    thumbnail,
    view,
    like,
    linkType = "blogs",
    userName,
    viewMode = "default",
    avatar,
}) => {
    return (
        <>
            {viewMode === "default" && (
                <article className="box-component article-blog">
                    <div className="body-component ">
                        <figure className="snap">
                            <img
                                className="img-fluid"
                                src={linkCDN(thumbnail, Img01)}
                            />
                            <figcaption>
                                <h5>Xem chi tiết</h5>
                            </figcaption>
                            <Link
                                to={`/${linkType}/${id}`}
                                title="Xem chi tiết"
                            />
                        </figure>
                        <time>{format(new Date(createdAt), "dd/MM/yyyy HH:mm:ss")}</time>
                        <h5>
                            <a href="#">{title}</a>
                        </h5>
                        <p>{shortContent}</p>
                        <div className="row sub-info-article">
                            <div className="col-6">
                                <p>
                                    <img
                                        className="object-fit-cover"
                                        src={linkCDN(avatar, defaultAvatar)}
                                        alt=""
                                    />{" "}
                                    {userName}
                                </p>
                            </div>
                            <div className="col-6">
                                <ul>
                                    <li>
                                        <i className="bi-heart"></i> {formatNumber(like)}
                                    </li>
                                    <li>
                                        <i className="bi-file-earmark-text"></i> {formatNumber(view)}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </article>
            )}

            {viewMode === "card" && (
                <article className="item-article">
                    <Link to={`/${linkType}/${id}`}>
                        <img
                            className="img-fluid"
                            src={linkCDN(thumbnail, Img01)}
                            alt=""
                        />
                    </Link>
                    <div className="pd-content">
                        <time>
                            <small>{format(new Date(createdAt), "MMMM dd, yyyy")}</small>
                        </time>
                        <h5>
                            <Link to={`/${linkType}/${id}`}>{title}</Link>
                        </h5>
                        <p>{shortContent}</p>
                        <ul>
                            <li>
                                <i className="bi-person"></i> {userName}
                            </li>
                            <li>
                                <i className="bi-hand-thumbs-up"></i> {formatNumber(like)}
                            </li>
                        </ul>
                    </div>
                </article>
            )}
        </>
    );
};

export default memo(Blog);
