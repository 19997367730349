export const listSchoolServiceType = [
    {
        label: "Dịch vụ",
        value: "SERVICE",
        color: "blue",
    },
    {
        label: "Hỗ trợ",
        value: "SUPPORT",
        color: "green",
    },
];

export const listLanguageSupports = [
    {
        label: "Tiếng Việt",
        value: "VI",
    },
    {
        label: "Tiếng Anh",
        value: "EN",
    },
    {
        label: "Tiếng Trung",
        value: "CN",
    },
];
