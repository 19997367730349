import { Button, Col, Collapse, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import { UserStatus } from "../user.const";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterUser: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Collapse
                        bordered={false}
                        ghost
                    >
                        <Collapse.Panel
                            header="Tìm kiếm nâng cao"
                            key="1"
                        >
                            <Row>
                                {/* col 1 */}
                                <Col span={6}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="username"
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Tên người dùng</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="firstName"
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Họ và tên đệm</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="code"
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Code</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={6}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="lastName"
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Tên riêng</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="gender"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                    >
                                                        <Select.Option value={"Male"}>Nam</Select.Option>
                                                        <Select.Option value={"Female"}>Nữ</Select.Option>
                                                        <Select.Option value={"Other"}>Khác</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <label>Giới tính</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                {/* col 2 */}
                                <Col span={6}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="email"
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Email</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="phone"
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Điện thoại</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={6}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="emailVerified"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                    >
                                                        <Select.Option value={1}>Đã xác minh</Select.Option>
                                                        <Select.Option value={0}>Chưa xác minh</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <label>Đã xác minh email</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="status"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                    >
                                                        <Select.Option value={UserStatus.ACTIVE}>Hoạt động</Select.Option>
                                                        <Select.Option value={UserStatus.INACTIVE}>
                                                            Khóa
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <label>Trạng thái</label>
                                            </div>
                                        </div>
                                        <Row justify="end">
                                            <Space>
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        Search
                                                    </Button>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => {
                                                            form.resetFields();
                                                        }}
                                                    >
                                                        Clear
                                                    </Button>
                                                </Form.Item>
                                            </Space>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </Form>
            </div>
        </>
    );
};

export default FilterUser;
