import { DatePicker, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import ColumnChart from "./ColumnChart";
import MajorBarChart from "./MajorBarChart";
import ScholarshipBarChart from "./ScholarshipBarChart";
import SpecializedBarChart from "./SpecializedBarChart";
import TrainingBarChart from "./TrainingBarChart";

const { RangePicker } = DatePicker;

const AdminDashboard = () => {
    const [query, setQuery] = useState<any>({})

    const onChange = (value: any) => {
        const fromYear = value[0].year();
        const toYear = value[1].year();
        setQuery({
            fromYear,
            toYear
        })
    };

    return (
        <section id="content-main">
            <div className="row">
                <div className="col-12 col-lg-12 d-flex">
                    <div className="box-component">
                        <Space direction="vertical" style={{
                            padding: "20px",
                        }}>
                            <Title level={4}>Chọn khoảng thời gian</Title>
                            <RangePicker
                                picker="year"
                                placeholder={["Từ năm", "Đến năm"]}
                                onChange={onChange}
                            />
                        </Space>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-12 d-flex">
                    <div className="box-component">
                        <div className="title-component">
                            <h4>Tổng đơn Apply</h4>
                            {/* <div className="action-right">
                                <ul>
                                    <li>
                                        <button
                                            className="active"
                                            type="button"
                                        >
                                            Checking
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button">Done</button>
                                    </li>
                                    <li>
                                        <button type="button">Failed</button>
                                    </li>
                                    <li>
                                        <button type="button">All</button>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="clearfix" />
                        </div>
                        <div className="body-component">
                            <ColumnChart query={query}/>
                        </div>
                    </div>
                </div>
            </div>

            <MajorBarChart query={query}/>
            <ScholarshipBarChart query={query}/>
            <SpecializedBarChart query={query}/>
            <TrainingBarChart query={query}/>
        </section>
    );
};

export default AdminDashboard;
