import nodata from "@assets/images/img-default/no-data.png";
import avatarLg from "@assets/images/img-default/avatar-lg.jpg";
import avatar from "@assets/images/img-default/avatar.jpg";
import schoolBg from "@assets/images/img-default/bg-default.jpg";
import schoolLogo from "@assets/images/img-default/logo-school.png";

export enum USER_ROLE {
    ADMIN = 1,
    CUSTOMER = 2,
    MEMBER = 3,
    SALE_MANAGER = 4,
    MARKETING_MANAGER = 5,
    HEAD_OF_TRANSLATION = 6,
    HEAD_OF_ACADEMIC = 7,
    HEAD_OF_DOCUMENT = 8,
    CHIEF_ACCOUNTANT = 9,
    BUSINESS_STAFF = 10,
    MARKETING_STAFF = 11,
    TRANSLATOR = 12,
    ACADEMIC_STAFF = 13,
    DOCUMENT_STAFF = 14,
    ACCOUNTANT = 15,
    INTERPRETER = 16,
}

export enum HSK {
    HSK1 = "HSK1",
    HSK2 = "HSK2",
    HSK3 = "HSK3",
    HSK4 = "HSK4",
    HSK5 = "HSK5",
    HSK6 = "HSK6",
}

export enum HSKK {
    HSKK_BEGINNER = "HSKK_BEGINNER",
    HSKK_INTERMEDIATE = "HSKK_INTERMEDIATE",
    HSKK_ADVANCED = "HSKK_ADVANCED",
}

export const listRoleAdmins = [
    USER_ROLE.ADMIN,
    USER_ROLE.SALE_MANAGER,
    USER_ROLE.MARKETING_MANAGER,
    USER_ROLE.HEAD_OF_TRANSLATION,
    USER_ROLE.HEAD_OF_ACADEMIC,
    USER_ROLE.HEAD_OF_DOCUMENT,
    USER_ROLE.CHIEF_ACCOUNTANT,
    USER_ROLE.BUSINESS_STAFF,
    USER_ROLE.MARKETING_STAFF,
    USER_ROLE.TRANSLATOR,
    USER_ROLE.ACADEMIC_STAFF,
    USER_ROLE.DOCUMENT_STAFF,
    USER_ROLE.ACCOUNTANT,
    USER_ROLE.INTERPRETER,
];
export const listRoleUsers = [USER_ROLE.CUSTOMER, USER_ROLE.MEMBER];

export const TEST = 6;

export const ERROR_CODE = {
    1039: "gender must be one of the following values: Male, Female, Other",
};

export const TOAST_CREATE_SUCCESS = {
    title: "Thành công!",
    message: `Tạo thành công!`,
    time: "1 giây trước",
};

export const TOAST_CREATE_ERROR = {
    title: "Lỗi!",
    message: "Tạo không thành công!",
    time: "1 giây trước",
};

export const TOAST_UPDATE_SUCCESS = {
    title: "Thành công!",
    message: `Cập nhật thành công!`,
    time: "1 giây trước",
};

export const TOAST_UPDATE_ERROR = {
    title: "Lỗi!",
    message: "cập nhật không thành công!",
    time: "1 giây trước",
};

export const TOAST_DELETE_SUCCESS = {
    title: "Thành công!",
    message: `Xóa thành công!`,
    time: "1 giây trước",
};

export const TOAST_DELETE_ERROR = {
    title: "Lỗi!",
    message: "Xóa không thành công!",
    time: "1 giây trước",
};

export const TOAST_UPLOAD_SUCCESS = {
    title: "Thành công!",
    message: `Tải lên thành công!`,
    time: "1 giây trước",
};

export const TOAST_UPLOAD_ERROR = {
    title: "Lỗi!",
    message: "Tải lên không thành công!",
    time: "1 giây trước",
};

export const GROUP_TAB = {
    GENERAL: "general",
    FAQ: "faq",
    IMAGE: "image",
    MAJOR: "major",
    RELATED_POST: "related_post",
    REVIEW: "review",
    SCHOLARSHIP: "scholarship",
    VIDEO: "video",
};

export const listTrainingLanguage = [
    {
        value: "VI",
        label: "Tiếng Việt",
    },
    {
        value: "EN",
        label: "Tiếng Anh",
    },
    {
        value: "CN",
        label: "Tiếng Trung",
    },
];

export { nodata, schoolBg, schoolLogo, avatar, avatarLg };

export const textUserRole: Record<number, string> = {
    [USER_ROLE.ADMIN]: "admin",
    [USER_ROLE.CUSTOMER]: "Khách hàng",
    [USER_ROLE.MEMBER]: "Người dùng",
    [USER_ROLE.SALE_MANAGER]: "Trưởng phòng kinh doanh",
    [USER_ROLE.MARKETING_MANAGER]: "Trưởng phòng marketing",
    [USER_ROLE.HEAD_OF_TRANSLATION]: "Trưởng phòng dịch thuật",
    [USER_ROLE.HEAD_OF_ACADEMIC]: "Trưởng phòng học thuật",
    [USER_ROLE.HEAD_OF_DOCUMENT]: "Trưởng phòng hồ sơ",
    [USER_ROLE.CHIEF_ACCOUNTANT]: "Kế toán trưởng",
    [USER_ROLE.BUSINESS_STAFF]: "Nhân viên kinh doanh",
    [USER_ROLE.MARKETING_STAFF]: "Nhân viên marketing",
    [USER_ROLE.TRANSLATOR]: "Nhân viên dịch thuật",
    [USER_ROLE.ACADEMIC_STAFF]: "Nhân viên học thuật",
    [USER_ROLE.DOCUMENT_STAFF]: "Nhân viên hồ sơ",
    [USER_ROLE.ACCOUNTANT]: "Nhân viên kế toán",
    [USER_ROLE.INTERPRETER]: "Nhân viên phiên dịch",
};

export const TICKET_OPTIONS = [
    {
        label: "Góp ý",
        value: "feedback",
    },
    {
        label: "Hỏi và đáp",
        value: "question",
    },
    {
        label: "Hỗ trợ tài khoản",
        value: "account-support",
    },
    {
        label: "Hỗ trợ dịch vụ",
        value: "service-support",
    },
    {
        label: "Hỗ trợ khác",
        value: "other-support",
    },
];

export const TICKET_LABELS: Record<string, string> = {
    ["feedback"]: "Góp ý",
    ["question"]: "Hỏi và đáp",
    ["account-support"]: "Hỗ trợ tài khoản",
    ["service-support"]: "Hỗ trợ dịch vụ",
    ["other-support"]: "Hỗ trợ khác",
    ["ticket-type-1"]: "Loại phiếu 1",
    ["ticket-type-2"]: "Loại phiếu 2",
    ["ticket-type-3"]: "Loại phiếu 3",
    ["ticket-type-4"]: "Loại phiếu 4",
    ["ticket-type-5"]: "Loại phiếu 5",
};
