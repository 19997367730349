import {
    CloseOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useLazyGetApplyByIdQuery,
    usePaidPaymentMutation
} from "@redux/queries/admin/admin.apply";
import { useGetListScholarshipTypeQuery } from "@redux/queries/admin/admin.scholarship.type";
import { useGetListSchoolServiceQuery } from "@redux/queries/admin/admin.school.service";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space, Tag, Typography } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchSchoolService from "./ModalSearchSchoolService";

const { Text } = Typography;
interface IProps {
    form: any;
    modal: any;
}

const ContractTab: React.FunctionComponent<IProps> = (props: IProps) => {
    const { showToast } = useToast();
    const location = useLocation();
    const id = location.pathname.split("/")[4];

    const [getApplyById, { data: dataEdit, isLoading }] = useLazyGetApplyByIdQuery();

    useEffect(() => {
        if (id) {
            getApplyById({
                id: id,
            });
        }
    }, [id]);

    const [selectedSchoolService, setSelectedSchoolService] = useState<any>(null);
    const [openSearchSchoolService, setOpenSearchSchoolService] = useState<boolean>(false);

    useEffect(() => {
        if (selectedSchoolService) {
            props.form.setFieldsValue({ schoolServiceId: selectedSchoolService });
        }
    }, [selectedSchoolService]);

    const {
        data: list_scholarship_type,
        isLoading: isLoadingScholarshipType,
        error: errorScholarshipType,
    } = useGetListScholarshipTypeQuery();

    const applyPaymentStatusColor = {
        PENDING: "yellow",
        PAID: "green",
    };

    const { data: list_school_service, isLoading: isLoadingSchoolService } = useGetListSchoolServiceQuery({});
    const [paidPayment] = usePaidPaymentMutation();

    const handlePaidPayment = async (paymentId: string) => {
        const result = await paidPayment({
            id: paymentId,
        });

        if ("error" in result) {
            showToast({ ...TOAST_UPDATE_ERROR });
        }

        if ("data" in result) {
            showToast({ ...TOAST_UPDATE_SUCCESS });
            getApplyById({
                id: id,
            });
        }
    };

    const confirmPaidPayment = (id: string) => {
        props.modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn cập nhật trạng thái thanh toán?",
            okText: "Đã thanh toán",
            cancelText: "Hủy",
            onOk() {
                handlePaidPayment(id);
            },
            centered: true,
        });
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                
                <div className="row">
                    {/* <div className="form-group">
                        <div
                            className="form-group"
                            style={{
                                width: "40%",
                                display: "inline-block",
                            }}
                        >
                            <div className="form-floating">
                                <Form.Item
                                    name="schoolServiceId"
                                    className="form-floating"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Thông tin bắt buộc",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="form-control form-floating"
                                        bordered={false}
                                        placeholder=""
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(v: string) => {
                                            setSelectedSchoolService(v);
                                        }}
                                    >
                                        {list_school_service &&
                                            list_school_service?.map((o: any, i: any) => {
                                                const schoolName = o.school?.language[0]?.name || "";
                                                const scholarshipName = o?.scholarship?.language[0]?.title || "";
                                                const trainingName = o.training?.language[0]?.title || "";
                                                const name = `${schoolName} - ${scholarshipName} - ${trainingName}`;
                                                return (
                                                    <Select.Option
                                                        key={o.id}
                                                        value={o.id}
                                                    >
                                                        {name}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                                <label>Dịch vụ (Trường - Học bổng - Hệ đào tạo)</label>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            style={{
                                height: "calc(2.6rem + 5px)",
                                width: "10%",
                                display: "inline-block",
                            }}
                            icon={<SearchOutlined />}
                            onClick={() => setOpenSearchSchoolService(true)}
                        >
                            Search
                        </Button>
                    </div> */}
                    {/* col 1 */}
                    <div className="col-6">
                        <Row>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="contractMethod"
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    options={[
                                                        {
                                                            label: "Offline",
                                                            value: "OFFLINE",
                                                        },
                                                        {
                                                            label: "Online",
                                                            value: "ONLINE",
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <label>Phương thức hợp đồng</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="contact"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="no-icon form-floating"
                                                />
                                            </Form.Item>
                                            <label>Thông tin liên hệ</label>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="discount"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        pattern: /^[0-9]*$/,
                                                        message: "Phải nhập số",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="no-icon form-floating"
                                                />
                                            </Form.Item>
                                            <label>Discount</label>
                                        </div>
                                    </div>
                                    <div className="form-floating">
                                        <Form.Item
                                            name="address"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="no-icon form-floating"
                                            />
                                        </Form.Item>
                                        <label>Địa chỉ</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* col 2 */}
                    <div className="col-6">
                        <Row>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="hskPlace"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="no-icon form-floating"
                                                />
                                            </Form.Item>
                                            <label>hskPlace</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="scholarshipTypes"
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    allowClear
                                                    mode="multiple"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {list_scholarship_type &&
                                                        list_scholarship_type.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Loại học bổng</label>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="body-component">
                                    <Form.List
                                        name="aspirations"
                                        initialValue={[""]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <div
                                                        key={field.key}
                                                        className="space-medias-tab"
                                                    >
                                                        <div
                                                            className="form-group"
                                                            style={{
                                                                width: "90%",
                                                                display: "inline-block",
                                                            }}
                                                        >
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name={[field.name]}
                                                                    className="form-floating"
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="no-icon form-floating"
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                </Form.Item>
                                                                <label>Nguyện vọng</label>
                                                            </div>
                                                        </div>
                                                        {fields?.length > 0 ? (
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                style={{
                                                                    width: "10%",
                                                                    display: "inline-block",
                                                                }}
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{ width: "100%" }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Thêm nguyện vọng
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Title level={5}>Thông tin thanh toán</Title>
                <Form.List
                    name="payments"
                    initialValue={[{}]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
                            <Row>
                                {fields.map((field, index) => {
                                    const paymentItem =
                                        dataEdit?.contract?.payments && (dataEdit?.contract?.payments[index] as any);
                                    return (
                                        <Col
                                            span={6}
                                            key={field.key}
                                        >
                                            <Card
                                                size="small"
                                                title={
                                                    <Space>
                                                        <Text>Thanh toán {index + 1}</Text>
                                                        {_.get(paymentItem, "status") && (
                                                            <Tag
                                                                color={_.get(
                                                                    applyPaymentStatusColor,
                                                                    _.get(paymentItem, "status")
                                                                )}
                                                            >
                                                                {_.get(paymentItem, "status")}
                                                            </Tag>
                                                        )}
                                                    </Space>
                                                }
                                                extra={
                                                    index > 0 && !paymentItem?.id ? (
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                remove(field.name);
                                                            }}
                                                        />
                                                    ) : null
                                                }
                                            >
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name={[field.name, "amount"]}
                                                            className="form-floating"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Thông tin bắt buộc",
                                                                },
                                                                {
                                                                    pattern: /^[0-9]*$/,
                                                                    message: "Phải nhập số",
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                type="text"
                                                                className="no-icon form-floating"
                                                            />
                                                        </Form.Item>
                                                        <label>Số tiền</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name={[field.name, "date"]}
                                                            className="form-floating"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Thông tin bắt buộc",
                                                                },
                                                            ]}
                                                            getValueProps={(i) => ({ value: dayjs(i) })}
                                                        >
                                                            <DatePicker
                                                                className="no-icon form-floating"
                                                                style={{ width: "100%", height: 45 }}
                                                                format="DD/MM/YYYY"
                                                            />
                                                        </Form.Item>
                                                        <label>Ngày</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name={[field.name, "expiredAt"]}
                                                            className="form-floating"
                                                            getValueProps={(i) => ({ value: dayjs(i) })}
                                                        >
                                                            <DatePicker
                                                                className="no-icon form-floating"
                                                                style={{ width: "100%", height: 45 }}
                                                                format="DD/MM/YYYY"
                                                            />
                                                        </Form.Item>
                                                        <label>Ngày hết hạn</label>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name={[field.name, "note"]}
                                                            className="form-floating"
                                                        >
                                                            <Input
                                                                type="text"
                                                                className="no-icon form-floating"
                                                            />
                                                        </Form.Item>
                                                        <label>Note</label>
                                                    </div>
                                                </div>

                                                {paymentItem?.id && paymentItem?.status === "PENDING" && (
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Button
                                                                style={{ width: "100%" }}
                                                                danger
                                                                onClick={() => confirmPaidPayment(paymentItem.id)}
                                                            >
                                                                Đã thanh toán
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Card>
                                        </Col>
                                    );
                                })}
                            </Row>

                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                            >
                                + Thêm thông tin thanh toán
                            </Button>
                        </div>
                    )}
                </Form.List>
            </div>
            <SearchSchoolService
                openSearchSchoolService={openSearchSchoolService}
                setOpenSearchSchoolService={(v: boolean) => setOpenSearchSchoolService(v)}
                setSelectedSchoolService={(v: any) => setSelectedSchoolService(v)}
                selectedSchoolService={selectedSchoolService}
            />
        </>
    );
};

const MemoizedContractTab = React.memo(ContractTab);

export default MemoizedContractTab;
