import { Pagination } from "@components/pagination";
import { useGetSchoolNewsHomeQuery } from "@redux/queries/user/intro.api";
import { FC, useState } from "react";
import CardNews from "./CardNews";
import Nodata from "@components/Nodata";
import { useSearchParams } from "react-router-dom";
import { omitBy } from "lodash";

const LIMIT = 5;

const ListNews: FC = () => {
    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get("category") || "";

    const [page, setPage] = useState(1);

    const { data } = useGetSchoolNewsHomeQuery(omitBy({ page, limit: LIMIT, categoryId }, (v) => v === ""));

    const { items: listSchoolNews, meta } = data || {};
    const { totalItems = 0 } = meta || {};

    return (
        <div className="col-12 col-sm-8">
            {totalItems > 0 ? (
                listSchoolNews?.map((news: SchoolNews, index: number) => (
                    <CardNews
                        key={index}
                        news={news}
                    />
                ))
            ) : (
                <Nodata />
            )}

            <Pagination
                defaultSize={LIMIT}
                onSizeChange={(size) => {}}
                total={totalItems || 0}
                showSize={false}
                totalPage={data?.meta.totalPages || 0}
                onChangePage={setPage}
                defaultCurrentPage={1}
                listSize={[12, 24, 50, 100]}
            />
        </div>
    );
};

export default ListNews;
