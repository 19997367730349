import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/apply/task";

export const adminApplyTaskApi = createApi({
    reducerPath: "adminApplyTaskApi",
    keepUnusedDataFor: 30,
    tagTypes: ["ApplyTask"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListApplyTask: builder.query<Array<IApplyTask>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "ApplyTask" as const, id })),
                              { type: "ApplyTask", id: "ApplyTask-LIST" },
                          ]
                        : [{ type: "ApplyTask", id: "ApplyTask-LIST" }],
            }),
            getListApplyTaskPaginate: builder.query<
                IResponseDataAdmin<IApplyTask>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "ApplyTask" as const, id })),
                              { type: "ApplyTask", id: "ApplyTask-LIST" },
                          ]
                        : [{ type: "ApplyTask", id: "ApplyTask-LIST" }],
            }),
            createApplyTask: builder.mutation<any, IApplyTask>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyTask"],
            }),
            updateApplyTask: builder.mutation<any, IApplyTask>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyTask"],
            }),
            deleteApplyTask: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyTask"],
            }),
            deleteMultiApplyTask: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyTask"],
            }),
        };
    },
});

export const {
    useGetListApplyTaskQuery,
    useGetListApplyTaskPaginateQuery,
    useLazyGetListApplyTaskPaginateQuery,
    useCreateApplyTaskMutation,
    useUpdateApplyTaskMutation,
    useDeleteApplyTaskMutation,
    useDeleteMultiApplyTaskMutation,
} = adminApplyTaskApi;
