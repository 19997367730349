import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { pickBy, identity } from "lodash";
import { useCreateDepositMutation, useUpdateDepositMutation } from "@redux/queries/admin/admin.deposit";
import { listApplyGrantStatus } from "@pages/admin/apply-grant/apply-grant.const";
import { DepositAmount, listDepositStatus } from "@pages/admin/deposit/deposit.const";
import { formatNumber } from "@utils/common";
import { useGetListUserQuery } from "@redux/queries/admin/admin.user";
import { useGetAllMethodQuery } from "@redux/queries/user/deposit.api";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createDeposit, isLoadingCreate] = useCreateDepositMutation();
    const [updateDeposit, isLoadingUpdate] = useUpdateDepositMutation();
    const { showToast } = useToast();
    const { data: list_user, isLoading: isLoadingUser, isFetching: isFetchingUser } = useGetListUserQuery();
    const {
        data: list_method,
        isLoading: isLoadingMethod,
        error: errorMethod,
    } = useGetAllMethodQuery({
        status: 1,
    });

    useEffect(() => {
        if (dataEdit) {
            console.log("dataEdit", dataEdit);
            form.setFieldsValue({
                ...dataEdit,
                userId: dataEdit?.user?.id,
                methodId: dataEdit?.method?.id,
            });
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        let value;
        try {
            value = await form.validateFields();
        } catch (errorInfo) {
            const fieldError = (errorInfo as any)?.errorFields[0].name[0];
            return;
        }

        const data = pickBy(
            {
                ...values,
            },
            identity
        ) as any;
        const result = dataEdit
            ? await updateDeposit({
                  id: dataEdit.id,
                  ...data,
              })
            : await createDeposit({
                  ...data,
                  status: "PENDING",
              });
        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Deposit" : "Add Deposit"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1000}
            >
                <div className="body-component">
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="userId"
                                        className="form-floating"
                                        rules={[{ required: true, message: "Thông tin bắt buộc" }]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            placeholder=""
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                            // onChange={(v: string) => {
                                            //     setSelectedUser(v);
                                            // }}
                                        >
                                            {list_user &&
                                                list_user.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {`${o.firstName || ""} ${o.lastName || ""}`}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Người dùng</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="amount"
                                        className="form-floating"
                                        rules={[{ required: true, message: "Thông tin bắt buộc" }]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {DepositAmount.map((o: any, i: any) => {
                                                return (
                                                    <Select.Option
                                                        key={o}
                                                        value={o}
                                                    >
                                                        {formatNumber(o)}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <label>Số tiền</label>
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="methodId"
                                        className="form-floating"
                                        rules={[{ required: true, message: "Thông tin bắt buộc" }]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {list_method &&
                                                list_method.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].name}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Phương thức thanh toán</label>
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="note"
                                        className="form-floating"
                                    >
                                        <Input.TextArea
                                            style={{ height: 100 }}
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Ghi chú</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
