import { useGetSchoolNewsHomeQuery } from "@redux/queries/user/intro.api";
import { FC } from "react";
import Categories from "./NewsDetails/components/Categories";
import RecentPost from "./NewsDetails/components/RecentPost";
import ListNews from "./components/ListNews";
import PopularNews from "./components/PopularNews";
import { format } from "date-fns";
import Img01 from "@assets/images/img-01.jpg";
import { linkCDN } from "@utils/common";
import { Link } from "react-router-dom";
import { Spin } from "antd";

const PAGE = 1;
const LIMIT = 5;

const News: FC = () => {
    const { data, isLoading } = useGetSchoolNewsHomeQuery({ page: PAGE, limit: LIMIT });

    const { items: listSchoolNews } = data || {};

    const firstNews = listSchoolNews?.[0];
    const twoNews = listSchoolNews?.slice(1, 3);

    const firstUserName = firstNews?.creator?.firstName
        ? `${firstNews?.creator?.firstName} ${firstNews?.creator?.lastName}`
        : firstNews?.creator?.userName;

    return (
        <section id="content-main">
            <hgroup className="title-page">
                <h2>Tin tức</h2>
            </hgroup>

            <Spin spinning={isLoading}>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Link to={`/news/${firstNews?.id}`}>
                            <article className="highlight-focus">
                                <img
                                    src={linkCDN(firstNews?.thumbnail, Img01)}
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                                <div className="content-overlay">
                                    {firstNews?.createdAt && format(new Date(firstNews?.createdAt), "MMMM dd, yyyy")}
                                    <h5>
                                        <Link to={`/news/${firstNews?.id}`}>{firstNews?.language?.[0]?.title}</Link>
                                    </h5>
                                    <ul>
                                        <li>
                                            <i className="bi-person"></i> {firstUserName}
                                        </li>
                                        <li>
                                            <i className="bi-hand-thumbs-up"></i> {firstNews?.like}
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </Link>
                    </div>

                    <div className="col-12 col-md-6">
                        {twoNews?.map((item, index) => {
                            const userName = item?.creator?.firstName
                                ? `${item?.creator?.firstName} ${item?.creator?.lastName}`
                                : item?.creator?.userName;
                            return (
                                <article
                                    key={index}
                                    className="item-focus-list"
                                >
                                    <div className="row">
                                        <div className="col-4 col-sm-5">
                                            <Link to={`/news/${item?.id}`}>
                                                <img
                                                    className="img-fluid"
                                                    src={linkCDN(item?.thumbnail, Img01)}
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                        <div className="col-8 col-sm-7">
                                            <div className="content-article-focus">
                                                <time>{format(new Date(item?.createdAt), "MMMM dd, yyyy")}</time>
                                                <h5>
                                                    <Link to={`/news/${item?.id}`}>{item?.language?.[0]?.title}</Link>
                                                </h5>
                                                <ul>
                                                    <li>
                                                        <i className="bi-person"></i> {userName}
                                                    </li>
                                                    <li>
                                                        <i className="bi-hand-thumbs-up"></i> {item?.like}
                                                    </li>
                                                </ul>
                                                <p>{item?.language?.[0]?.shortContent}</p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            );
                        })}
                    </div>
                </div>

                <PopularNews />

                <div className="row">
                    <ListNews />

                    <div className="col-12 col-sm-4">
                        <Categories />
                        <RecentPost />
                    </div>
                </div>
            </Spin>
        </section>
    );
};

export default News;
