import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import { schoolLogo } from "@consts";
import {
    useApprovePaymentMutation,
    useDeleteMultiPaymentMutation,
    useDeletePaymentMutation,
    useGetListPaymentPaginateQuery,
} from "@redux/queries/admin/admin.payment";
import { Avatar, Button, Modal, Space, Table, Tag, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterPayment from "../../../admin/payment/components/Filter";
import HandleForm from "../../../admin/payment/components/HandleForm";
import {
    listFundPayment,
    listSourcePayment,
    listStatusPayment,
    listTypePayment,
} from "../../../admin/payment/payment.const";

const { Text } = Typography;

const Payment: React.FunctionComponent = () => {
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching, refetch } = useGetListPaymentPaginateQuery(query as any);

    const [deletePayment, isLoadingDelete] = useDeletePaymentMutation();
    const [deleteMultiPayment, isLoadingMultiDelete] = useDeleteMultiPaymentMutation();
    const [approvePayment] = useApprovePaymentMutation();

    const handleDelete = async (id: string) => {
        const result = await deletePayment({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiPayment({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleApprove = async (id: string) => {
        const result = await approvePayment({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
        refetch();
    };

    const columns = [
        {
            title: <div className="text-title-table">Khách hàng</div>,
            key: "user",
            dataIndex: "user",
            render: (user: any) => {
                return (
                    <Space>
                        <Avatar src={process.env.REACT_APP_CDN + user?.avatar}>{user?.username?.charAt(0)}</Avatar>
                        <Text>{user?.username}</Text>
                    </Space>
                );
            },
        },
        {
            title: <div className="text-title-table">Số tiền</div>,
            key: "amount",
            dataIndex: "amount",
            render: (amount: any) => {
                return Number(amount).toLocaleString("en-US");
            },
        },
        {
            title: <div className="text-title-table">Loại</div>,
            key: "type",
            dataIndex: "type",
            render: (type: any) => {
                const paymentType = listTypePayment.find((item) => item.value === type);
                return <Tag color={paymentType?.color}>{paymentType?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Nguồn tiền</div>,
            key: "source",
            dataIndex: "source",
            render: (source: any) => {
                const paymentSource = listSourcePayment.find((item) => item.value === source);
                return <Tag color={paymentSource?.color}>{paymentSource?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Phương thức</div>,
            key: "fund",
            dataIndex: "fund",
            render: (fund: any) => {
                const paymentFund = listFundPayment.find((item) => item.value === fund);
                return <Tag color={paymentFund?.color}>{paymentFund?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (status: any, record: any) => {
                const paymentStatus = listStatusPayment.find((item) => item.value === status);
                return status === "PENDING" ? (
                    <Space>
                        <Tag color={paymentStatus?.color}>{paymentStatus?.label}</Tag>
                        <Button
                            size="small"
                            onClick={() => handleApprove(record.id)}
                        >
                            <i className="bi bi-check"></i>Hoàn thành
                        </Button>
                    </Space>
                ) : (
                    <Tag color={paymentStatus?.color}>{paymentStatus?.label}</Tag>
                );
            },
            width: 200,
        },
        {
            title: <div className="text-title-table">Người duyệt</div>,
            key: "approve",
            dataIndex: "approve",
            render: (approve: any) => {
                return approve ? approve.email : "";
            },
        },
        {
            title: <div className="text-title-table">Id giao dịch</div>,
            key: "txId",
            dataIndex: "txId",
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
    ];

    return (
        <div className="container-fluid padding0">
            <main>
                <div className="box-component">
                    <div className="title-component">
                        <h4>Transactions</h4>
                        <div className="clearfix" />
                    </div>
                    <div className="body-component">
                        <div className="table-responsive">
                            <Table
                                //rowSelection={rowSelection}
                                columns={columns}
                                dataSource={(data?.items as any) || []}
                                pagination={false}
                                rowKey="id"
                                loading={isLoading || isFetching}
                                scroll={{ x: 1500 }}
                                expandable={{
                                    expandIconColumnIndex: -1,
                                }}
                            />
                            {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                            <Pagination
                                onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                total={data?.meta.totalItems || 0}
                                showSize={true}
                                totalPage={data?.meta.totalPages || 0}
                                onChangePage={(page) => setQuery({ ...query, page: page })}
                                defaultCurrentPage={query.page}
                            />
                        </div>
                    </div>
                </div>
            </main>
            {contextHolder}
        </div>
    );
};

export default Payment;
