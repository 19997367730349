import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import NewsContent from "./components/NewsContent";
import RelateNews from "./components/RelateNews";
import ReviewPost from "./components/ReviewPost";
import Categories from "./components/Categories";
import RecentPost from "./components/RecentPost";
import { useGetSchoolNewsByIdQuery } from "@redux/queries/admin/admin.school.news";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Spin } from "antd";

const NewsDetails: FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data, isLoading, isFetching } = useGetSchoolNewsByIdQuery(id ? { id } : skipToken);

    return (
        <section id="content-main">
            <Spin spinning={isLoading}>
                <div className="row">
                    <div className="col-12 col-sm-8">
                        <NewsContent data={data} />

                        <RelateNews categoryId={data?.category?.id} />

                        {/* <ReviewPost /> */}
                    </div>
                    <div className="col-12 col-sm-4">
                        <aside>
                            <Categories />

                            <RecentPost />
                        </aside>
                    </div>
                </div>
            </Spin>
        </section>
    );
};

export default NewsDetails;
