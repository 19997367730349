import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useCreateApplyGrantMutation, useUpdateApplyGrantMutation } from "@redux/queries/admin/admin.apply.grant";
import { useGetListApplyProcessQuery } from "@redux/queries/admin/admin.apply.process";
import { useGetListUserQuery } from "@redux/queries/admin/admin.user";
import { validateNoSpaces } from "@utils/common";
import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import SearchUser from "@pages/admin/apply/components/ModalSearchUser";
import { listApplyGrantStatus } from "../apply-grant.const";
import moment from "moment";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createApplyGrant, isLoadingCreate] = useCreateApplyGrantMutation();
    const [updateApplyGrant, isLoadingUpdate] = useUpdateApplyGrantMutation();
    const { showToast } = useToast();

    const { data: list_apply_process } = useGetListApplyProcessQuery();
    const { data: list_user, isLoading: isLoadingUser, isFetching: isFetchingUser } = useGetListUserQuery();
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [openSearchUser, setOpenSearchUser] = useState<boolean>(false);

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue({
                dataEdit,
            });
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updateApplyGrant({
                  id: dataEdit.id,
                  ...values,
                  expiredAt: moment(values.expiredAt).toISOString(),
              })
            : await createApplyGrant({
                  ...values,
                  expiredAt: moment(values.expiredAt).toISOString(),
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Apply Grant" : "Add Apply Grant"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={600}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="applyProcessId"
                                className="form-floating"
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {list_apply_process &&
                                        list_apply_process.map((o: any, i: any) => {
                                            return (
                                                <Select.Option
                                                    key={o.id}
                                                    value={o.id}
                                                >
                                                    {o.id}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <label>Apply Process</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div
                            className="form-group"
                            style={{
                                width: "80%",
                                display: "inline-block",
                            }}
                        >
                            <div className="form-floating">
                                <Form.Item
                                    name="userId"
                                    className="form-floating"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Thông tin bắt buộc",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="form-control form-floating"
                                        bordered={false}
                                        placeholder=""
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(v: string) => {
                                            setSelectedUser(v);
                                        }}
                                    >
                                        {list_user &&
                                            list_user.map((o: any, i: any) => {
                                                return (
                                                    <Select.Option
                                                        key={o.id}
                                                        value={o.id}
                                                    >
                                                        {`${o.firstName || ""} ${o.lastName || ""} - ${o.code || ""}`}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                                <label>Khách hàng</label>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            style={{
                                height: "calc(2.6rem + 5px)",
                                width: "20%",
                                display: "inline-block",
                            }}
                            icon={<SearchOutlined />}
                            onClick={() => setOpenSearchUser(true)}
                        >
                            Search
                        </Button>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="status"
                                className="form-floating"
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    allowClear
                                >
                                    {listApplyGrantStatus.map((o: any, i: any) => {
                                        return (
                                            <Select.Option
                                                key={o.value}
                                                value={o.value}
                                            >
                                                {o.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <label>Trạng thái</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="expiredAt"
                                className="form-floating"
                            >
                                <DatePicker
                                    className="no-icon form-floating"
                                    style={{ width: "100%", height: 45 }}
                                    format="DD/MM/YYYY"
                                />
                            </Form.Item>
                            <label>Ngày hết hạn</label>
                        </div>
                    </div>
                </div>
                <SearchUser
                    openSearchUser={openSearchUser}
                    setOpenSearchUser={(v: boolean) => setOpenSearchUser(v)}
                    selectedUser={selectedUser}
                    setSelectedUser={(v: any) => setSelectedUser(v)}
                />
            </Modal>
        </Form>
    );
};

export default HandleForm;
