import { DatePicker, Select, Space, TimeRangePickerProps } from "antd";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import SourceColumnChart from "./SourceColumnChart";
import FundColumnChart from "./FundColumnChart";
import dayjs from "dayjs";
import { listFundPayment, listSourcePayment } from "@pages/admin/payment/payment.const";
import { useGetListPaymentMethodQuery } from "@redux/queries/admin/admin.payment.method";
import PieChart from "./PieChart";
// import PieChart from "./PieChart";

const { RangePicker } = DatePicker;

const RevenueDashboard = () => {
    const [query, setQuery] = useState<any>({});

    const { data: listPaymentMethod } = useGetListPaymentMethodQuery({ status: 1});

    const rangePresets: TimeRangePickerProps["presets"] = [
        { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
        { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
        { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
        { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
    ];

    const onChangeDate = (value: any) => {
        if (value && value[0] && value[1]) {
            const fromDateUnix = value[0].unix();
            const toDateUnix = value[1].unix();
            setQuery({ ...query, fromDate: fromDateUnix, toDate: toDateUnix });
        } else {
            setQuery({
                ...query,
                fromDate: undefined,
                toDate: undefined,
            });
        }
    };

    const onChange = (key: string, value: any) => {
        setQuery({ ...query, [key]: value });
    };

    return (
        <section id="content-main">
            <div className="row">
                <div className="col-12 col-lg-12 d-flex">
                    <div className="box-component">
                        <Space
                            direction="vertical"
                            style={{
                                padding: "20px",
                            }}
                        >
                            <Title level={4}>Chọn khoảng thời gian</Title>
                            <RangePicker
                                presets={[
                                    {
                                        label: <span aria-label="Current Time to End of Day">Now ~ EOD</span>,
                                        value: () => [dayjs(), dayjs().endOf("day")], // 5.8.0+ support function
                                    },
                                    ...rangePresets,
                                ]}
                                placeholder={["Từ ngày", "Đến ngày"]}
                                onChange={onChangeDate}
                            />
                        </Space>
                        s
                        <Space
                            direction="vertical"
                            style={{
                                padding: "20px",
                            }}
                        >
                            <Title level={4}>Chọn nguồn tiền</Title>
                            <Select
                                style={{ width: "100%" }}
                                allowClear
                                onChange={(value) => onChange("source", value)}
                            >
                                {listSourcePayment.map((o: any, i: any) => {
                                    return (
                                        <Select.Option
                                            key={o.value}
                                            value={o.value}
                                        >
                                            {o.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Space>
                        <Space
                            direction="vertical"
                            style={{
                                padding: "20px",
                            }}
                        >
                            <Title level={4}>Chọn phương thức</Title>
                            <Select
                                style={{ width: "100%" }}
                                allowClear
                                onChange={(value) => onChange("fund", value)}
                            >
                                {listFundPayment.map((o: any, i: any) => {
                                    return (
                                        <Select.Option
                                            key={o.value}
                                            value={o.value}
                                        >
                                            {o.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Space>
                        <Space
                            direction="vertical"
                            style={{
                                padding: "20px",
                            }}
                        >
                            <Title level={4}>
                                Chọn phương thức thanh toán &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160;
                                &#160; &#160; &#160; &#160;
                            </Title>
                            <Select
                                allowClear
                                style={{ width: "100%" }}
                                onChange={(value) => onChange("methodId", value)}
                            >
                                {listPaymentMethod?.map((o: any, i: any) => {
                                    return (
                                        <Select.Option
                                            key={o.id}
                                            value={o.id}
                                        >
                                            {o?.language[0].name} - {o?.info?.accountNumber} - {o?.info?.owner}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Space>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-12 d-flex">
                    <div className="box-component">
                        <div className="title-component">
                            <h4>Tỉ lệ nguồn tiền/phương thức</h4>
                            <div className="clearfix" />
                        </div>
                        <div className="body-component"><PieChart query={query} /></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-12 d-flex">
                    <div className="box-component">
                        <div className="title-component">
                            <h4>Biểu đồ biến động theo nguồn tiền</h4>
                            <div className="clearfix" />
                        </div>
                        <div className="body-component">
                            <SourceColumnChart query={query} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-12 d-flex">
                    <div className="box-component">
                        <div className="title-component">
                            <h4>Biểu đồ biến động theo phương thức</h4>
                            <div className="clearfix" />
                        </div>
                        <div className="body-component">
                            <FundColumnChart query={query} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RevenueDashboard;
