import { nodata } from "@consts";

const Nodata = () => (
    <div className="w-100 no-data d-flex align-items-center justify-content-center flex-column">
        <img
            className="img-fluid "
            src={nodata}
        />
        <p className="mt-3">No data</p>
    </div>
);

export default Nodata;
