import store, { wrapperPersistStore } from "@redux/store";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "@i18n/i18n";
import { ToastProvider } from "@components/toast/ToastProvider";
import { ModalProvider } from "@components/modal/ModalProvider";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate
                persistor={wrapperPersistStore}
                loading={<p>Loading</p>}
            >
                <ConfigProvider
                    theme={{
                        token: {
                            fontSize: 13,
                            colorPrimary: "#733aea",
                        },
                    }}
                >
                    <ModalProvider>
                        <ToastProvider>
                            <I18nextProvider i18n={i18n}>
                                <App />
                            </I18nextProvider>
                        </ToastProvider>
                    </ModalProvider>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
