import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatNumber } from "@utils/common";
import { useLikeMutation, useUnlikeMutation } from "@redux/queries/user/authaction.api";
import { useSelector } from "react-redux";
import { authSelector } from "@redux/slices/auth.slice";
import { schoolBg, schoolLogo } from "@consts";
import ModalApply from "../../components/ModalApply";

interface SchoolItemProps {
    item: {
        id: string;
        background?: string;
        views?: number;
        rank?: number;
        rate?: number;
        logo?: string;
        name?: string;
        saved?: number;
        like?: number;
        area: Area;
        code?: number;
        project?: Array<string>;
        liked?: boolean;
        slug?: string;
        supportApply?: boolean;
    };
}

const SchoolItem: React.FunctionComponent<SchoolItemProps> = (props) => {
    const {
        id,
        background,
        views,
        rank,
        rate,
        logo,
        name,
        saved,
        like,
        area,
        code,
        project,
        liked,
        slug,
        supportApply,
    } = props.item;
    const MAX_STAR = 5;
    const light: any = (rate ?? MAX_STAR) > MAX_STAR ? MAX_STAR : rate;
    const normal = MAX_STAR - light;
    const colors = ["text-box-green", "text-box-red", "text-box-blue"];

    const [openApply, setOpenApply] = useState<boolean>(false);

    const [triggeerLike, result] = useLikeMutation();
    const [triggerUnlike, resultUnlike] = useUnlikeMutation();
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);

    const navigate = useNavigate();
    const handleLike = (e: any) => {
        e.preventDefault();

        if (!isLogin) {
            return;
        }
        if (!liked) {
            triggeerLike({
                schoolId: id,
            });
        } else {
            triggerUnlike({
                schoolId: id,
            });
        }
    };

    const goToDetail = () => {
        navigate(`/schools/${slug ?? id}`);
    };

    const handleApply = () => {
        if (!isLogin) {
            return;
        }

        setOpenApply(true);
    };

    return (
        <div className="col-12 col-sm-6 col-lg-12 col-xl-6 col-xxl-3 d-flex">
            <div className="box-filter-item w-100">
                <Link to={`/schools/${slug ?? id}`}>
                    <div
                        onClick={goToDetail}
                        className="bg-item"
                        style={{
                            background: `url("${
                                background ? `${process.env.REACT_APP_CDN}${background}` : schoolBg
                            }") no-repeat center center`,
                        }}
                    >
                        <div className="d-flex bg-content-sub">
                            <p>
                                <i className="bi-heart" /> {formatNumber(views ?? 0)} views
                            </p>
                            <ul>
                                {light > 0 &&
                                    Array(light)
                                        .fill("star")
                                        .map((e, id) => (
                                            <li
                                                key={id}
                                                className="active"
                                            >
                                                <i className="bi-star-fill" />
                                            </li>
                                        ))}
                                {normal > 0 &&
                                    Array(normal)
                                        .fill("normal")
                                        .map((e, id) => (
                                            <li key={id}>
                                                <i className="bi-star-fill star-normal" />
                                            </li>
                                        ))}
                            </ul>
                            <p className="text-p">{`#${rank}`}</p>
                        </div>
                    </div>
                    <div className="title-filter-item">
                        <div className="row">
                            <div className="col-8 col-sm-7 col-xl-8 no-pd">
                                <h6 onClick={goToDetail}>
                                    <img
                                        src={logo ? logo : schoolLogo}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = schoolLogo;
                                        }}
                                        alt="logo-school"
                                    />
                                    {name ?? ""}
                                </h6>
                            </div>
                            <div className="col-4 col-sm-5 col-xl-4 no-pd">
                                <ul>
                                    {/* <li>
                                    <i className="bi-journal-bookmark-fill" />
                                    {formatNumber(saved ?? 0)}
                                </li> */}
                                    <li onClick={handleLike}>
                                        <i
                                            className={
                                                liked
                                                    ? "bi-hand-thumbs-up-fill liked cursor-pointer"
                                                    : "bi-hand-thumbs-up cursor-pointer"
                                            }
                                        />
                                        {formatNumber(like ?? 0)}
                                    </li>
                                    <li>
                                        <i className="bi-eye-fill" />
                                        {formatNumber(views ?? 0)}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="body-filter-item">
                        <ul>
                            <li>
                                Trường tổng hợp
                                <Link
                                    to="#"
                                    style={{ color: "white" }}
                                    type="button"
                                    onClick={handleApply}
                                >
                                    Apply Now
                                </Link>
                            </li>
                            <li>
                                Khu vực: {area?.language?.find((e) => e.lang === "vi")?.title}{" "}
                                {supportApply && <Link to="#">Hỗ trợ Apply</Link>}
                            </li>
                            <li>Mã trường: {code}</li>
                        </ul>
                    </div>
                    <div className="footer-filter-item">
                        {project?.map((e, i) => {
                            const index = i % 3;

                            return (
                                <span
                                    key={i}
                                    className={colors[index] + " me-1"}
                                >
                                    {e}
                                </span>
                            );
                        })}
                        {/* <span className="text-box-green">985</span>
                    <span className="text-box-red">211</span>
                    <span className="text-box-blue">Trực thuộc BGĐ</span> */}
                    </div>
                </Link>
            </div>
            {openApply && (
                <ModalApply
                    open={openApply}
                    schoolId={id}
                    onClose={() => setOpenApply(false)}
                />
            )}
        </div>
    );
};

export default SchoolItem;
