import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

export const blogApi = createApi({
    reducerPath: "blog",
    tagTypes: ["User-blog"],
    keepUnusedDataFor: 30 * 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            listBlog: builder.query<ResponseWithPaginateFormat<UserBlog>, { page: number; limit: number }>({
                query: (params) => ({
                    url: "/api/user/blog/paginate",
                    params,
                }),
                keepUnusedDataFor: 0,
                providesTags: ["User-blog"],
            }),
            createBlog: builder.mutation<Response<UserBlog>, InputCreateBlog>({
                query: (body) => ({
                    url: "/api/user/blog",
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["User-blog"],
            }),
            updateBlogById: builder.mutation<Response<UserBlog>, InputUpdateBlogById>({
                query: (body) => ({
                    url: `/api/user/blog/${body.id}`,
                    method: "PATCH",
                    body: {
                        content: body.content,
                        title: body.title,
                        shortContent: body.shortContent,
                        thumbnail: body.thumbnail,
                        tags: body.tags,
                    },
                }),
                invalidatesTags: ["User-blog"],
            }),
            deleteBlog: builder.mutation<Response<UserBlog>, { id: string }>({
                query: (body) => ({
                    url: `/api/user/blog/${body.id}`,
                    method: "DELETE",
                }),
                invalidatesTags: ["User-blog"],
            }),
            newCategory: builder.query<Category[], void>({
                query: () => ({
                    url: "/api/school/new-category",
                }),
            }),
            blogDetails: builder.query<UserBlog, { id: string }>({
                query: (body) => ({
                    url: `/api/user/blog/${body.id}`,
                }),
            }),
        };
    },
});

export const {
    useCreateBlogMutation,
    useDeleteBlogMutation,
    useLazyListBlogQuery,
    useListBlogQuery,
    useUpdateBlogByIdMutation,
    useNewCategoryQuery,
    useBlogDetailsQuery,
} = blogApi;
