import Nodata from "@components/Nodata";
import { Pagination } from "@components/pagination";
import {
    useCancelDepositMutation,
    useCreateDepositMutation,
    useDeleteDepositMutation,
    useGetAllDepositByUserQuery,
} from "@redux/queries/user/deposit.api";
import { formatNumber } from "@utils/common";
import { Spin, message } from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const LIMIT = 10;
const ListDeposit: React.FunctionComponent = () => {
    const recaptchaRef = useRef<any>(null);

    const [page, setPage] = useState(1);

    const { data, isLoading } = useGetAllDepositByUserQuery({
        page: page,
        limit: LIMIT,
    });

    const [triggerCancelDeposit, resultCancelDeposit] = useCancelDepositMutation();

    const getDepositStatus = (status: "PENDING" | "REJECT" | "DONE" | "CANCEL") => {
        const statusGroup = {
            PENDING: <span className="text-box-gold">Đang xử lý</span>,
            REJECT: <span className="text-box-red">Thất bại</span>,
            DONE: <span className="text-box-green">Hoàn thành</span>,
            CANCEL: <span className="text-box-gray">Huỷ</span>,
        };
        return statusGroup[status];
    };

    const cancelDeposit = async (deposit: Deposit) => {
        try {
            await recaptchaRef.current.execute();
            const recaptchaValue = recaptchaRef && recaptchaRef.current && recaptchaRef.current.getValue();

            const result = await triggerCancelDeposit({
                id: deposit.id,
                recaptcha: recaptchaValue,
            }).unwrap();

            message.success("Hủy giao dịch nạp tiền thành công!");
        } catch (e) {
            message.error("Hủy giao dịch nạp tiền thất bại!");
        }
    };

    return (
        <div className="box-item-element">
            <div className="title-element">
                <h5>Lịch sử nạp tiền</h5>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY as any}
                    ref={recaptchaRef}
                    size="invisible"
                />

                <hr />
            </div>
            <div className="body-element">
                <Spin spinning={isLoading || resultCancelDeposit.isLoading}>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th className="text-start">Phương thức nạp tiền</th>
                                    <th>Thời gian</th>
                                    <th>Số tiền</th>
                                    <th>Ghi chú</th>
                                    <th className="text-center">Tình trạng</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {data?.items && data?.items?.length > 0 ? (
                                    data?.items?.map((e, i) => (
                                        <tr key={i}>
                                            <td>
                                                #{(data?.meta?.currentPage - 1) * data?.meta?.itemsPerPage + i + 1}{" "}
                                            </td>
                                            <td className="infor-customer">
                                                <b>{e.method.language[0].name}</b>
                                            </td>
                                            <td>{moment(e.createdAt).format("DD/MM/YYYY HH:MM:ss")}</td>
                                            <td>
                                                <b>{formatNumber(e.amount)}</b>
                                            </td>
                                            <td>{e?.note}</td>
                                            <td className="text-center">
                                                {getDepositStatus(e.status)}
                                                {/* Include 4 class status: .status-red, .status-gold, .status-green, .status-gray */}
                                            </td>
                                            <td>
                                                {e.status === "PENDING" && (
                                                    <div className="btn-group">
                                                        <button
                                                            type="button"
                                                            className="btn dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="bi-three-dots" />
                                                        </button>
                                                        <ul
                                                            className="dropdown-menu dropdown-menu-end"
                                                            style={{}}
                                                        >
                                                            <li>
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={() => cancelDeposit(e)}
                                                                >
                                                                    <i className="bi-file-earmark-text" /> Hủy giao dịch
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7}>
                                            <Nodata />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* BEGIN: Pagination */}
                    <div>
                        {(data?.meta?.totalItems || 0) > 0 && (
                            <div className="d-flex justify-content-center mt-4">
                                <Pagination
                                    defaultSize={LIMIT}
                                    onSizeChange={(size) => {}}
                                    total={data?.meta.totalItems || 0}
                                    showSize={false}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={setPage}
                                    defaultCurrentPage={1}
                                    listSize={[12, 24, 50, 100]}
                                />
                            </div>
                        )}
                    </div>
                    {/* END: Pagination */}
                </Spin>
            </div>
        </div>
    );
};

export default ListDeposit;
