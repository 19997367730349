import Nodata from "@components/Nodata";
import { Pagination } from "@components/pagination";
import ReviewItem from "@pages/pro-tool/schools/DetailSchool/components/TabReview/ReviewItem";
import { useUserReviewHistoryQuery } from "@redux/queries/user/user.api";
import { authSelector } from "@redux/slices/auth.slice";
import { Spin } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
interface TabReviewHistoryProps {}
const LIMIT = 12;

const TabReviewHistory: React.FunctionComponent<TabReviewHistoryProps> = () => {
    const [page, setPage] = useState(1);
    const { user } = useSelector(authSelector);

    const { data, isLoading } = useUserReviewHistoryQuery({
        userId: user?.id || "",
        page,
        limit: LIMIT,
    });

    return (
        <div
            className={`tab-pane fade ${location.hash === "#history-review-tab" ? "show active" : ""}`}
            id="history-review"
            role="tabpanel"
            aria-labelledby="history-review-tab"
        >
            {/* BEGIN: Section Box Item Element */}
            <div className="box-item-element">
                <div className="title-element">
                    <h5>Lịch sử Review</h5>
                    <hr />
                </div>
                <Spin spinning={isLoading}>
                    <div className="body-element">
                        {data?.meta?.totalItems === 0 && <Nodata />}

                        {data?.items?.map((e) => (
                            <ReviewItem
                                username={e?.user?.firstName + " " + e?.user?.lastName}
                                avatar={e?.user?.avatar}
                                time={e.createdAt}
                                tag={e.tags || []}
                                content={e.content || ""}
                                rate={e.rating || 1}
                                key={e.id}
                            />
                        ))}
                        {(data?.meta?.totalItems || 0) > 0 && (
                            <div className="d-flex justify-content-center mt-4">
                                <Pagination
                                    defaultSize={LIMIT}
                                    onSizeChange={(size) => {}}
                                    total={data?.meta.totalItems || 0}
                                    showSize={false}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={setPage}
                                    defaultCurrentPage={1}
                                    listSize={[12, 24, 50, 100]}
                                />
                            </div>
                        )}
                    </div>
                </Spin>
            </div>
            {/* END: Section Box Item Element */}
        </div>
    );
};

export default TabReviewHistory;
