/* ================= Menu Button Trigger on Mobile ================= */

document.addEventListener("DOMContentLoaded", function() {
    function darken_screen(yesno) {
        if (yesno == true) {
            document.querySelector(".screen-darken").classList.add("active");
        } else if (yesno == false) {
            document.querySelector(".screen-darken").classList.remove("active");
        }
    }

    function close_offcanvas() {
        darken_screen(false);
        document.querySelector(".mobile-offcanvas.show").classList.remove("show");
        document.body.classList.remove("offcanvas-active");
    }

    function show_offcanvas(offcanvas_id) {
        darken_screen(true);
        document.getElementById(offcanvas_id).classList.add("show");
        document.body.classList.add("offcanvas-active");
    }


    document.querySelectorAll("[data-trigger]").forEach(function(everyelement) {
        let offcanvas_id = everyelement.getAttribute("data-trigger");
        everyelement.addEventListener("click", function(e) {
            e.preventDefault();
            show_offcanvas(offcanvas_id);
        });
    });

    document.querySelectorAll(".btn-close").forEach(function(everybutton) {
        everybutton.addEventListener("click", function(e) {
            e.preventDefault();
            close_offcanvas();
        });
    });

    if (document.querySelector(".screen-darken")) {

        document.querySelector(".screen-darken").addEventListener("click", function(event) {
            close_offcanvas();
        });
    }
});

/* ================= Menu Show / Hide Navbar (only on Desktop) ================= */
export function showNavbar() {
    var x = document.getElementById("navbar-main");
    if (x.style.display === "none") {
        x.style.display = "block";
        document.getElementById("header-top").classList.remove("remove-padding");
        document.getElementById("content-main").classList.remove("remove-padding");
    } else {
        x.style.display = "none";
        document.getElementById("header-top").classList.add("remove-padding");
        document.getElementById("content-main").classList.add("remove-padding");
    }
}

/* ================= Current Time ================= */
let myVar = setInterval(myTimer, 1000);

function myTimer() {
    const d = new Date();
    const element = document.getElementById("timing");
    if (element) {
        element.innerHTML = d.toLocaleTimeString();
    }
}

/* ================= Count Number Animation use JavaScript ================= */
const animationDuration = 2000;
const frameDuration = 1000 / 60;
const totalFrames = Math.round(animationDuration / frameDuration);
const easeOutQuad = (t) => t * (2 - t);

const animateCountUp = (el) => {
    let frame = 0;
    const countTo = parseInt(el.innerHTML || 0, 10);
    const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        const currentCount = Math.round(countTo * progress);

        // if (parseInt(el?.innerHTML || 0, 10) !== currentCount) {
        //     el?.innerHTML = currentCount;
        // }

        if (frame === totalFrames) {
            clearInterval(counter);
        }
    }, frameDuration);
};
const countupEls = document.querySelectorAll(".countup");
countupEls.forEach(animateCountUp);


// slide

// function openModal() {
//     document.getElementById('myModal').style.display = "block";
// }

// function closeModal() {
//     document.getElementById('myModal').style.display = "none";
// }

// var slideIndex = 1;
// showSlides(slideIndex);

// function plusSlides(n) {
//     showSlides(slideIndex += n);
// }

// function currentSlide(n) {
//     showSlides(slideIndex = n);
// }

// function showSlides(n) {
//     var i;
//     var slides = document.getElementsByClassName("mySlides");
//     var dots = document.getElementsByClassName("demo");
//     var captionText = document.getElementById("caption");
//     if (n > slides.length) { slideIndex = 1 }
//     if (n < 1) { slideIndex = slides.length }
//     for (i = 0; i < slides.length; i++) {
//         slides[i].style.display = "none";
//     }
//     for (i = 0; i < dots.length; i++) {
//         dots[i].className = dots[i].className.replace(" active", "");
//     }
//     slides[slideIndex - 1].style.display = "block";
//     dots[slideIndex - 1].className += " active";
//     captionText.innerHTML = dots[slideIndex - 1].alt;
// }