import React from "react";
import TableUserPayment from "./TableUserPayment";
import { useGetPaymentStatisticsQuery } from "@redux/queries/user/payment.api";
import NumberFormat from "@components/NumberFormat";

interface TabPaymentProps {}
const TabPayment: React.FunctionComponent<TabPaymentProps> = () => {
    const { data: dataStatistics, isLoading } = useGetPaymentStatisticsQuery();
    const { totalAmount, totalDeposit, totalAmountSuccess } = dataStatistics || {};

    return (
        <div
            className={`tab-pane fade ${location.hash === "#history-payment-tab" ? "show active" : ""}`}
            id="history-payment"
            role="tabpanel"
            aria-labelledby="history-payment-tab"
        >
            <div className="box-item-element">
                <div className="body-element">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="p-4 box-component box-option box-style-01">
                                <h6>Tổng thu</h6>
                                <h4>
                                    <NumberFormat
                                        value={totalAmount}
                                        suffix={" đ"}
                                    />
                                </h4>
                                <span className="abs_icon text-box-blue">
                                    <i className="bi-person-add"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="p-4 box-component box-option box-style-01">
                                <h6>Số đơn nạp</h6>
                                <h4>
                                    <NumberFormat
                                        value={totalDeposit}
                                        suffix={" đơn"}
                                    />
                                </h4>
                                <span className="abs_icon text-box-gold">
                                    <i className="bi-person-add"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="p-4 box-component box-option box-style-01">
                                <h6>Đơn hoàn thành</h6>
                                <h4>
                                    <NumberFormat
                                        value={totalAmountSuccess}
                                        suffix={" đ"}
                                    />
                                </h4>
                                <span className="abs_icon text-box-green">
                                    <i className="bi-person-add"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <StatisticBox /> */}
                    <TableUserPayment />
                </div>
            </div>
        </div>
    );
};

export default TabPayment;
