import { useStartWelcomeMutation } from "@redux/queries/user/intro.api";
import { Dispatch, FC, SetStateAction, memo } from "react";
import { Link } from "react-router-dom";

interface IFirstStep {
    dataWelcome: InputWelcomeUser;
    setDataWelcome: Dispatch<SetStateAction<InputWelcomeUser>>;
    setActiveTab: Dispatch<SetStateAction<number>>;
}

const FirstStep: FC<IFirstStep> = ({ dataWelcome, setDataWelcome, setActiveTab }) => {
    const [startWelcome] = useStartWelcomeMutation();

    const handleStartWelcome = async () => {
        const response = await startWelcome();

        if (response) {
            setActiveTab(2);
        }
        setActiveTab(2);
    };

    return (
        <section id="content-main">
            <div className="box-component min-h-400px bg-intro">
                <div className="box-introduction">
                    <div className="content-li-step">
                        <ul className="li-step">
                            <li className="active">
                                <span>1</span>
                            </li>
                            <li>
                                <span>2</span>
                            </li>
                            <li>
                                <span>3</span>
                            </li>
                            <li>
                                <span>4</span>
                            </li>
                            <li>
                                <span>5</span>
                            </li>
                        </ul>
                    </div>

                    <div className="content-introduction">
                        <div className="text-center">
                            <img
                                src="images/img-intro.png?v=1.0"
                                alt=""
                            />
                            <h5>Chào mừng bạn đến với StudyinChina.io</h5>
                            <p>
                                Hãy cho chúng tôi biết một chút về bản thân bạn. Hệ thống sẽ dựa vào các thông tin mà
                                bạn cung cấp để đưa ra cho bạn những thông tin học bổng, nguyện vọng trường phù hợp và
                                mang lại cho bạn trải nghiệm tuyệt vời nhất trên StudyinChina.io. Việc này sẽ chỉ mất
                                một hoặc hai phút, hãy để chúng mình đồng hành cùng bạn.
                            </p>
                            <div className="group-button">
                                <Link
                                    to="#"
                                    className="btn btn-blue"
                                    onClick={handleStartWelcome}
                                >
                                    Bắt đầu
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default memo(FirstStep);
