import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useCreateStepMutation,
    useUpdateStepMutation,
} from "@redux/queries/admin/admin.step";
import { validateNoSpaces } from "@utils/common";
import { Form, Input, Modal } from "antd";
import { useEffect } from "react";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createStep, isLoadingCreate] = useCreateStepMutation();
    const [updateStep, isLoadingUpdate] = useUpdateStepMutation();
    const { showToast } = useToast();

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue(dataEdit);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updateStep({
                  id: dataEdit.id,
                  ...values,
                  order: parseInt(values.order),
              })
            : await createStep({
                    ...values,
                    order: parseInt(values.order),
                }
            );

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Step" : "Add Step"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()} 
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="name"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                    {
                                        validator: (rule, value, callback) =>
                                            validateNoSpaces(rule, value, callback, "vi"),
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Tên</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="order"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                    {
                                        pattern: /^[0-9]*$/,
                                        message: "Phải nhập số",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Vị trí</label>
                        </div>
                    </div>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
