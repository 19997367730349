import { Pagination } from "@components/pagination";
import { useGetActivityPaginateQuery, useGetListActivityQuery } from "@redux/queries/admin/admin.activity";
import { appConfig } from "@utils/common";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { listTypeActivity } from "./TypeActivity";
import { Tag } from "antd";

const Activity = () => {
    const [query, setQuery] = useState({
        page: 1,
        limit: 8,
    });
    const { data, isFetching, isLoading } = useGetActivityPaginateQuery(query as any);
    return (
        <div className="">
            <ul className="list-item">
                {data?.items.map((item: any) => {
                    const typeActivity = listTypeActivity.find((o: any) => o.value === item.type);
                    return (
                        <li key={item.id}>
                            <img
                                // src="images/default.png"
                                src={window?.location?.origin + "/images/default.png"}
                                alt="icon-avatar"
                            />
                            <h6>
                                <span className="">
                                    {item.description}
                                    <Tag
                                        color={typeActivity?.color}
                                        className="rounded-4 ms-2"
                                    >
                                        <i className={typeActivity?.icon}></i>
                                    </Tag>
                                </span>
                                <time>{dayjs(item.createAt).format(appConfig.formatDateTime)}</time>
                            </h6>
                        </li>
                    );
                })}
            </ul>
            <Pagination
                onSizeChange={(size) => setQuery({ ...query, limit: size })}
                total={data?.meta.totalItems || 0}
                showSize={false}
                totalPage={data?.meta.totalPages || 0}
                onChangePage={(page) => setQuery({ ...query, page: page })}
                defaultCurrentPage={query.page}
            />
        </div>
    );
};

export default Activity;
