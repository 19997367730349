import { useGetListAreaQuery } from "@redux/queries/admin/admin.area";
import { useGetListScholarshipTypeQuery } from "@redux/queries/admin/admin.scholarship.type";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { removeEmptyValues } from "@utils/common";
import { Form, Select } from "antd";
import { Dispatch, FC, SetStateAction, memo } from "react";

interface IFourthStep {
    dataWelcome: InputWelcomeUser;
    setDataWelcome: Dispatch<SetStateAction<InputWelcomeUser>>;
    setActiveTab: Dispatch<SetStateAction<number>>;
}

const FourthStep: FC<IFourthStep> = ({ dataWelcome, setDataWelcome, setActiveTab }) => {
    const { data: listTrainings = [] } = useGetListTrainingQuery();
    const { data: listScholarshipTypes = [] } = useGetListScholarshipTypeQuery();
    const { data: listAreas = [] } = useGetListAreaQuery();

    const optionsAreas = listAreas.map((item) => {
        return { value: item.id, label: item.language[0].title };
    });

    const [form] = Form.useForm();

    const onSubmit = (values: any) => {
        const { applyYear, applySemester, area } = values || {};
        const trainingValues: any[] = [];
        const scholarshipTypeValues: any[] = [];

        Object.keys(values).forEach((key) => {
            const keyTraining = key.split("training")[1];

            if (values[`training${keyTraining}`]) {
                trainingValues.push(keyTraining);
            }
        });

        Object.keys(values).forEach((key) => {
            const keyScholarshipType = key.split("scholarshipType")[1];

            if (values[`scholarshipType${keyScholarshipType}`]) {
                scholarshipTypeValues.push(keyScholarshipType);
            }
        });

        setDataWelcome((dataWelcome) => ({
            ...dataWelcome,
            aspirationApply: removeEmptyValues({
                applyYear: applyYear && Number(applyYear),
                applySemester: applySemester && Number(applySemester),
                training: trainingValues,
                scholarshipType: scholarshipTypeValues,
                area,
            }),
        }));

        console.log("trainingValues", trainingValues);
        console.log("scholarshipTypeValues", scholarshipTypeValues);
        console.log("areaValues", values?.area);

        handleNextStep();
    };

    const handleNextStep = () => {
        setActiveTab(5);
    };

    const handleBackStep = () => {
        setActiveTab(3);
    };

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    return (
        <section id="content-main">
            <div className="box-component">
                <div className="box-introduction">
                    <div className="content-li-step">
                        <ul className="li-step">
                            <li className="active">
                                <span>1</span>
                            </li>
                            <li className="active">
                                <span>2</span>
                            </li>
                            <li className="active">
                                <span>3</span>
                            </li>
                            <li className="active">
                                <span>4</span>
                            </li>
                            <li>
                                <span>5</span>
                            </li>
                        </ul>
                    </div>
                    <Form
                        className="font-13"
                        form={form}
                        onFinish={onSubmit}
                    >
                        <div className="content-introduction">
                            <div className="step-title">
                                <h6>Bước 3: Nguyện vọng của bạn</h6>
                                <h5>Nguyện vọng Apply học bổng</h5>
                                <p>
                                    Hãy cho chúng tôi biết thêm thông tin về nguyện vọng của bạn. Dựa vào các thông tin
                                    mà bạn cung cấp, chúng tôi sẽ đưa ra các chương trình học bổng phù hợp và tốt cho
                                    bạn nhất.
                                </p>
                            </div>

                            <div className="box-form-part">
                                <h6 className="title-part-form">Bạn muốn Apply mùa học bổng nào?</h6>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group mb-0">
                                            <Form.Item
                                                name="applyYear"
                                                className="mb-0"
                                                initialValue={"2023"}
                                            >
                                                <div className="form-floating">
                                                    <select className="form-select">
                                                        <option value="2023">2023</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2025">2025</option>
                                                        <option value="2026">2026</option>
                                                    </select>
                                                    <label>Năm</label>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group mb-0">
                                            <Form.Item
                                                name="applySemester"
                                                className="mb-0"
                                                initialValue={"1"}
                                            >
                                                <div className="form-floating">
                                                    <select className="form-select">
                                                        <option value="1">Kỳ 1</option>
                                                        <option value="2">Kỳ 2</option>
                                                    </select>
                                                    <label>Kỳ nhập học</label>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="box-form-part">
                                <h6 className="title-part-form">Chương trình học mà bạn muốn đăng ký</h6>
                                <div className="row">
                                    {listTrainings.length > 0 &&
                                        listTrainings.map((item, index) => (
                                            <div
                                                className="col-6 col-md-4"
                                                key={index}
                                            >
                                                <div className="form-group checkbox-mobile mb-0">
                                                    <Form.Item
                                                        name={`training${item.id}`}
                                                        className="mb-0"
                                                        valuePropName="checked"
                                                    >
                                                        <label className="custom-checkbox">
                                                            <input type="checkbox" />
                                                            <span className="btnCheck">{item.language[0].title}</span>
                                                        </label>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div className="box-form-part">
                                <h6 className="title-part-form">Loại học bổng bạn muốn Apply là gì?</h6>
                                <div className="row justify-content-center">
                                    {listScholarshipTypes.length > 0 &&
                                        listScholarshipTypes.map((item, index) => (
                                            <div
                                                className="col-6 col-sm-4"
                                                key={index}
                                            >
                                                <div className="form-group checkbox-mobile mb-0">
                                                    <Form.Item
                                                        name={`scholarshipType${item?.id}`}
                                                        className="mb-0"
                                                        valuePropName="checked"
                                                    >
                                                        <label className="custom-checkbox">
                                                            <input type="checkbox" />
                                                            <span className="btnCheck">{item?.language[0].title}</span>
                                                        </label>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div className="box-form-part">
                                <h6 className="title-part-form">Khu vực mà bạn muốn lựa chọn là gì?</h6>
                                <div className="row justify-content-center">
                                    <Form.Item
                                        name="area"
                                        className="user-form"
                                    >
                                        <Select
                                            mode="tags"
                                            className="w-50"
                                            placeholder="Nhập khu vực bạn muốn lựa chọn"
                                            onChange={handleChange}
                                            options={optionsAreas}
                                            optionFilterProp="label"
                                        />
                                    </Form.Item>
                                    {/* {listAreas.length > 0 &&
                                        listAreas.map((item, index) => (
                                            <div
                                                className="col-6 col-sm-4"
                                                key={index}
                                            >
                                                <div className="form-group mb-0">
                                                    <Form.Item
                                                        name={`area${item?.id}`}
                                                        className="mb-0"
                                                        valuePropName="checked"
                                                    >
                                                        <label className="custom-checkbox">
                                                            <input type="checkbox" />
                                                            <span className="btnCheck">{item?.language[0].title}</span>
                                                        </label>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        ))} */}
                                </div>
                            </div>
                        </div>

                        <hr className="hr-border" />

                        <div className="group-button-footer">
                            <button
                                type="button"
                                className="continue btn btn-gold"
                                onClick={handleBackStep}
                            >
                                <i className="bi-arrow-left"></i> Quay lại
                            </button>
                            <button
                                type="submit"
                                className="continue btn btn-blue"
                            >
                                Tiếp tục <i className="bi-arrow-right"></i>
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
    );
};

export default memo(FourthStep);
