import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

const BASE = "/api/school/new-category";

export const adminSchoolCategoryApi = createApi({
    reducerPath: "adminSchoolCategoryApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["SchoolCategory"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolCategory: builder.query<Array<ISchoolCategory>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "SchoolCategory" as const, id })),
                              { type: "SchoolCategory", id: "SchoolCategory-LIST" },
                          ]
                        : [{ type: "SchoolCategory", id: "SchoolCategory-LIST" }],
            }),
            getListSchoolCategoryPaginate: builder.query<
                IResponseDataAdmin<ISchoolCategory>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: {
                        page: query.page,
                        limit: query.limit,
                        name: query.name,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "SchoolCategory" as const, id })),
                              { type: "SchoolCategory", id: "SchoolCategory-LIST" },
                          ]
                        : [{ type: "SchoolCategory", id: "SchoolCategory-LIST" }],
            }),
            createSchoolCategory: builder.mutation<any, ISchoolCategoryInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolCategory"],
            }),
            updateSchoolCategory: builder.mutation<any, ISchoolCategoryInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        language: params.language,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolCategory"],
            }),
            deleteSchoolCategory: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolCategory"],
            }),
            deleteMultiSchoolCategory: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolCategory"],
            }),
        };
    },
});

export const {
    useGetListSchoolCategoryQuery,
    useGetListSchoolCategoryPaginateQuery,
    useLazyGetListSchoolCategoryPaginateQuery,
    useCreateSchoolCategoryMutation,
    useUpdateSchoolCategoryMutation,
    useDeleteSchoolCategoryMutation,
    useDeleteMultiSchoolCategoryMutation,
} = adminSchoolCategoryApi;
