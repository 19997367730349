import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useGetListTeamQuery } from "@redux/queries/admin/admin.team";
import { useGetListEmployeePaginateQuery } from "@redux/queries/admin/admin.user";
import { useCreateUserTeamMutation, useUpdateUserTeamMutation } from "@redux/queries/admin/admin.user-team";
import { validateNoSpaces } from "@utils/common";
import { Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createUserTeam, isLoadingCreate] = useCreateUserTeamMutation();
    const [updateUserTeam, isLoadingUpdate] = useUpdateUserTeamMutation();
    const { showToast } = useToast();

    const {
        data: list_employee,
        isLoading,
        isFetching,
    } = useGetListEmployeePaginateQuery({
        page: 1,
        limit: 1000,
    });

    const { data: list_team } = useGetListTeamQuery();

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue(dataEdit);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updateUserTeam({
                  id: dataEdit.id,
                  ...values,
              })
            : await createUserTeam({
                  ...values,
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update User Team" : "Add User Team"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="teamId"
                                className="form-floating"
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    allowClear
                                >
                                    {list_team?.map((o: any, i: any) => {
                                        return (
                                            <Select.Option
                                                key={o.id}
                                                value={o.id}
                                            >
                                                {o.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <label>Team</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="userId"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    placeholder=""
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {list_employee?.items &&
                                        list_employee?.items.map((o: any, i: any) => {
                                            return (
                                                <Select.Option
                                                    key={o.id}
                                                    value={o.id}
                                                >
                                                    {`${o.firstName || ""} ${o.lastName || ""} - ${o.code || ""}`}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <label>Nhân viên</label>
                        </div>
                    </div>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
