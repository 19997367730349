import { Form, Select, Upload, message } from "antd";
import { RcFile } from "antd/es/upload";
import { useFormik } from "formik";
import { FC, useState } from "react";
import * as Yup from "yup";

import { UploadFile } from "antd";

import { UploadProps } from "antd/lib";
import { useCreateTicketMutation } from "@redux/queries/user/user.api";
import { TICKET_OPTIONS } from "@consts";

interface ICreateTicket {}

const CreateTicket: FC<ICreateTicket> = () => {
    const [triggerCreateTicket] = useCreateTicketMutation();

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPngOrPdf) {
            message.error("Không đúng định dạng file yêu cầu");
            return isJpgOrPngOrPdf;
        }

        if (!isLt5M) {
            message.error("Kích thước file phải nhỏ hơn 5MB!");
            return isLt5M;
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: "",
            labels: [],
            question: "",
            thumbnail: "",
        },
        validationSchema: Yup.object({
            title: Yup.string().min(5, "Tiêu đề tối thiểu 5 ký tự").required("Không được bỏ trống Tiêu đề"),
            labels: Yup.array()
                .of(Yup.string())
                .min(1, "Không được bỏ trống Loại phiếu hỗ trợ")
                .required("Không được bỏ trống Loại phiếu hỗ trợ"),
            question: Yup.string().required("Không được bỏ trống Nội dung"),
        }),
        onSubmit: async (values) => {
            try {
                console.log("values", values);

                const formData = new FormData();

                const fields = {
                    title: values.title,
                    question: values.question,
                };

                Object.entries(fields).forEach(([name, value]) => {
                    formData.append(name, value);
                });

                values.labels.forEach((labelItem) => {
                    formData.append("labels[]", labelItem);
                });

                fileList.forEach((file) => {
                    formData.append("files", file.originFileObj as File);
                });

                const response: any = await triggerCreateTicket(formData);

                if (response?.error) {
                    throw new Error(response.error.message);
                }

                message.success(`Gửi phiếu hỗ trợ thành công!`);
                formik.resetForm();
                setFileList([]);
            } catch (e) {
                message.error(`Gửi phiếu hỗ trợ thất bại!`);
            }
        },
    });

    const handleChange: UploadProps["onChange"] = (info) => {
        setFileList(info.fileList);
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj as RcFile, (url) => {});
        }
    };

    const handleUploadFile = async ({ file, onError, onSuccess }: any) => {
        try {
            const formData = new FormData();
            formData.append("file", file);

            onSuccess();
        } catch (error) {
            console.log(error);
            message.error("Tải hình ảnh thất bại!");
            onError();
        }
    };

    const handleSelectChange = (value: any) => {
        console.log("value", value);
        formik.setFieldValue("labels", value);
    };

    return (
        <section id="content-main">
            <div>
                <div className="box-item-element">
                    <div className="body-element">
                        <form
                            autoComplete="off"
                            onSubmit={formik.handleSubmit}
                            className="col-12 col-sm-10 col-md-8 mx-auto"
                        >
                            <div className="form-group form-style">
                                <h5 className="text-center">Tạo phiếu hỗ trợ</h5>
                                <hr />
                                <div className="form-group form-style">
                                    <label>Chọn loại phiếu hỗ trợ</label>

                                    <Form.Item className="user-form mb-0">
                                        <Select
                                            value={formik.values.labels || []}
                                            mode="multiple"
                                            placeholder="-- Chọn loại phiếu --"
                                            options={TICKET_OPTIONS}
                                            onChange={handleSelectChange}
                                        />
                                    </Form.Item>

                                    {formik.touched.labels && formik.errors.labels ? (
                                        // @ts-ignore
                                        <p className="text-red">{formik?.errors?.labels}</p>
                                    ) : null}
                                </div>

                                <label>Tiêu đề</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    id="title"
                                    name="title"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.title}
                                />
                                {formik.touched.title && formik.errors.title ? (
                                    // @ts-ignore
                                    <p className="text-red">{formik?.errors?.title}</p>
                                ) : null}
                            </div>

                            <div className="form-group form-style">
                                <label>Nội dung</label>
                                <textarea
                                    className="form-control"
                                    rows={10}
                                    defaultValue={""}
                                    id="question"
                                    name="question"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.question}
                                />
                                {formik.touched.question && formik.errors.question ? (
                                    // @ts-ignore
                                    <p className="text-red">{formik?.errors?.question}</p>
                                ) : null}
                            </div>

                            <div className="form-group form-style">
                                <Upload
                                    multiple
                                    listType="picture"
                                    maxCount={5}
                                    fileList={fileList}
                                    accept="image/png, image/jpeg"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    customRequest={handleUploadFile}
                                    className="upload-create-ticket"
                                >
                                    <div>
                                        <button
                                            className="btn-blue mb-2"
                                            type="button"
                                        >
                                            <i className="bi-bi-arrow-90deg-up"></i>
                                            Tải lên file ảnh
                                        </button>
                                    </div>
                                </Upload>
                            </div>

                            <hr />

                            <button
                                type="submit"
                                className="btn btn-blue d-flex m-auto text-center"
                            >
                                <i className="bi-send-plus me-2"></i>
                                Gửi phiếu
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CreateTicket;
