import Nodata from "@components/Nodata";
import Post from "@pages/user/profile/components/TabMyPost/Post";
import { useServiceSearchQuery } from "@redux/queries/user/authaction.api";
import { useListBlogQuery } from "@redux/queries/user/blog.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FC } from "react";

const PAGE = 1;
const LIMIT = 5;

interface IRecentService {
    schoolId: string;
}

const RecentService: FC<IRecentService> = ({ schoolId }) => {
    const { data, isLoading } = useServiceSearchQuery(schoolId ? { schoolId, page: PAGE, limit: LIMIT } : skipToken);

    const { items: listServices } = data || {};

    return (
        <div className="box-item-element">
            <div className="title-element">
                <h5>Thông tin tuyển sinh liên quan</h5>
                <hr />
            </div>
            <div className="body-element">
                {listServices && listServices?.length > 0 ? (
                    listServices?.map((service, index) => {
                        return (
                            <Post
                                key={index}
                                data={
                                    {
                                        thumbnail: service?.school?.background,
                                        createdAt: service?.createdAt,
                                    } as any
                                }
                                title={service?.language?.[0]?.title}
                                shortContent={service?.language?.[0]?.shortContent}
                                link={`/pro-tool/service/${service.id}`}
                            />
                        );
                    })
                ) : (
                    <Nodata />
                )}
            </div>
        </div>
    );
};

export default RecentService;
