import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "./customFetchBase";

const BASE = "/api/auth";

export const authApi = createApi({
    reducerPath: "authApi",
    keepUnusedDataFor: 10,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            login: builder.mutation<
                Response<{
                    accessToken: string;
                    type: string;
                    expriresIn: number;
                }>,
                LoginInput
            >({
                query: (params) => ({
                    url: `${BASE}/login`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            register: builder.mutation<Response<null>, RegisterInput>({
                query: (params) => ({
                    url: `${BASE}/register`,
                    method: "POST",
                    body: {
                        username: params.username,
                        firstName: params.firstName,
                        lastName: params.lastName,
                        gender: params.gender,
                        birthday: params.birthday,
                        email: params.email,
                        password: params.password,
                        ref: params?.ref,
                    },
                    headers: {
                        recaptcha: params.recaptcha,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            forgotPass: builder.mutation<Response<null>, ForgotPass>({
                query: (params) => ({
                    url: `${BASE}/forgot-password`,
                    method: "POST",
                    body: {
                        email: params.email,
                    },
                    headers: {
                        recaptcha: params.recaptcha,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            resetPass: builder.mutation<Response<null>, ResetPass>({
                query: (params) => ({
                    url: `${BASE}/reset-password`,
                    method: "POST",
                    body: {
                        hash: params.hash,
                        token: params.token,
                        password: params.password,
                    },
                    headers: {
                        recaptcha: params.recaptcha,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            checkResetPasswordToken: builder.mutation<Response<null>, CheckResetPass>({
                query: (params) => {
                    return {
                        url: `${BASE}/reset-password/${params.token}/${params.hash}`,
                        method: "PATCH",
                    };
                },
            }),
            socialFacebook: builder.query<Response<null>, void>({
                query: (params) => {
                    return {
                        url: `${BASE}/social/facebook`,
                    };
                },
            }),
            socialGoogle: builder.query<Response<null>, void>({
                query: (params) => {
                    return {
                        url: `${BASE}/social/google`,
                    };
                },
            }),
            emailConfirm: builder.query<Response<null>, { idHash: string }>({
                query: (params) => {
                    return {
                        url: `/api/email/confirm/${params.idHash}`,
                    };
                },
            }),
            authToken: builder.mutation<
                Response<{
                    accessToken: string;
                    type: string;
                    expriresIn: number;
                }>,
                { code: string; state: string }
            >({
                query: (params) => ({
                    url: `${BASE}/token`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
        };
    },
});

export const {
    useLoginMutation,
    useRegisterMutation,
    useEmailConfirmQuery,
    useForgotPassMutation,
    useResetPassMutation,
    useLazySocialFacebookQuery,
    useLazySocialGoogleQuery,
    useAuthTokenMutation,
    useCheckResetPasswordTokenMutation,
} = authApi;
