import {
    useLazyAllTrainingsQuery,
    useLazyAreasQuery,
    useLazyCitiesQuery,
    useLazyMajorsQuery,
    useLazyProjectsQuery,
    useLazyScholarshipTypeQuery,
    useLazySchoolTypesQuery,
    useLazySpecializedsQuery,
} from "@redux/queries/user/public.api";
import { Field } from "formik";
import { cloneDeep } from "lodash";
import React, { useEffect, useState, useRef, useMemo } from "react";
import ClearFilter from "./ClearFilter";
import ListCheckboxFilter, { FormRefListBox } from "./FilterListCheckbox";
import FilterYear, { FormRef, YEAR_MAX, YEAR_MIN } from "./FilterYear";

interface FilterServicesProps {
    onChange: (filter: ServiceFilter) => void;
}

const FilterServices: React.FunctionComponent<FilterServicesProps> = (props) => {
    const defaultFilter = {
        area: [],
        training: [],
        languageSupport: [],
        scholarshipType: [],
        school_type: [],
        school_project: [],
        major: [],
        specialized: [],
        year_from: YEAR_MIN,
        year_to: YEAR_MAX,
    };
    const { onChange } = props;

    const [filter, setFilter] = useState<ServiceFilter>(defaultFilter);

    const [triggerAreas, resultAreas] = useLazyAreasQuery();
    const [triggerTrainings, resultTrainings] = useLazyAllTrainingsQuery();
    const [triggerScholarshipTypes, resultScholarshipTypes] = useLazyScholarshipTypeQuery();
    const [triggerSpecializeds, resultSpecializeds] = useLazySpecializedsQuery();

    const refYear = useRef<FormRef>(null);
    const refArea = useRef<FormRefListBox>(null);
    const refTraining = useRef<FormRefListBox>(null);
    const refLanguageSupport = useRef<FormRefListBox>(null);
    const refScholarshipType = useRef<FormRefListBox>(null);
    const refSpecilized = useRef<FormRefListBox>(null);

    const areas = (resultAreas?.data?.map((e) => ({
        id: e.id,
        name: e?.language?.find((e) => e.lang === "vi")?.title,
    })) || []) as any;

    // const cities = useMemo(() => {
    //     let result = [];
    //     if (filter.area.length !== 0) {
    //         result = (resultTrainings?.data
    //             ?.filter((e) => filter.area.includes(e.area.id))
    //             .map((e) => ({
    //                 id: e.id,
    //                 name: e?.language?.find((e) => e.lang === "vi")?.title,
    //             })) || []) as any;
    //     }
    //     return result;
    // }, [resultAreas, filter]);

    const trainings = (resultTrainings?.data?.map((e) => ({
        id: e.id,
        name: e?.language?.find((e) => e.lang === "vi")?.title,
    })) || []) as any;

    const scholarshipTypes = (resultScholarshipTypes?.data?.map((e) => ({
        id: e.id,
        name: e?.language?.find((e) => e.lang === "vi")?.title,
    })) || []) as any;

    const handleChangeFilter = (key: string, values: Array<string>) => {
        const cloneFilter = cloneDeep(filter);
        const newFilter = {
            ...cloneFilter,
            [key]: values,
        };

        if (key === "major") {
            const selectedMajorIds = values;
            const selectedSpecializedIds = filter.specialized;
            const newSelectedSpecializedIds = selectedSpecializedIds.filter((e) => {
                const isSpecInSelectedMajor = resultSpecializeds?.data?.find(
                    (ele) => e === ele.id && selectedMajorIds.includes(ele.major.id)
                );
                return !!isSpecInSelectedMajor;
            });
            newFilter.specialized = newSelectedSpecializedIds;
            refSpecilized?.current?.setSelectedList?.(newSelectedSpecializedIds);
        }

        setFilter(newFilter);
        onChange(newFilter);
    };

    const handleChangeYear = (values: { yearFrom: number; yearTo: number }) => {
        const cloneFilter = cloneDeep(filter);
        const newFilter = {
            ...cloneFilter,
            year_from: values.yearFrom,
            year_to: values.yearTo,
        };
        setFilter(newFilter);
        onChange(newFilter);
    };

    const handleResetFilter = () => {
        refYear?.current?.resetFields?.();
        refArea?.current?.resetFields?.();
        refTraining?.current?.resetFields?.();
        refLanguageSupport?.current?.resetFields?.();
        refScholarshipType?.current?.resetFields?.();
        setFilter(defaultFilter);
        onChange(defaultFilter);
    };

    useEffect(() => {
        triggerAreas();
        triggerTrainings();
        triggerScholarshipTypes({});
        triggerSpecializeds();
    }, []);

    return (
        <aside className="group-filter-box">
            <ClearFilter onClear={handleResetFilter} />
            <Field name="year">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <FilterYear
                        title={"Năm tuyển sinh"}
                        ref={refYear}
                        onChange={(values: { yearFrom: number; yearTo: number }) => handleChangeYear(values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
            <Field name="area">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <ListCheckboxFilter
                        ref={refArea}
                        title={"Khu vực"}
                        listData={areas}
                        onChange={(values: Array<string>) => handleChangeFilter("area", values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
            <Field name="training">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <ListCheckboxFilter
                        ref={refTraining}
                        title="Hệ đào tạo"
                        listData={trainings}
                        onChange={(values: Array<string>) => handleChangeFilter("training", values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>

            <Field name="languageSupport">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <ListCheckboxFilter
                        ref={refLanguageSupport}
                        title="Chương trình học"
                        listData={[
                            {
                                id: "CN",
                                name: "Tiếng Trung",
                            },
                            {
                                id: "EN",
                                name: "Tiếng Anh",
                            },
                        ]}
                        onChange={(values: Array<string>) => handleChangeFilter("languageSupport", values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
            <Field name="scholarshipType">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <ListCheckboxFilter
                        ref={refScholarshipType}
                        title="Loại Học bổng"
                        listData={scholarshipTypes}
                        onChange={(values: Array<string>) => handleChangeFilter("scholarshipType", values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
        </aside>
    );
};

export default FilterServices;
