import ModalApply from "@pages/pro-tool/schools/components/ModalApply";
import {
    useAreasQuery,
    useGetSchoolCompareQuery,
    useLazyGetSchoolCompareByIdQuery,
} from "@redux/queries/user/public.api";
import { authSelector } from "@redux/slices/auth.slice";
import { formatNumber } from "@utils/common";
import { Select, SelectProps } from "antd";
import { debounce, isArray } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Compare: FC = () => {
    const { data: areas } = useAreasQuery();
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);

    const [areaSelected, setAreaSelected] = useState<Record<string, string>>();
    const [schoolSelected, setSchoolSelected] = useState<ShortSchool[]>([]);
    const [searchSchool, setSearchSchool] = useState("");
    const [openApply, setOpenApply] = useState(false);
    const [schoolId, setSchoolId] = useState("");
    const [schoolIdSelected, setSchoolIdSelected] = useState<string[]>([]);
    const [listDataCompare, setListDataCompare] = useState<ShortSchool[]>([]);
    const [loading, setLoading] = useState(false);

    const [trigger, dataSchool] = useLazyGetSchoolCompareByIdQuery();

    const { data } = useGetSchoolCompareQuery({ page: 1, limit: 10, s: searchSchool, ...areaSelected });
    const { items: listSchools } = data || { items: [] };

    const areaOptions: SelectProps["options"] =
        areas?.map((area) => ({
            label: area?.language?.[0]?.title,
            value: area?.id,
        })) || [];

    const schoolOptions: ShortSchool[] =
        listSchools?.map((school: any) => ({
            ...school,
            label: school?.language?.[0]?.name,
            value: school?.id,
        })) || [];

    const handleSelectAreas = (value: string[]) => {
        let listAreas: Record<string, string> = {};
        value?.map((e, i) => {
            listAreas[`areas[${i}]`] = e;
        });

        setAreaSelected(listAreas);
    };

    const handleSelectSchools = async (value: string[], option: ShortSchool | ShortSchool[]) => {
        try {
            // setLoading(true);
            if (value) {
                const selectedSchoolId = value.filter((element) => !schoolIdSelected.includes(element)).slice(-1);

                if (selectedSchoolId?.[0]) {
                    const exist = listDataCompare.find((school) => school.id === selectedSchoolId?.[0]);

                    if (!exist) {
                        const { data: dataSchoolCompare } = await trigger({ id: selectedSchoolId?.[0] });

                        setListDataCompare((prev) => [...prev, dataSchoolCompare]);
                    }
                }

                setSchoolIdSelected(value);
            }

            // if (isArray(option)) {
            //     setSchoolSelected(option);
            // }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedCallback = useCallback(
        debounce((value) => {
            setSearchSchool(value);
        }, 300),
        []
    );

    // student: "Số lượng sinh viên",
    // international_student: "Số lượng sinh viên quốc tế",
    // year_of_school_establishment: "Năm thành lập",
    // school_level: "Cấp học",
    // school_type: "Loại hình",
    // number_of_majors: "Số lượng ngành học",
    // number_of_lecturers: "Số lượng giảng viên",
    // acreage: "Diện tích (m2)",
    // cityLevel: "Cấp thành phố",
    // is_central_city: "Thành phố trực thuộc TW",
    // area: "Khu vực",
    // economic_zone: "Đặc khu kinh tế",
    // provinceLevel: "Cấp tỉnh",
    // numberOfScholarshipTypes: "Số loại học bổng",
    // tuition_fee_university: "Học phí hệ Đại học",
    // tuition_fee_master: "Học phí hệ Thạc sĩ",
    // dormitory_fee: "Phí kí túc xá",
    // living_expense_fee: "Sinh hoạt phí",
    // major_focus: "Ngành trọng điểm",
    // dining_hall_number: "Số nhà ăn",
    // national_ranking: 'Xếp hạng quốc gia',
    // number_of_specialized: 'Số lượng chuyên ngành',
    // top_specialized: 'Top ngành',

    const getAttributes = (school: ShortSchool | undefined, name: string) => {
        return school?.attributes?.find((attribute) => attribute?.name === name)?.value;
    };

    // dùng để ẩn hiện column nếu cần, ví dụ: hideColumn("dining_hall_number")
    // const hideColumn = (column: string) => {
    //     return schoolSelected?.every((school) => getAttributes(school, column) === undefined);
    // };

    return (
        <section id="content-main">
            <div className="group-title-outline">
                <h2>So sánh Trường</h2>
            </div>

            <div className="row">
                <div className="col-12 col-sm-4">
                    <div className="form-group">
                        <div className="form-floating">
                            <Select
                                disabled={schoolIdSelected?.length !== 0}
                                mode="multiple"
                                className="compare-select"
                                style={{ width: "100%" }}
                                placeholder="Chọn khu vực"
                                options={areaOptions}
                                optionFilterProp="label"
                                onChange={handleSelectAreas}
                            />
                            <label>Khu vực</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-4">
                    <div className="form-group">
                        <div className="form-floating">
                            <Select
                                disabled={loading}
                                mode="multiple"
                                className="compare-select"
                                style={{ width: "100%" }}
                                placeholder="Chọn trường"
                                options={schoolOptions}
                                optionFilterProp="label"
                                onChange={handleSelectSchools}
                                onSearch={(value) => debouncedCallback(value)}
                                onDropdownVisibleChange={(open) => {
                                    if (!open) debouncedCallback("");
                                }}
                            />
                            <label>Tên Trường</label>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="compare-school"
                className="box-component"
            >
                <div className="table-responsive">
                    <table className="table">
                        {schoolIdSelected?.length > 0 && (
                            <tr>
                                <td></td>
                                {schoolIdSelected?.map((schoolId) => (
                                    <td
                                        className="text-center"
                                        key={schoolId}
                                    >
                                        <button
                                            className="btn-blue"
                                            onClick={() => {
                                                setOpenApply(true);
                                                setSchoolId(schoolId);
                                            }}
                                            disabled={!isLogin}
                                        >
                                            Apply Now
                                        </button>
                                    </td>
                                ))}
                            </tr>
                        )}
                        <tr>
                            <td>
                                <i className="bi-bank"></i> Tên Trường
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return <td key={school?.id}>{school?.language?.[0]?.name}</td>;
                            })}
                        </tr>

                        {/* <tr>
                            <td>
                                <i className="bi-bank"></i> Loại trường
                            </td>
                            {schoolSelected?.map((school) => <td key={school?.id}></td>)}
                        </tr> */}
                        <tr>
                            <td>
                                <i className="bi-bank"></i> Loại hình Trường
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return <td key={school?.id}>{getAttributes(school, "school_type")}</td>;
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-receipt-cutoff"></i> Diện tích (m²)
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                const acreage = getAttributes(school, "acreage");
                                return <td key={school?.id}>{acreage && formatNumber(Number(acreage))}</td>;
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-award"></i> Dự án
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        <ul className="m-0">
                                            {school?.projects?.map((project) => {
                                                return <li key={project?.id}>- {project?.name}</li>;
                                            })}
                                        </ul>
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-pin-map"></i> Khu vực
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return <td key={school?.id}>{school?.area?.language?.[0]?.title}</td>;
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-pin-map"></i> Thành phố
                            </td>

                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return <td key={school?.id}>{school?.city?.language?.[0]?.title}</td>;
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-asterisk"></i> Cấp thành phố
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return <td key={school?.id}>{getAttributes(school, "cityLevel")}</td>;
                            })}
                        </tr>

                        <tr>
                            <td>
                                <i className="bi-asterisk"></i> Xếp hạng quốc gia
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "national_ranking") &&
                                            formatNumber(Number(getAttributes(school, "national_ranking")))}
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <td>
                                <i className="bi-asterisk"></i> Số chuyên ngành
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "number_of_specialized") &&
                                            formatNumber(Number(getAttributes(school, "number_of_specialized")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-asterisk"></i> Ngành trọng điểm
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return <td key={school?.id}>{getAttributes(school, "major_focus")}</td>;
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-people"></i> Số giảng viên
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "number_of_lecturers") &&
                                            formatNumber(Number(getAttributes(school, "number_of_lecturers")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-people"></i> Sinh viên
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "student") &&
                                            formatNumber(Number(getAttributes(school, "student")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-people"></i> Sinh viên quốc tế
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "international_student") &&
                                            formatNumber(Number(getAttributes(school, "international_student")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-currency-dollar"></i> Học phia hệ Đại học
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "tuition_fee_university") &&
                                            formatNumber(Number(getAttributes(school, "tuition_fee_university")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-currency-dollar"></i> học phí hệ Thạc sĩ
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "tuition_fee_master") &&
                                            formatNumber(Number(getAttributes(school, "tuition_fee_master")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-currency-dollar"></i> Phí ký túc xá
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "dormitory_fee") &&
                                            formatNumber(Number(getAttributes(school, "dormitory_fee")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-currency-dollar"></i> Sinh hoạt phí
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "living_expense_fee") &&
                                            formatNumber(Number(getAttributes(school, "living_expense_fee")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-currency-dollar"></i>
                                Số nhà ăn
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "dining_hall_number") &&
                                            formatNumber(Number(getAttributes(school, "dining_hall_number")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-award"></i>
                                Số lượng học bổng
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "numberOfScholarshipTypes") &&
                                            formatNumber(Number(getAttributes(school, "numberOfScholarshipTypes")))}
                                    </td>
                                );
                            })}
                        </tr>
                        <tr>
                            <td>
                                <i className="bi-file-ruled"></i> Top ngành
                            </td>
                            {schoolIdSelected?.map((schoolId) => {
                                const school = listDataCompare.find((school) => school?.id === schoolId);
                                return (
                                    <td key={school?.id}>
                                        {getAttributes(school, "top_specialized") &&
                                            formatNumber(Number(getAttributes(school, "top_specialized")))}
                                    </td>
                                );
                            })}
                        </tr>
                    </table>
                </div>
            </div>
            {openApply && (
                <ModalApply
                    open={openApply}
                    schoolId={schoolId}
                    onClose={() => setOpenApply(false)}
                />
            )}
        </section>
    );
};

export default Compare;
