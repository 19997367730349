import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

const BASE = "/api/deposit";

export const adminDepositApi = createApi({
    reducerPath: "adminDepositApi",
    keepUnusedDataFor: 3,
    tagTypes: ["Deposit"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListDeposit: builder.query<Array<IDeposit>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListDepositPaginate: builder.query<IResponseDataAdmin<IDeposit>, { page: number; limit: number,userId?: string; status?: UserDepositStatus, methodId?: string, startAt?: number, endAt?: number }>({
                query: (query) => {
                    return ({
                        url: `${BASE}/paginate`,
                        method: "GET",
                        params: query,
                    })
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Deposit" as const, id })),
                              { type: "Deposit", id: "Deposit-LIST" },
                          ]
                        : [{ type: "Deposit", id: "Deposit-LIST" }],
            }),
            createDeposit: builder.mutation<any, IAdminDepositInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: {
                        amount: params.amount,
                        note: params.note,
                        methodId: params.methodId,
                        memo: params.memo,
                        status: params.status,
                        userId: params.userId,
                        expireAt: params.expireAt

                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Deposit"],
            }),
            updateDeposit: builder.mutation<any, IAdminDepositInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        amount: params.amount,
                        note: params.note,
                        methodId: params.methodId,
                        memo: params.memo,
                        status: params.status,
                        userId: params.userId,
                        expireAt: params.expireAt
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Deposit"],
            }),
            deleteDeposit: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Deposit"],
            }),
            deleteMultiDeposit: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Deposit"],
            }),
        };
    },
});

export const {
    useGetListDepositQuery,
    useGetListDepositPaginateQuery,
    useLazyGetListDepositPaginateQuery,
    useCreateDepositMutation,
    useUpdateDepositMutation,
    useDeleteDepositMutation,
    useDeleteMultiDepositMutation,
} = adminDepositApi;
