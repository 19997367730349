import { useToast } from "@components/toast/ToastProvider";
import {
    TOAST_CREATE_ERROR,
    TOAST_CREATE_SUCCESS,
    TOAST_UPDATE_ERROR,
    TOAST_UPDATE_SUCCESS,
    listTrainingLanguage,
} from "@consts";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import {
    useCreateSchoolServiceMutation,
    useUpdateSchoolServiceMutation,
} from "@redux/queries/admin/admin.school.service";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";
import { useLazyScholarshipBySchoolQuery } from "@redux/queries/user/public.api";
import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { listSchoolServiceType } from "../school-service.const";

import SchoolServiceTab from "./SchoolServiceTab";
import { useGetListScholarshipQuery } from "@redux/queries/admin/admin.scholarship";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createSchoolService, isLoadingCreate] = useCreateSchoolServiceMutation();
    const [updateSchoolService, isLoadingUpdate] = useUpdateSchoolServiceMutation();

    const [selectedSchool, setSelectedSchool] = useState<any>(null);
    const [selectedSchoolScholarShip, setSelectedSchoolScholarShip] = useState<any>(null);
    const [listTrain, setListTrain] = useState<any>([]);
    const [listService, setListService] = useState<any>([]);
    const [getSchoolScholarship, { data: list_schoolScholarship }] = useLazyScholarshipBySchoolQuery();

    const {
        data: list_scholarship,
        isLoading: isLoadingScholarship,
        error: errorScholarship,
    } = useGetListScholarshipQuery();

    const { data: list_train, isLoading: isLoadingTrain, error: errorCity } = useGetListTrainingQuery();

    useEffect(() => {
        form.setFieldsValue({
            scholarshipId: undefined,
            trainingId: undefined,
        });
        if (selectedSchool) {
            getSchoolScholarship({
                schoolId: selectedSchool,
                page: 1,
                limit: 1000,
            });
        }
    }, [selectedSchool]);

    useEffect(() => {
        form.setFieldsValue({
            trainingId: undefined,
        });
        if (selectedSchoolScholarShip) {
            const schoolScholarship = list_schoolScholarship?.items?.find(
                (o: any) => o.scholarshipId === selectedSchoolScholarShip
            );
            const listTrain = schoolScholarship?.trainings;
            setListTrain(listTrain);
        }
    }, [selectedSchoolScholarShip]);

    const { showToast } = useToast();
    const { data: list_school, isLoading, isFetching } = useGetListSchoolQuery();

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");
    const [errorContent, setErrorContent] = useState<string>();

    useEffect(() => {
        if (dataEdit) {
            const formatDate = dataEdit?.schoolServiceTrainings.map((item: any) => {
                const introduceVi = item?.language?.find((item: any) => item.lang === "vi")?.content;
                const introduceEn = item?.language?.find((item: any) => item.lang === "en")?.content;
                const introduceCn = item?.language?.find((item: any) => item.lang === "cn")?.content;
                const shortContentVi = item?.language?.find((item: any) => item.lang === "vi")?.shortContent;
                const shortContentEn = item?.language?.find((item: any) => item.lang === "en")?.shortContent;
                const shortContentCn = item?.language?.find((item: any) => item.lang === "cn")?.shortContent;
                setIntroduceVi(introduceVi || "");
                setIntroduceEn(introduceEn || "");
                setIntroduceCn(introduceCn || "");
                return {
                    ...item,
                    expireApply: dayjs(item.expireApply),
                    titleVi: item?.language && item?.language[0].title,
                    titleEn: item?.language && item?.language[1].title,
                    titleCn: item?.language && item?.language[2].title,
                    shortContentVi,
                    shortContentEn,
                    shortContentCn,
                    introduceVi,
                    introduceEn,
                    introduceCn,
                };
            });
            form.setFieldsValue({
                schoolId: dataEdit?.schoolId,
                scholarshipId: dataEdit?.scholarshipId,
                serviceTab: formatDate,
            });
            setSelectedSchool(dataEdit.schoolId);
            setSelectedSchoolScholarShip(dataEdit.scholarshipId);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const service = values.serviceTab;
        const formatService = service.map((item: any) => {
            const expireApplyDate = moment(item.expireApply.$d).toISOString();
            const language = [
                {
                    lang: "vi",
                    title:
                        item.titleVi ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "vi")?.title;
                        }),
                    content:
                        item.introduceVi ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "vi")?.content;
                        }),
                    shortContent:
                        item.shortContentVi ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "vi")?.shortContent;
                        }),
                },
                {
                    lang: "en",
                    title:
                        item.titleEn ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "en")?.title;
                        }),
                    content:
                        item.introduceEn ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "en")?.content;
                        }),
                    shortContent:
                        item.shortContentEn ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "en")?.shortContent;
                        }),
                },
                {
                    lang: "cn",
                    title:
                        item.titleCn ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "cn")?.title;
                        }),
                    content:
                        item.introduceCn ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "cn")?.content;
                        }),
                    shortContent:
                        item.shortContentCn ||
                        dataEdit?.schoolServiceTrainings?.map((item: any) => {
                            item?.language?.find((language: any) => language.lang === "cn")?.shortContent;
                        }),
                },
            ];

            return {
                ...item,
                expireApply: expireApplyDate,
                amount: Number(item.amount),
                language,
            };
        });

        const data = {
            scholarshipId: values.scholarshipId,
            schoolServiceTrainings: [...formatService],
            schoolId: values.schoolId,
        } as any;
        const result = dataEdit
            ? await updateSchoolService({
                  id: dataEdit.id,
                  ...data,
              })
            : await createSchoolService({
                  ...data,
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setOpenHandleForm(false);
        }
    };

    const {
        data: list_specialized,
        isLoading: isLoadingSpecialized,
        error: errorSpecialized,
    } = useGetListSpecializedQuery();

    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ serviceTab: [{}] }}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                    form.resetFields();
                }}
                title={dataEdit ? "Update School Service" : "Add School Service"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1200}
            >
                <div className="body-component">
                    <div className="form-group">
                        <Row gutter={24}>
                            <Col span={12}>
                                <div className="form-floating">
                                    <Form.Item
                                        name="schoolId"
                                        className="form-floating"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: "Thông tin bắt buộc",
                                        //     },
                                        // ]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                            onChange={(value) => setSelectedSchool(value)}
                                            allowClear
                                        >
                                            {list_school &&
                                                list_school.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].name}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Trường</label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="form-floating">
                                    <Form.Item
                                        name={"scholarshipId"}
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin bắt buộc",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            allowClear
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                            showSearch
                                            onChange={(value) => setSelectedSchoolScholarShip(value)}
                                        >
                                            {(selectedSchool ? list_schoolScholarship?.items : list_scholarship)?.map((o: any, i: any) => {
                                                    console.log(o);
                                                    return (
                                                        <Select.Option
                                                            key={o.scholarshipId || o.id}
                                                            value={o.scholarshipId || o.id}
                                                        >
                                                            {o.scholarship ? o.scholarship.language[0].title : o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Học bổng</label>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <SchoolServiceTab
                        listTrain={selectedSchoolScholarShip ? listTrain : list_train}
                        listSchoolServiceType={listSchoolServiceType}
                        listTrainingLanguage={listTrainingLanguage}
                        list_specialized={list_specialized}
                        form={form}
                        introduceCn={introduceCn}
                        introduceEn={introduceEn}
                        introduceVi={introduceVi}
                        errorContent={errorContent}
                        setErrorContent={(dt: any) => setErrorContent(dt)}
                        setIntroduceCn={(dt: any) => setIntroduceCn(dt)}
                        setIntroduceEn={(dt: any) => setIntroduceEn(dt)}
                        setIntroduceVi={(dt: any) => setIntroduceVi(dt)}
                    />
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
