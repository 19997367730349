import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useDeleteMultiApplyTaskMutation,
    useDeleteApplyTaskMutation,
    useGetListApplyTaskPaginateQuery,
} from "@redux/queries/admin/admin.apply.task";
import { Button, Modal, Table, Typography, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import HandleForm from "./components/HanleForm";
import _ from "lodash";
import FilterApplyTask from "./components/FilterApplyTask";
import { UserRoleGrantList } from "./apply-task.const";

const { Text } = Typography;

const listStatusPublic = [
    {
        value: false,
        label: "Tạm ẩn",
    },
    {
        value: true,
        label: "Công khai",
    },
];

const ApplyTask: React.FunctionComponent = () => {
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({})

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching } = useGetListApplyTaskPaginateQuery(query as any);

    const [deleteApplyTask, isLoadingDelete] = useDeleteApplyTaskMutation();
    const [deleteMultiApplyTask, isLoadingMultiDelete] = useDeleteMultiApplyTaskMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteApplyTask({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDelete = async () => {
        const deletePromises = selectedRowKeys.map((id: any) => {
            return deleteApplyTask({ id });
        });

        try {
            const results = await Promise.all(deletePromises);

            // Check for errors in the results
            const errorResults = results.filter((result) => "error" in result);
            if (errorResults.length > 0) {
                // show error using toast
            } else {
                // show success using toast
            }
        } catch (error) {
            // Handle any errors that occurred during the deletion process
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Tên</div>,
            dataIndex: "name",
            key: "name",
        },
        {
            title: <div className="text-title-table">Mô tả</div>,
            dataIndex: "description",
            key: "description",
        },
        {
            title: <div className="text-title-table">Roles</div>,
            dataIndex: "roles",
            key: "roles",
            render: (roles: string[]) => {
                return roles?.map((item: string, index) => {
                    const role = UserRoleGrantList.find((role) => role.value.toString() === item.toString());
                    return (
                        <Tag key={index}>
                            {role?.label}
                        </Tag>
                    );
                });
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            dataIndex: "public",
            key: "public",
            render: (publicStatus: boolean) => {
                const statusItem = listStatusPublic.find((item) => item.value === publicStatus);
                return <Tag color={statusItem?.value ? "green" : "red"}>{statusItem?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterApplyTask 
                                        data={filter}
                                        onSearch={(value: any) => setFilter(value)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default ApplyTask;
