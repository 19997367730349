import {
    useGetApplyTrainingsQuery,
    useMajorsBySchoolQuery,
    useScholarshipBySchoolQuery,
    useScholarshipTypeQuery,
    useSpecializedByMajorQuery,
    useTrainingsQuery,
} from "@redux/queries/user/public.api";
import { useApplySchoolMutation } from "@redux/queries/user/user.api";
import { authSelector } from "@redux/slices/auth.slice";
import { Button, Form, Modal, Select, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface ModalApplyProps {
    scholarshipTypeId?: string;
    scholarshipId?: string;
    trainingId?: string;
    open: boolean;
    schoolId: string;
    onClose: () => void;
}

const ModalApply: React.FunctionComponent<ModalApplyProps> = (props) => {
    const { open, schoolId, onClose } = props;
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);

    const [form] = Form.useForm();
    const [trainingId, setTrainingId] = useState("");
    const [majorId, setMajorId] = useState("");
    const [scholarshiptypeId, setScholarshiptypeId] = useState("");
    const [triggerApplySchool, result] = useApplySchoolMutation();

    const currentYear = moment().year();

    const { data: trainings, isLoading: isLoadingTraining } = useGetApplyTrainingsQuery(
        {
            schoolId: schoolId,
        },
        {
            skip: !schoolId,
        }
    );

    const { data: majors, isLoading: isLoadingMajor } = useMajorsBySchoolQuery(
        {
            page: 1,
            limit: 100,
            schoolId: schoolId,
            trainingId: trainingId,
        },
        {
            skip: !trainingId,
        }
    );

    const { data: specializeds, isLoading: loadingSpecialize } = useSpecializedByMajorQuery(
        {
            schoolId: schoolId,
            majorId: majorId,
            page: 1,
            limit: 100,
        },
        {
            skip: !majorId,
        }
    );
    const { data: scholarshipTypes, isLoading: isLoadingScholarshipType } = useScholarshipTypeQuery(
        {
            schoolId: schoolId,
            trainingId: trainingId,
        },
        {
            skip: !trainingId || !schoolId,
        }
    );

    const { data: scholarships, isLoading: loadingScholarship } = useScholarshipBySchoolQuery(
        {
            schoolId: schoolId,
            page: 1,
            limit: 100,
            scholarshipTypeId: scholarshiptypeId,
        },
        {
            skip: !scholarshiptypeId,
        }
    );

    const handleApply = async (values: InputCreateUserApply & { specializedId: string }) => {
        try {
            const response = await triggerApplySchool({
                schoolId: schoolId,
                majorId: values.majorId,
                scholarshipTypeId: values.scholarshipTypeId,
                scholarshipId: values.scholarshipId,
                trainingId: values.trainingId,
                year: values.year,
                period: values.period,
                specializeds: [values.specializedId],
            }).unwrap();

            if (response) {
                message.success("Đăng ký thành công!");
                onClose();
            }
        } catch (error: any) {
            console.log(error);
            if (error?.data?.errorCode === 400) {
                message.error("Bạn đã đăng ký học bổng rồi!");
            }
        }
    };

    useEffect(() => {
        if (props.trainingId) {
            form.setFieldValue("trainingId", props.trainingId);
            setTrainingId(props.trainingId);
        }
        if (props.scholarshipTypeId) {
            form.setFieldValue("scholarshipTypeId", props.scholarshipTypeId);
            setScholarshiptypeId(props.scholarshipTypeId);
        }
        if (props.scholarshipId) {
            form.setFieldValue("scholarshipId", props.scholarshipId);
            // setScholarshipId(props.scholarshipId);
        }
    }, [props]);

    useEffect(() => {
        if (!open) {
            form.resetFields();

            setTrainingId("");
            setMajorId("");
            setScholarshiptypeId("");
        }
    }, [open]);

    return (
        <Modal
            className="modal-apply"
            open={!!isLogin && open}
            footer={null}
            onCancel={onClose}
            title="Apply"
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleApply}
            >
                <div className="row">
                    <div className="col-12">
                        <Form.Item
                            label="Hệ đào tạo"
                            name="trainingId"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập đầy đủ thông tin!",
                                },
                            ]}
                        >
                            <Select
                                loading={isLoadingTraining}
                                onChange={(value) => {
                                    setTrainingId(value);
                                    setMajorId("");
                                    setScholarshiptypeId("");
                                    form.resetFields(["majorId"]);
                                    form.resetFields(["scholarshipTypeId"]);
                                    form.resetFields(["specializedId"]);
                                    form.resetFields(["scholarshipId"]);
                                }}
                            >
                                {trainings?.map((e: any) => (
                                    <Select.Option
                                        key={e?.id}
                                        value={e?.id}
                                    >
                                        {e?.language?.find((x: any) => x.lang === "vi")?.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-6">
                        <Form.Item
                            label="Ngành"
                            name="majorId"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập đầy đủ thông tin!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="label"
                                disabled={!trainingId}
                                loading={isLoadingMajor}
                                onChange={(value) => {
                                    setMajorId(value);

                                    form.resetFields(["specializedId"]);
                                }}
                            >
                                {majors?.items?.map((e) => (
                                    <Select.Option
                                        key={e.id}
                                        value={e.id}
                                        label={e.language?.find((x) => x.lang === "vi")?.title}
                                    >
                                        {e.language?.find((x) => x.lang === "vi")?.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-6">
                        <Form.Item
                            label="Chuyên ngành"
                            name="specializedId"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập đầy đủ thông tin!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="label"
                                disabled={!majorId}
                                loading={loadingSpecialize}
                            >
                                {specializeds?.items?.map((e: any) => {
                                    return (
                                        <Select.Option
                                            key={e?.specialized?.id}
                                            value={e?.specialized?.id}
                                            label={e?.specialized?.language?.find((x: any) => x.lang === "vi")?.title}
                                        >
                                            {e?.specialized?.language?.find((x: any) => x.lang === "vi")?.title}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-6">
                        <Form.Item
                            label="Loại học bổng"
                            name="scholarshipTypeId"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập đầy đủ thông tin!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="label"
                                onChange={(value) => {
                                    setScholarshiptypeId(value);

                                    form.resetFields(["scholarshipId"]);
                                }}
                                loading={isLoadingScholarshipType}
                            >
                                {scholarshipTypes?.map((e) => (
                                    <Select.Option
                                        key={e.id}
                                        value={e.id}
                                        label={e.language?.find((x) => x.lang === "vi")?.title}
                                    >
                                        {e.language?.find((x) => x.lang === "vi")?.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-6">
                        <Form.Item
                            label="Học bổng"
                            name="scholarshipId"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập đầy đủ thông tin!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="label"
                                disabled={!scholarshiptypeId}
                                loading={loadingScholarship}
                            >
                                {scholarships?.items?.map((e) => (
                                    <Select.Option
                                        key={e?.scholarship?.id}
                                        value={e?.scholarship?.id}
                                        label={e?.scholarship?.language?.find((x: any) => x.lang === "vi")?.title}
                                    >
                                        {e?.scholarship?.language?.find((x: any) => x.lang === "vi")?.title}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-6">
                        <Form.Item
                            label="Năm học"
                            name="year"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập đầy đủ thông tin!",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option
                                    key={currentYear}
                                    value={currentYear}
                                >
                                    {currentYear}
                                </Select.Option>
                                <Select.Option
                                    key={currentYear + 1}
                                    value={currentYear + 1}
                                >
                                    {currentYear + 1}
                                </Select.Option>
                                <Select.Option
                                    key={currentYear + 2}
                                    value={currentYear + 2}
                                >
                                    {currentYear + 2}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-6">
                        <Form.Item
                            label="Kỳ học"
                            name="period"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập đầy đủ thông tin!",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option
                                    key={1}
                                    value={1}
                                >
                                    Kỳ 1
                                </Select.Option>
                                <Select.Option
                                    key={2}
                                    value={2}
                                >
                                    Kỳ 2
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <Button
                    htmlType="submit"
                    block
                    className="btn-blue"
                >
                    Submit
                </Button>
            </Form>
        </Modal>
    );
};

export default ModalApply;
