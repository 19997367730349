import { SearchOutlined } from "@ant-design/icons";
import { useToast } from "@components/toast/ToastProvider";
import { useGetListMajorQuery } from "@redux/queries/admin/admin.majors";
import { useGetListSchoolQuery, useListSchoolMutation } from "@redux/queries/admin/admin.school";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";
import { useGetListUserGrantQuery, useGetListUserQuery } from "@redux/queries/admin/admin.user";
import { useLazyScholarshipBySchoolQuery } from "@redux/queries/user/public.api";
import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import SearchUser from "./ModalSearchUser";
import { useLazyGetListSchoolServiceQuery } from "@redux/queries/admin/admin.school.service";
import { useGetAllContractUsersQuery, useGetListContractUsersQuery } from "@redux/queries/user/contractUser.api";

interface IProps {
    form: any;
    dataEdit?: any;
    setUserId: (id: any) => void;
    setSchoolServiceTrainingId: (id: any) => void;
}

const GeneralTab: React.FunctionComponent<IProps> = (props: IProps) => {
    const [getSchoolScholarship, { data }] = useLazyScholarshipBySchoolQuery();
    const { data: list_school, isLoading, isFetching } = useGetListSchoolQuery();
    const { data: list_user, isLoading: isLoadingUser, isFetching: isFetchingUser } = useGetListUserQuery();
    const { data: list_user_grant } = useGetListUserGrantQuery();
    const { data: list_major, isLoading: isLoadingMajor, error: errorMajor } = useGetListMajorQuery({});
    const { showToast } = useToast();

    const [openSearchUser, setOpenSearchUser] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [selectedSchool, setSelectedSchool] = useState<any>(null);
    const [selectedSchoolScholarShip, setSelectedSchoolScholarShip] = useState<any>(null);
    const [listTrain, setListTrain] = useState<any>([]);
    const [selectedTraining, setSelectedTraining] = useState<any>(null);

    const [getSchoolService, { data: list_school_service }] = useLazyGetListSchoolServiceQuery({});

    useEffect(() => {
        props.form.setFieldsValue({
            trainingId: undefined,
        });
        if (selectedSchoolScholarShip) {
            const schoolScholarship = data?.items?.find((o: any) => o.scholarshipId === selectedSchoolScholarShip);
            const listTrain = schoolScholarship?.trainings;
            setListTrain(listTrain);
        }
    }, [selectedSchoolScholarShip]);

    useEffect(() => {
        props.form.setFieldsValue({ schoolServiceId: undefined });
        if (selectedTraining && selectedSchoolScholarShip) {
            getSchoolService({
                schoolId: selectedSchool,
                scholarshipId: selectedSchoolScholarShip,
                trainingId: selectedTraining,
            });
        }
    }, [selectedTraining]);

    useEffect(() => {
        if (selectedUser) {
            props.form.setFieldsValue({ userId: selectedUser });
        }
    }, [selectedUser]);

    useEffect(() => {
        props.form.setFieldsValue({
            scholarshipId: undefined,
            trainingId: undefined,
        });
        if (selectedSchool) {
            getSchoolScholarship({
                schoolId: selectedSchool,
                page: 1,
                limit: 1000,
            });
        }
    }, [selectedSchool]);

    useEffect(() => {
        if (props.dataEdit) {
            const schoolId = props.dataEdit?.school?.id;
            const scholarshipId = props.dataEdit?.schoolScholarshipTraining?.schoolScholarship?.scholarshipId;
            const trainingId = props.dataEdit?.schoolScholarshipTraining?.training?.id;
            if (schoolId) {
                getSchoolScholarship({
                    schoolId,
                    page: 1,
                    limit: 1000,
                });
            }
            if (scholarshipId) {
                const schoolScholarship = data?.items?.find((o: any) => o.scholarshipId === scholarshipId);
                const listTrain = schoolScholarship?.trainings;
                setListTrain(listTrain);
            }
            if (trainingId && schoolId && scholarshipId) {
                getSchoolService({
                    schoolId,
                    scholarshipId,
                    trainingId,
                });
            }
        }
    }, [props.dataEdit, data]);

    const {
        data: list_specialized,
        isLoading: isLoadingSpecialized,
        error: errorSpecialized,
    } = useGetListSpecializedQuery();

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <div className="form-group">
                            <div
                                className="form-group"
                                style={{
                                    width: "80%",
                                    display: "inline-block",
                                }}
                            >
                                <div className="form-floating">
                                    <Form.Item
                                        name="userId"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            placeholder=""
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                            onChange={(v: string) => {
                                                setSelectedUser(v);
                                                props.setUserId(v);
                                            }}
                                        >
                                            {list_user &&
                                                list_user.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {`${o.firstName || ""} ${o.lastName || ""} - ${
                                                                o.code || ""
                                                            }`}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Khách hàng</label>
                                </div>
                            </div>

                            <Button
                                type="primary"
                                style={{
                                    height: "calc(2.6rem + 5px)",
                                    width: "20%",
                                    display: "inline-block",
                                }}
                                icon={<SearchOutlined />}
                                onClick={() => setOpenSearchUser(true)}
                            >
                                Search
                            </Button>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="form-group">
                            <div
                                className="form-group"
                                style={{
                                    width: "100%",
                                    display: "inline-block",
                                }}
                            >
                                <div className="form-floating">
                                    <Form.Item
                                        name="grantIds"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            placeholder=""
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                            mode="multiple"
                                        >
                                            {list_user_grant &&
                                                list_user_grant.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {`${o.firstName || ""} ${o.lastName || ""} - ${
                                                                o.code || ""
                                                            }`}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Nhân viên phụ trách</label>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="row">
                    {/* col 1 */}
                    <div className="col-6">
                        <Row>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="schoolId"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    onChange={(v: string) => {
                                                        setSelectedSchool(v);
                                                    }}
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    showSearch
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {list_school &&
                                                        list_school.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Trường</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="year"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                    {
                                                        pattern: /^[0-9]*$/,
                                                        message: "Phải nhập số",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="no-icon form-floating"
                                                />
                                            </Form.Item>
                                            <label>Năm</label>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={"scholarshipId"}
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    onChange={(value) => setSelectedSchoolScholarShip(value)}
                                                >
                                                    {data &&
                                                        data.items.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.scholarshipId}
                                                                    value={o.scholarshipId}
                                                                >
                                                                    {o.scholarship && o.scholarship.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Học bổng</label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="period"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                    {
                                                        pattern: /^[0-9]*$/,
                                                        message: "Phải nhập số",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="no-icon form-floating"
                                                />
                                            </Form.Item>
                                            <label>Giai đoạn</label>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* col 2 */}
                    <div className="col-6">
                        <Row>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="trainingId"
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    onChange={(v: string) => setSelectedTraining(v)}
                                                >
                                                    {listTrain &&
                                                        listTrain.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Hệ đào tạo</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="storageUri"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "url",
                                                        message: "Sai định dạng url",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="no-icon form-floating"
                                                />
                                            </Form.Item>
                                            <label>Uri Lưu trữ</label>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={"majorId"}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    allowClear
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {list_major &&
                                                        list_major.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Ngành học</label>
                                        </div>
                                    </div>
                                    <div className="form-floating">
                                        <Form.Item
                                            name="specializeds"
                                            className="form-floating"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin bắt buộc",
                                                },
                                            ]}
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                className="form-control form-floating"
                                                bordered={false}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {list_specialized &&
                                                    list_specialized.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Chuyên ngành</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <div
                        className="form-group"
                        style={{ width: "50%" }}
                    >
                        <div className="form-floating">
                            <Form.Item
                                name="schoolServiceTrainingId"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    placeholder=""
                                    showSearch
                                    onChange={(v: string) => {
                                        props.setSchoolServiceTrainingId(v);
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {list_school_service &&
                                        list_school_service?.map((o: any, i: any) => {
                                            const schoolName = o.school?.language[0]?.name || "";
                                            const scholarshipName = o?.scholarship?.language[0]?.title || "";
                                            const trainingId = props.form.getFieldValue("trainingId");
                                            const schoolServiceTraining = o.schoolServiceTrainings.find(
                                                (item: any) =>
                                                    item.trainingId === selectedTraining ||
                                                    item.trainingId === trainingId
                                            );
                                            const trainingName =
                                                schoolServiceTraining?.training?.language[0]?.title || "";
                                            const name = `${schoolName} - ${scholarshipName} - ${trainingName}`;
                                            return (
                                                <Select.Option
                                                    key={schoolServiceTraining?.id || o.id}
                                                    value={schoolServiceTraining?.id || o.id}
                                                >
                                                    {name}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <label>Dịch vụ (Trường - Học bổng - Hệ đào tạo)</label>
                        </div>
                    </div>
                </Row>
            </div>
            <SearchUser
                openSearchUser={openSearchUser}
                setOpenSearchUser={(v: boolean) => setOpenSearchUser(v)}
                selectedUser={selectedUser}
                setSelectedUser={(v: any) => setSelectedUser(v)}
            />
        </>
    );
};

const MemoizedGeneralTab = React.memo(GeneralTab);

export default MemoizedGeneralTab;
