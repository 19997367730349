import Nodata from "@components/Nodata";
import { Pagination } from "@components/pagination";
import { usePaginateReviewBySchoolIdQuery } from "@redux/queries/user/public.api";
import React, { useState } from "react";
import ReviewItem from "./ReviewItem";

import defaultAvatar from "@assets/images/default.png";
import { useCreateReviewMutation } from "@redux/queries/user/user.api";
import { useFormik } from "formik";
import ReactStars from "react-stars";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { authSelector } from "@redux/slices/auth.slice";
import { useSelector } from "react-redux";
import { linkCDN } from "@utils/common";

const LIMIT = 12;
const TabReview: React.FunctionComponent<{ id?: string }> = ({ id }) => {
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);
    const [page, setPage] = useState(1);

    const [createReview, result] = useCreateReviewMutation();

    const { data, refetch } = usePaginateReviewBySchoolIdQuery(
        id
            ? {
                  id: id as any,
                  page,
                  limit: LIMIT,
              }
            : skipToken
    );

    const formik = useFormik({
        initialValues: {
            content: "",
            rating: 5,
        },
        onSubmit: async (values) => {
            if (id) {
                await createReview({ ...values, schoolId: id });
                refetch();

                formik.resetForm();
            }
        },
    });

    const ratingChanged = (rating: number) => {
        formik.setFieldValue("rating", rating);
    };

    return (
        <>
            <div className="box-component">
                <div className="body-component">
                    <div className="title-element">
                        <h5>Review</h5>
                        <hr />
                    </div>

                    {/* BEGIN: Form Input Review */}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="header-avatar-post mb-4">
                            <img
                                className="img-avatar object-fit-cover"
                                src={linkCDN(user?.avatar, defaultAvatar)}
                                alt="avatar"
                            />
                            <div className="d-flex align-items-center gap-2 mb-1 fw-bold">
                                Đánh giá:{" "}
                                <ReactStars
                                    count={5}
                                    size={16}
                                    value={formik.values.rating}
                                    onChange={ratingChanged}
                                    color2={"#ffd700"}
                                    half={false}
                                />
                            </div>
                            <textarea
                                name="content"
                                className="form-control"
                                rows={3}
                                value={formik.values.content}
                                onChange={formik.handleChange}
                                placeholder="Nhập bình luận tại đây"
                            />
                            <button
                                disabled={result?.isLoading || !id || !isLogin}
                                className="btn-blue"
                                type="submit"
                            >
                                <i className="bi-send"></i> Gửi đi
                            </button>
                            <div className="clearfix"></div>
                        </div>
                    </form>
                    {/* END: Form Input Review */}

                    {data?.meta?.totalItems && data?.meta?.totalItems > 0 ? (
                        <>
                            <hr />
                            {data?.items?.map((e) => (
                                <ReviewItem
                                    username={e?.user?.firstName + " " + e?.user?.lastName}
                                    avatar={e?.user?.avatar}
                                    time={e.createdAt}
                                    tag={e.tags || []}
                                    content={e.content || ""}
                                    rate={e.rating || 1}
                                    key={e.id}
                                />
                            ))}
                        </>
                    ) : (
                        <Nodata />
                    )}

                    {(data?.meta?.totalItems || 0) > 0 && (
                        <div className="d-flex justify-content-center mt-4">
                            <Pagination
                                defaultSize={LIMIT}
                                onSizeChange={(size) => {}}
                                total={data?.meta.totalItems || 0}
                                showSize={false}
                                totalPage={data?.meta.totalPages || 0}
                                onChangePage={setPage}
                                defaultCurrentPage={1}
                                listSize={[12, 24, 50, 100]}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default TabReview;
