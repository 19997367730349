import { Pagination } from "@components/pagination";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import {
    useGetListSchoolScholarshipTrainingPaginateQuery
} from "@redux/queries/admin/admin.school.scholarship.training";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { Button, Col, Form, Modal, Row, Select, Space, Table } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";

interface SearchSchoolScholarshipTrainingProps {
    openSearchSchoolScholarshipTraining: boolean;
    setOpenSearchSchoolScholarshipTraining: (visible: boolean) => void;
    selectedSchoolScholarshipTraining?: any;
    setSelectedSchoolScholarshipTraining?: (data: any) => void;
}

const SearchSchoolScholarshipTraining = ({
    openSearchSchoolScholarshipTraining,
    setOpenSearchSchoolScholarshipTraining,
    selectedSchoolScholarshipTraining,
    setSelectedSchoolScholarshipTraining,
}: SearchSchoolScholarshipTrainingProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedSchoolScholarshipTraining) {
           setSelectedRowKeys([selectedSchoolScholarshipTraining]);
        }
    }
    , [selectedSchoolScholarshipTraining]);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});

    const { data, isLoading, isFetching } = useGetListSchoolScholarshipTrainingPaginateQuery(query as any);

    const onFinish = async (values: any) => {
        const newQuery = _.pickBy(
            { ...query, ...values, page: 1 },
            (item: any) => item !== "" && item !== null && item !== undefined && item !== "-1" && item
        );
        setQuery(newQuery);
    };

    const { data: list_school, isLoading: isLoadingSchool, isFetching: isFetchingSchool } = useGetListSchoolQuery();
    const { data: list_train, isLoading: isLoadingTrain, error: error } = useGetListTrainingQuery();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [query]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        type: "radio" as any,
    };

    const columns = [
        {
            title: <div className="text-title-table">Trường</div>,
            key: "school",
            dataIndex: "school",
            render: (text: string, record: any) => {
                return record?.school?.language[0]?.name;
            },
        },
        {
            title: <div className="text-title-table">Học bổng</div>,
            key: "schoolScholarship",
            dataIndex: "schoolScholarship",
            render: (text: string, record: any) => {
                return record?.schoolScholarship?.scholarship?.language[0]?.title;
            },
        },
        {
            title: <div className="text-title-table">Hệ đào tạo</div>,
            key: "training",
            dataIndex: "training",
            render: (text: string, record: any) => {
                return record?.training?.language[0]?.title;
            },
        },
        {
            title: <div className="text-title-table">Tổng số</div>,
            key: "total",
            dataIndex: "total",
        },
        {
            title: <div className="text-title-table">Điểm đỗ</div>,
            key: "passScore",
            dataIndex: "passScore",
        },
    ];

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openSearchSchoolScholarshipTraining}
                onCancel={() => {
                    form.resetFields();
                    setOpenSearchSchoolScholarshipTraining(false);
                }}
                title={"Search School Scholarship Training"}
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        disabled={selectedRowKeys.length === 0}
                        onClick={() => {
                            setSelectedSchoolScholarshipTraining && setSelectedSchoolScholarshipTraining(selectedRowKeys[0]);
                            form.resetFields();
                            setOpenSearchSchoolScholarshipTraining(false);
                        }}
                    >
                        Xác nhận
                    </Button>,
                ]}
                width={1000}
            >
                <Row>
                    <Col span={8}>
                        <div className="body-component">
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="schoolId"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin bắt buộc",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {list_school &&
                                                list_school.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].name}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Trường</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="trainingId"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                        >
                                            {list_train &&
                                                list_train.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Hệ đào tạo</label>
                                </div>
                            </div>
                            <Row justify="end">
                                <Space>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            onClick={() => {
                                                form.submit();
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            onClick={() => {
                                                form.resetFields();
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Row>
                        </div>
                    </Col>
                    <Col span={16}>
                        <Table
                            rowSelection={rowSelection}
                            rowKey="id"
                            columns={columns}
                            dataSource={(data?.items as any) || []}
                            pagination={false}
                            loading={isLoading || isFetching}
                        />
                        <Pagination
                            onSizeChange={(size) => setQuery({ ...query, limit: size })}
                            total={data?.meta.totalItems || 0}
                            showSize={true}
                            totalPage={data?.meta.totalPages || 0}
                            onChangePage={(page) => setQuery({ ...query, page: page })}
                            defaultCurrentPage={query.page}
                        />
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default SearchSchoolScholarshipTraining;
