import { useGetListMajorQuery } from "@redux/queries/admin/admin.majors";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";
import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import { useGetListUserPaginateQuery } from "@redux/queries/admin/admin.user";
import { useGetAllMethodQuery } from "@redux/queries/user/deposit.api";
import  moment from "moment";


interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterDeposit: React.FunctionComponent<IProps> = (props) => {
    enum UserDepositStatus {
        PENDING = 'PENDING',
        CANCEL = 'CANCEL',
        REJECT = 'REJECT',
        DONE = 'DONE',
    }
    const [form] = Form.useForm();
    const { data: list_users, isLoading, isFetching } = useGetListUserPaginateQuery({
        page: 1,
        limit: 10,
    });

    const { data: list_method } = useGetAllMethodQuery({});

    const list_method_options = list_method?.map((o: any) => ({
        label: o.language[0].name,
        value: o.id,
    }));

    const list_status: { label: string; value: string }[] = Object.keys(UserDepositStatus).map((key) => ({
        label: key,
        value: UserDepositStatus[key as keyof typeof UserDepositStatus],
    }));


    const onFinish = (values: any) => {
        props.onSearch({
            ...values,
            startAt: values.startAt ? moment(new Date(values.startAt)).startOf('day').valueOf() : undefined,
            endAt: values.endAt ? moment(new Date(values.endAt)).endOf('day').valueOf() : undefined,
        });
    };


    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={"userId"}
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {list_users &&
                                                    list_users.items.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.username}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Người dùng</label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={"methodId"}
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                                options={list_method_options}
                                            >

                                            </Select>
                                        </Form.Item>
                                        <label>Phương thức thanh toán</label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={"status"}
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                                options={list_status}
                                            >

                                            </Select>
                                        </Form.Item>
                                        <label>Trạng thái</label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="startAt"
                                            className="form-floating"
                                        >
                                            <DatePicker
                                                className="no-icon form-floating"
                                                style={{ width: "100%", height: 45 }}
                                                format="DD/MM/YYYY"
                                            />
                                        </Form.Item>
                                        <label>Ngày bắt đầu</label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="endAt"
                                            className="form-floating"
                                        >
                                            <DatePicker
                                                className="no-icon form-floating"
                                                style={{ width: "100%", height: 45 }}
                                                format="DD/MM/YYYY"
                                            />
                                        </Form.Item>
                                        <label>Ngày kết thúc</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterDeposit;
