export enum UserRole {
    ADMIN = 1,
    CUSTOMER = 2,
    MEMBER = 3,
    SALE_MANAGER = 4,
    MARKETING_MANAGER = 5,
    HEAD_OF_TRANSLATION = 6,
    HEAD_OF_ACADEMIC = 7,
    HEAD_OF_DOCUMENT = 8,
    CHIEF_ACCOUNTANT = 9,
    BUSINESS_STAFF = 10,
    MARKETING_STAFF = 11,
    TRANSLATOR = 12,
    ACADEMIC_STAFF = 13,
    DOCUMENT_STAFF = 14,
    ACCOUNTANT = 15,
    INTERPRETER = 16,
}

export const UserRoleAccessGrant = [UserRole.ADMIN, UserRole.SALE_MANAGER, UserRole.MARKETING_MANAGER];
export const UserRoleGrant = [
    UserRole.SALE_MANAGER,
    UserRole.MARKETING_MANAGER,
    UserRole.HEAD_OF_TRANSLATION,
    UserRole.HEAD_OF_ACADEMIC,
    UserRole.HEAD_OF_DOCUMENT,
    UserRole.CHIEF_ACCOUNTANT,
    UserRole.BUSINESS_STAFF,
    UserRole.MARKETING_STAFF,
    UserRole.TRANSLATOR,
    UserRole.ACADEMIC_STAFF,
    UserRole.DOCUMENT_STAFF,
    UserRole.ACCOUNTANT,
    UserRole.INTERPRETER,
];

export const UserRoleAccessGrantList = [
    {
        value: UserRole.ADMIN,
        label: "Quản trị viên",
    },
    {
        value: UserRole.SALE_MANAGER,
        label: "Trưởng phòng kinh doanh",
    },
    {
        value: UserRole.MARKETING_MANAGER,
        label: "Trưởng phòng marketing",
    },
];

export const UserRoleGrantList = [
    {
        value: UserRole.SALE_MANAGER,
        label: "Trưởng phòng kinh doanh",
    },
    {
        value: UserRole.MARKETING_MANAGER,
        label: "Trưởng phòng marketing",
    },
    {
        value: UserRole.HEAD_OF_TRANSLATION,
        label: "Trưởng phòng dịch thuật",
    },
    {
        value: UserRole.HEAD_OF_ACADEMIC,
        label: "Trưởng phòng học thuật",
    },
    {
        value: UserRole.HEAD_OF_DOCUMENT,
        label: "Trưởng phòng văn phòng",
    },
    {
        value: UserRole.CHIEF_ACCOUNTANT,
        label: "Kế toán trưởng",
    },
    {
        value: UserRole.BUSINESS_STAFF,
        label: "Nhân viên kinh doanh",
    },
    {
        value: UserRole.MARKETING_STAFF,
        label: "Nhân viên marketing",
    },
    {
        value: UserRole.TRANSLATOR,
        label: "Nhân viên dịch thuật",
    },
    {
        value: UserRole.ACADEMIC_STAFF,
        label: "Nhân viên học thuật",
    },
    {
        value: UserRole.DOCUMENT_STAFF,
        label: "Nhân viên văn phòng",
    },
    {
        value: UserRole.ACCOUNTANT,
        label: "Nhân viên kế toán",
    },
    {
        value: UserRole.INTERPRETER,
        label: "Nhân viên phiên dịch",
    },
];
