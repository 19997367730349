import React, { useEffect, useState, useImperativeHandle } from "react";
import _, { debounce } from "lodash";
import { FieldInputProps } from "formik";
import searchIcon from "@assets/images/icons/search.svg";
import { useSearchParams } from "react-router-dom";
export interface FormRefListBox {
    resetFields: () => void;
    setSelectedList?: (values: Array<string>) => void;
}

interface ListCheckboxFilterProps {
    title: string;
    listData: Array<{
        id: string;
        name: string;
    }>;
    onChange: (value: Array<string>) => void;
    field: FieldInputProps<string>;
    form: any;
}

const ListCheckboxFilter: React.ForwardRefRenderFunction<FormRefListBox, ListCheckboxFilterProps> = (props, ref) => {
    const id = props.field.name;
    const { title, listData, onChange } = props;
    const [selectedList, setSelectedList] = useState<Array<string>>([]);
    const [searchstr, setSearchStr] = useState<string>("");
    const [data, setData] = useState<
        Array<{
            id: string;
            name: string;
        }>
    >(listData);

    const [searchParams] = useSearchParams();
    const queryArea = searchParams.get("area") || "";
    const majorArea = searchParams.get("major") || "";

    useEffect(() => {
        if (id === "area" && queryArea) {
            setSelectedList((selectedList) => [...selectedList, queryArea]);
            props.form.setFieldValue(props.field.name, [...selectedList, queryArea]);
            onChange([...selectedList, queryArea]);
        }

        if (id === "major" && majorArea) {
            setSelectedList((selectedList) => [...selectedList, majorArea]);
            props.form.setFieldValue(props.field.name, [...selectedList, majorArea]);
            onChange([...selectedList, majorArea]);
        }
    }, [queryArea, majorArea]);

    useImperativeHandle(ref, () => ({
        resetFields: () => {
            setSelectedList([]);
            // props.form.setFieldValue(props.field.name, []);
            // onChange([]);
        },
        setSelectedList: (values) => {
            setSelectedList(values);
        },
    }));

    const handleChangeSearchStr = debounce((e: any) => {
        setSearchStr(e.target.value);
    }, 200);

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>, ele: { id: string; name: string }) => {
        const isSelected = e.target.checked;
        const cloneSelectedList = _.cloneDeep(selectedList);
        let newSelectedList: any = [];

        if (isSelected) {
            newSelectedList = cloneSelectedList.concat(ele.id).filter((e) => !!listData.find((ele) => ele.id === e));
            _.uniq(newSelectedList);
        } else {
            newSelectedList = cloneSelectedList.filter((e) => !!listData.find((ele) => ele.id === e));
            _.remove(newSelectedList, (e) => e === ele.id);
        }

        setSelectedList(newSelectedList);
        props.form.setFieldValue(props.field.name, newSelectedList);
        onChange(newSelectedList);
    };

    useEffect(() => {
        let newData;
        if (searchstr) {
            newData = listData?.filter((e) => e.name.toLowerCase().includes(searchstr.toLowerCase()));
        } else {
            newData = _.cloneDeep(listData);
        }
        setData(newData);
    }, [searchstr, listData]);

    return (
        <div className="group-item-filter">
            <button
                className="btn btn-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${id}`}
                aria-expanded="false"
                aria-controls="collapseList"
            >
                {title} <i className="bi-chevron-down" />
            </button>
            <div
                className="collapse show"
                id={`${id}`}
            >
                <div className="item-collapse">
                    <div className="form-search-text">
                        <input
                            type="text"
                            placeholder="Search here..."
                            onChange={handleChangeSearchStr}
                        />
                        <img
                            src={searchIcon}
                            alt="search"
                        />
                    </div>
                    <div className="group-box-item">
                        {data.map((ele, i) => (
                            <div
                                key={ele.id}
                                className="form-check"
                            >
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={selectedList.includes(ele.id)}
                                    id={`flex-${ele.id}`}
                                    onChange={(e) => handleSelect(e, ele)}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`flex-${ele.id}`}
                                >
                                    {ele.name}
                                    {/* <small>78 trường</small> */}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.forwardRef(ListCheckboxFilter);
