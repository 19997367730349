import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useDeleteMultiNotificationMutation,
    useDeleteNotificationMutation,
    useGetListNotificationPaginateQuery,
    useSendNotificationMutation,
} from "@redux/queries/admin/admin.notification";
import { Button, Divider, Modal, Space, Table, Tabs, Tag, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import FilterNotification from "./components/Filter";
import HandleForm from "./components/HandleForm";
import ReactQuill from "react-quill";
import { listTypeNotification } from "./notification.const";
import { SendOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const Notification: React.FunctionComponent = () => {
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const [activeKey, setActiveKey] = useState("titleVi");
    const [detail, setDetail] = useState<any>();
    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const [filter, setFilter] = useState<any>({});
    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching, refetch } = useGetListNotificationPaginateQuery(query as any);

    const [deleteNotification, isLoadingDelete] = useDeleteNotificationMutation();
    const [deleteMultiNotification, isLoadingMultiDelete] = useDeleteMultiNotificationMutation();
    const [sendNotification, isLoadingSend] = useSendNotificationMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteNotification({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleSend = async (id: string) => {
        const result = await sendNotification({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
        refetch();
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiNotification({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (record: any) => {
        setDetail(record);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Type</div>,
            key: "type",
            dataIndex: "type",
            render: (type: string) => {
                const typeNotification = listTypeNotification.find((item) => item.value === type);
                return <Tag color={typeNotification?.color}>{typeNotification?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Mô tả</div>,
            key: "description",
            dataIndex: "description",
        },
        {
            title: <div className="text-title-table">Gửi toàn bộ khách hàng</div>,
            key: "allUser",
            dataIndex: "allUser",
            render: (allUser: boolean) => {
                return allUser ? "Gửi toàn bộ" : "Không";
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (status: string, record: any) => {
                return status === "PENDING" ? (
                    <Space>
                        <Tag color="red">PENDING</Tag>
                        <Button
                            icon={<SendOutlined />}
                            onClick={() => handleSend(record.id)}
                        >
                            Gửi ngay
                        </Button>
                    </Space>
                ) : (
                    <Tag color="green">{status}</Tag>
                );
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-gray"
                            onClick={() => handleViewDetail(record)}
                        >
                            <i className="bi-eye"></i>
                        </button>
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterNotification
                                        data={filter}
                                        onSearch={(value: any) => setFilter(value)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
            <Modal
                title="Xem trước thông báo"
                open={!!detail}
                onCancel={() => {
                    setDetail(null);
                    setActiveKey("titleVi");
                }}
                footer={null}
                width={800}
            >
                <Tabs
                    onChange={onChange}
                    activeKey={activeKey}
                    items={[
                        {
                            label: "Tiếng Việt",
                            children: (
                                <div className="news-detail">
                                    <Title level={5}>{detail?.language[0]?.title}</Title>
                                    <Divider />
                                    <ReactQuill
                                        className="text-editor"
                                        value={detail?.language[0]?.content}
                                        readOnly={true}
                                    />
                                </div>
                            ),
                            key: "titleVi",
                            closable: false,
                        },
                        {
                            label: "English ",
                            children: (
                                <div className="news-detail">
                                    <Title level={5}>{detail?.language[1]?.title}</Title>
                                    <Divider />
                                    <ReactQuill
                                        className="text-editor"
                                        value={detail?.language[1]?.content}
                                        readOnly={true}
                                    />
                                </div>
                            ),
                            key: "titleEn",
                            closable: false,
                        },
                        {
                            label: "中国人",
                            children: (
                                <div className="news-detail">
                                    <Title level={5}>{detail?.language[2]?.title}</Title>
                                    <Divider />
                                    <ReactQuill
                                        className="text-editor"
                                        value={detail?.language[2]?.content}
                                        readOnly={true}
                                    />
                                </div>
                            ),
                            key: "titleCn",
                            closable: false,
                        },
                    ]}
                />
            </Modal>
        </div>
    );
};

export default Notification;
