export enum SchoolAttributeKeys {
    Student = "student",
    InternationalStudent = "international_student",
    YearOfSchoolEstablishment = "year_of_school_establishment",
    SchoolLevel = "school_level",
    SchoolType = "school_type",
    NumberOfMajors = "number_of_majors",
    NumberOfLecturers = "number_of_lecturers",
    Acreage = "acreage",
    CityLevel = "cityLevel",
    IsCentralCity = "is_central_city",
    Area = "area",
    EconomicZone = "economic_zone",
    ProvinceLevel = "provinceLevel",
    NumberOfScholarshipTypes = "numberOfScholarshipTypes",
    TuitionFeeUniversity = "tuition_fee_university",
    TuitionFeeMaster = "tuition_fee_master",
    DormitoryFee = "dormitory_fee",
    LivingExpenseFee = "living_expense_fee",
    MajorFocus = "major_focus",
    DiningHallNumber = "dining_hall_number",
    NationalRanking = "national_ranking",
    NumberOfSpecialized = "number_of_specialized",
    TopSpecialized = "top_specialized",
    GlobalRank = "globalRank",
    Facility = "facility",
    ExerciseYard = "exerciseYard",
    Laboratory = "laboratory",
    Library = "library",
    GovernmentScholarship = "governmentScholarship",
    ConfuciusScholarship = "confuciusScholarship",
    ProvincialScholarship = "provincialScholarship",
    CityScholarship = "cityScholarship",
    SchoolScholarship = "schoolScholarship",
    TuitionForAYear = "tuitionForAYear",
    TuitionFeeForMaster = "tuitionFeeForMaster",
    TuitionFeeForPhD = "tuitionFeeForPhD",
    TypeOfSchool = "typeOfSchool",
    CollegeStudent = "collegeStudent",
    Postgraduate = "postgraduate",
    MasterMajor = "masterMajor",
    PhDMajor = "phDMajor",
}

export const SchoolAttributeLabels = {
    student: "Số lượng sinh viên",
    international_student: "Số lượng sinh viên quốc tế",
    year_of_school_establishment: "Năm thành lập",
    school_level: "Cấp học",
    school_type: "Loại hình",
    number_of_majors: "Số lượng ngành học",
    number_of_lecturers: "Số lượng giảng viên",
    acreage: "Diện tích (m2)",
    cityLevel: "Cấp thành phố",
    is_central_city: "Thành phố trực thuộc TW",
    area: "Khu vực",
    economic_zone: "Đặc khu kinh tế",
    provinceLevel: "Cấp tỉnh",
    numberOfScholarshipTypes: "Số loại học bổng",
    tuition_fee_university: "Học phí hệ Đại học",
    tuition_fee_master: "Học phí hệ Thạc sĩ",
    dormitory_fee: "Phí kí túc xá",
    living_expense_fee: "Sinh hoạt phí",
    major_focus: "Ngành trọng điểm",
    dining_hall_number: "Số nhà ăn",
    national_ranking: "Xếp hạng quốc gia",
    number_of_specialized: "Số lượng chuyên ngành",
    top_specialized: "Top ngành",
    globalRank: "Xếp hạng thế giới",
    facility: "Cơ sở trường",
    exerciseYard: "Sân tập thể dục",
    laboratory: "Phòng thí nghiệm",
    library: "Sách thư viện",
    governmentScholarship: "Học bổng Chính phủ",
    confuciusScholarship: "Học bổng Khổng Tử",
    provincialScholarship: "Học bổng Tỉnh",
    cityScholarship: "Học bổng Thành phố",
    schoolScholarship: "Học bổng Trường",
    tuitionForAYear: "Học phí 1 năm tiếng",
    tuitionFeeForMaster: "Học phí hệ Thạc sĩ (Tệ/năm)",
    tuitionFeeForPhD: "Học phí hệ Tiến sĩ (Tệ/năm)",
    typeOfSchool: "Loại hình trường",
    collegeStudent: "Sinh viên đại học",
    postgraduate: "Nghiên cứu sinh",
    masterMajor: "Chuyên ngành thạc sĩ",
    phDMajor: "Chuyên ngành tiến sĩ",
};
