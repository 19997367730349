import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const publicApi = createApi({
    reducerPath: "publicApi",
    tagTypes: ["Public", "School"],
    keepUnusedDataFor: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_HOST }),
    endpoints(builder) {
        return {
            schoolTypes: builder.query<Array<SchoolType>, void>({
                query: (params) => `/api/school/type`,
            }),
            areas: builder.query<Array<Area>, void>({
                query: (params) => `/api/area`,
            }),
            cities: builder.query<Array<City>, void>({
                query: (params) => `/api/city`,
            }),
            allTrainings: builder.query<Array<Training>, void>({
                query: (params) => `/api/training`,
            }),
            majors: builder.query<Array<Major>, void>({
                query: (params) => `/api/majors`,
            }),
            specializeds: builder.query<Array<Specialized>, void>({
                query: (params) => `/api/specialized`,
            }),
            projects: builder.query<Array<Project>, void>({
                query: (params) => `/api/school/project`,
            }),
            getDetailSchoolById: builder.query<SchoolDetail, { id: string }>({
                query: (params) => `/api/school/${params.id}`,
                providesTags: ["Public"],
            }),
            paginateMediaBySchoolId: builder.query<
                ResponseWithPaginateFormat<ImageSchool>,
                { id: string; page: number; limit: number }
            >({
                query: (params) => {
                    return {
                        url: `/api/school/media/paginate/${params.id}`,
                        params: {
                            limit: params.limit,
                            page: params.page,
                            type: "IMAGE",
                        },
                    };
                },
            }),
            paginateVideoBySchoolId: builder.query<
                ResponseWithPaginateFormat<ImageSchool>,
                { id: string; page: number; limit: number }
            >({
                query: (params) => {
                    return {
                        url: `/api/school/media/paginate/${params.id}`,
                        params: {
                            limit: params.limit,
                            page: params.page,
                            type: "VIDEO",
                        },
                    };
                },
            }),
            paginateSpecializedBySchoolId: builder.query<
                ResponseWithPaginateFormat<SchoolDetailSpecialized>,
                { id: string; page: number; limit: number }
            >({
                query: (params) => {
                    return {
                        url: `/api/school/specialized/paginate/${params.id}`,
                        params: {
                            limit: params.limit,
                            page: params.page,
                        },
                    };
                },
            }),
            paginateScholarshipBySchoolId: builder.query<
                ResponseWithPaginateFormat<SchoolDetailScholarship>,
                { id: string; page: number; limit: number }
            >({
                query: (params) => {
                    return {
                        url: `/api/school/scholarship/paginate/${params.id}`,
                        params: {
                            limit: params.limit,
                            page: params.page,
                        },
                    };
                },
            }),
            paginateReviewBySchoolId: builder.query<
                ResponseWithPaginateFormat<SchoolReview>,
                { id: string; page: number; limit: number }
            >({
                query: (params) => {
                    return {
                        url: `/api/school/review/paginate/${params.id}`,
                        params: {
                            limit: params.limit,
                            page: params.page,
                        },
                    };
                },
            }),
            paginateNewsBySchoolId: builder.query<
                ResponseWithPaginateFormat<SchoolNews>,
                { id: string; page: number; limit: number }
            >({
                query: (params) => {
                    return {
                        url: `/api/school/news/paginate/${params.id}`,
                        params: {
                            limit: params.limit,
                            page: params.page,
                        },
                    };
                },
            }),
            paginateFaqBySchoolId: builder.query<
                ResponseWithPaginateFormat<FaqSchool>,
                { id: string; page: number; limit: number }
            >({
                query: (params) => {
                    return {
                        url: `/api/school/faq/paginate/${params.id}`,
                        params: {
                            limit: params.limit,
                            page: params.page,
                        },
                    };
                },
            }),
            majorsBySchool: builder.query<
                ResponseWithPaginateFormat<Major>,
                {
                    page: number;
                    limit: number;
                    schoolId: string;
                    trainingId: string;
                }
            >({
                query: (params) => ({
                    url: `/api/majors/paginate`,
                    params: {
                        page: 1,
                        limit: 300,
                        schoolId: params.schoolId,
                        trainingId: params.trainingId,
                    },
                }),
            }),
            specializedByMajor: builder.query<
                ResponseWithPaginateFormat<Specialized>,
                {
                    schoolId: string;
                    majorId: string;
                    page: number;
                    limit: number;
                }
            >({
                query: (params) => ({
                    url: `/api/school/specialized/paginate/${params.schoolId}`,
                    params: params,
                }),
            }),
            scholarshipBySchool: builder.query<
                ResponseWithPaginateFormat<any>,
                {
                    schoolId: string;
                    scholarshipTypeId?: string;
                    page: number;
                    limit: number;
                }
            >({
                query: (params) => ({
                    url: `/api/school/scholarship/paginate/${params.schoolId}`,
                    params: params,
                }),
            }),
            scholarshipType: builder.query<Array<IScholarshipType>, any>({
                query: (params) => ({
                    url: `/api/scholarship/type`,
                    params,
                }),
            }),
            trainings: builder.query<
                any,
                {
                    schoolId: string;
                }
            >({
                query: (params) => ({
                    url: `/api/school/scholarship-training`,
                    params,
                }),
            }),
            getApplyTrainings: builder.query<
                any,
                {
                    schoolId: string;
                }
            >({
                query: (params) => ({
                    url: `/api/training/school/${params?.schoolId}/scholarship`,
                }),
            }),
            specializedBySchoolId: builder.query<SpecializedBySchoolId[], { id: string }>({
                query: (params) => {
                    return {
                        url: `/api/school/specialized/${params.id}`,
                    };
                },
            }),
            getPaginateScholarships: builder.query<
                ResponseWithPaginateFormat<IScholarship>,
                {
                    page: number;
                    limit: number;
                    title?: string;
                    scholarshipTypeIds: string;
                }
            >({
                query: (params) => {
                    return {
                        url: `/api/scholarship/paginate`,
                        params: {
                            page: params.page,
                            limit: params.limit,
                            title: params?.title,
                            ["scholarshipTypeIds[0]"]: params?.scholarshipTypeIds,
                        },
                    };
                },
            }),
            getPaginateSpecializeds: builder.query<
                ResponseWithPaginateFormat<ISpecialized>,
                {
                    page: number;
                    limit: number;
                    title?: string;
                    trainings: string;
                }
            >({
                query: (params) => {
                    return {
                        url: `/api/specialized/paginate`,
                        params: {
                            page: params.page,
                            limit: params.limit,
                            title: params?.title,
                            ["trainings[0]"]: params?.trainings,
                        },
                    };
                },
            }),
            GetSpecializedDetails: builder.query<
                ISpecialized,
                {
                    id: string;
                }
            >({
                query: (params) => {
                    return {
                        url: `/api/specialized/${params.id}`,
                    };
                },
            }),
            getScholarshipDetails: builder.query<
                IScholarship,
                {
                    id: string;
                }
            >({
                query: (params) => {
                    return {
                        url: `/api/scholarship/${params.id}`,
                    };
                },
            }),
            getSchoolArea: builder.query<any, any>({
                query: (params) => {
                    return {
                        url: `/api/school/area`,
                        params,
                    };
                },
            }),
            getPopularMajors: builder.query<any, any>({
                query: (params) => {
                    return {
                        url: `/api/majors/statistic/specialized`,
                        params,
                    };
                },
            }),
            getSchoolCompare: builder.query<any, any>({
                query: (params) => {
                    return {
                        url: `/api/school/compare`,
                        params,
                    };
                },
            }),

            getSchoolCompareById: builder.query<any, any>({
                query: (params) => {
                    return {
                        url: `/api/school/compare/${params?.id}`,
                    };
                },
            }),
            getServiceDetails: builder.query<any, any>({
                query: (params) => {
                    return {
                        url: `/api/school/service/${params?.id}`,
                        params,
                    };
                },
            }),
        };
    },
});

export const {
    useAreasQuery,
    useLazyAreasQuery,
    useLazyCitiesQuery,
    useLazyAllTrainingsQuery,
    useLazyMajorsQuery,
    useLazyScholarshipTypeQuery,
    useMajorsQuery,
    useMajorsBySchoolQuery,
    useScholarshipBySchoolQuery,
    useSpecializedByMajorQuery,
    useLazyProjectsQuery,
    useLazySchoolTypesQuery,
    useLazySpecializedsQuery,
    useGetDetailSchoolByIdQuery,
    usePaginateMediaBySchoolIdQuery,
    usePaginateVideoBySchoolIdQuery,
    usePaginateSpecializedBySchoolIdQuery,
    usePaginateScholarshipBySchoolIdQuery,
    usePaginateReviewBySchoolIdQuery,
    usePaginateNewsBySchoolIdQuery,
    usePaginateFaqBySchoolIdQuery,
    useScholarshipTypeQuery,
    useTrainingsQuery,
    useGetApplyTrainingsQuery,
    useLazyScholarshipBySchoolQuery,
    useSpecializedBySchoolIdQuery,
    useGetPaginateScholarshipsQuery,
    useLazyGetPaginateScholarshipsQuery,
    useLazyGetPaginateSpecializedsQuery,
    useGetSpecializedDetailsQuery,
    useGetScholarshipDetailsQuery,
    useGetPaginateSpecializedsQuery,
    useGetSchoolAreaQuery,
    useGetPopularMajorsQuery,
    useGetSchoolCompareQuery,
    useGetServiceDetailsQuery,
    useGetSchoolCompareByIdQuery,
    useLazyGetSchoolCompareByIdQuery,
} = publicApi;
