import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/school/specialized";

export const adminSchoolSpecializedApi = createApi({
    reducerPath: "adminSchoolSpecializedApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["SchoolSpecialized"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolSpecialized: builder.query<Array<ISpecialized>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "SchoolSpecialized" as const, id })),
                              { type: "SchoolSpecialized", id: "SchoolSpecialized-LIST" },
                          ]
                        : [{ type: "SchoolSpecialized", id: "SchoolSpecialized-LIST" }],
            }),
            getListSchoolSpecializedPaginate: builder.query<
                IResponseDataAdmin<ISpecialized>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "SchoolSpecialized" as const, id })),
                              { type: "SchoolSpecialized", id: "SchoolSpecialized-LIST" },
                          ]
                        : [{ type: "SchoolSpecialized", id: "SchoolSpecialized-LIST" }],
            }),
            createSchoolSpecialized: builder.mutation<any, ISpecialized>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "POST",
                    body: _.omit(params, ["id"])
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolSpecialized"],
            }),
            updateSchoolSpecialized: builder.mutation<any, ISpecialized>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"])
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolSpecialized"],
            }),
            deleteSchoolSpecialized: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolSpecialized"],
            }),
            deleteMultiSchoolSpecialized: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolSpecialized"],
            }),
        };
    },
});

export const {
    useGetListSchoolSpecializedQuery,
    useGetListSchoolSpecializedPaginateQuery,
    useLazyGetListSchoolSpecializedPaginateQuery,
    useUpdateSchoolSpecializedMutation,
    useDeleteSchoolSpecializedMutation,
    useDeleteMultiSchoolSpecializedMutation,
} = adminSchoolSpecializedApi;
