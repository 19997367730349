import { FC } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

export const DEFAULT_DECIMAL_SCALE = 2;

interface NumberFormatProps {
    nullValue?: string;
    unsetDecimal?: boolean;
    className?: string;
    formatValue?: string | number;
}

const NumberFormat: FC<NumberFormatProps & NumericFormatProps> = ({
    value,
    prefix = "",
    suffix = "",
    decimalScale = DEFAULT_DECIMAL_SCALE,
    unsetDecimal,
    nullValue = "0",
    className,
    formatValue,
    ...props
}) => {
    const displayValue = formatValue ? formatValue : Number(value);

    return (
        <div className={`${className || ""} number-format`}>
            {displayValue ? (
                <NumericFormat
                    displayType="text"
                    thousandSeparator
                    prefix={prefix}
                    suffix={suffix}
                    value={displayValue}
                    decimalScale={!unsetDecimal ? decimalScale : undefined}
                    {...props}
                />
            ) : (
                `${prefix}${nullValue}${suffix}`
            )}
        </div>
    );
};

export default NumberFormat;
