import React from "react";

interface ClearFilterProps {
    onClear: () => void;
}

const ClearFilter: React.FunctionComponent<ClearFilterProps> = (props) => {
    return (
        <div
            className="group-item-filter"
            key={"12312"}
        >
            <button
                onClick={props.onClear}
                className="btn btn-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#content-00"
                aria-expanded="false"
                aria-controls="collapseList"
            >
                Bộ lọc{" "}
                <i
                    className="bi bi-trash"
                    onClick={props.onClear}
                ></i>
            </button>
        </div>
    );
};

export default ClearFilter;
