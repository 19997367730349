import { useAuthTokenMutation } from "@redux/queries/auth.api";
import { useLazyGetUserProfileQuery } from "@redux/queries/user.api";
import { login } from "@redux/slices/auth.slice";
import { setUserProfile } from "@redux/slices/auth.slice";
import { history } from "@utils/helper-router";
import useQueryString from "@utils/useQueryString";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const SocialAuth = () => {
    const [doAuth, result] = useAuthTokenMutation();
    const { getAllParams } = useQueryString();
    const params = getAllParams();
    const code = params["code"];
    const state = params["state"];
    const dispatch = useDispatch();
    const [getUserInfo, lazyUserInfoResult] = useLazyGetUserProfileQuery();

    useEffect(() => {
        if (code && state) {
            (async () => {
                await doAuth({
                    code,
                    state,
                }).then(async (resultAuth) => {
                    if ("data" in resultAuth) {
                        dispatch(
                            login({
                                accessToken: (resultAuth.data as any).accessToken,
                                type: (resultAuth.data as any).type,
                            })
                        );
                        const resultUserInfo = await getUserInfo();
                        if ("data" in resultUserInfo) {
                            const user = resultUserInfo.data;
                            dispatch(setUserProfile(user as any));
                            history.navigate("/");
                        }
                    }
                });
            })();
        }
    }, [code, state]);

    if ("error" in result) {
        return <p className="text-danger">{(result.error as any).message}</p>;
    }

    return null;
};

export default SocialAuth;
