import { useGetCommonReportQuery } from "@redux/queries/dashboard/dashboard.report";
import { Link } from "react-router-dom";
import ItemBox from "./components/ItemBox";
import Activity from "./components/Activity";
import Transaction from "./components/Transaction";
import { Col, Row } from "antd";

const AdminDashboard = () => {
    const { data } = useGetCommonReportQuery();
    const { general } = data || {};
    const {
        totalNew,
        totalBlog,
        totalApply,
        totalMajor,
        totalMember,
        totalReview,
        totalSchool,
        totalTicket,
        totalScholarship,
        totalSpecialized,
    } = general || {};
    return (
        <section id="content-main">
            {/*  BEGIN: 4 box Component + Activity Section */}
            <div className="row">
                <div className="col-12 col-lg-12 d-flex">
                    <div className="row">
                        <ItemBox
                            total={totalSchool}
                            title="Trường học"
                        />
                        <ItemBox
                            total={totalSpecialized}
                            title="Chuyên ngành"
                        />
                        <ItemBox
                            total={totalMember}
                            title="Thành viên"
                        />
                        <ItemBox
                            total={totalBlog + totalNew}
                            title="Bài viết"
                        />
                        <ItemBox
                            total={totalApply}
                            title="Đơn Apply"
                        />
                        <ItemBox
                            total={totalReview}
                            title="Đánh giá"
                        />
                        <ItemBox
                            total={totalMajor}
                            title="Ngành học"
                        />
                        <ItemBox
                            total={totalScholarship}
                            title="Học bổng"
                        />
                        <ItemBox
                            total={totalTicket}
                            title="Ticket"
                        />
                    </div>
                </div>
            </div>
            <Row gutter={10}>
                <Col span={16}>
                    <Transaction />
                </Col>
                <Col span={8}>
                    <div className="box-component">
                        <div className="title-component">
                            <h4>Recent Activities</h4>
                            <div className="clearfix" />
                        </div>
                        <div className="body-component">
                            <Activity />
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default AdminDashboard;
