import { useWelcomeMutation } from "@redux/queries/user/intro.api";
import { Dispatch, FC, SetStateAction, memo } from "react";
import { purposeOfUse } from "../constants";
import { useGetListAreaQuery } from "@redux/queries/admin/admin.area";
import { useGetListScholarshipTypeQuery } from "@redux/queries/admin/admin.scholarship.type";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { removeEmptyValues } from "@utils/common";

interface IFifthStep {
    setActiveTab: Dispatch<SetStateAction<number>>;
    dataWelcome: InputWelcomeUser;
    setDataWelcome: Dispatch<SetStateAction<InputWelcomeUser>>;
    setDataComplete: Dispatch<SetStateAction<ResponseWelcomeUser | null>>;
}

const FifthStep: FC<IFifthStep> = ({ dataWelcome, setDataWelcome, setActiveTab, setDataComplete }) => {
    const { data: listTrainings = [] } = useGetListTrainingQuery();
    const { data: listScholarshipTypes = [] } = useGetListScholarshipTypeQuery();
    const { data: listAreas = [] } = useGetListAreaQuery();

    const { purposeOfUseId, profileInformation, aspirationApply } = dataWelcome || {};

    const {
        currentStatus,
        highestLevelOfEducation,
        trainingIndustry,
        Gpa,
        Hsk,
        Hskk,
        ielts,
        certificate,
        exCertificate,
        graduateSpecialized,
        graduateYear,
        scientificResearchAchievement,
    } = profileInformation || {};
    const { applyYear, applySemester, training, scholarshipType, area } = aspirationApply || {};

    const [welcome] = useWelcomeMutation();
    const handleBackStep = () => {
        setActiveTab(4);
    };

    const handleSubmit = async () => {
        const response: any = await welcome(removeEmptyValues(dataWelcome));

        if (response?.data) {
            setDataComplete(response?.data);
        }

        setActiveTab(6);

        console.log("response", response);
    };

    return (
        <section id="content-main">
            <div className="box-component">
                <div className="box-introduction">
                    <div className="content-li-step">
                        <ul className="li-step">
                            <li className="active">
                                <span>1</span>
                            </li>
                            <li className="active">
                                <span>2</span>
                            </li>
                            <li className="active">
                                <span>3</span>
                            </li>
                            <li className="active">
                                <span>4</span>
                            </li>
                            <li className="active">
                                <span>5</span>
                            </li>
                        </ul>
                    </div>

                    <div className="content-introduction">
                        <div className="step-title">
                            <h6>Bước 4: Xem kết quả</h6>
                            <h5>Tổng hợp thông tin mà bạn cung cấp</h5>
                            <p>
                                Chúng tôi tổng hợp lại thông tin mà bạn cung cấp. Nếu bạn muốn sửa đổi lại những thông
                                tin này, bạn có thể vào mục <b>"Hồ sơ cá nhân"</b> để sửa đổi lại nhé!
                            </p>
                        </div>

                        <div className="table-responsive table-review">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Đầu mục</th>
                                        <th>Thông tin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purposeOfUseId && purposeOfUseId.length > 0 && (
                                        <tr>
                                            <td>Bạn sử dụng hệ thống Studinychina.io</td>
                                            <td>
                                                {purposeOfUseId.map((item, index) => {
                                                    const label = purposeOfUse.find((purpose) => purpose.id === item)
                                                        ?.language[0].content;

                                                    return (
                                                        <span key={index}>
                                                            - {label}. <br />
                                                        </span>
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    )}
                                    {currentStatus && (
                                        <tr>
                                            <td>Tình trạng hiện tại</td>
                                            <td>{currentStatus}</td>
                                        </tr>
                                    )}
                                    {highestLevelOfEducation && (
                                        <tr>
                                            <td>Cấp bậc học cao nhất</td>
                                            <td>{highestLevelOfEducation}</td>
                                        </tr>
                                    )}
                                    {trainingIndustry && (
                                        <tr>
                                            <td>Hệ đào tạo</td>
                                            <td>{trainingIndustry}</td>
                                        </tr>
                                    )}

                                    {!!Gpa?.mark && (
                                        <tr>
                                            <td>Điểm GPA</td>
                                            <td>
                                                {Gpa.mark} / {Gpa?.coefficients}
                                            </td>
                                        </tr>
                                    )}

                                    {Hsk?.level && (
                                        <tr>
                                            <td>Trình độ HSK</td>
                                            <td>{Hsk.level}</td>
                                        </tr>
                                    )}
                                    {!!Hsk?.mark && (
                                        <tr>
                                            <td>Điểm thi HSK</td>
                                            <td>{Hsk.mark}</td>
                                        </tr>
                                    )}
                                    {!!Hskk?.mark && (
                                        <tr>
                                            <td>Điểm thi HSKK</td>
                                            <td>{Hskk.mark}</td>
                                        </tr>
                                    )}
                                    {graduateSpecialized && (
                                        <tr>
                                            <td>Tốt nghiệp Chuyên ngành</td>
                                            <td>{graduateSpecialized}</td>
                                        </tr>
                                    )}
                                    {!!graduateYear && (
                                        <tr>
                                            <td>Năm tốt nghiệp</td>
                                            <td>{graduateYear}</td>
                                        </tr>
                                    )}

                                    {!!certificate && (
                                        <tr>
                                            <td>Số lượng giấy khen</td>
                                            <td>{certificate}</td>
                                        </tr>
                                    )}

                                    {scientificResearchAchievement && (
                                        <tr>
                                            <td>Thành tích nghiên cứu khoa học</td>
                                            <td>{scientificResearchAchievement}</td>
                                        </tr>
                                    )}
                                    {!!ielts && (
                                        <tr>
                                            <td>Chứng chỉ IELTS</td>
                                            <td>{ielts} / 9</td>
                                        </tr>
                                    )}
                                    {!!exCertificate && (
                                        <tr>
                                            <td>Giải thưởng đạt được khác</td>
                                            <td>{exCertificate}</td>
                                            {/* <td>
                                            - Tốt nghiệp trường Học viện Kỹ thuật Quân sự (MTA), chuyên ngành Công nghệ
                                            phần mềm. <br />
                                            - Chứng chỉ lập trình tại Bách Khoa Aptech. <br />- Chứng chỉ JLPT N3.
                                        </td> */}
                                        </tr>
                                    )}

                                    {applyYear && applyYear > 0 && (
                                        <tr>
                                            <td>Năm Apply</td>
                                            <td>{applyYear}</td>
                                        </tr>
                                    )}
                                    {applySemester && applySemester > 0 && (
                                        <tr>
                                            <td>Kỳ Apply</td>
                                            <td>{applySemester}</td>
                                        </tr>
                                    )}
                                    {training && training.length > 0 && (
                                        <tr>
                                            <td>Chương trình học Apply</td>
                                            <td>
                                                {training.map((item, index) => {
                                                    const label = listTrainings.find((training) => training.id === item)
                                                        ?.language[0].title;

                                                    return (
                                                        <span key={index}>
                                                            - {label}. <br />
                                                        </span>
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    )}
                                    {scholarshipType && scholarshipType.length > 0 && (
                                        <tr>
                                            <td>Học bổng Apply</td>
                                            <td>
                                                {scholarshipType.map((item, index) => {
                                                    const label = listScholarshipTypes.find(
                                                        (scholarshipType) => scholarshipType.id === item
                                                    )?.language[0].title;

                                                    return (
                                                        <span key={index}>
                                                            - {label}. <br />
                                                        </span>
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    )}
                                    {area && area.length > 0 && (
                                        <tr>
                                            <td>Khu vực</td>
                                            <td>
                                                {area.map((item, index) => {
                                                    const label = listAreas.find((area) => area.id === item)
                                                        ?.language[0].title;

                                                    return (
                                                        <span key={index}>
                                                            - {label}. <br />
                                                        </span>
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <p className="font-14">
                            Đây là các thông tin mà bạn đã cung cấp cho chúng tôi. Bạn có thể vào mục{" "}
                            <b>"Hồ sơ cá nhân"</b> để có thể chỉnh sửa lại thông tin nếu muốn.
                        </p>
                    </div>

                    <hr className="hr-border" />
                    <div className="group-button-footer">
                        <button
                            type="button"
                            className="continue btn btn-gold"
                            onClick={handleBackStep}
                        >
                            <i className="bi-arrow-left"></i> Quay lại
                        </button>
                        <button
                            type="button"
                            className="continue btn btn-blue"
                            onClick={handleSubmit}
                        >
                            Đồng ý <i className="bi-check-circle"></i>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default memo(FifthStep);
