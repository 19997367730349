import Img01 from "@assets/images/img-01.jpg";
import { linkCDN } from "@utils/common";
import { format } from "date-fns";
import { FC, memo } from "react";

interface ICompleteListBlogs {
    news: ResponseWithPaginateFormat<SchoolNews> | undefined;
}

const CompleteListBlogs: FC<ICompleteListBlogs> = ({ news }) => {
    return (
        <div className="four-blog">
            <div className="title-element">
                <h5>Bài viết đề xuất</h5>
                <hr />
            </div>
            <div className="row">
                {news?.items &&
                    news?.items?.length > 0 &&
                    news?.items?.map((news, index) => {
                        const { firstName, lastName, userName } = news?.creator || {};
                        const displayUserName = firstName || lastName ? `${firstName} ${lastName}` : userName;

                        return (
                            <div
                                className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex"
                                key={index}
                            >
                                <article className="item-article">
                                    <div className="box-img">
                                        <a href="#">
                                            <img
                                                className="img-fluid"
                                                src={linkCDN(news?.thumbnail, Img01)}
                                                alt=""
                                            />
                                        </a>
                                    </div>                                    
                                    <div className="pd-content">
                                        <time>
                                            <small>
                                                {news?.createdAt && format(new Date(news?.createdAt), "MMMM dd, yyyy")}
                                            </small>
                                        </time>
                                        <h5>
                                            <a href="#">{news?.language[0].title}</a>
                                        </h5>
                                        <p>{news?.language[0].shortContent}</p>
                                        <ul>
                                            <li>
                                                <i className="bi-person"></i> {displayUserName}
                                            </li>
                                            <li>
                                                <i className="bi-hand-thumbs-up"></i> {news?.like}
                                            </li>
                                            <li>
                                                <i className="bi-chat-dots"></i> {news?.status} Comments
                                            </li>
                                        </ul>
                                    </div>
                                </article>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default memo(CompleteListBlogs);
