import blankImage from "@assets/images/blank.png";
import store from "@redux/store";
import { message } from "antd";
import Upload, { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { useEffect, useState } from "react";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_SUCCESS, TOAST_UPLOAD_ERROR, TOAST_UPLOAD_SUCCESS } from "@consts";

interface ImageTabProps {
    logo?: any;
    background?: any;
    setLogo?: (logo: any) => void;
    setBackground?: (background: any) => void;
    editLogo?: any;
    editBackground?: any;
}

const ImageTab = (props: ImageTabProps) => {
    const { logo, background, setLogo, setBackground, editBackground, editLogo } = props;
    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileListBackground, setFileListBackground] = useState<UploadFile[]>([]);

    const { showToast } = useToast();

    useEffect(() => {
        const logoHtml = document.getElementById("logo") as HTMLImageElement;
        logoHtml.src = "" + process.env.REACT_APP_CDN + editLogo;
    }, [editLogo]);

    useEffect(() => {
        const backgroundHtml = document.getElementById("background") as HTMLImageElement;
        backgroundHtml.src = "" + process.env.REACT_APP_CDN + editBackground;
    }, [editBackground]);

    const uploadProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPngOrPdf) {
                message.error("Không đúng định dạng file yêu cầu");
            }
            const isLt1M = file.size / 1024 / 1024 < 1;
            if (!isLt1M) {
                message.error("File tải lên phải nhỏ hơn 1MB");
            }
            if (isJpgOrPngOrPdf && isLt1M) {
                const logo = document.getElementById("logo") as HTMLImageElement;
                logo.src = URL.createObjectURL(file);
            }
            return isJpgOrPngOrPdf && isLt1M;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileList([info.file]);
                setLogo && setLogo(info.file.response && info.file.response[0].url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileList([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    const uploadBackgroundProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList: fileListBackground,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPngOrPdf) {
                message.error("Không đúng định dạng file yêu cầu");
            }
            const isLt1M = file.size / 1024 / 1024 < 1;
            if (!isLt1M) {
                message.error("File tải lên phải nhỏ hơn 1MB");
            }

            if (isJpgOrPngOrPdf && isLt1M) {
                const logo = document.getElementById("background") as HTMLImageElement;
                logo.src = URL.createObjectURL(file);
            }
            return isJpgOrPngOrPdf && isLt1M;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileListBackground(info.fileList);
            if (info.file.response && info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileListBackground([info.file]);
                setBackground && setBackground(info.file.response && info.file.response[0].url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileListBackground([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    return (
        <>
            <div
                className="tab-pane fade show"
                id="image-tab-pane"
                role="tabpanel"
                aria-labelledby="image-tab"
                tabIndex={0}
            >
                <div className="row">
                    {/* col 1 */}
                    <div className="col-12 col-sm-6 col-xl-4">
                        <div className="body-component">
                            <div className="form-group">
                                <div className="frame-upload">
                                    <img
                                        className="img-fluid"
                                        src={blankImage}
                                        alt=""
                                        id="logo"
                                    />
                                </div>
                                <Upload.Dragger {...uploadProps}>
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">
                                            <i className="bi-upload"></i>
                                            Tải ảnh logo
                                        </button>
                                    </div>
                                </Upload.Dragger>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-4">
                        <div className="body-component">
                            <div className="form-group">
                                <div className="frame-upload">
                                    <img
                                        className="img-fluid"
                                        src={blankImage}
                                        alt=""
                                        id="background"
                                    />
                                </div>
                                <Upload.Dragger {...uploadBackgroundProps}>
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">
                                            <i className="bi-upload"></i>
                                            Tải ảnh background
                                        </button>
                                    </div>
                                </Upload.Dragger>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImageTab;
