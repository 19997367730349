import Nodata from "@components/Nodata";
import Blog from "@pages/dashboard/UserDashboard/components/Blog";
import { useSchoolNewsHomeMutation } from "@redux/queries/user/intro.api";
import { Spin } from "antd";
import { FC, useEffect } from "react";

const PAGE = 1;
const LIMIT = 6;

interface IRelateNews {
    categoryId: string | undefined;
}

const RelateNews: FC<IRelateNews> = ({ categoryId }) => {
    const [fetch, { data, isLoading }] = useSchoolNewsHomeMutation();

    useEffect(() => {
        if (categoryId) {
            fetch({ page: PAGE, limit: LIMIT, categoryId });
        }
    }, [categoryId]);

    const { items: listSchoolNews } = data || {};
    return (
        <div className="four-blog">
            <div className="title-element">
                <h5>Bài viết liên quan</h5>
                <hr />
            </div>
            <Spin spinning={isLoading}>
                <div className="row">
                    {listSchoolNews && listSchoolNews?.length > 0 ? (
                        listSchoolNews?.map(
                            ({ id, createdAt, language, slug, thumbnail, view, like, creator }, index) => (
                                <div
                                    className="col-12 col-sm-6 col-lg-4"
                                    key={index}
                                >
                                    <Blog
                                        linkType="news"
                                        id={id}
                                        createdAt={createdAt}
                                        title={language?.[0]?.title}
                                        shortContent={language?.[0]?.shortContent}
                                        slug={slug}
                                        thumbnail={thumbnail}
                                        view={view}
                                        like={like}
                                        userName={
                                            creator?.firstName
                                                ? creator?.firstName + " " + creator?.lastName
                                                : creator?.userName
                                        }
                                        avatar={creator?.avatar}
                                    />
                                </div>
                            )
                        )
                    ) : (
                        <Nodata />
                    )}
                </div>
            </Spin>
        </div>
    );
};

export default RelateNews;
