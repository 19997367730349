import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/school/review";

export const adminSchoolReviewApi = createApi({
    reducerPath: "adminSchoolReviewApi",
    keepUnusedDataFor: 30,
    tagTypes: ["SchoolReview"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolReview: builder.query<Array<any>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "SchoolReview" as const, id })),
                              { type: "SchoolReview", id: "SchoolReview-LIST" },
                          ]
                        : [{ type: "SchoolReview", id: "SchoolReview-LIST" }],
            }),
            getListSchoolReviewPaginate: builder.query<
                IResponseDataAdmin<any>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "SchoolReview" as const, id })),
                              { type: "SchoolReview", id: "SchoolReview-LIST" },
                          ]
                        : [{ type: "SchoolReview", id: "SchoolReview-LIST" }],
            }),
            createSchoolReview: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/${params.schoolId}`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolReview"],
            }),
            updateSchoolReview: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"])
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolReview"],
            }),
            approveSchoolReview: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/approve/${params.id}`,
                    method: "PATCH",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolReview"],
            }),
            rejectSchoolReview: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/reject/${params.id}`,
                    method: "PATCH",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolReview"],
            }),
            deleteSchoolReview: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolReview"],
            }),
            deleteMultiSchoolReview: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolReview"],
            }),
        };
    },
});

export const {
    useGetListSchoolReviewQuery,
    useGetListSchoolReviewPaginateQuery,
    useLazyGetListSchoolReviewPaginateQuery,
    useCreateSchoolReviewMutation,
    useUpdateSchoolReviewMutation,
    useDeleteSchoolReviewMutation,
    useDeleteMultiSchoolReviewMutation,
    useApproveSchoolReviewMutation,
    useRejectSchoolReviewMutation,
} = adminSchoolReviewApi;
