import Nodata from "@components/Nodata";
import { useNewCategoryQuery } from "@redux/queries/user/blog.api";
import { Spin } from "antd";
import { FC } from "react";
import { Link, useSearchParams } from "react-router-dom";

const Categories: FC = () => {
    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get("category") || "";

    const { data, isLoading, isFetching } = useNewCategoryQuery();

    return (
        <>
            <Spin spinning={isLoading}>
                <div className="box-item-element infor-school">
                    <div className="title-element">
                        <h5>Danh mục</h5>
                        <hr />
                    </div>
                    <div className="body-element scrollbar-300 pe-1">
                        <ul className="list-group list-categories">
                            {data && data?.length > 0 ? (
                                data?.map((item) => (
                                    <li
                                        className="list-group-item"
                                        style={{ backgroundColor: categoryId === item?.id ? "#f5f5f5" : "" }}
                                        key={item?.id}
                                    >
                                        <Link
                                            to={`/news?category=${item?.id}`}
                                            style={{ color: categoryId === item?.id ? "#733aea" : "" }}
                                        >
                                            {item?.language[0].title}
                                            {/* <span className="badge bg-blue">{item.total}</span> */}
                                        </Link>
                                    </li>
                                ))
                            ) : (
                                <Nodata />
                            )}
                        </ul>
                    </div>
                </div>
            </Spin>
        </>
    );
};

export default Categories;
