import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/statistics";

export const dashBoardApplyApi = createApi({
    reducerPath: "dashBoardApplyApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Apply-Statistic"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getAllStatisticApply: builder.query<any, void>({
                query: (query: any) => ({
                    url: `${BASE}/apply`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getAllStatisticApplyMajor: builder.query<any, void>({
                query: (query: any) => ({
                    url: `${BASE}/majors`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getAllStatisticApplyScholarship: builder.query<any, void>({
                query: (query: any) => ({
                    url: `${BASE}/scholarship`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getAllStatisticApplySpecialized: builder.query<any, void>({
                query: (query: any) => ({
                    url: `${BASE}/school/specialized`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getAllStatisticApplyTraining: builder.query<any, void>({
                query: (query: any) => ({
                    url: `${BASE}/training`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getAllStatisticsRevenue: builder.query<any, void>({
                query: (query: any) => ({
                    url: `${BASE}/revenue`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
        };
    },
});

export const { 
    useGetAllStatisticApplyQuery,
    useGetAllStatisticApplyMajorQuery,
    useGetAllStatisticApplyScholarshipQuery,
    useGetAllStatisticApplySpecializedQuery,
    useGetAllStatisticApplyTrainingQuery,
    useGetAllStatisticsRevenueQuery,
} = dashBoardApplyApi;
