import {
    useCreateUserGrantMutation,
    useGetListCustomerPaginateQuery,
    useGetListEmployeePaginateQuery,
} from "@redux/queries/admin/admin.user";
import { Avatar, Col, Form, Modal, Row, Space, Table, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { Pagination } from "@components/pagination";
import _ from "lodash";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS } from "@consts";
import FilterGrantCustomer from "./FilterGrantCustomer";

interface FormGrantProp {
    setOpenFormGrant: (b: boolean) => void;
    openFormGrant: boolean;
    employeeId: any;
}

const { Text } = Typography;

const FormGrant = (props: FormGrantProp) => {
    const { setOpenFormGrant, openFormGrant, employeeId } = props;

    const [form] = Form.useForm();

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });
    const [filter, setFilter] = useState<any>({});

    const { showToast } = useToast();

    const [createUserGrant, isLoadingCreate] = useCreateUserGrantMutation();
    const { data, isLoading, isFetching } = useGetListCustomerPaginateQuery(query as any);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Tên</div>,
            key: "firstName",
            dataIndex: "firstName",
            render: (firstName: any, record: any) => {
                return (
                    <Space>
                        <Avatar src={record.avatar}>{firstName?.charAt(0)}</Avatar>
                        <Text>{firstName + " - " + record?.lastName || ""}</Text>
                    </Space>
                );
            },
        },
        {
            title: <div className="text-title-table">Mã</div>,
            key: "code",
            dataIndex: "code",
        },
        {
            title: <div className="text-title-table">Email</div>,
            key: "email",
            dataIndex: "email",
        },
        {
            title: <div className="text-title-table">Số điện thoại</div>,
            key: "phone",
            dataIndex: "phone",
        },
    ];

    const onFinish = async () => {
        const data = {
            employeeId: employeeId,
            customerIds: selectedRowKeys,
        };
        const result = await createUserGrant(data);
        if ("error" in result) {
            showToast({ ...TOAST_CREATE_ERROR });
        }

        if ("data" in result) {
            showToast({ ...TOAST_CREATE_SUCCESS });
            setOpenFormGrant(false);
        }
    };

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openFormGrant}
                onCancel={() => {
                    form.resetFields();
                    setOpenFormGrant(false);
                    setSelectedRowKeys([]);
                }}
                title={"Add Support Employee"}
                onOk={() => form.submit()}
                okText={"Add"}
                width={1000}
            >
                <div className="group-action-head">
                    <FilterGrantCustomer
                        data={filter}
                        onSearch={(data: any) => setFilter(data)}
                    />
                </div>

                <div className="table-responsive">
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={(data?.items as any) || []}
                        pagination={false}
                        rowKey="id"
                        loading={isLoading || isFetching}
                        scroll={{ x: 1500 }}
                    />

                    <Pagination
                        onSizeChange={(size) => setQuery({ ...query, limit: size })}
                        total={data?.meta.totalItems || 0}
                        showSize={true}
                        totalPage={data?.meta.totalPages || 0}
                        onChangePage={(page) => setQuery({ ...query, page: page })}
                        defaultCurrentPage={query.page}
                    />
                </div>
            </Modal>
        </Form>
    );
};

export default FormGrant;
