import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Tabs, Typography, UploadFile, message } from "antd";
import { validateNoSpaces } from "@utils/common";
import _ from "lodash";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useGetListSchoolCategoryQuery } from "@redux/queries/admin/admin.school.category";
import { TOAST_UPLOAD_SUCCESS, TOAST_DELETE_SUCCESS, TOAST_UPLOAD_ERROR } from "@consts";
import Upload, { RcFile, UploadChangeParam } from "antd/es/upload";
import { useToast } from "@components/toast/ToastProvider";
import store from "@redux/store";
import blankImage from "@assets/images/blank.png";
import ReactQuill from "react-quill";
import "jodit";
import JoditEditor from "jodit-react";
import { editorConfig } from "@utils/editConfig";

interface IProps {
    introduceVi: string;
    introduceEn: string;
    introduceCn: string;

    setIntroduceVi: (value: string) => void;
    setIntroduceEn: (value: string) => void;
    setIntroduceCn: (value: string) => void;

    thumbnail?: string;
    setThumbnail?: (value: string) => void;
    editThumbnail?: string;

    form: any;
    errorContent?: string;
    setErrorContent?: (value: string) => void;
}

const GeneralTab: React.FunctionComponent<IProps> = (props: IProps) => {
    const {
        introduceVi,
        introduceEn,
        introduceCn,
        setIntroduceVi,
        setIntroduceEn,
        setIntroduceCn,
        form,
        thumbnail,
        setThumbnail,
        editThumbnail,
        errorContent,
        setErrorContent,
    } = props;

    useEffect(() => {
        const thumbnailHtml = document.getElementById("thumbnail") as HTMLImageElement;
        thumbnailHtml.src = "" + process.env.REACT_APP_CDN + editThumbnail;
    }, [editThumbnail]);

    const { data: list_school, isLoading, isFetching } = useGetListSchoolQuery();
    const {
        data: list_new_category,
        isLoading: isLoadingNewCategory,
        isFetching: isFetchingNewCategory,
    } = useGetListSchoolCategoryQuery();
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const { showToast } = useToast();

    const initialItems = [] as any[];

    const [activeKey, setActiveKey] = useState("titleVi");
    const [items, setItems] = useState(initialItems);

    useEffect(() => {
        if (errorContent === "titleVi" || errorContent === "titleEn" || errorContent === "titleCn") {
            setActiveKey(errorContent);
        }
        if (errorContent === "introduceVi") {
            setActiveKey("titleVi");
            form.setFields([
                {
                    name: "introduceVi",
                    errors: ["Thông tin bắt buộc"],
                },
            ]);
        }
        if (errorContent === "introduceEn") {
            setActiveKey("titleEn");
            form.setFields([
                {
                    name: "introduceEn",
                    errors: ["Field required"],
                },
            ]);
        }
        if (errorContent === "introduceCn") {
            setActiveKey("titleCn");
            form.setFields([
                {
                    name: "introduceCn",
                    errors: ["必填字段"],
                },
            ]);
        }
        setErrorContent && setErrorContent("");
    }, [errorContent]);

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const newTabIndex = useRef(0);

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({ label: "New Tab", children: <>Content of new Tab</>, key: newActiveKey, closable: true });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: any) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    const uploadProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPngOrPdf) {
                message.error("Không đúng định dạng file yêu cầu");
            }
            const thumbnail = document.getElementById("thumbnail") as HTMLImageElement;
            thumbnail.src = URL.createObjectURL(file);
            return isJpgOrPngOrPdf;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileList([info.file]);
                setThumbnail && setThumbnail(info.file.response && info.file.response[0].url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileList([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <div className="row">
                    {/* col 1 */}
                    <div className="col-6">
                        <Tabs
                            type="editable-card"
                            onChange={onChange}
                            activeKey={activeKey}
                            onEdit={onEdit}
                            items={[
                                {
                                    label: "Tiếng Việt",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleVi"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "vi"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Tiêu đề</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="shortContentVi"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "vi"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Mô tả ngắn</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="introduceVi"
                                                        className="form-floating"
                                                    >
                                                        {/* <ReactQuill
                                                            className="text-editor"
                                                            value={introduceVi}
                                                            onChange={(value: any) => {
                                                                setIntroduceVi(value);
                                                            }}
                                                            formats={formats}
                                                            modules={modules}
                                                        /> */}
                                                        <JoditEditor
                                                            className="text-editor"
                                                            value={introduceVi}
                                                            config={editorConfig as any}
                                                            onBlur={(value) => setIntroduceVi(value)}
                                                        />
                                                    </Form.Item>
                                                    <label>Nội dung</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "titleVi",
                                    closable: false,
                                },
                                {
                                    label: "English",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleEn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Field required",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "en"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Title</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="shortContentEn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Field required",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "en"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Short Content</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="introduceEn"
                                                        className="form-floating"
                                                    >
                                                        {/* <ReactQuill
                                                            className="text-editor"
                                                            value={introduceEn}
                                                            onChange={(value: any) => {
                                                                setIntroduceEn(value);
                                                            }}
                                                            formats={formats}
                                                            modules={modules}
                                                        /> */}
                                                        <JoditEditor
                                                            className="text-editor"
                                                            value={introduceEn}
                                                            config={editorConfig as any}
                                                            onBlur={(value) => setIntroduceEn(value)}
                                                        />
                                                    </Form.Item>
                                                    <label>Content</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "titleEn",
                                    closable: false,
                                },
                                {
                                    label: "中国人",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleCn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "必填字段",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "cn"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>标题</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="shortContentCn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "必填字段",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "cn"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>简短的内容</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="introduceCn"
                                                        className="form-floating"
                                                    >
                                                        {/* <ReactQuill
                                                            className="text-editor"
                                                            value={introduceCn}
                                                            onChange={(value: any) => {
                                                                setIntroduceCn(value);
                                                            }}
                                                            formats={formats}
                                                            modules={modules}
                                                        /> */}
                                                        <JoditEditor
                                                            className="text-editor"
                                                            value={introduceCn}
                                                            config={editorConfig as any}
                                                            onBlur={(value) => setIntroduceCn(value)}
                                                        />
                                                    </Form.Item>
                                                    <label>内容</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "titleCn",
                                    closable: false,
                                },
                            ]}
                        />
                    </div>

                    {/* col 2 */}
                    <div className="col-6">
                        <Row>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="schoolId"
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    showSearch
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {list_school &&
                                                        list_school.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Trường</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="frame-upload">
                                            <img
                                                className="img-fluid"
                                                src={blankImage}
                                                alt=""
                                                id="thumbnail"
                                            />
                                        </div>
                                        <Upload.Dragger {...uploadProps}>
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">
                                                    <i className="bi-upload"></i>
                                                    Tải ảnh thumbnail
                                                </button>
                                            </div>
                                        </Upload.Dragger>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="categoryId"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    showSearch
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        (option?.children as any)
                                                            ?.toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {list_new_category &&
                                                        list_new_category.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.id}
                                                                    value={o.id}
                                                                >
                                                                    {o.language[0].title}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                            <label>Danh mục tin tức</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="status"
                                                className="form-floating"
                                                initialValue={1}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                >
                                                    <Select.Option value={0}>INACTIVE</Select.Option>
                                                    <Select.Option value={1}>ACTIVE</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <label>Trạng thái</label>
                                        </div>
                                    </div>
                                    <Form.List
                                        name="tags"
                                        initialValue={[""]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <div
                                                        key={field.key}
                                                        className="space-medias-tab"
                                                    >
                                                        <div
                                                            className="form-group"
                                                            style={{
                                                                width: "90%",
                                                                display: "inline-block",
                                                            }}
                                                        >
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name={[field.name]}
                                                                    className="form-floating"
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="no-icon form-floating"
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                </Form.Item>
                                                                <label>Tag</label>
                                                            </div>
                                                        </div>
                                                        {fields?.length > 0 ? (
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                style={{
                                                                    width: "10%",
                                                                    display: "inline-block",
                                                                }}
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{ width: "100%" }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Thêm tag
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

const MemoizedGeneralTab = React.memo(GeneralTab);

export default MemoizedGeneralTab;
