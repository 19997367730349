import { Form, Select } from "antd";
import { Dispatch, FC, SetStateAction, memo } from "react";
import { GPACoefficientsOptions, HSKKOptions, HSKOptions } from "../constants";
import { HSKK_MAX, HSK_MAX } from "@pages/admin/users/user.const";
import { removeEmptyValues } from "@utils/common";

interface IThirdStep {
    dataWelcome: InputWelcomeUser;
    setDataWelcome: Dispatch<SetStateAction<InputWelcomeUser>>;
    setActiveTab: Dispatch<SetStateAction<number>>;
}

const ThirdStep: FC<IThirdStep> = ({ dataWelcome, setDataWelcome, setActiveTab }) => {
    const [form] = Form.useForm();

    const onSubmit = (values: any) => {
        console.log("values", values);
        const {
            currentStatus,
            highestLevelOfEducation,
            trainingIndustry,
            GPAMark,
            GPAcoefficients,
            HskLevel,
            HskMark,
            HskkLevel,
            HskkMark,
            graduateSpecialized,
            graduateYear,
            certificate,
            exCertificate,
            scientificResearchAchievement,
            ielts,
        } = values;

        setDataWelcome((dataWelcome) => ({
            ...dataWelcome,
            profileInformation: removeEmptyValues({
                currentStatus,
                highestLevelOfEducation,
                trainingIndustry,
                Gpa: removeEmptyValues({
                    coefficients: GPAcoefficients && Number(GPAcoefficients),
                    mark: GPAMark && Number(GPAMark),
                }),
                Hsk: removeEmptyValues({
                    level: HskLevel && HskLevel,
                    mark: HskMark && Number(HskMark),
                }),
                Hskk: removeEmptyValues({
                    level: HskkLevel && HskkLevel,
                    mark: HskkMark && Number(HskkMark),
                }),
                graduateSpecialized,
                graduateYear: graduateYear && Number(graduateYear),
                certificate: certificate && Number(certificate),
                exCertificate: exCertificate && Number(exCertificate),
                scientificResearchAchievement,
                ielts: ielts && Number(ielts),
            }),
        }));

        handleNextStep();
    };

    const handleNextStep = () => {
        setActiveTab(4);
    };

    const handleBackStep = () => {
        setActiveTab(2);
    };

    const handleChange = () => {};

    return (
        <section id="content-main">
            <div className="box-component">
                <div className="box-introduction">
                    <div className="content-li-step">
                        <ul className="li-step">
                            <li className="active">
                                <span>1</span>
                            </li>
                            <li className="active">
                                <span>2</span>
                            </li>
                            <li className="active">
                                <span>3</span>
                            </li>
                            <li>
                                <span>4</span>
                            </li>
                            <li>
                                <span>5</span>
                            </li>
                        </ul>
                    </div>

                    <Form
                        form={form}
                        onFinish={onSubmit}
                    >
                        <div className="content-introduction">
                            <div className="step-title">
                                <h6>Bước 2: Hoàn thiện hồ sơ</h6>
                                <h5>Thông tin hồ sơ của bạn</h5>
                                <p>
                                    Việc tạo hồ sơ của bạn sẽ giúp cho chúng tôi phân loại, sắp xếp, đánh giá năng lực
                                    và tìm trường cũng như học bổng phù hợp với bạn.
                                </p>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="currentStatus"
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Tình trạng hiện tại</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="highestLevelOfEducation"
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Cấp bậc học cao nhất</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="trainingIndustry"
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Hệ đào tạo</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <Form.Item
                                            name="GPAcoefficients"
                                            className="user-form mb-0"
                                        >
                                            <Select
                                                placeholder="chọn hệ số GPA"
                                                onChange={handleChange}
                                                options={GPACoefficientsOptions}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="GPAMark"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value, callback) {
                                                        const GPAcoefficients = getFieldValue("GPAcoefficients");

                                                        if (value && value > GPAcoefficients) {
                                                            callback(`Điểm GPA <= ${GPAcoefficients}`);
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Điểm GPA</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <Form.Item
                                            name="HskLevel"
                                            className="user-form mb-0"
                                        >
                                            <Select
                                                placeholder="chọn trình độ HSK"
                                                onChange={handleChange}
                                                options={HSKOptions}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="HskMark"
                                            rules={[
                                                {
                                                    validator(rule, value, callback) {
                                                        if (value && value > HSK_MAX) {
                                                            callback(`Điểm HSK phải nhỏ hơn hoặc bằng ${HSK_MAX}`);
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Điểm thi HSK</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <Form.Item
                                            name="HskkLevel"
                                            className="user-form mb-0"
                                        >
                                            <Select
                                                placeholder="chọn trình độ HSKK"
                                                onChange={handleChange}
                                                options={HSKKOptions}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="HskkMark"
                                            rules={[
                                                {
                                                    validator(rule, value, callback) {
                                                        if (value && value > HSKK_MAX) {
                                                            callback(`Điểm HSKK phải nhỏ hơn hoặc bằng ${HSKK_MAX}`);
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Điểm thi HSKK</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="graduateSpecialized"
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Tốt nghiệp chuyên ngành</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="graduateYear"
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Năm tốt nghiệp</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="certificate"
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Số lượng giấy khen</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="scientificResearchAchievement"
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Thành tích nghiên cứu khoa học</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="ielts"
                                            rules={[
                                                {
                                                    validator(rule, value, callback) {
                                                        if (value && value > 9) {
                                                            callback("Điểm IELTS phải nhỏ hơn hoặc bằng 9");
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Chứng chỉ IELTS</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <Form.Item
                                            className="mb-0"
                                            name="exCertificate"
                                        >
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                                <label>Giải thưởng đạt được khác</label>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="hr-border" />

                        <div className="group-button-footer">
                            <button
                                type="button"
                                className="continue btn btn-gold"
                                onClick={handleBackStep}
                            >
                                <i className="bi-arrow-left"></i> Quay lại
                            </button>
                            <button
                                type="submit"
                                className="continue btn btn-blue"
                            >
                                Tiếp tục <i className="bi-arrow-right"></i>
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
    );
};

export default memo(ThirdStep);
