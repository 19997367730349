import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useDeleteMultiTeamMutation,
    useDeleteTeamMutation,
    useGetListTeamPaginateQuery,
} from "@redux/queries/admin/admin.team";
import { Avatar, Button, Modal, Space, Table, Tag, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import HandleForm from "./components/HanleForm";
import { useDeleteUserTeamMutation, useDeleteMultiUserTeamMutation } from "@redux/queries/admin/admin.user-team";
import { listUserRole } from "../users/user.const";

const { Text } = Typography;

const Team: React.FunctionComponent = () => {
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const { data, isLoading, isFetching, refetch } = useGetListTeamPaginateQuery(query as any);

    const [deleteTeam, isLoadingDelete] = useDeleteTeamMutation();
    const [deleteMultiTeam, isLoadingMultiDelete] = useDeleteMultiTeamMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteTeam({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiTeam({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Tên</div>,
            dataIndex: "name",
            key: "name",
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };

    const userTeamsColumns = [
        {
            title: <div className="text-title-table">Team</div>,
            dataIndex: "team",
            key: "team",
            render: (team: any) => {
                return <Text>{team.name}</Text>;
            },
        },
        {
            title: <div className="text-title-table">Tên</div>,
            key: "firstName",
            dataIndex: "firstName",
            render: (firstName: any, record: any) => {
                return (
                    <Space>
                        <Avatar src={record?.user?.avatar}>{record?.user?.firstName?.charAt(0)}</Avatar>
                        <Text>{record?.user?.firstName + " - " + record?.user?.lastName || ""}</Text>
                    </Space>
                );
            },
        },
        {
            title: <div className="text-title-table">Ngày sinh</div>,
            key: "birthday",
            dataIndex: "user",
            render: (user: any) => {
                return user?.birthday ? moment(user?.birthday).format("DD/MM/YYYY") : "";
            },
        },
        {
            title: <div className="text-title-table">Tuổi</div>,
            key: "birthday",
            dataIndex: "user",
            render: (user: any) => {
                return user?.birthday ? moment().diff(user?.birthday, "years") : "";
            },
        },
        {
            title: <div className="text-title-table">Mã</div>,
            key: "code",
            dataIndex: "user",
            render: (user: any) => {
                return user?.code;
            },
        },
        {
            title: <div className="text-title-table">Email</div>,
            key: "email",
            dataIndex: "user",
            render: (user: any) => {
                return user?.email;
            },
        },
        {
            title: <div className="text-title-table">Số điện thoại</div>,
            key: "phone",
            dataIndex: "user",
            render: (user: any) => {
                return user?.phone;
            },
        },
        {
            title: <div className="text-title-table">Role</div>,
            key: "role",
            dataIndex: "user",
            render: (user: any) => {
                const userRole = listUserRole?.find((o: any) => o.value === user?.role);
                return <Tag color={userRole?.color}>{userRole?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        {/* <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button> */}
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteUserTeamRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const [deleteUserTeam] = useDeleteUserTeamMutation();
    const [deleteMultiUserTeam] = useDeleteMultiUserTeamMutation();

    const handleDeleteUserTeam = async (id: string) => {
        const result = await deleteUserTeam({
            id: id,
        });

        refetch();

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDeleteUserTeam = async () => {
        const result = await deleteMultiUserTeam({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const confirmDeleteUserTeamRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDeleteUserTeam(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteUserTeamRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeysUsersTeam.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDeleteUserTeam();
            },
            centered: true,
        });
    };

    const [selectedRowKeysUsersTeam, setSelectedRowKeysUsersTeam] = useState<React.Key[]>([]);

    const onSelectChangeUsersTeam = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelectionUsersTeam = {
        selectedRowKeysUsersTeam,
        onChange: onSelectChangeUsersTeam,
    };

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row"></div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            {/* <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button> */}
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    //rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    expandable={{
                                        expandedRowRender: (e) => {
                                            return (
                                                <Table
                                                    columns={userTeamsColumns}
                                                    dataSource={(e?.usersTeam as any) || []}
                                                    pagination={false}
                                                    loading={isLoading || isFetching}
                                                    scroll={{ x: 1500 }}
                                                />
                                            );
                                        },
                                    }}
                                />
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default Team;
