import GeneralTab from "../tab/GeneralTab";
import ContactTab from "../tab/ContactTab";
import ImageTab from "../tab/ImageTab";
import ScholarTab from "../tab/ScholarTab";
import { useToast } from "@components/toast/ToastProvider";
import {
    useCreateSchoolMutation,
    useGetSchoolDetailByIdQuery,
    useLazyGetSchoolDetailByIdQuery,
    useUpdateSchoolMutation,
} from "@redux/queries/admin/admin.school";
import { useCallback, useEffect, useState } from "react";
import { useGetListSchoolTypeQuery } from "@redux/queries/admin/admin.school.type";
import { useGetListProjectQuery } from "@redux/queries/admin/admin.project";
import { Button, Card, Form, Row, Space, Tabs, message } from "antd";
import MediasTab from "../tab/MediasTab";
import _ from "lodash";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import AttributeTab from "../tab/AttributeTab";
import { useLocation } from "react-router-dom";
import { history } from "@utils/helper-router";
import axios from "axios";
import store from "@redux/store";

export interface FormSchoolValues {
    title_vi?: string;
    title_en?: string;
    title_cn?: string;
    linkDetail?: string;
    rank?: number;
    code: string;
    projectIds?: string;
    schoolTypeId?: string;
    view720?: string;
}

const Create: React.FunctionComponent = () => {
    const [createSchool, result] = useCreateSchoolMutation();
    const [updateSchool, resultUpdate] = useUpdateSchoolMutation();
    const { showToast } = useToast();
    const location = useLocation();
    const id = location.pathname.split("/")[4];

    const [getSchoolDetailById, { data: dataEdit }] = useLazyGetSchoolDetailByIdQuery();

    useEffect(() => {
        if (id) {
            getSchoolDetailById({
                id,
            });
        }
    }, [id]);

    const [editLogo, setEditLogo] = useState<any>(null);
    const [editBackground, setEditBackground] = useState<any>(null);
    const [editImages, setEditImages] = useState<any>([]);

    useEffect(() => {
        if (dataEdit) {
            const titleVi = dataEdit?.language?.find((o: any) => o.lang === "vi")?.name;
            const titleEn = dataEdit?.language?.find((o: any) => o.lang === "en")?.name;
            const titleCn = dataEdit?.language?.find((o: any) => o.lang === "cn")?.name;
            const introduceVi = dataEdit?.language?.find((o: any) => o.lang === "vi")?.introduce;
            const introduceEn = dataEdit?.language?.find((o: any) => o.lang === "en")?.introduce;
            const introduceCn = dataEdit?.language?.find((o: any) => o.lang === "cn")?.introduce;
            const linkDetail = dataEdit?.linkDetail;
            const rank = dataEdit?.rank;
            const code = dataEdit?.code;
            const isPublic = dataEdit?.isPublic;
            const address = dataEdit?.contact?.address;
            const phone = dataEdit?.contact?.phone;
            const email = dataEdit?.contact?.email;
            const website = dataEdit?.contact?.website;
            const websiteInternationalInstitute = dataEdit?.contact?.websiteInternationalInstitute;
            const facebook = dataEdit?.contact?.socials?.facebook;
            const youtube = dataEdit?.contact?.socials?.youtube;
            const instagram = dataEdit?.contact?.socials?.instagram;
            const twitter = dataEdit?.contact?.socials?.twitter;
            const linkedin = dataEdit?.contact?.socials?.linkedin;
            const areaId = dataEdit?.area?.id;
            const cityId = dataEdit?.city?.id;
            const schoolTypeId = dataEdit?.schoolTypeId;
            const view720 = dataEdit?.view720;
            const projectIds = dataEdit?.projects?.map((o: any) => o.id);
            const logo = dataEdit?.logo;
            const background = dataEdit?.background;
            const specializedIds = dataEdit?.specializeds?.map((o: any) => o.specialized?.id);
            const syncLink = dataEdit?.syncLink;
            const lastSessionPass = dataEdit?.lastSessionPass;
            const map = dataEdit?.map;
            setEditLogo(logo);
            setEditBackground(background);
            setIntroduceVi(introduceVi || "");
            setIntroduceEn(introduceEn || "");
            setIntroduceCn(introduceCn || "");
            const scholarships = dataEdit?.scholarships?.map((o: any) => {
                return {
                    scholarshipId: o?.scholarship?.id,
                    scholarshipTypeId: o?.scholarshipType?.id,
                    trainIds: o?.trainings?.map((o: any) => o?.training?.id),
                };
            });
            const videos = dataEdit?.medias
                ?.filter((item: { type: string }) => item.type === "VIDEO")
                ?.map((o: any) => {
                    return {
                        link: o?.url,
                    };
                });
            const images = dataEdit?.medias?.filter((item: { type: string }) => item.type === "IMAGE");
            setEditImages(images);
            const vi = {} as any;
            const en = {} as any;
            const cn = {} as any;
            dataEdit?.attributes?.forEach((o: any) => {
                if (o.language === "vi") {
                    vi[o.name] = o.value;
                }
                if (o.language === "en") {
                    en[o.name] = o.value;
                }
                if (o.language === "cn") {
                    cn[o.name] = o.value;
                }
            });
            form.setFieldsValue({
                isPublic,
                titleVi,
                titleEn,
                titleCn,
                linkDetail,
                rank,
                code,
                address,
                phone,
                email,
                website,
                facebook,
                youtube,
                instagram,
                twitter,
                linkedin,
                areaId,
                cityId,
                schoolTypeId,
                projectIds,
                scholarships,
                videos,
                specializedIds,
                syncLink,
                lastSessionPass,
                map,
                websiteInternationalInstitute,
                vi,
                en,
                cn,
            });
        }
    }, [dataEdit]);

    const [form] = Form.useForm();

    const [submit, setSubmit] = useState<any>([]);
    const [dataTab1, setDataTab1] = useState<any>({});

    const [logo, setLogo] = useState<any>(null);
    const [background, setBackground] = useState<any>(null);
    const [images, setImages] = useState<any>([]);

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");

    const { data: list_project, isLoading: isLoadingProject, error: errorProject } = useGetListProjectQuery();

    useEffect(() => {
        setSubmit(list_project);
    });

    const handleTab1 = (value: any) => {
        setDataTab1({ ...dataTab1, ...value });
    };

    const token = store.getState().auth.accessToken;

    const checkExistCode = async (code: string) => {
        const response = (await axios.patch(
            `${process.env.REACT_APP_HOST}/api/school/check/${code}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        )) as any;
        return response?.data?.exist;
    };

    const handleSubmit = async () => {
        let value;
        try {
            value = await form.validateFields();
            const exist = await checkExistCode(value.code);
            if (exist && value.code.toString() !== dataEdit?.code?.toString()) {
                form.setFields([
                    {
                        name: "code",
                        errors: ["Mã trường đã tồn tại"],
                    },
                ]);
                const codeElement = document.getElementById("school-code") as HTMLInputElement;
                codeElement.scrollIntoView({ behavior: "smooth" });
                return;
            }
        } catch (errorInfo) {
            return;
        }
        const {
            titleVi = dataEdit?.language?.find((o: any) => o.lang === "vi")?.name,
            titleEn = dataEdit?.language?.find((o: any) => o.lang === "en")?.name,
            titleCn = dataEdit?.language?.find((o: any) => o.lang === "cn")?.name,
            linkDetail,
            rank,
            code,
            isPublic,
            projectIds,
            schoolTypeId,
            address,
            phone,
            email,
            website,
            facebook,
            youtube,
            instagram,
            twitter,
            linkedin,
            areaId,
            cityId,
            specializedIds,
            scholarships,
            videos,
            websiteInternationalInstitute,
            syncLink,
            lastSessionPass,
            map,
            view720,
            vi,
            en,
            cn,
        } = value;
        const language = [
            {
                lang: "vi",
                name: titleVi,
                introduce: introduceVi || dataEdit?.language?.find((o: any) => o.lang === "vi")?.introduce,
            },
            {
                lang: "en",
                name: titleEn,
                introduce: introduceEn || dataEdit?.language?.find((o: any) => o.lang === "en")?.introduce,
            },
            {
                lang: "cn",
                name: titleCn,
                introduce: introduceCn || dataEdit?.language?.find((o: any) => o.lang === "cn")?.introduce,
            },
        ];
        const socials = _.pickBy(
            {
                facebook,
                youtube,
                instagram,
                twitter,
                linkedin,
            },
            _.identity
        );
        const contact = _.pickBy(
            {
                address,
                phone,
                email,
                website,
                socials,
                websiteInternationalInstitute,
            },
            _.identity
        );
        const schoolSpecializeds = specializedIds?.map((o: any) => {
            return {
                specializedId: o,
            };
        });
        const listScholarships = scholarships?.map((o: any) => {
            return {
                scholarshipId: o.scholarshipId,
                scholarshipTypeId: o.scholarshipTypeId,
                trainings: o.trainIds?.map((o: any) => {
                    return {
                        trainingId: o,
                    };
                }),
            };
        });
        const medias = [] as any;
        if (images?.length > 0) {
            images.forEach((o: any) => {
                medias.push({
                    type: "IMAGE",
                    url: o,
                });
            });
        }
        if (videos?.length > 0) {
            videos.forEach((o: any) => {
                if (o.link) {
                    medias.push({
                        type: "VIDEO",
                        url: o.link,
                    });
                }
            });
        }
        const mediasFilter = medias?.filter((o: any) => o.url);
        const attributes = [];
        for (let key in vi) {
            if ((vi && vi[key]) || (en && en[key]) || (cn && cn[key])) {
                vi && vi[key] && attributes.push({
                    name: key,
                    value: vi && vi[key],
                    language: "vi",
                    pk: true,
                });
                en && en[key] && attributes.push({
                    name: key,
                    value: en && en[key],
                    language: "en",
                    pk: true,
                });
                cn && cn[key] && attributes.push({
                    name: key,
                    value: cn && cn[key],
                    language: "cn",
                    pk: true,
                });
            }
        }
        const data = _.pickBy(
            {
                language,
                isPublic,
                code: Number(code),
                rank: Number(rank),
                linkDetail,
                projectIds,
                schoolTypeId,
                contact,
                areaId,
                cityId,
                schoolSpecializeds,
                scholarships: listScholarships,
                logo,
                background,
                medias: mediasFilter,
                attributes,
                syncLink,
                lastSessionPass,
                map,
                view720,
            },
            _.identity
        );
        const result = id
            ? await updateSchool({
                  ...data,
                  id,
              } as any)
            : await createSchool(data as any);
        if ("error" in result) {
            id ? showToast({ ...TOAST_UPDATE_ERROR }) : showToast({ ...TOAST_CREATE_ERROR });
        }

        if ("data" in result) {
            id ? showToast({ ...TOAST_UPDATE_SUCCESS }) : showToast({ ...TOAST_CREATE_SUCCESS });
            history.navigate("/admin/school");
        }
    };

    const memoizedSetInput = useCallback((value: any) => {
        setDataTab1(value);
    }, []);

    return (
        <>
            <div className="container-fluid padding0">
                <span className="screen-darken"></span>
                <main>
                    <div>
                        <section id="content-main">
                            <Form
                                form={form}
                                scrollToFirstError
                                initialValues={dataEdit}
                            >
                                <div className="box-component">
                                    <div>
                                        <Card
                                            title="Thông tin chung"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                        >
                                            <GeneralTab
                                                editCode={dataEdit?.code?.toString()}
                                                form={form}
                                                introduceVi={introduceVi}
                                                introduceEn={introduceEn}
                                                introduceCn={introduceCn}
                                                setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                                setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                                setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                            />
                                        </Card>
                                    </div>
                                    <div>
                                        <Card
                                            title="Thông tin liên hệ"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                        >
                                            <ContactTab />
                                        </Card>
                                    </div>
                                    <div>
                                        <Card
                                            title="Image"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                        >
                                            <ImageTab
                                                logo={logo as any}
                                                background={background}
                                                setLogo={(value: any) => setLogo(value)}
                                                setBackground={(value: any) => setBackground(value)}
                                                editLogo={editLogo}
                                                editBackground={editBackground}
                                            />
                                        </Card>
                                    </div>
                                    <div>
                                        <Card
                                            title="Media"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                        >
                                            <MediasTab
                                                images={images}
                                                setImages={(value: any) => setImages(value)}
                                                editImages={editImages}
                                            />
                                        </Card>
                                    </div>
                                    <div>
                                        <Card
                                            title="Học bổng và hệ đào tạo"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                        >
                                            <ScholarTab />
                                        </Card>
                                    </div>
                                    <div>
                                        <Card
                                            title="School Attributes"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                        >
                                            <Tabs
                                                type="editable-card"
                                                items={[
                                                    {
                                                        label: "Tiếng Việt",
                                                        children: <AttributeTab language="vi" />,
                                                        key: "1",
                                                        closable: false,
                                                    },
                                                    {
                                                        label: "Tiếng Anh",
                                                        children: <AttributeTab language="en" />,
                                                        key: "2",
                                                        closable: false,
                                                    },
                                                    {
                                                        label: "Tiếng Trung",
                                                        children: <AttributeTab language="cn" />,
                                                        key: "3",
                                                        closable: false,
                                                    },
                                                ]}
                                            ></Tabs>
                                            {/* <AttributeTab /> */}
                                        </Card>
                                    </div>
                                    <Row
                                        justify="center"
                                        gutter={2}
                                    >
                                        <Space>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        history.navigate("/admin/school");
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Form.Item>
                                        </Space>
                                    </Row>
                                </div>
                            </Form>
                        </section>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Create;
