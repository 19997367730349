import React, { useEffect, useRef } from "react";
import { Modal } from "bootstrap";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    footer?: Array<React.ReactNode> | null;
    onOk?: () => void;
    onCancel?: () => void;
    okText?: string;
    cancelText?: string;
}

const ModalCustome: React.FunctionComponent<React.PropsWithChildren<ModalProps>> = (props) => {
    const { open, onCancel, onClose, title, footer, onOk, okText, cancelText } = props;
    const modalRef = useRef(null);

    const showModal = () => {
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle as any, {
            backdrop: "static",
            keyboard: false,
        });
        bsModal.show();
    };

    const hideModal = () => {
        const modalEle = modalRef.current;
        const bsModal = Modal.getInstance(modalEle as any);
        bsModal?.hide();
    };

    const handleOk = () => {
        if (onOk) {
            onOk();
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            onClose();
        }
    };

    const renderFooter = () => {
        if (footer && footer.length > 0) {
            return footer.map((e) => e);
        } else {
            return (
                <>
                    <button
                        type="button"
                        className="text-box-gray"
                        onClick={handleCancel}
                    >
                        {cancelText ?? "Cancel"}
                    </button>
                    <button
                        type="button"
                        className="btn-blue"
                        onClick={handleOk}
                    >
                        {okText ?? "Submit"}
                    </button>
                </>
            );
        }
    };

    useEffect(() => {
        if (open) {
            showModal();
        } else {
            hideModal();
        }
    }, [props.open]);

    return (
        <div
            className="modal fade"
            ref={modalRef}
            tabIndex={-1}
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                {/* include: modal-xl, modal-lg, modal-sm */}
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">{title}</h1>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={props.onClose}
                        />
                    </div>
                    <div className="modal-body">{props.children}</div>
                    <div className="modal-footer">{renderFooter()}</div>
                </div>
            </div>
        </div>
    );
};

export default ModalCustome;
