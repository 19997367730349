import { useToast } from "@components/toast/ToastProvider";
import { TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useGetApplyByIdQuery } from "@redux/queries/admin/admin.apply";
import { useUpdateApplyProcessStatusMutation } from "@redux/queries/admin/admin.apply.process";
import { Button, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
export const listApplyProcessStatus = [
    {
        value: "PENDING",
        label: "Pending",
    },
    {
        value: "PROCESSING",
        label: "Processing",
    },
    {
        value: "DONE",
        label: "Done",
    },
];

interface IProps {
    item: any;
}

const UpdateProcessStatus = (props: IProps) => {
    const { item } = props;
    const [form] = Form.useForm();
    const location = useLocation();
    const id = location.pathname.split("/")[4];
    const { showToast } = useToast();

    useEffect(() => {
        form.setFieldsValue({
            status: item?.status,
        });
    }, [item]);

    const { refetch } = useGetApplyByIdQuery({
        id,
    });

    const [updateApplyProcessStatus, result] = useUpdateApplyProcessStatusMutation();

    const onFinish = async (values: any) => {
        const result = await updateApplyProcessStatus({
            id: item.id,
            status: values.status,
        });

        if ("error" in result) {
            showToast({ ...TOAST_UPDATE_ERROR });
        }

        if ("data" in result) {
            showToast({ ...TOAST_UPDATE_SUCCESS });
            refetch();
        }
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
                style={{ marginTop: 40 }}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <div className="form-group">
                        <div
                            className="form-group"
                            style={{
                                width: "55%",
                                display: "inline-block",
                            }}
                        >
                            <div className="form-floating">
                                <Form.Item
                                    name="status"
                                    className="form-floating"
                                >
                                    <Select
                                        className="form-control form-floating"
                                        bordered={false}
                                    >
                                        {listApplyProcessStatus &&
                                            listApplyProcessStatus.map((o: any, i: any) => {
                                                return (
                                                    <Select.Option
                                                        key={o.value}
                                                        value={o.value}
                                                    >
                                                        {o.label}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                                <label>Trạng thái</label>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            style={{
                                height: "calc(2.6rem + 5px)",
                                width: "40%",
                                display: "inline-block",
                            }}
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            Update
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default UpdateProcessStatus;
