import { useCheckResetPasswordTokenMutation, useResetPassMutation } from "@redux/queries/auth.api";
import useQueryString from "@utils/useQueryString";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import AlertPage from "./components/AlertPage";
import { useTranslation } from "react-i18next";
import { history } from "@utils/helper-router";
import { message } from "antd";
import { Link } from "react-router-dom";

const ResetPass: React.FunctionComponent = () => {
    const { getAllParams } = useQueryString();
    const { t } = useTranslation();
    const params = getAllParams();
    const recaptchaRef = useRef<any>(null);
    const [doResetPass, result] = useResetPassMutation();
    const [checkResetPass] = useCheckResetPasswordTokenMutation();
    const token = params["token"];
    const hash = params["hash"];

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPass: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Không được bỏ trống Mật khẩu").min(8, "Tối thiểu 8 kí tự"),
            confirmPass: Yup.string()
                .oneOf([Yup.ref("password"), null as any], "Nhập lại mật khẩu không khớp")
                .required("Không được bỏ trống xác nhận mật khẩu"),
        }),
        onSubmit: async (values) => {
            await recaptchaRef.current.execute();
            const recaptchaValue = recaptchaRef && recaptchaRef.current && recaptchaRef.current.getValue();
            const result: any = await doResetPass({
                password: values.password,
                hash,
                token,
                recaptcha: recaptchaValue,
            });
        },
    });

    useEffect(() => {
        const handleCheckResetPass = async () => {
            const response: any = await checkResetPass({ hash, token });
            if (response?.error) {
                message.error("Liên kết này đã không còn khả dụng. Vui lòng nhập lại email");
                history.navigate("/forgot-pass");
            }
        };

        handleCheckResetPass();
    }, []);

    if (result.data) {
        return (
            <AlertPage
                title={t("page_resetpass_alert_tit", "Thay đổi mật khẩu thành công!")}
                description={t(
                    "page_resetpass_alert_desc",
                    "Mật khẩu của bạn đã được thay đổi, vui lòng đăng nhập để thực thiện các thao tác!"
                )}
                button={{
                    navigateTo: "/login",
                    text: t("page_resetpass_alert_login", "Đăng nhập"),
                }}
            />
        );
    }

    return (
        <div className="login-auth">
            <div className="ocean">
                <div className="wave" />
                <div className="wave" />
            </div>
            {/* BEGIN: Header Logo + Sign Up */}
            <div className="header-login container">
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <Link
                            className="logo-header"
                            to="/"
                        >
                            <img
                                src="images/logo.png"
                                alt="logo-riba"
                            />
                        </Link>
                    </div>
                    <div className="col-12 col-sm-8">
                        <p className="dont-account">
                            {t("page_resetpass_notacc", "Bạn chưa có tài khoản?")}{" "}
                            <Link
                                to="/register"
                                className="btn-green cursor-pointer"
                            >
                                {t("page_resetpass_register", "Đăng ký ngay!")}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <section className="inline-block">
                <form
                    id="signin"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="title-sign">
                        <h1>{t("page_resetpass_title", "Thay đổi mật khẩu")}</h1>
                        <p>{t("page_resetpass_desc", "TVui lòng nhập mật khẩu mới vào ô dưới đây:")} </p>
                    </div>
                    <div className="body-sign">
                        <div className="form-group">
                            <div className="form-floating">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    className="form-control"
                                    placeholder="example@site.com"
                                />

                                <label>{t("page_resetpass_newpass", "Mật khẩu mới")}</label>
                                <i className="bi-key" />
                            </div>
                            {formik.touched.password && formik.errors.password ? (
                                // @ts-ignore
                                <p className="text-red">{formik?.errors?.password}</p>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <div className="form-floating">
                                <input
                                    id="confirmPass"
                                    name="confirmPass"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPass}
                                    type="password"
                                    className="form-control"
                                    placeholder="example@site.com"
                                />
                                <label>{t("page_resetpass_reconfirm", "Nhập lại mật khẩu")}</label>
                                <i className="bi-key" />
                            </div>
                            {formik.touched.confirmPass && formik.errors.confirmPass ? (
                                // @ts-ignore
                                <p className="text-red">{formik?.errors?.confirmPass}</p>
                            ) : null}
                        </div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY as any}
                            ref={recaptchaRef}
                            size="invisible"
                        />
                        <div className="mirror mirror-child">
                            {(result?.error as any)?.message && (
                                <p className="text-red text-center">{(result?.error as any)?.message}</p>
                            )}
                            <button
                                className="btn-submit btn-blue"
                                type="submit"
                                disabled={result.isLoading}
                            >
                                {result.isLoading && (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                <span className="ps-2">{t("page_resetpass_sendme", "Xác nhận")}</span>
                            </button>
                        </div>
                    </div>
                </form>
                <p className="copyright-sign">{t("page_resetpass_inc", "Bản quyền © 2023 thuộc Studychina, Inc.")}</p>
            </section>
        </div>
    );
};

export default ResetPass;
