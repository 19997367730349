import { useGetListUserQuery } from "@redux/queries/admin/admin.user";
import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import { listFundPayment, listSourcePayment, listStatusPayment, listTypePayment } from "../payment.const";
import { useGetListPaymentMethodQuery } from "@redux/queries/admin/admin.payment.method";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterPayment: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch({
            ...values,
            fromDate: values.fromDate ? new Date(values.fromDate).getTime() : undefined,
            toDate: values.toDate ? new Date(values.toDate).getTime() : undefined,
        });
    };

    const { data: list_user } = useGetListUserQuery();
    const { data: listPaymentMethod } = useGetListPaymentMethodQuery({ status: 1 });

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        {/* col 1 */}
                        <Col span={12}>
                            <Row>
                                <Col span={12}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="userIds"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        placeholder=""
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        mode="multiple"
                                                    >
                                                        {list_user &&
                                                            list_user.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {`${o.firstName || ""} ${o.lastName || ""} - ${
                                                                            o.code || ""
                                                                        }`}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Khách hàng</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="fund"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                    >
                                                        {listFundPayment.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.value}
                                                                    value={o.value}
                                                                >
                                                                    {o.label}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Phương thức</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="type"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                    >
                                                        {listTypePayment.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.value}
                                                                    value={o.value}
                                                                >
                                                                    {o.label}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Loại</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="fromDate"
                                                    className="form-floating"
                                                >
                                                    <DatePicker
                                                        className="no-icon form-floating"
                                                        style={{ width: "100%", height: 45 }}
                                                        format="DD/MM/YYYY"
                                                    />
                                                </Form.Item>
                                                <label>Ngày bắt đầu</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-group" style={{
                                marginTop: -20,
                                marginLeft: 20,
                            }}>
                                <div className="form-floating">
                                    <Form.Item
                                        name="methodId"
                                        className="form-floating"
                                    >
                                        <Select
                                            allowClear
                                            className="form-control form-floating"
                                            bordered={false}
                                        >
                                            {listPaymentMethod?.map((o: any, i: any) => {
                                                return (
                                                    <Select.Option
                                                        key={o.id}
                                                        value={o.id}
                                                    >
                                                        {o?.language[0].name} - {o?.info?.accountNumber} -{" "}
                                                        {o?.info?.owner}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <label>Phương thức thanh toán</label>
                                </div>
                            </div>
                        </Col>
                        {/* col 2 */}

                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="source"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                            >
                                                {listSourcePayment.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.value}
                                                            value={o.value}
                                                        >
                                                            {o.label}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <label>Nguồn tiền</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="toDate"
                                            className="form-floating"
                                        >
                                            <DatePicker
                                                className="no-icon form-floating"
                                                style={{ width: "100%", height: 45 }}
                                                format="DD/MM/YYYY"
                                            />
                                        </Form.Item>
                                        <label>Ngày kết thúc</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="status"
                                            className="form-floating"
                                        >
                                            <Select
                                                allowClear
                                                className="form-control form-floating"
                                                bordered={false}
                                            >
                                                {listStatusPayment.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.value}
                                                            value={o.value}
                                                        >
                                                            {o.label}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <label>Trạng thái</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="txId"
                                            className="form-floating"
                                        >
                                            <Input
                                                className="form-control no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Id giao dịch</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterPayment;
