import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/user";

export const adminUserApi = createApi({
    reducerPath: "adminUserApi",
    keepUnusedDataFor: 30,
    tagTypes: ["User"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListUser: builder.query<Array<IUser>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "User" as const, id })),
                              { type: "User", id: "User-LIST" },
                          ]
                        : [{ type: "User", id: "User-LIST" }],
            }),
            getListUserPaginate: builder.query<
                IResponseDataAdmin<IUser>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "User" as const, id })),
                              { type: "User", id: "User-LIST" },
                          ]
                        : [{ type: "User", id: "User-LIST" }],
            }),
            getListCustomerPaginate: builder.query<
                IResponseDataAdmin<IUser>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/customer/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "User" as const, id })),
                              { type: "User", id: "User-LIST" },
                          ]
                        : [{ type: "User", id: "User-LIST" }],
            }),
            getListEmployeePaginate: builder.query<
                IResponseDataAdmin<IUser>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/employee/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "User" as const, id })),
                              { type: "User", id: "User-LIST" },
                          ]
                        : [{ type: "User", id: "User-LIST" }],
            }),
            createUser: builder.mutation<any, IUser>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["User"],
            }),
            updateUser: builder.mutation<any, IUser>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["User"],
            }),
            deleteUser: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["User"],
            }),
            deleteMultiUser: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["User"],
            }),
            getUserById: builder.query<IUser, { id: string }>({
                query: (params) => {
                    return {
                        url: `${BASE}/${params.id}`,
                        method: "GET",
                    };
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListUserGrant: builder.query<Array<IUser>, void>({
                query: (query) => ({
                    url: `${BASE}/access`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "User" as const, id })),
                              { type: "User", id: "User-LIST" },
                          ]
                        : [{ type: "User", id: "User-LIST" }],
            }),
            createUserGrant: builder.mutation<any, IUserGrant>({
                query: (body) => ({
                    url: `${BASE}/grant`,
                    method: "POST",
                    body,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
        };
    },
});

export const {
    useGetListUserQuery,
    useGetListUserPaginateQuery,
    useGetListCustomerPaginateQuery,
    useGetListEmployeePaginateQuery,
    useLazyGetListUserPaginateQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useDeleteMultiUserMutation,
    useGetUserByIdQuery,
    useLazyGetUserByIdQuery,
    useGetListUserGrantQuery,
    useCreateUserGrantMutation,
} = adminUserApi;
