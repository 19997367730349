import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";
import queryString from "query-string";

const BASE = "/api/apply";

export const adminApplyApi = createApi({
    reducerPath: "adminApplyApi",
    keepUnusedDataFor: 30,
    tagTypes: ["Apply"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListApply: builder.query<Array<IApply>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "Apply" as const, id })),
                              { type: "Apply", id: "Apply-LIST" },
                          ]
                        : [{ type: "Apply", id: "Apply-LIST" }],
            }),
            getListApplyPaginate: builder.query<IResponseDataAdmin<IApply>, any>({
                query: (query) => ({
                    url: `${BASE}/paginate?` + queryString.stringify(query, { arrayFormat: "bracket" }),
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Apply" as const, id })),
                              { type: "Apply", id: "Apply-LIST" },
                          ]
                        : [{ type: "Apply", id: "Apply-LIST" }],
            }),
            createApply: builder.mutation<any, IApply>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Apply"],
            }),
            updateApply: builder.mutation<any, IApply>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Apply"],
            }),
            deleteApply: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Apply"],
            }),
            deleteMultiApply: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Apply"],
            }),
            getApplyById: builder.query<IApply, { id: string }>({
                query: (params) => {
                    return {
                        url: `${BASE}/${params.id}`,
                        method: "GET",
                    };
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            paidPayment: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/payment/paid/${params.id}`,
                    method: "PATCH",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Apply"],
            }),
            createPayment: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/payment/${params.applyId}`,
                    method: "POST",
                    body: _.omit(params, ["applyId"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Apply"],
            }),
            createApplyDiscuss: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/discuss`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Apply"],
            }),
            getApplyDiscuss: builder.query<any, any>({
                query: (applyId) => ({
                    url: `${BASE}/discuss/${applyId}`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
        };
    },
});

export const {
    useGetListApplyQuery,
    useGetListApplyPaginateQuery,
    useLazyGetListApplyPaginateQuery,
    useCreateApplyMutation,
    useUpdateApplyMutation,
    useDeleteApplyMutation,
    useDeleteMultiApplyMutation,
    useGetApplyByIdQuery,
    usePaidPaymentMutation,
    useLazyGetApplyByIdQuery,
    useCreatePaymentMutation,
    useCreateApplyDiscussMutation,
    useGetApplyDiscussQuery,
    useLazyGetApplyDiscussQuery,
} = adminApplyApi;
