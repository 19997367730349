import { Modal, Upload, UploadFile } from "antd";
import { FC, useState } from "react";

import { message } from "antd";
import { RcFile } from "antd/es/upload";
import { UploadProps } from "antd/lib";
import { useScholarshipOfferApplyMutation } from "@redux/queries/user/user.api";

interface ScholarshipOfferModalProps {
    open: boolean;
    applySelected: ApplyProcess | null;
    onCancel: () => void;
    refetchListApplies: () => void;
}

const ScholarshipOfferModal: FC<ScholarshipOfferModalProps> = ({
    open,
    applySelected,
    onCancel,
    refetchListApplies,
}) => {
    const [triggerScholarshipOfferApply, resultScholarshipOfferApply] = useScholarshipOfferApplyMutation();

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPngOrPdf) {
            message.error("Không đúng định dạng file yêu cầu");
            return isJpgOrPngOrPdf;
        }

        if (!isLt5M) {
            message.error("Kích thước file phải nhỏ hơn 5MB!");
            return isLt5M;
        }
    };

    const handleUploadFile = async ({ file, onError, onSuccess }: any) => {
        try {
            const formData = new FormData();
            formData.append("file", file);

            onSuccess();
        } catch (error) {
            console.log(error);
            message.error("Tải hình ảnh thất bại!");
            onError();
        }
    };

    const handleChange: UploadProps["onChange"] = (info) => {
        setFileList(info.fileList);
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj as RcFile, (url) => {});
        }
    };

    const handleSubmit = async () => {
        try {
            const response: any = await triggerScholarshipOfferApply({ id: applySelected?.id, fileList });

            if (response?.error) {
                return message.error("Báo đỗ thất bại");
            }

            refetchListApplies();
            message.success("Báo đỗ thành công");
            onCancel();
        } catch (error) {
            console.log(error);
            message.error("Báo đỗ thất bại");
        }
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            title={<div className="h4">Yêu cầu xác minh</div>}
            okText="Upload"
            width={600}
            onOk={handleSubmit}
        >
            <div>
                <p className="mb-2">
                    Chúc mừng bạn đã đỗ{" "}
                    <span className="fw-bold">
                        {applySelected?.schoolScholarshipTraining?.schoolScholarship?.scholarship?.language?.[0]?.title}{" "}
                        - {applySelected?.school?.language?.[0]?.name}
                    </span>
                    . Vui lòng cung cấp tài liệu xác minh để được nhận quà từ hệ thống.
                </p>
                <div className="w-fit-content">
                    <Upload
                        multiple
                        listType="picture"
                        maxCount={5}
                        accept="image/png, image/jpeg"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        customRequest={handleUploadFile}
                        className="upload-scholaship-offer"
                    >
                        <div>
                            <button className="btn-blue mb-2">Upload File</button>
                        </div>
                    </Upload>
                </div>
            </div>
        </Modal>
    );
};

export default ScholarshipOfferModal;
