import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

const BASE = "/api/scholarship/policy";

export const adminScholarshipPolicyApi = createApi({
    reducerPath: "adminScholarshipPolicyApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["ScholarshipPolicy"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListScholarshipPolicy: builder.query<Array<IScholarshipPolicy>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListScholarshipPolicyPaginate: builder.query<
                IResponseDataAdmin<IScholarshipPolicy>,
                { page: number; limit: number }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: {
                        page: query.page,
                        limit: query.limit,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "ScholarshipPolicy" as const, id })),
                              { type: "ScholarshipPolicy", id: "ScholarshipPolicy-LIST" },
                          ]
                        : [{ type: "ScholarshipPolicy", id: "ScholarshipPolicy-LIST" }],
            }),
            createScholarshipPolicy: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: {
                        language: params.language,
                        value: params.value,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ScholarshipPolicy"],
            }),
            updateScholarshipPolicy: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        language: params.language,
                        value: params.value,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ScholarshipPolicy"],
            }),
            deleteScholarshipPolicy: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ScholarshipPolicy"],
            }),
            deleteMultiScholarshipPolicy: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ScholarshipPolicy"],
            }),
        };
    },
});

export const {
    useGetListScholarshipPolicyQuery,
    useGetListScholarshipPolicyPaginateQuery,
    useLazyGetListScholarshipPolicyPaginateQuery,
    useCreateScholarshipPolicyMutation,
    useUpdateScholarshipPolicyMutation,
    useDeleteScholarshipPolicyMutation,
    useDeleteMultiScholarshipPolicyMutation,
} = adminScholarshipPolicyApi;
