import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/contract";

export const contractUserApi = createApi({
    reducerPath: "contractUserApi",
    keepUnusedDataFor: 30,
    tagTypes: ["contractUser"],
    baseQuery: customFetchBase,
    endpoints(build) {
        return {
            getAllContractUsers: build.query<
                Array<IContractUser>,
                {
                    userId?: string;
                    schoolServiceTrainingId?: string;
                    contractNumber?: number;
                }
            >({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                    params: {
                        userId: query.userId,
                        schoolServiceTrainingId: query.schoolServiceTrainingId,
                        contractNumber: query.contractNumber,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListContractUsers: build.query<
                IResponseDataAdmin<IContractUser>,
                {
                    page: number;
                    limit: number;
                    userId?: string;
                    schoolServiceTrainingId?: string;
                    contractNumber?: number;
                }
            >({
                query: (query) => ({
                    url: `${BASE}/user`,
                    method: "GET",
                    params: {
                        page: query.page,
                        limit: query.limit,
                        userId: query.userId,
                        schoolServiceTrainingId: query.schoolServiceTrainingId,
                        contractNumber: query.contractNumber,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "contractUser" as const, id })),
                              { type: "contractUser", id: "contractUser-LIST" },
                          ]
                        : [{ type: "contractUser", id: "contractUser-LIST" }],
            }),
        };
    },
});

export const {
    useGetAllContractUsersQuery,
    useLazyGetAllContractUsersQuery,
    useGetListContractUsersQuery,
    useLazyGetListContractUsersQuery,
} = contractUserApi;
