import React from "react";
import { randomNum } from "@utils/common";
import renderHTML from "react-render-html";

interface Faq {
    index: number;
    question: string;
    answer: string;
    title: string;
}

const FaqItem: React.FunctionComponent<{ item: Faq }> = (props) => {
    const { index, question, answer, title } = props.item;

    return (
        <>
            <div className="accordion-item">
                <h2
                    className="accordion-header"
                    id={`heading-${index}`}
                >
                    <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${index}`}
                        aria-expanded="true"
                        aria-controls={`collapse-${index}`}
                    >
                        {title}
                    </button>
                </h2>
                <div
                    id={`collapse-${index}`}
                    className="accordion-collapse collapse show"
                    aria-labelledby={`heading-${index}`}
                    data-bs-parent="#faq"
                >
                    {question && (
                        <div className="accordion-body render-content-html">
                            <b className="text-blue me-2">Question:</b>
                            {renderHTML(question)}
                        </div>
                    )}

                    {answer && (
                        <div className="accordion-body render-content-html">
                            <b className="me-2">Answer:</b> {renderHTML(answer)}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FaqItem;
