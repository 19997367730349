import { useUpdateUserProfileMutation, useUserProfileQuery } from "@redux/queries/user/user.api";
import { authSelector, setUserProfile } from "@redux/slices/auth.slice";
import { message } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import "./styles.css";
import { NumericFormat } from "react-number-format";
import { HSKK_MAX, HSK_MAX } from "@pages/admin/users/user.const";
import CopyToClipboard from "react-copy-to-clipboard";
import DailyCheckin from "./DailyCheckin";
import ListPost from "./TabMyPost/ListPost";
import FormAddPost from "./TabMyPost/FormAddPost";

interface TabProfileProps {}

export enum ACTIVE_TAB {
    LIST_POST = 1,
    ADD_POST = 2,
}

const { LIST_POST, ADD_POST } = ACTIVE_TAB;

const TabProfile: React.FunctionComponent<TabProfileProps> = () => {
    const { user } = useSelector(authSelector);
    const linkReferral = `https://cms.studyinchina.io/?refid=${user?.code}`;

    const { data, isSuccess } = useUserProfileQuery();
    const [triggerUpdate, result] = useUpdateUserProfileMutation();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState<ACTIVE_TAB>(LIST_POST);
    const [blogSelected, setBlogSelected] = useState<UserBlog | SchoolNews | null>(null);

    const handleChangeTab = () => {
        if (activeTab === LIST_POST) {
            setActiveTab(ADD_POST);
            return;
        }

        setActiveTab(LIST_POST);
    };

    const handleClickEditBlog = (blog: UserBlog | SchoolNews) => {
        setBlogSelected(blog);
        setActiveTab(ADD_POST);
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            avatar: "",
            phone: "",
            gender: "",
            birthday: "",
            gpaCoefficients: "",
            gpaMark: "",
            hskLevel: "",
            hskMark: "",
            hskkLevel: "",
            hskkMark: "",
            ielts: "",
            certificate: "",
            exCertificate: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().max(15, "Tên không được quá 15 kí tự").required("Không được bỏ trống Tên"),
            lastName: Yup.string().max(20, "Họ không được quá 20 kí tự").required("Không được bỏ trống Họ"),
            birthday: Yup.date()
                .max(new Date(), "Ngày sinh phải là ngày trong quá khứ")
                .required("Không được bỏ trống Ngày sinh"),
        }),
        onSubmit: async (values) => {
            try {
                const result = await triggerUpdate({
                    firstName: values.firstName?.trim(),
                    lastName: values.lastName?.trim(),
                    avatar: values.avatar?.trim(),
                    phone: values.phone,
                    gender: values.gender as any,
                    birthday: values.birthday,
                    profile: {
                        Gpa: {
                            coefficients: values.gpaCoefficients as any,
                            mark: values.gpaMark as any,
                        },
                        Hsk: {
                            level: values.hskLevel as any,
                            mark: values.hskMark as any,
                        },
                        Hskk: {
                            level: values.hskkLevel as any,
                            mark: values.hskkMark as any,
                        },
                        ielts: values.ielts as any,
                        certificate: values.certificate as any,
                        exCertificate: values.exCertificate as any,
                    },
                }).unwrap();
                if (result.status) {
                    message.success("Cập nhật thành công!");
                    setIsUpdate(false);
                }
            } catch (error) {
                console.log(error);
                message.error("Cập nhật lỗi!");
            }
        },
    });

    useEffect(() => {
        if (data) {
            dispatch(setUserProfile(data as any));
            const profile = data?.profile as any;
            const firstName = data?.firstName;
            const lastName = data?.lastName;
            const avatar = data?.avatar;
            const phone = data?.phone;
            const gender = data?.gender;
            const birthday = data?.birthday;
            const gpaCoefficients = profile?.Gpa?.coefficients || 10;
            const gpaMark = profile?.Gpa?.mark || 0;
            const hskLevel = profile?.Hsk?.level || "HSK1";
            const hskMark = profile?.Hsk?.mark || 0;
            const hskkLevel = profile?.Hskk?.level || "HSKK_BEGINNER";
            const hskkMark = profile?.Hskk?.mark || 0;
            const ielts = profile?.ielts || 0;
            const certificate = profile?.certificate || 0;
            const exCertificate = profile?.exCertificate || 0;
            formik.setValues({
                firstName,
                lastName,
                avatar,
                phone,
                gender,
                birthday,
                gpaCoefficients,
                gpaMark,
                hskLevel,
                hskMark,
                hskkLevel,
                hskkMark,
                ielts,
                certificate,
                exCertificate,
            } as any);
        }
    }, [data, isSuccess]);

    return (
        <div
            className={`tab-pane fade ${location.hash === "#profile-tab" ? "show active" : ""}`}
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
        >
            {!isUpdate ? (
                <div className="row">
                    <div className="mb-2 d-flex justify-content-between">
                        <button
                            onClick={() => {
                                if (!isUpdate) {
                                    setIsUpdate(true);
                                } else {
                                    formik.submitForm();
                                }
                            }}
                            className="btn btn-blue"
                        >
                            {isUpdate ? "Cập nhật" : "Chỉnh sửa"}
                        </button>
                        <button
                            className="btn btn-blue"
                            onClick={handleChangeTab}
                        >
                            {activeTab === LIST_POST ? "Tạo bài viết mới" : "Danh sách bài viết"}
                        </button>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="about-me">
                            <p className="h6 pb-1 mb-0 fw-bold border-0">Thông tin cá nhân</p>
                            <ul className="mb-0">
                                <li>
                                    <i className="bi-file-earmark-person"></i>Tên: {data?.firstName} {data?.lastName}
                                </li>

                                <li>
                                    <i className="bi-telephone-forward-fill"></i> Số điện thoại: {data?.phone}
                                </li>

                                <li>
                                    <i className="bi-gender-ambiguous"></i> Giới tính: {data?.gender}
                                </li>
                                <li>
                                    <i className="bi-calendar-fill"></i> Ngày sinh: {data?.birthday}
                                </li>
                                <hr />
                                <p className="h6 pb-1 mb-0 fw-bold border-0">Thông tin học vấn</p>
                                <li>
                                    <i className="bi-1-circle-fill"></i> Điểm GPA: {data?.profile?.Gpa?.mark || "--"}/
                                    {data?.profile?.Gpa?.coefficients || "--"}
                                </li>
                                <li>
                                    <i className="bi-2-circle-fill"></i> Thành tích HSK:{" "}
                                    {data?.profile?.Hsk?.mark || "--"} / {data?.profile?.Hsk?.level || "--"}
                                </li>
                                <li>
                                    <i className="bi-3-circle-fill"></i> Thành tích HSKK:{" "}
                                    {data?.profile?.Hskk?.mark || "--"} / {data?.profile?.Hskk?.level || "--"}
                                </li>
                                <li>
                                    <i className="bi-4-circle-fill"></i> Ielts: {data?.profile?.ielts || "--"}
                                </li>
                                <li>
                                    <i className="bi-5-circle-fill"></i> Bằng/Giấy khen:{" "}
                                    {data?.profile?.certificate || "--"}
                                </li>
                                <li className="mb-0">
                                    <i className="bi-6-circle-fill"></i> Chứng chỉ hoạt động ngoại khóa:{" "}
                                    {data?.profile?.exCertificate || "--"}
                                </li>
                            </ul>
                        </div>
                        {/* ******** BEGIN: Link Referral ******** */}
                        <div className="box-component bg-blue link-referral">
                            <div className="body-component">
                                <div className="title-element">
                                    <h5>Link giới thiệu</h5>
                                    <hr />
                                </div>
                                <form className="d-flex">
                                    <input
                                        className="form-control me-2"
                                        type="text"
                                        value={linkReferral}
                                    />
                                    <CopyToClipboard
                                        text={linkReferral}
                                        onCopy={() => message.success("Copied")}
                                    >
                                        <button
                                            className="btn btn-white"
                                            type="button"
                                        >
                                            Copy
                                        </button>
                                    </CopyToClipboard>
                                </form>
                            </div>
                        </div>
                        {/* ******** END: Link Referral ******** */}

                        <DailyCheckin />
                    </div>
                    <div className="col-12 col-sm-8">
                        <div className="box-item-element">
                            <div className="body-element">
                                <div className={activeTab !== ADD_POST ? "d-none" : ""}>
                                    <FormAddPost
                                        activeTab={activeTab}
                                        blogSelected={blogSelected}
                                        setBlogSelected={setBlogSelected}
                                        setActiveTab={setActiveTab}
                                    />
                                </div>
                                <div className={activeTab !== LIST_POST ? "d-none" : ""}>
                                    <ListPost handleClickEditBlog={handleClickEditBlog} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="box-item-element">
                    <div className="title-element">
                        <h5>
                            <div className="d-flex align-items-center gap-2">
                                <button
                                    className="btn btn-blue"
                                    onClick={() => {
                                        setIsUpdate(false);
                                    }}
                                >
                                    Quay lại
                                </button>
                                <button
                                    onClick={() => {
                                        if (!isUpdate) {
                                            setIsUpdate(true);
                                        } else {
                                            formik.submitForm();
                                        }
                                    }}
                                    className="btn btn-blue"
                                >
                                    {isUpdate ? "Cập nhật" : "Chỉnh sửa"}
                                </button>
                            </div>
                            {/* <span className="d-block pt-2">Thông tin cá nhân</span> */}
                        </h5>
                        <hr />
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="body-element">
                            <div className="row">
                                <div className="col-6 col-lg-4">
                                    <div className="form-group form-style">
                                        <label>Tên</label>
                                        <input
                                            readOnly={!isUpdate}
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            id="firstName"
                                            name="firstName"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.firstName}
                                        />
                                        {formik.touched.firstName && formik.errors.firstName ? (
                                            // @ts-ignore
                                            <p className="text-red">{formik?.errors?.firstName}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 col-lg-4">
                                    <div className="form-group form-style">
                                        <label>Họ và đệm</label>
                                        <input
                                            readOnly={!isUpdate}
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            id="lastName"
                                            name="lastName"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.lastName}
                                        />
                                        {formik.touched.lastName && formik.errors.lastName ? (
                                            // @ts-ignore
                                            <p className="text-red">{formik?.errors?.lastName}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 col-lg-4">
                                    <div className="form-group form-style">
                                        <label>Số điện thoại</label>
                                        <input
                                            min="0"
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            id="phone"
                                            name="phone"
                                            readOnly={!isUpdate}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phone}
                                        />

                                        {formik.touched.phone && formik.errors.phone ? (
                                            // @ts-ignore
                                            <p className="text-red">{formik?.errors?.phone}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group form-style">
                                        <label>Giới tính</label>
                                        <select
                                            disabled={!isUpdate}
                                            id="gener"
                                            name="gender"
                                            className="form-select"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.gender}
                                        >
                                            <option value={"Male"}>Nam</option>
                                            <option value={"Female"}>Nữ</option>
                                            {/* <option value={"Other"}>{t("page_register_gender_other", "Khác")}</option> */}
                                        </select>
                                        {formik.touched.gender && formik.errors.gender ? (
                                            // @ts-ignore
                                            <p className="text-red">{formik?.errors?.gender}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group form-style">
                                        <label>Ngày sinh</label>
                                        <input
                                            readOnly={!isUpdate}
                                            name="birthday"
                                            id="birthday"
                                            type="date"
                                            max={new Date().toISOString().split("T")[0]}
                                            className="form-control position-relative"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.birthday}
                                        />
                                        {formik.touched.birthday && formik.errors.birthday ? (
                                            // @ts-ignore
                                            <p className="text-red">{formik?.errors?.birthday}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-item-element mt-5">
                            <div className="title-element">
                                <h5>Thông tin học vấn</h5>
                                <hr />
                            </div>
                            <div className="body-element">
                                <div className="row">
                                    <div className="form-group form-style">
                                        <label>Điểm GPA</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <select
                                                    disabled={!isUpdate}
                                                    id="gpaCoefficients"
                                                    name="gpaCoefficients"
                                                    className="form-select"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.gpaCoefficients}
                                                    defaultValue={10}
                                                >
                                                    <option value={10}>Hệ số 10</option>
                                                    <option value={4}>Hệ số 4</option>
                                                </select>
                                            </div>
                                            <div className="col-6">
                                                <NumericFormat
                                                    allowNegative={false}
                                                    thousandSeparator
                                                    readOnly={!isUpdate}
                                                    className="form-control"
                                                    placeholder="Điểm"
                                                    id="gpaMark"
                                                    name="gpaMark"
                                                    onValueChange={(e) => {
                                                        formik.setFieldValue("gpaMark", e.floatValue);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.gpaMark}
                                                    isAllowed={(values) => {
                                                        const { floatValue } = values;
                                                        return floatValue
                                                            ? floatValue <= Number(formik.values.gpaCoefficients)
                                                            : true;
                                                    }}
                                                />

                                                {/* <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Điểm"
                                                id="gpaMark"
                                                name="gpaMark"
                                                readOnly={!isUpdate}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.gpaMark}
                                            /> */}
                                                {formik.touched.gpaMark && formik.errors.gpaMark ? (
                                                    // @ts-ignore
                                                    <p className="text-red">{formik?.errors?.gpaMark}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group form-style">
                                        <label>Thành tích HSK</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <select
                                                    disabled={!isUpdate}
                                                    className="form-select"
                                                    id="hskLevel"
                                                    name="hskLevel"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.hskLevel}
                                                >
                                                    <option value="HSK1">HSK1</option>
                                                    <option value="HSK2">HSK2</option>
                                                    <option value="HSK3">HSK3</option>
                                                    <option value="HSK4">HSK4</option>
                                                    <option value="HSK5">HSK5</option>
                                                    <option value="HSK6">HSK6</option>
                                                </select>
                                                {formik.touched.hskLevel && formik.errors.hskLevel ? (
                                                    // @ts-ignore
                                                    <p className="text-red">{formik?.errors?.hskLevel}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-6">
                                                <NumericFormat
                                                    allowNegative={false}
                                                    thousandSeparator
                                                    readOnly={!isUpdate}
                                                    className="form-control"
                                                    placeholder="Điểm"
                                                    id="hskMark"
                                                    name="hskMark"
                                                    onValueChange={(e) => {
                                                        formik.setFieldValue("hskMark", e.floatValue);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.hskMark}
                                                    isAllowed={(values) => {
                                                        const { floatValue } = values;
                                                        return floatValue ? floatValue <= HSK_MAX : true;
                                                    }}
                                                />
                                                {formik.touched.hskMark && formik.errors.hskMark ? (
                                                    // @ts-ignore
                                                    <p className="text-red">{formik?.errors?.hskMark}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group form-style">
                                        <label>Thành tích HSKK</label>
                                        <div className="row">
                                            <div className="col-6">
                                                <select
                                                    disabled={!isUpdate}
                                                    className="form-select"
                                                    id="hskkLevel"
                                                    name="hskkLevel"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.hskkLevel}
                                                >
                                                    <option value="HSKK_BEGINNER">Sơ cấp</option>
                                                    <option value="HSKK_INTERMEDIATE">Trung cấp</option>
                                                    <option value="HSKK_ADVANCED">Cao cấp</option>
                                                </select>
                                                {formik.touched.hskkLevel && formik.errors.hskkLevel ? (
                                                    // @ts-ignore
                                                    <p className="text-red">{formik?.errors?.hskkLevel}</p>
                                                ) : null}
                                            </div>
                                            <div className="col-6">
                                                <NumericFormat
                                                    allowNegative={false}
                                                    thousandSeparator
                                                    readOnly={!isUpdate}
                                                    className="form-control"
                                                    placeholder="Điểm"
                                                    id="hskkMark"
                                                    name="hskkMark"
                                                    onValueChange={(e) => {
                                                        formik.setFieldValue("hskkMark", e.floatValue);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.hskkMark}
                                                    isAllowed={(values) => {
                                                        const { floatValue } = values;
                                                        return floatValue ? floatValue <= HSKK_MAX : true;
                                                    }}
                                                />
                                                {formik.touched.hskkMark && formik.errors.hskkMark ? (
                                                    // @ts-ignore
                                                    <p className="text-red">{formik?.errors?.hskkMark}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group form-style">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group form-style">
                                                    <label>Ielts</label>

                                                    <NumericFormat
                                                        allowNegative={false}
                                                        thousandSeparator
                                                        readOnly={!isUpdate}
                                                        className="form-control"
                                                        placeholder="Điểm"
                                                        id="ielts"
                                                        name="ielts"
                                                        onValueChange={(e) => {
                                                            formik.setFieldValue("ielts", e.floatValue);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.ielts}
                                                        isAllowed={(values) => {
                                                            const { floatValue } = values;
                                                            return floatValue ? floatValue <= 9 : true;
                                                        }}
                                                    />

                                                    {formik.touched.ielts && formik.errors.ielts ? (
                                                        // @ts-ignore
                                                        <p className="text-red">{formik?.errors?.ielts}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group form-style">
                                                    <label>Bằng/Giấy khen </label>

                                                    <NumericFormat
                                                        allowNegative={false}
                                                        thousandSeparator
                                                        readOnly={!isUpdate}
                                                        className="form-control"
                                                        placeholder="Điểm"
                                                        id="certificate"
                                                        name="certificate"
                                                        onValueChange={(e) => {
                                                            formik.setFieldValue("certificate", e.floatValue);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.certificate}
                                                    />
                                                    {formik.touched.certificate && formik.errors.certificate ? (
                                                        // @ts-ignore
                                                        <p className="text-red">{formik?.errors?.certificate}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group form-style">
                                                    <label>Chứng chỉ hoạt động ngoại khóa:</label>

                                                    <NumericFormat
                                                        allowNegative={false}
                                                        thousandSeparator
                                                        readOnly={!isUpdate}
                                                        className="form-control"
                                                        placeholder="Điểm"
                                                        id="exCertificate"
                                                        name="exCertificate"
                                                        onValueChange={(e) => {
                                                            formik.setFieldValue("exCertificate", e.floatValue);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.exCertificate}
                                                    />
                                                    {formik.touched.exCertificate && formik.errors.exCertificate ? (
                                                        // @ts-ignore
                                                        <p className="text-red">{formik?.errors?.exCertificate}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default TabProfile;
