import Nodata from "@components/Nodata";
import { Pagination } from "@components/pagination";
import { useGetPaginateUserPaymentQuery } from "@redux/queries/user/payment.api";
import { Spin } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { formatNumber } from "@utils/common";

const LIMIT = 10;
const TableUserPayment = () => {
    const [page, setPage] = useState(1);
    const { data, isLoading } = useGetPaginateUserPaymentQuery({
        page: page,
        limit: LIMIT,
    });

    // <span className="text-box-gold">Paid</span>
    // <span className="text-box-gray">Paid</span>
    // <span className="text-box-blue">Paid</span>
    // <span className="text-box-red">Paid</span>
    // <span className="text-box-green">Paid</span>

    const getDepositStatus = (status: "PENDING" | "REJECT" | "DONE" | "CANCEL") => {
        const statusGroup = {
            PENDING: <span className="text-box-gold">Đang xử lý</span>,
            REJECT: <span className="text-box-red">Thất bại</span>,
            DONE: <span className="text-box-green">Hoàn thành</span>,
            CANCEL: <span className="text-box-gray">Huỷ</span>,
        };
        return statusGroup[status];
    };

    return (
        <div className="table-responsive">
            <Spin spinning={isLoading}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th className="w-25">Note</th>
                            <th className="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.items?.map((e: any, i) => (
                            <tr key={i}>
                                <td>#{(data?.meta?.currentPage - 1) * data?.meta?.itemsPerPage + i + 1} </td>
                                <td>{moment(e.date).format("DD/MM/YYYY HH:MM:ss")}</td>
                                <td>
                                    <b>{formatNumber(e.amount)}</b>
                                </td>
                                <td className="w-25">{e.note}</td>
                                <td className="text-center">{getDepositStatus(e.status)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {data?.meta?.totalItems === 0 && <Nodata />}

                <div className="row group-sub-action">
                    {(data?.meta?.totalItems || 0) > 0 && (
                        <div className="d-flex justify-content-center mt-4">
                            <Pagination
                                defaultSize={LIMIT}
                                onSizeChange={(size) => {}}
                                total={data?.meta.totalItems || 0}
                                showSize={false}
                                totalPage={data?.meta.totalPages || 0}
                                onChangePage={setPage}
                                defaultCurrentPage={1}
                                listSize={[12, 24, 50, 100]}
                            />
                        </div>
                    )}
                </div>
            </Spin>
        </div>
    );
};

export default TableUserPayment;
