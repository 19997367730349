import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useCreateApplyProcessMutation, useUpdateApplyProcessMutation } from "@redux/queries/admin/admin.apply.process";
import { Form, Input, Modal, Select } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { listApplyProcessStatus } from "../apply-process.const";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createApplyProcess, isLoadingCreate] = useCreateApplyProcessMutation();
    const [updateApplyProcess, isLoadingUpdate] = useUpdateApplyProcessMutation();
    const { showToast } = useToast();

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue({
                status: dataEdit?.status,
                description: dataEdit?.description,
            });
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updateApplyProcess({
                  id: dataEdit.id,
                  ...values,
                  expiredAt: moment(values.expiredAt).toISOString(),
              })
            : await createApplyProcess({
                  ...values,
                  expiredAt: moment(values.expiredAt).toISOString(),
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Apply Process" : "Add Apply Process"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="description"
                                className="form-floating"
                            >
                                <Input.TextArea
                                    style={{ height: 100 }}
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Mô tả</label>
                        </div>
                    </div>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
