import Nodata from "@components/Nodata";
import Blog from "@pages/dashboard/UserDashboard/components/Blog";
import { useGetSchoolNewsHomeQuery } from "@redux/queries/user/intro.api";
import { Spin } from "antd";
import { FC } from "react";

const PAGE = 1;
const LIMIT = 4;

const PopularNews: FC = () => {
    const { data, isLoading } = useGetSchoolNewsHomeQuery({ page: PAGE, limit: LIMIT });

    const { items: listSchoolNews, meta } = data || {};
    const { totalItems = 0 } = meta || {};

    return (
        <div className="four-blog">
            <div className="title-element">
                <h5>Bài viết được quan tâm</h5>
                <hr />
            </div>
            <Spin spinning={isLoading}>
                <div className="row">
                    {listSchoolNews && totalItems > 0 ? (
                        listSchoolNews?.map(
                            ({ id, createdAt, language, slug, thumbnail, view, like, creator }, index) => (
                                <div
                                    className="col-12 col-sm-6 col-lg-3 d-flex"
                                    key={index}
                                >
                                    <Blog
                                        viewMode="card"
                                        linkType="news"
                                        id={id}
                                        createdAt={createdAt}
                                        title={language?.[0]?.title}
                                        shortContent={language?.[0]?.shortContent}
                                        slug={slug}
                                        thumbnail={thumbnail}
                                        view={view}
                                        like={like}
                                        userName={
                                            creator?.firstName
                                                ? creator?.firstName + " " + creator?.lastName
                                                : creator?.userName
                                        }
                                        avatar={creator?.avatar}
                                    />
                                </div>
                            )
                        )
                    ) : (
                        <Nodata />
                    )}
                </div>
            </Spin>
        </div>
    );
};

export default PopularNews;
