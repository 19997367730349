import { Pagination } from "@components/pagination";
import { useGetListCustomerPaginateQuery } from "@redux/queries/admin/admin.user";
import { Avatar, Button, Checkbox, CheckboxOptionType, Modal, Select, Space, Table, Tag, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterUser from "./components/FilterUser";
import { listApplyStatus } from "../apply/apply.enum";
import { Link } from "react-router-dom";
import { listApplyPaymentStatus } from "../apply-payment/apply-payment.const";
import HandleForm from "../users/components/HanleForm";
import { listSchoolServiceType } from "../school-service/school-service.const";
import FormGrant from "./components/FormGrant";
import useSessionStorageState from "use-session-storage-state";

const { Text } = Typography;

const User: React.FunctionComponent = () => {
    const [modal, contextHolder] = Modal.useModal();

    const [detailId, setDetailID] = useState<string | null>(null);
    const [customerId, setCustomerId] = useState();
    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [openFormGrant, setOpenFormGrant] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const columns = [
        {
            title: <div className="text-title-table">STT</div>,
            key: "index",
            dataIndex: "code",
            width: 75,
            render: (text: any, record: any, index: number) => {
                return <span>{(query.page - 1) * query.limit + index + 1}</span>;
            },
        },
        {
            title: <div className="text-title-table">Tên</div>,
            key: "firstName",
            dataIndex: "firstName",
            render: (firstName: any, record: any) => {
                return (
                    <Space>
                        <Avatar src={record.avatar}>{firstName?.charAt(0)}</Avatar>
                        <Text>{firstName + " - " + record?.lastName || ""}</Text>
                    </Space>
                );
            },
            width: 200,
        },
        {
            title: <div className="text-title-table">Mã</div>,
            key: "code",
            dataIndex: "code",
            width: 100,
        },
        {
            title: <div className="text-title-table">Số đơn apply</div>,
            key: "apply",
            dataIndex: "apply",
            width: 100,
            render: (apply: any) => {
                return apply?.length;
            },
        },
        {
            title: <div className="text-title-table">Số coin</div>,
            key: "coin",
            dataIndex: "coin",
            width: 100,
            render: (coin: any) => {
                return Number(coin).toLocaleString("en-US");
            },
        },
        {
            title: <div className="text-title-table">Ngày sinh</div>,
            key: "birthday",
            dataIndex: "birthday",
            render: (birthday: any) => {
                return birthday ? moment(birthday).format("DD/MM/YYYY") : "";
            },
            width: 120,
        },
        {
            title: <div className="text-title-table">Tuổi</div>,
            key: "old",
            dataIndex: "birthday",
            render: (birthday: any) => {
                return birthday ? moment().diff(birthday, "years") : "";
            },
            width: 60,
        },
        {
            title: <div className="text-title-table">Email</div>,
            key: "email",
            dataIndex: "email",
            width: 170,
        },
        {
            title: <div className="text-title-table">Số điện thoại</div>,
            key: "phone",
            dataIndex: "phone",
            width: 120,
        },
        {
            title: <div className="text-title-table">Gói dịch vụ</div>,
            key: "schoolServiceType",
            dataIndex: "apply",
            width: 170,
            render: (apply: any) => {
                const list = apply?.map((item: any) => {
                    const type = listSchoolServiceType.find((i) => i.value === item?.contract?.schoolService?.type);
                    return type;
                });
                const unique = _.uniqBy(
                    list.filter((item: any) => !!item),
                    "value"
                );
                return unique?.map((item: any) => {
                    return (
                        <Tag
                            key={item?.value}
                            color={item?.color}
                        >
                            {item?.label}
                        </Tag>
                    );
                });
            },
        },
        {
            title: <div className="text-title-table">Phí dịch vụ</div>,
            key: "amount",
            dataIndex: "apply",
            width: 170,
            render: (apply: any) => {
                return apply?.map((item: any) => {
                    return item?.contract?.amount ? (
                        <Tag key={item?.contract?.id}>{Number(item?.contract?.amount).toLocaleString("en-US")}</Tag>
                    ) : null;
                });
            },
        },
        {
            title: <div className="text-title-table">Loại học bổng</div>,
            key: "scholarshipType",
            dataIndex: "apply",
            width: 170,
            render: (apply: any) => {
                const list = apply?.map((item: any) => {
                    return {
                        id: item?.schoolScholarshipTraining?.schoolScholarship.scholarshipType._id,
                        title: item?.schoolScholarshipTraining?.schoolScholarship.scholarshipType.language[0]?.title,
                    };
                });
                const unique = _.uniqBy(list, "id");
                return unique?.map((item: any) => {
                    return <Tag key={item.id}>{item.title}</Tag>;
                });
            },
        },
        {
            title: <div className="text-title-table">Hệ đào tạo</div>,
            key: "training",
            dataIndex: "apply",
            width: 170,
            render: (apply: any) => {
                return apply?.map((item: any) => {
                    return (
                        <Tag key={item?.contract?.schoolService?.training._id}>
                            {item?.contract?.schoolService?.training.language[0]?.title}
                        </Tag>
                    );
                });
            },
        },
        {
            title: <div className="text-title-table">Khu vực muốn đi</div>,
            key: "area-city",
            dataIndex: "apply",
            width: 170,
            render: (apply: any) => {
                return apply?.map((item: any) => {
                    const city = item?.school?.city.language[0]?.title;
                    const area = item?.school?.area.language[0]?.title;
                    return <Tag key={item?.school?.id}>{`${city} - ${area}`}</Tag>;
                });
            },
        },
        {
            title: <div className="text-title-table">Chuyên ngành muốn apply</div>,
            key: "applySpecializeds",
            dataIndex: "apply",
            width: 170,
            render: (apply: any) => {
                return apply?.map((item: any) => {
                    return item?.applySpecializeds?.map((item: any) => {
                        return <Tag key={item.id}>{item.language[0]?.title}</Tag>;
                    });
                });
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <button
                        type="button"
                        className="text-green"
                        onClick={() => {
                            setOpenFormGrant(true);
                            setCustomerId(record.id);
                        }}
                    >
                        <i className="bi bi-person-fill-add fs-3"></i>
                    </button>
                );
            },
        },
    ];

    const defaultCheckedList = columns.map((item) => item.key as string);
    const [checkedList, setCheckedList] = useSessionStorageState("customer_fields", {
        defaultValue: defaultCheckedList,
    });
    const [dynamicColumns, setDynamicColumns] = useState<any>(columns);

    useEffect(() => {
        const newColumns = columns.filter((item) => checkedList.includes(item.key));
        setDynamicColumns(newColumns);
    }, [checkedList]);

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching } = useGetListCustomerPaginateQuery(query as any);

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const applyColumns = [
        {
            title: <div className="text-title-table">Khách hàng</div>,
            key: "userId",
            dataIndex: "user",
            render: (user: IUser, record: any) => {
                const userName = user ? `${user?.firstName} ${user?.lastName}` : "";
                return <Link to={`/admin/apply/edit/${record?.id}`}>{userName}</Link>;
            },
        },
        {
            title: <div className="text-title-table">Trường</div>,
            key: "schoolId",
            dataIndex: "school",
            render: (school: IAdminSchool, record: any) => {
                return school?.language[0]?.name || "";
            },
        },
        {
            title: <div className="text-title-table">Ngành học</div>,
            key: "majorId",
            dataIndex: "major",
            render: (major: IMajor, record: any) => {
                return major?.language[0]?.title || "";
            },
        },
        {
            title: <div className="text-title-table">Chuyên ngành</div>,
            key: "applySpecializeds",
            dataIndex: "applySpecializeds",
            render: (applySpecializeds: ISpecialized[], record: any) => {
                return applySpecializeds?.map((item) => <Tag key={item.id}>{item.language[0]?.title}</Tag>);
            },
        },
        {
            title: <div className="text-title-table">Năm / Giai đoạn</div>,
            dataIndex: "year",
            key: "year",
            render: (year: number, record: any) => {
                return `${year} / ${record.period}`;
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            dataIndex: "status",
            key: "status",
            render: (status: string) => {
                const statusItem = listApplyStatus.find((item) => item.value === status);
                return <Tag color={statusItem?.color}>{statusItem?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
    ];

    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
    }));

    const applyPaymentColumns = [
        {
            title: <div className="text-title-table">Số tiền</div>,
            key: "amount",
            dataIndex: "amount",
            render: (amount: string) => {
                return Number(amount).toLocaleString("en-US");
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Ngày hết hạn</div>,
            key: "expiredAt",
            dataIndex: "expiredAt",
            render: (expiredAt: any) => {
                return moment(expiredAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            dataIndex: "status",
            key: "status",
            render: (status: string) => {
                const statusItem = listApplyPaymentStatus.find((item) => item.value === status);
                return <Tag color={statusItem?.color}>{statusItem?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterUser
                                        data={filter}
                                        onSearch={(d: any) => setFilter(d)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            {/* <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button> */}
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <Select
                                mode="multiple"
                                value={checkedList}
                                options={options}
                                onChange={(value: any) => {
                                    setCheckedList(value as string[]);
                                }}
                                style={{ width: "90%", marginBottom: 20 }}
                            />
                            <div className="table-responsive">
                                <Table
                                    // rowSelection={rowSelection}
                                    columns={dynamicColumns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 2300 }}
                                    expandable={{
                                        expandedRowRender: (record: any) => {
                                            return (
                                                <div className="table-responsive">
                                                    <Table
                                                        columns={applyColumns}
                                                        dataSource={(record.apply as any) || []}
                                                        pagination={false}
                                                        loading={isLoading || isFetching}
                                                        scroll={{ x: 1500 }}
                                                        expandable={{
                                                            expandedRowRender: (record) => {
                                                                return (
                                                                    <div className="table-responsive">
                                                                        <Typography.Title level={5}>
                                                                            Thông tin thanh toán
                                                                        </Typography.Title>
                                                                        <Table
                                                                            columns={applyPaymentColumns}
                                                                            dataSource={
                                                                                (record.contract.payments as any) || []
                                                                            }
                                                                            pagination={false}
                                                                            loading={isLoading || isFetching}
                                                                            scroll={{ x: 1500 }}
                                                                        />
                                                                    </div>
                                                                );
                                                            },
                                                            rowExpandable: (record) =>
                                                                record.contract &&
                                                                record.contract.payments &&
                                                                record.contract.payments.length > 0,
                                                        }}
                                                    />
                                                </div>
                                            );
                                        },
                                        rowExpandable: (record) => record.apply && record.apply.length > 0,
                                    }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
                <FormGrant
                    openFormGrant={openFormGrant}
                    setOpenFormGrant={(b: boolean) => setOpenFormGrant(b)}
                    customerId={customerId}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default User;
