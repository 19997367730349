import ModalCustome from "@components/modal/Modal";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useUpdateProjectMutation } from "@redux/queries/admin/admin.project";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

interface UpdateProjectProps {
    data: IProject | null;
    onClose: () => void;
}

const UpdateProject: React.FunctionComponent<UpdateProjectProps> = (props) => {
    const { data } = props;
    const [updateProject, result] = useUpdateProjectMutation();
    const [open, setOpen] = useState<boolean>(false);
    const closeModal = () => {
        setOpen(false);
        props.onClose();
    };
    const openModal = () => setOpen(true);

    const { showToast } = useToast();

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            description: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Không được để trống"),
            description: Yup.string().required("Không được để trống"),
        }),
        onSubmit: async (values) => {
            const result = await updateProject({
                id: values.id,
                name: values.name,
                description: values.description,
            });
            if ("data" in result) {
                closeModal();
                showToast({ ...TOAST_UPDATE_SUCCESS });
            }
            if ("error" in result) {
                showToast({ ...TOAST_UPDATE_ERROR });
            }
            // show error use toast
        },
    });

    useEffect(() => {
        if (data) {
            openModal();
            formik.setValues({
                id: data.id,
                name: data.name,
                description: data.description,
            });
        } else {
            closeModal();
        }
    }, [data]);

    return (
        <>
            <ModalCustome
                open={open}
                onClose={closeModal}
                title="Update Project"
                footer={[
                    <button
                        key="1"
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                        className="btn-blue"
                    >
                        {result.isLoading && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <span className="ps-2">Submit</span>
                    </button>,
                    <button
                        key="2"
                        type="button"
                        onClick={closeModal}
                        className="text-box-gray"
                    >
                        Close
                    </button>,
                ]}
            >
                <form>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            <label>Tên dự án</label>
                        </div>
                        {formik.touched.name && formik.errors.name ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.name}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                            />
                            <label>Mô Tả</label>
                        </div>
                        {formik.touched.description && formik.errors.description ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.description}</p>
                        ) : null}
                    </div>
                </form>
            </ModalCustome>
        </>
    );
};

export default UpdateProject;
