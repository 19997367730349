import {
    useFavoriteMutation,
    useLikeMutation,
    useUnfavoriteMutation,
    useUnlikeMutation,
    useUserSchoolfavoriteQuery,
    useUserSchoolLikeQuery,
} from "@redux/queries/user/authaction.api";
import { authSelector } from "@redux/slices/auth.slice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { formatNumber } from "@utils/common";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

interface ActionProps {
    views: number;
    favourite: number;
    like: number;
    onRefresh: () => void;
    id?: string;
}

const Action: React.FunctionComponent<ActionProps> = (props) => {
    const { views, favourite, like, onRefresh, id } = props;

    const navigate = useNavigate();
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);
    const { data: likeData } = useUserSchoolLikeQuery(isLogin ? (id ? { "schoolIds[1]": id } : skipToken) : skipToken);
    const { data: favoriteData } = useUserSchoolfavoriteQuery(
        isLogin ? (id ? { "schoolIds[1]": id } : skipToken) : skipToken
    );

    const [triggerLike, result] = useLikeMutation();
    const [triggerUnlike, resultUnlike] = useUnlikeMutation();

    const [triggerFavorite, resultFavorite] = useFavoriteMutation();
    const [triggerUnfavorite, resultUnfavorite] = useUnfavoriteMutation();

    const handleLike = async () => {
        if (!accessToken) {
            return;
        }
        const liked = likeData && likeData?.length > 0;
        let result;
        if (!liked) {
            result = await triggerLike({
                schoolId: id || "",
            });
        } else {
            result = await triggerUnlike({
                schoolId: id || "",
            });
        }
        if (!("error" in result)) {
            onRefresh();
        }
    };

    const handleFavorite = async () => {
        if (!accessToken) {
            return;
        }
        const liked = favoriteData && favoriteData?.length > 0;
        let result;
        if (!liked) {
            result = await triggerFavorite({
                schoolId: id || "",
            });
        } else {
            result = await triggerUnfavorite({
                schoolId: id || "",
            });
        }
        if (!("error" in result)) {
            onRefresh();
        }
    };

    return (
        <ul className="icon-list-item">
            <li>
                <button
                    type="button"
                    onClick={handleFavorite}
                >
                    <i
                        className={
                            favoriteData && favoriteData?.length > 0 ? "bi-heart-fill text-red" : "bi-heart-fill"
                        }
                    />
                    {formatNumber(favourite)}
                </button>
            </li>

            <li>
                <button
                    type="button"
                    onClick={handleLike}
                >
                    <i
                        className={
                            likeData && likeData?.length > 0
                                ? "bi-hand-thumbs-up-fill text-green cursor-pointer"
                                : "bi-hand-thumbs-up-fill cursor-pointer"
                        }
                    />
                    {formatNumber(like)}
                </button>
            </li>
            <li>
                <button
                    type="button"
                    onClick={() => navigate("/compare")}
                >
                    <i className="bi-arrow-left-right" />
                    PK
                </button>
            </li>
            <li>
                <button type="button">
                    <i className="bi-eye-fill" />
                    {formatNumber(views)}
                </button>
            </li>
        </ul>
    );
};

export default Action;
