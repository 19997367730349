import Nodata from "@components/Nodata";
import { Pagination } from "@components/pagination";
import ReviewItem from "@pages/pro-tool/schools/DetailSchool/components/TabReview/ReviewItem";
import { useGetListTicketsQuery, useUserReviewHistoryQuery } from "@redux/queries/user/user.api";
import { authSelector } from "@redux/slices/auth.slice";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TicketItem from "./TicketItem";
import { Spin } from "antd";
interface TabListTicketsProps {}
const LIMIT = 12;

const TabListTickets: React.FunctionComponent<TabListTicketsProps> = () => {
    const { user } = useSelector(authSelector);

    const { data: listTickets = [], isLoading } = useGetListTicketsQuery();

    return (
        <div
            className={`tab-pane fade ${location.hash === "#ticket-tab" ? "show active" : ""}`}
            id="ticket"
            role="tabpanel"
            aria-labelledby="ticket-tab"
        >
            <div className="box-item-element">
                <div className="title-element">
                    <h5>Phiếu hỗ trợ</h5>
                    <hr />
                </div>
                <Spin spinning={isLoading}>
                    <div className="body-element">
                        {(!listTickets || listTickets?.length === 0) && <Nodata />}

                        {listTickets?.map((ticket) => (
                            <TicketItem
                                id={ticket?.id || ""}
                                title={ticket?.title || ""}
                                avatar={ticket?.user?.avatar || ""}
                                time={ticket?.createdAt}
                                tag={ticket?.labels || []}
                                question={ticket?.question || ""}
                                rate={1}
                                key={ticket?.id}
                            />
                        ))}
                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default TabListTickets;
