import Nodata from "@components/Nodata";
import Post from "@pages/user/profile/components/TabMyPost/Post";
import { useSchoolNewsHomeMutation } from "@redux/queries/user/intro.api";
import { FC, useEffect } from "react";

const PAGE = 1;
const LIMIT = 5;

const RecentPost: FC = () => {
    const [fetch, { data, isLoading }] = useSchoolNewsHomeMutation();

    useEffect(() => {
        fetch({ page: PAGE, limit: LIMIT });
    }, []);

    const { items: listSchoolNews } = data || {};

    return (
        <div className="box-item-element mobile-article">
            <div className="title-element">
                <h5>Bài viết gần đây</h5>
                <hr />
            </div>
            <div className="body-element">
                {listSchoolNews && listSchoolNews?.length > 0 ? (
                    listSchoolNews?.map((item, index) => {
                        return (
                            <Post
                                key={index}
                                data={item}
                                title={item?.language?.[0]?.title}
                                shortContent={item?.language?.[0]?.shortContent}
                                link={`/news/${item?.id}`}
                            />
                        );
                    })
                ) : (
                    <Nodata />
                )}
            </div>
        </div>
    );
};

export default RecentPost;
