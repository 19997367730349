import { authSelector } from "@redux/slices/auth.slice";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import defaulAvartar from "@assets/images/img-default/avatar-lg.jpg";
import { formatNumber, linkCDN } from "@utils/common";
import { Form, Spin, Upload } from "antd";

import { useUpdateUserProfileMutation, useUploadAvatarMutation } from "@redux/queries/user/user.api";
import { history } from "@utils/helper-router";
import { RcFile } from "antd/es/upload";
import TabApplyProcess from "./components/TabApplyProcess";
import TabCoin from "./components/TabDeposit";
import TabNotification from "./components/TabNotification";
import TabPayment from "./components/TabPayment";
import TabProfile from "./components/TabProfile";
import TabReviewHistory from "./components/TabReviewHistory";
import TabListTickets from "./components/TabListTickets";

const Profile: React.FunctionComponent = () => {
    const { user } = useSelector(authSelector);

    const [uploadFile, resultUploadFile] = useUploadAvatarMutation();
    const [triggerUpdate, result] = useUpdateUserProfileMutation();

    const [activeTab, setActiveTab] = useState("#profile-tab");

    useEffect(() => {
        if (location.hash === "") {
            history.navigate("#profile-tab");
            setActiveTab("#profile-tab");
            return;
        }

        setActiveTab(location.hash);
    }, [location.hash]);

    const handleTabClick = (tabId: string) => {
        history.navigate(tabId);
    };

    const [form] = Form.useForm();

    const beforeUpload = (file: RcFile) => {
        const isLt1M = file.size / 1024 / 1024 < 1;
        const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPngOrPdf) {
            message.error("Không đúng định dạng file yêu cầu");
            return isJpgOrPngOrPdf;
        }

        if (!isLt1M) {
            message.error("Kích thước ảnh phải nhỏ hơn 1MB!");
            return isLt1M;
        }
    };

    const handleUploadFile = async ({ file, onError, onSuccess }: any) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const result = await uploadFile(formData).unwrap();
            const url = result?.url;
            form.setFieldValue("logo", url);
            const resultUpdateProfile = await triggerUpdate({
                avatar: url,
            }).unwrap();

            if (resultUpdateProfile.status) {
                message.success("Cập nhật thành công!");
            }
            onSuccess();
        } catch (error) {
            message.error("Cập nhật ảnh lỗi!");
            onError();
        }
    };

    const name = ((user?.firstName || "") + " " + (user?.lastName || "")).trim();

    return (
        <section id="content-main">
            <div
                className="box-avatar-person"
                style={{ background: `url('../images/wallpaper-person.jpg') no-repeat center center` }}
            >
                <div className="bg-avatar-opacity">
                    <div className="auto-container">
                        <Form className="d-flex justify-content-center">
                            <Form.Item
                                name="logo"
                                style={{
                                    display: "none",
                                }}
                            />
                            <Form.Item
                                name="image"
                                className="mb-0"
                            >
                                <Spin
                                    size="small"
                                    spinning={resultUploadFile.isLoading}
                                >
                                    <Upload
                                        beforeUpload={beforeUpload}
                                        showUploadList={false}
                                        customRequest={handleUploadFile}
                                    >
                                        <img
                                            className="object-fit-cover"
                                            src={linkCDN(user?.avatar, defaulAvartar)}
                                            alt="icon-avatar"
                                        />
                                    </Upload>
                                </Spin>
                            </Form.Item>
                        </Form>
                        <div className="clearfix" />

                        <h4>{user?.username}</h4>
                        {name && <small className="text-center d-block">{name}</small>}
                        <p>
                            Hạnh phúc đạt được khi bạn ngừng chờ đợi điều đó xảy ra và thực hiện các bước để biến nó
                            thành hiện thực.
                        </p>
                        <p className="price h-gold d-flex w-50 justify-content-between align-items-center mx-auto">
                            <strong>Điểm: {user?.score || 0}</strong>
                            <strong>|</strong>
                            <span>
                                <strong>Xu: {formatNumber(user?.coin || 0)}</strong>
                                RIBA
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="box-component">
                <div className="body-component">
                    <ul
                        className="nav nav-tabs justify-content-center"
                        id="myTab"
                        role="tablist"
                    >
                        <li
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                onClick={() => handleTabClick("#profile-tab")}
                                className={`nav-link ${activeTab === "#profile-tab" ? "active" : ""}`}
                                id="profile-tab"
                                type="button"
                                role="tab"
                                data-bs-toggle="tab"
                                aria-controls="profile"
                                data-bs-target="#profile"
                                aria-selected={activeTab === "#profile-tab"}
                            >
                                Hồ sơ cá nhân
                            </button>
                        </li>
                        <li
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                onClick={() => {
                                    history.navigate("#history-payment-tab");
                                }}
                                className={`nav-link ${activeTab === "#history-payment-tab" ? "active" : ""}`}
                                id="history-payment-tab"
                                type="button"
                                role="tab"
                                aria-controls="history-payment"
                                data-bs-toggle="tab"
                                data-bs-target="#history-payment"
                                aria-selected={activeTab === "#history-payment-tab"}
                            >
                                Thanh toán
                            </button>
                        </li>
                        <li
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                onClick={() => {
                                    history.navigate("#history-deposit-tab");
                                }}
                                className={`nav-link ${activeTab === "#history-deposit-tab" ? "active" : ""}`}
                                id="history-deposit-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#history-deposit"
                                type="button"
                                role="tab"
                                aria-controls="history-deposit"
                                aria-selected={activeTab === "#history-deposit-tab"}
                            >
                                Nạp tiền
                            </button>
                        </li>
                        <li
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                onClick={() => {
                                    history.navigate("#history-review-tab");
                                }}
                                className={`nav-link ${activeTab === "#history-review-tab" ? "active" : ""}`}
                                id="history-review-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#history-review"
                                type="button"
                                role="tab"
                                aria-controls="history-review"
                                aria-selected={activeTab === "#history-review-tab"}
                            >
                                Lịch sử Review
                            </button>
                        </li>
                        <li
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                onClick={() => {
                                    history.navigate("#ticket-tab");
                                }}
                                className={`nav-link ${activeTab === "#ticket-tab" ? "active" : ""}`}
                                id="ticket-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#ticket"
                                type="button"
                                role="tab"
                                aria-controls="ticket"
                                aria-selected={activeTab === "#ticket-tab"}
                            >
                                Phiếu hỗ trợ
                            </button>
                        </li>

                        <li
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                onClick={() => {
                                    history.navigate("#process-apply-tab");
                                }}
                                className={`nav-link ${activeTab === "#process-apply-tab" ? "active" : ""}`}
                                id="process-apply-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#process-apply"
                                type="button"
                                role="tab"
                                aria-controls="process-apply"
                                aria-selected={activeTab === "#process-apply-tab"}
                            >
                                Danh sách Apply
                            </button>
                        </li>
                        <li
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                onClick={() => {
                                    history.navigate("#notice-tab");
                                }}
                                className={`nav-link ${activeTab === "#notice-tab" ? "active" : ""}`}
                                id="notice-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#notice"
                                type="button"
                                role="tab"
                                aria-controls="notice"
                                aria-selected={activeTab === "#notice-tab"}
                            >
                                Thông báo
                            </button>
                        </li>
                    </ul>
                    <div
                        className="tab-content"
                        id="myTabContent"
                    >
                        <TabProfile />
                        <TabPayment />
                        <TabCoin />
                        <TabReviewHistory />
                        <TabApplyProcess />
                        <TabListTickets />
                        <TabNotification />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Profile;
