const Privacy: React.FunctionComponent = () => (
    <div>
        <h1>Privacy Policy</h1>
        <p>
            Studying in China ("we", "us", or "our") is committed to protecting the privacy of our users. This Privacy
            Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and
            use our mobile applications (collectively, the "Services"). Please read this Privacy Policy carefully. By
            using our Services, you consent to the collection, use, disclosure, and retention of your personal
            information as described in this Privacy Policy.
        </p>

        <h2>Information We Collect</h2>
        <p>
            We collect personal information that you voluntarily provide to us when you register for an account,
            download our mobile applications, or otherwise interact with our Services. The types of personal information
            we collect include:
        </p>
        <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Username and password</li>
            <li>Billing and shipping address</li>
            <li>Payment information</li>
            <li>Device and usage information, such as device type, operating system, and application usage data</li>
        </ul>
        <p>We may also collect information automatically when you access and use our Services, including:</p>
        <ul>
            <li>IP address</li>
            <li>Device and browser information</li>
            <li>
                Usage data, such as the pages you visit, the links you click, and the features you use within our mobile
                applications
            </li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We use your personal information to:</p>
        <ul>
            <li>Provide, maintain, and improve our Services</li>
            <li>Personalize your user experience</li>
            <li>Process transactions and send you related information, including confirmations and invoices</li>
            <li>Send you technical notices, updates, and support messages</li>
            <li>Monitor and analyze usage and trends to improve our Services</li>
            <li>Detect, investigate, and prevent fraudulent transactions and other illegal activities</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>
            We do not sell, trade, or otherwise transfer your personal information to third parties without your
            consent, except in the following circumstances:
        </p>
        <ul>
            <li>
                We may share your information with third-party service providers who perform services on our behalf,
                such as payment processing, data analysis, and email delivery.
            </li>
            <li>
                We may disclose your information to comply with applicable laws and regulations, to respond to a
                subpoena or court order, or to protect our rights, property, or the safety of our users or others.
            </li>
        </ul>

        <h2>Data Security</h2>
        <p>
            We implement reasonable measures to protect your personal information from unauthorized access, use, or
            disclosure. However, no method of data transmission or storage is 100% secure, and we cannot guarantee the
            absolute security of your information.
        </p>

        <h2>International Data Transfers</h2>
        <p>
            We implement reasonable measures to protect your personal information from unauthorized access, use, or
            disclosure. However, no method of data transmission or storage is 100% secure, and we cannot guarantee the
            absolute security of your information.
        </p>

        <h2>Children's Privacy</h2>
        <p>
            Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal
            information from children under 13. If we become aware that a child under 13 has provided us with personal
            information, we will take steps to delete such information.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy. You are
            advised to review this Privacy Policy periodically for any changes.
        </p>
    </div>
);

export default Privacy;
