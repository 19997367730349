import { Comment } from "@ant-design/compatible";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_ERROR, TOAST_DELETE_SUCCESS } from "@consts";
import { listUserRole } from "@pages/admin/users/user.const";
import {
    useCreateTicketAnswerMutation,
    useDeleteMultiTicketMutation,
    useDeleteTicketMutation,
    useGetListTicketPaginateQuery,
    useLazyGetTicketByIdQuery,
} from "@redux/queries/user/ticket.api";
import { Badge, Button, Drawer, Form, Image, Input, List, Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";

const Editor = ({ onChange, onSubmit, submitting, value }: any) => (
    <>
        <Form.Item>
            <Input.TextArea
                autoFocus
                rows={4}
                onChange={onChange}
                value={value}
            />
        </Form.Item>
        <Form.Item>
            <Button
                htmlType="submit"
                loading={submitting}
                onClick={onSubmit}
                type="primary"
            >
                Add Comment
            </Button>
        </Form.Item>
    </>
);

const Ticket = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });
    const [detailId, setDetailID] = useState<string | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState("");

    const { showToast } = useToast();

    const { data, isLoading, isFetching } = useGetListTicketPaginateQuery(query as any);
    const [deleteTicket] = useDeleteTicketMutation();
    const [deleteMultiTicket] = useDeleteMultiTicketMutation();
    const [createAnswerTicket] = useCreateTicketAnswerMutation();
    const [getTicketById, { data: dataTicket, isLoading: isLoadingTicket }] = useLazyGetTicketByIdQuery();
    const [modal, contextHolder] = Modal.useModal();

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleDeleteTicker = async (id: string) => {
        try {
            await deleteTicket({
                id: id,
            });
            showToast(TOAST_DELETE_SUCCESS);
        } catch (error) {
            showToast(TOAST_DELETE_ERROR);
        }
    };
    const handleDeleteMutilTicket = async () => {
        try {
            await deleteMultiTicket({
                ids: selectedRowKeys as any,
            });
            showToast(TOAST_DELETE_SUCCESS);
        } catch (error) {
            showToast(TOAST_DELETE_ERROR);
        }
    };

    const handleViewChat = (record: any) => {
        setDetailID(record.id);
        setOpenDrawer(true);
        getTicketById({ id: record.id });
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    const handleSubmit = async (id: any, values: any) => {
        if (!value) return;

        setSubmitting(true);

        const dataAnswer = {
            idTicket: id,
            answer: values,
            rate: 0,
        };
        await createAnswerTicket(dataAnswer);

        getTicketById({ id: id });

        setSubmitting(false);

        setValue("");
    };

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDeleteTicker(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDeleteMutilTicket();
            },
            centered: true,
        });
    };

    const column = [
        {
            title: <div className="text-title-table">Người đặt câu hỏi</div>,
            key: "user",
            width: 175,
            dataIndex: "user",
            render: (user: any) => {
                return user?.firstName;
            },
        },
        {
            title: <div className="text-title-table">Tiêu đề</div>,
            key: "title",
            dataIndex: "title",
            width: 300,
            render: (title: string) => {
                return title;
            },
        },
        {
            title: <div className="text-title-table">Câu hỏi</div>,
            key: "question",
            dataIndex: "question",
            width: 500,
            render: (question: string) => {
                return <div>{question}</div>;
            },
        },
        {
            title: <div className="text-title-table">Nhãn</div>,
            key: "labels",
            width: 195,
            dataIndex: "labels",
            render: (record: any) => {
                return record?.map((item: string, index: number) => (
                    <span
                        key={index}
                        className="border rounded px-2 py-1 mx-1"
                    >
                        {item}
                    </span>
                ));
            },
        },
        {
            title: <div className="text-title-table">Tệp đính kèm</div>,
            key: "fileAttachments",
            dataIndex: "fileAttachments",
            width: 500,
            render: (file: any) => {
                return file?.map((url: string, index: number) => (
                    <Image
                        key={index}
                        src={"" + process.env.REACT_APP_CDN + url}
                        width={140}
                    />
                ));
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-gray"
                            onClick={() => handleViewChat(record)}
                        >
                            <Badge count={record?.totalDiscuss}>
                                <i className="bi-chat"></i>
                            </Badge>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    // useEffect(() => {
    //     if (detailId) {
    //         getTicketById({ id: detailId });
    //     }
    // }, [detailId]);

    return (
        <div className="container-fluid padding0">
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <Button
                                type="primary"
                                danger
                                disabled={selectedRowKeys.length === 0}
                                onClick={confirmMultiDeleteRecord}
                                className="mb-3"
                            >
                                <i className="bi-trash"></i> Delete
                            </Button>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={column}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                />

                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Drawer
                title="Reply"
                placement={"right"}
                closable={false}
                onClose={() => {
                    setOpenDrawer(false);
                    setValue("");
                }}
                open={openDrawer}
                width={600}
                extra={
                    <CloseOutlined
                        onClick={() => {
                            setOpenDrawer(false);
                            setValue("");
                        }}
                    />
                }
            >
                <List
                    className="comment-list"
                    loading={isLoadingTicket}
                    itemLayout="horizontal"
                    dataSource={dataTicket?.answers
                        ?.map((item: any) => {
                            const role = listUserRole.find((role: any) => role.value === item.userAnswer?.role);
                            let name = item.user?.username;
                            if (item.user?.firstName && item.user?.lastName) {
                                name = `${item.user?.firstName} ${item.user?.lastName}`;
                            }

                            if (item?.answer) {
                                return {
                                    author: (
                                        <>
                                            <Tag color={role?.color}>{role?.label}</Tag>
                                            {name}
                                        </>
                                    ),
                                    content: <p>{item.answer}</p>,
                                    datetime: (
                                        <Tooltip title={moment(item.createdAt).format("HH:mm DD/MM/YYYY")}>
                                            <span>{moment(item.createdAt).fromNow()}</span>
                                        </Tooltip>
                                    ),
                                };
                            } else {
                                return null;
                            }
                        })
                        .filter(Boolean)
                        .reverse()}
                    renderItem={(item: any) => (
                        <li>
                            <Comment
                                author={item.author}
                                content={item.content}
                                datetime={item.datetime}
                            />
                        </li>
                    )}
                    footer={
                        <Comment
                            content={
                                <Editor
                                    onChange={handleChange}
                                    onSubmit={() => handleSubmit(detailId, value)}
                                    submitting={submitting}
                                    value={value}
                                />
                            }
                        />
                    }
                />
            </Drawer>
            {contextHolder}
        </div>
    );
};

export default Ticket;
