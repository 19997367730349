import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { listApproveBlog } from "../approve.enum";

interface IProps {
    data: any;
    onSearch: (item: any) => void;
}

const FilterBlog = (props: IProps) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        {/* col 1 */}
                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="approved"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                            >
                                                {listApproveBlog.map((item) => (
                                                    <Select.Option
                                                        value={item.value === "true" ? 1 : 0}
                                                        key={item.value}
                                                    >
                                                        {item.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <label>Trạng thái</label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="title"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Tên bài viết</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterBlog;
