import { default as avatar, default as defaultAvatar } from "@assets/images/default.png";
import { TICKET_LABELS } from "@consts";
import { useGetTicketDetailQuery, useReplyTicketMutation } from "@redux/queries/user/user.api";
import { authSelector } from "@redux/slices/auth.slice";
import { formatTimeAgo, linkCDN } from "@utils/common";
import { Spin, Upload, message } from "antd";
import { format } from "date-fns";
import { useFormik } from "formik";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactStars from "react-stars";

const colors = ["text-box-green", "text-box-red", "text-box-blue"];

export const TICKET_STATUS: Record<string, string> = {
    new: "Mới",
    pending: "Chờ xử lý",
    solved: "Đã giải quyết",
    closed: "Đã đóng",
    hidden: "Ẩn",
};

const TicketDetails = () => {
    const { id = "" } = useParams();
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);

    const [replyTicket, result] = useReplyTicketMutation();

    const { data, isLoading, refetch } = useGetTicketDetailQuery({ id });

    console.log("ticket details", data);

    const formik = useFormik({
        initialValues: {
            answer: "",
            rate: 5,
        },
        onSubmit: async (values) => {
            try {
                if (id) {
                    const response: any = await replyTicket({ ...values, id });

                    if (response?.error) {
                        throw new Error(response?.error?.message || response?.error?.data?.message);
                    }

                    message.success("Gửi phản hồi thành công!");
                    refetch();
                    formik.resetForm();
                }
            } catch (error) {
                console.log(error);
                message.error("Gửi phản hồi thất bại!");
            }
        },
    });

    const ratingChanged = (rating: number) => {
        formik.setFieldValue("rate", rating);
    };

    return (
        <section
            id="content-main"
            className="title-page-ticket"
        >
            <hgroup className="title-page">
                <h2>Chi tiết phiếu hỗ trợ</h2>
            </hgroup>
            <Spin spinning={isLoading}>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="box-component">
                            <div className="body-component page-ticket">
                                <h1>{data?.title}</h1>
                                <div className="mb-2">
                                    {data?.labels?.map((label, index) => (
                                        <span
                                            key={index}
                                            className={`text-box ${colors[index % 3]} me-1`}
                                        >
                                            {TICKET_LABELS[label]}
                                        </span>
                                    ))}
                                </div>
                                <div className="desc-ticket">
                                    <p>
                                        <strong>Nội dung</strong>
                                        <br />
                                        {data?.question}
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="box-component">
                            <div className="body-component">
                                <div className="title-element">
                                    <h5>Phản hồi</h5>
                                    <hr />
                                </div>

                                <form onSubmit={formik.handleSubmit}>
                                    <div className="header-avatar-post mb-4">
                                        <img
                                            className="img-avatar object-fit-cover"
                                            src={linkCDN(data?.user?.avatar, defaultAvatar)}
                                            alt="avatar"
                                        />
                                        <div className="d-flex align-items-center gap-2 mb-1 fw-bold">
                                            Đánh giá:{" "}
                                            <ReactStars
                                                count={5}
                                                size={16}
                                                value={formik.values.rate}
                                                onChange={ratingChanged}
                                                color2={"#ffd700"}
                                                half={false}
                                            />
                                        </div>
                                        <textarea
                                            name="answer"
                                            className="form-control"
                                            rows={3}
                                            value={formik.values.answer}
                                            onChange={formik.handleChange}
                                            placeholder="Nhập phản hồi tại đây"
                                        />
                                        <button
                                            disabled={result?.isLoading || !id || !isLogin}
                                            className="btn-blue"
                                            type="submit"
                                        >
                                            <i className="bi-send"></i> Gửi đi
                                        </button>
                                        <div className="clearfix"></div>
                                    </div>
                                </form>

                                {data && data?.answers?.length > 0 && <hr />}

                                {data?.answers?.map((answer, index) => {
                                    const isYou = answer?.userAnswerId === user?.id;
                                    const userName = answer?.userAnswer?.firstName
                                        ? `${answer?.userAnswer?.firstName} ${answer?.userAnswer?.lastName}`
                                        : answer?.userAnswer?.username;
                                    return (
                                        <Fragment key={index}>
                                            <div
                                                key={index}
                                                className="p-2"
                                                style={{ backgroundColor: isYou ? "#F0F0F0" : "" }}
                                            >
                                                {isYou ? (
                                                    <>
                                                        <div className="header-avatar-post d-flex align-items-center justify-content-end">
                                                            <h6 className="ms-0 me-2 text-end">
                                                                <strong>{isYou ? "You" : userName}</strong>
                                                                <small>{formatTimeAgo(answer?.createdAt)}</small>
                                                            </h6>
                                                            <img
                                                                className="img-avatar me-0"
                                                                src={linkCDN(answer?.userAnswer?.avatar, avatar)}
                                                                alt="avatar"
                                                            />
                                                            <div className="clearfix"></div>
                                                        </div>
                                                        <div className="body-post text-end">
                                                            <p>{answer?.answer}</p>
                                                            <div className="d-flex align-items-center justify-content-end gap-2 mb-1 fw-bold">
                                                                <ReactStars
                                                                    edit={false}
                                                                    count={5}
                                                                    size={16}
                                                                    value={answer?.rate}
                                                                    color2={"#ffd700"}
                                                                    half={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="header-avatar-post">
                                                            <img
                                                                className="img-avatar"
                                                                src={linkCDN(answer?.userAnswer?.avatar, avatar)}
                                                                alt="avatar"
                                                            />
                                                            <h6>
                                                                <strong>{userName}</strong>
                                                                <small>{formatTimeAgo(answer?.createdAt)}</small>
                                                            </h6>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                        <div className="body-post">
                                                            <p>{answer?.answer}</p>
                                                            <div className="d-flex align-items-center gap-2 mb-1 fw-bold">
                                                                <ReactStars
                                                                    edit={false}
                                                                    count={5}
                                                                    size={16}
                                                                    value={answer?.rate}
                                                                    color2={"#ffd700"}
                                                                    half={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <hr />
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="box-item-element">
                            <div className="title-element">
                                <h5>Danh mục</h5>
                                <hr />
                            </div>
                            <div className="body-element">
                                <ul className="list-ul-item">
                                    <li>
                                        <span>Mã số phiếu</span> #{data?.ticketId}
                                    </li>
                                    <li>
                                        <span>Trạng thái</span> {TICKET_STATUS[data?.status || ""]}
                                    </li>
                                    {data?.priority && (
                                        <li>
                                            <span>Mức độ ưu tiên</span>
                                            {data?.priority}
                                        </li>
                                    )}
                                    <li>
                                        <span>Ngày tạo phiếu</span>
                                        {data?.createdAt && format(new Date(data?.createdAt), "MMMM dd, yyyy")}
                                    </li>
                                    <li>
                                        <span>Ngày hết hạn</span>
                                        {data?.dueDate && format(new Date(data?.dueDate), "MMMM dd, yyyy")}
                                    </li>
                                </ul>

                                {data && data?.fileAttachments?.length > 0 && (
                                    <>
                                        <div className="list-ul-item li span">
                                            <li className="border-0">
                                                <span>Dánh sách file</span>
                                            </li>
                                        </div>

                                        <div>
                                            <Upload
                                                multiple
                                                listType="picture-card"
                                                showUploadList={{
                                                    showRemoveIcon: false,
                                                }}
                                                fileList={
                                                    data?.fileAttachments?.map((file, index) => ({
                                                        uid: index.toString(),
                                                        name: file,
                                                        status: "done",
                                                        url: linkCDN(file),
                                                    })) || []
                                                }
                                            ></Upload>
                                        </div>

                                        <hr className="m-0 mt-2" />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </section>
    );
};

export default TicketDetails;

{
    /* <div className="comment-post">
    <ul className="action-post">
        <li>
            <button type="button">
                <i className="bi-hand-thumbs-up"></i> Like <small>(12,984)</small>
            </button>
        </li>
        <li>
            <button type="button">
                <i className="bi-chat-left-dots"></i> Comments <small>(10,867)</small>
            </button>
        </li>
        <li>
            <button type="button">
                <i className="bi-bookmark-plus"></i> Save <small>(2,758)</small>
            </button>
        </li>
    </ul>

    <div className="bg-comment-post">
        <div className="box-user-comment">
            <img
                className="avatar-user"
                src={avatar}
                alt="avatar"
            />
            <div className="content-comment-user">
                <h6>
                    Michael Jackson <time>60 mins ago</time>
                </h6>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book.
                </p>
                <ul className="action-sub-post">
                    <li>
                        <button type="button">
                            <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                        </button>
                    </li>
                    <li>
                        <button type="button">
                            <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div className="replies-comment">
            <div className="box-user-comment">
                <img
                    className="avatar-user"
                    src={avatar}
                    alt="avatar"
                />
                <div className="content-comment-user">
                    <h6>
                        Michael Jackson <time>60 mins ago</time>
                    </h6>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>
                    <ul className="action-sub-post">
                        <li>
                            <button type="button">
                                <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                            </button>
                        </li>
                        <li>
                            <button type="button">
                                <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="replies-comment-lv2">
                <div className="box-user-comment">
                    <img
                        className="avatar-user"
                        src={avatar}
                        alt="avatar"
                    />
                    <div className="content-comment-user">
                        <h6>
                            Michael Jackson <time>60 mins ago</time>
                        </h6>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                        <ul className="action-sub-post">
                            <li>
                                <button type="button">
                                    <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                                </button>
                            </li>
                            <li>
                                <button type="button">
                                    <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="replies-comment-lv2">
                <div className="box-user-comment">
                    <img
                        className="avatar-user"
                        src={avatar}
                        alt="avatar"
                    />
                    <div className="content-comment-user">
                        <h6>
                            Michael Jackson <time>60 mins ago</time>
                        </h6>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                        <ul className="action-sub-post">
                            <li>
                                <button type="button">
                                    <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                                </button>
                            </li>
                            <li>
                                <button type="button">
                                    <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="replies-comment">
            <div className="box-user-comment">
                <img
                    className="avatar-user"
                    src={avatar}
                    alt="avatar"
                />
                <div className="content-comment-user">
                    <h6>
                        Michael Jackson <time>60 mins ago</time>
                    </h6>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>
                    <ul className="action-sub-post">
                        <li>
                            <button type="button">
                                <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                            </button>
                        </li>
                        <li>
                            <button type="button">
                                <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="replies-comment-lv2">
                <div className="box-user-comment">
                    <img
                        className="avatar-user"
                        src={avatar}
                        alt="avatar"
                    />
                    <div className="content-comment-user">
                        <h6>
                            Michael Jackson <time>60 mins ago</time>
                        </h6>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                        <ul className="action-sub-post">
                            <li>
                                <button type="button">
                                    <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                                </button>
                            </li>
                            <li>
                                <button type="button">
                                    <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="box-user-comment">
            <img
                className="avatar-user"
                src={avatar}
                alt="avatar"
            />
            <div className="content-comment-user">
                <h6>
                    Michael Jackson <time>60 mins ago</time>
                </h6>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book.
                </p>
                <ul className="action-sub-post">
                    <li>
                        <button type="button">
                            <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                        </button>
                    </li>
                    <li>
                        <button type="button">
                            <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div className="replies-comment">
            <div className="box-user-comment">
                <img
                    className="avatar-user"
                    src={avatar}
                    alt="avatar"
                />
                <div className="content-comment-user">
                    <h6>
                        Michael Jackson <time>60 mins ago</time>
                    </h6>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>
                    <ul className="action-sub-post">
                        <li>
                            <button type="button">
                                <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                            </button>
                        </li>
                        <li>
                            <button type="button">
                                <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="box-user-comment">
            <img
                className="avatar-user"
                src={avatar}
                alt="avatar"
            />
            <div className="content-comment-user">
                <h6>
                    Michael Jackson <time>60 mins ago</time>
                </h6>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book.
                </p>
                <ul className="action-sub-post">
                    <li>
                        <button type="button">
                            <i className="bi-hand-thumbs-up"></i> Like <small>(38,940)</small>
                        </button>
                    </li>
                    <li>
                        <button type="button">
                            <i className="bi-chat-left-dots"></i> Replies <small>(25,914)</small>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>; */
}
