import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

export const paymentApi = createApi({
    reducerPath: "payment",
    tagTypes: ["payment"],
    keepUnusedDataFor: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getPaginateUserPayment: builder.query<ResponseWithPaginateFormat<UserPayment>, InputGetAllPaymentByUser>({
                query: (params) => ({
                    url: "/api/user/payment/paginate",
                    params,
                }),
                providesTags: ["payment"],
            }),
            getPaymentStatistics: builder.query<ResponsePaymentStatistics, void>({
                query: () => ({
                    url: "/api/user/payment/statistic",
                }),
                providesTags: ["payment"],
            }),
        };
    },
});

export const { useGetPaymentStatisticsQuery, useGetPaginateUserPaymentQuery } = paymentApi;
