import { useGetListApplyQuery } from "@redux/queries/admin/admin.apply";
import { useGetListStepQuery } from "@redux/queries/admin/admin.step";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import { listApplyStepStatus } from "../apply-step.const";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterApplyStep: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    const { data: list_step } = useGetListStepQuery();
    const { data: list_apply } = useGetListApplyQuery();

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        {/* col 1 */}
                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="applyId"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {list_apply &&
                                                    list_apply.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.id}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Đơn đăng ký</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="stepId"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {list_step &&
                                                    list_step.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {`${o.name} - ${o.order}`}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Bước (Tên - Thứ tự)</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="status"
                                            className="form-floating"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin bắt buộc",
                                                },
                                            ]}
                                        >
                                            <Select
                                                allowClear
                                                className="form-control form-floating"
                                                bordered={false}
                                            >
                                                {listApplyStepStatus?.map((item) => (
                                                    <Select.Option
                                                        value={item.value}
                                                        key={item.value}
                                                    >
                                                        {item.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <label>Trạng thái</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterApplyStep;
