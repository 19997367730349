import { authApi } from "@redux/queries/auth.api";
import { userPersistConfig, userReducer } from "@redux/slices/user.slice";
import { authPersistConfig, authReducer } from "@redux/slices/auth.slice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import { userApi } from "@redux/queries/user.api";
import { adminAreaApi } from "@redux/queries/admin/admin.area";
import { adminCityApi } from "@redux/queries/admin/admin.city";
import { adminTrainingApi } from "@redux/queries/admin/admin.training";
import { adminMajorApi } from "@redux/queries/admin/admin.majors";
import { adminSpecializedApi } from "@redux/queries/admin/admin.specialized";
import { adminProjectApi } from "@redux/queries/admin/admin.project";
import { adminScholarshipApi } from "@redux/queries/admin/admin.scholarship";
import { adminScholarshipPolicyApi } from "@redux/queries/admin/admin.scholarship.policy";
import { adminScholarshipTypeApi } from "@redux/queries/admin/admin.scholarship.type";
import { adminSchoolApi } from "@redux/queries/admin/admin.school";
import { publicApi } from "@redux/queries/user/public.api";
import { adminSchoolTypeApi } from "@redux/queries/admin/admin.school.type";
import { actionReducer } from "@redux/slices/action.slice";
import { authActionApi } from "@redux/queries/user/authaction.api";
import { adminSchoolCategoryApi } from "@redux/queries/admin/admin.school.category";
import { adminTeamApi } from "@redux/queries/admin/admin.team";
import { adminUserTeamApi } from "@redux/queries/admin/admin.user-team";
import { adminSchoolServiceApi } from "@redux/queries/admin/admin.school.service";
import { adminSchoolFaqApi } from "@redux/queries/admin/admin.school.faq";
import { adminSchoolNewsApi } from "@redux/queries/admin/admin.school.news";
import { adminSchoolReviewApi } from "@redux/queries/admin/admin.school.review";
import { adminStepApi } from "@redux/queries/admin/admin.step";
import { adminApplyTaskApi } from "@redux/queries/admin/admin.apply.task";
import { userProfileApi } from "@redux/queries/user/user.api";
import { notificationApi } from "@redux/queries/user/notification.api";
import { adminApplyApi } from "@redux/queries/admin/admin.apply";
import { adminUserApi } from "@redux/queries/admin/admin.user";
import { adminSchoolScholarshipTrainingApi } from "@redux/queries/admin/admin.school.scholarship.training";
import { adminApplyStepApi } from "@redux/queries/admin/admin.apply.step";
import { adminApplyProcessApi } from "@redux/queries/admin/admin.apply.process";
import { adminApplyGrantApi } from "@redux/queries/admin/admin.apply.grant";
import { adminApplyPaymentApi } from "@redux/queries/admin/admin.apply.payment";
import { depositApi } from "@redux/queries/user/deposit.api";
import { blogApi } from "@redux/queries/user/blog.api";
import { paymentApi } from "@redux/queries/user/payment.api";
import { introApi } from "@redux/queries/user/intro.api";
import { adminDepositApi } from "@redux/queries/admin/admin.deposit";
import { adminSchoolSpecializedApi } from "@redux/queries/admin/admin.school.specialized";
import { adminNotificationApi } from "@redux/queries/admin/admin.notification";
import { adminConsultationApi } from "@redux/queries/admin/admin.consultation";
import { adminPaymentMethodApi } from "@redux/queries/admin/admin.payment.method";
import { adminApproveBlog } from "@redux/queries/admin/admin.approve.bog";
import { adminPaymentApi } from "@redux/queries/admin/admin.payment";
import { ticketApi } from "@redux/queries/user/ticket.api";
import { adminRoleApi } from "@redux/queries/admin/admin.role";
import { dashBoardReportApi } from "@redux/queries/dashboard/dashboard.report";
import { dashBoardApplyApi } from "@redux/queries/dashboard/dashboard.apply";
import { adminActivity } from "@redux/queries/admin/admin.activity";
import { categoryBlogApi } from "@redux/queries/user/categories.blog";
import { contractUserApi } from "@redux/queries/user/contractUser.api";

const rootPersistConfig = {
    key: "root",
    storage,
    whitelist: [""],
};

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    action: actionReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [authActionApi.reducerPath]: authActionApi.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [depositApi.reducerPath]: depositApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [introApi.reducerPath]: introApi.reducer,
    //admin
    [adminAreaApi.reducerPath]: adminAreaApi.reducer,
    [adminCityApi.reducerPath]: adminCityApi.reducer,
    [adminTrainingApi.reducerPath]: adminTrainingApi.reducer,
    [adminMajorApi.reducerPath]: adminMajorApi.reducer,
    [adminSpecializedApi.reducerPath]: adminSpecializedApi.reducer,
    [adminProjectApi.reducerPath]: adminProjectApi.reducer,
    [adminScholarshipApi.reducerPath]: adminScholarshipApi.reducer,
    [adminScholarshipPolicyApi.reducerPath]: adminScholarshipPolicyApi.reducer,
    [adminScholarshipTypeApi.reducerPath]: adminScholarshipTypeApi.reducer,
    [adminSchoolApi.reducerPath]: adminSchoolApi.reducer,
    [adminSchoolTypeApi.reducerPath]: adminSchoolTypeApi.reducer,
    [adminSchoolCategoryApi.reducerPath]: adminSchoolCategoryApi.reducer,
    [adminTeamApi.reducerPath]: adminTeamApi.reducer,
    [adminUserTeamApi.reducerPath]: adminUserTeamApi.reducer,
    [adminSchoolServiceApi.reducerPath]: adminSchoolServiceApi.reducer,
    [adminSchoolFaqApi.reducerPath]: adminSchoolFaqApi.reducer,
    [adminSchoolReviewApi.reducerPath]: adminSchoolReviewApi.reducer,
    [adminSchoolNewsApi.reducerPath]: adminSchoolNewsApi.reducer,
    [adminStepApi.reducerPath]: adminStepApi.reducer,
    [adminApplyTaskApi.reducerPath]: adminApplyTaskApi.reducer,
    [adminApplyApi.reducerPath]: adminApplyApi.reducer,
    [adminUserApi.reducerPath]: adminUserApi.reducer,
    [adminSchoolScholarshipTrainingApi.reducerPath]: adminSchoolScholarshipTrainingApi.reducer,
    [adminApplyStepApi.reducerPath]: adminApplyStepApi.reducer,
    [adminApplyProcessApi.reducerPath]: adminApplyProcessApi.reducer,
    [adminApplyGrantApi.reducerPath]: adminApplyGrantApi.reducer,
    [adminApplyPaymentApi.reducerPath]: adminApplyPaymentApi.reducer,
    [adminDepositApi.reducerPath]: adminDepositApi.reducer,
    [adminSchoolSpecializedApi.reducerPath]: adminSchoolSpecializedApi.reducer,
    [adminNotificationApi.reducerPath]: adminNotificationApi.reducer,
    [adminConsultationApi.reducerPath]: adminConsultationApi.reducer,
    [adminPaymentMethodApi.reducerPath]: adminPaymentMethodApi.reducer,
    [adminApproveBlog.reducerPath]: adminApproveBlog.reducer,
    [adminPaymentApi.reducerPath]: adminPaymentApi.reducer,
    [adminActivity.reducerPath]: adminActivity.reducer,

    // Tikcet
    [ticketApi.reducerPath]: ticketApi.reducer,
    [adminRoleApi.reducerPath]: adminRoleApi.reducer,
    [dashBoardReportApi.reducerPath]: dashBoardReportApi.reducer,
    [dashBoardApplyApi.reducerPath]: dashBoardApplyApi.reducer,

    // User
    [categoryBlogApi.reducerPath]: categoryBlogApi.reducer,
    [contractUserApi.reducerPath]: contractUserApi.reducer,
});

const middlewares = [
    authApi.middleware,
    userApi.middleware,
    publicApi.middleware,
    authActionApi.middleware,
    userProfileApi.middleware,
    notificationApi.middleware,
    depositApi.middleware,
    blogApi.middleware,
    paymentApi.middleware,
    introApi.middleware,
    //admin
    adminAreaApi.middleware,
    adminCityApi.middleware,
    adminTrainingApi.middleware,
    adminMajorApi.middleware,
    adminSpecializedApi.middleware,
    adminProjectApi.middleware,
    adminScholarshipApi.middleware,
    adminScholarshipPolicyApi.middleware,
    adminScholarshipTypeApi.middleware,
    adminSchoolApi.middleware,
    adminSchoolTypeApi.middleware,
    adminSchoolCategoryApi.middleware,
    adminTeamApi.middleware,
    adminUserTeamApi.middleware,
    adminSchoolServiceApi.middleware,
    adminSchoolFaqApi.middleware,
    adminSchoolReviewApi.middleware,
    adminSchoolNewsApi.middleware,
    adminStepApi.middleware,
    adminApplyTaskApi.middleware,
    adminApplyApi.middleware,
    adminUserApi.middleware,
    adminSchoolScholarshipTrainingApi.middleware,
    adminApplyStepApi.middleware,
    adminApplyProcessApi.middleware,
    adminApplyGrantApi.middleware,
    adminApplyPaymentApi.middleware,
    adminDepositApi.middleware,
    adminSchoolSpecializedApi.middleware,
    adminNotificationApi.middleware,
    adminConsultationApi.middleware,
    adminPaymentMethodApi.middleware,
    adminApproveBlog.middleware,
    adminPaymentApi.middleware,
    adminActivity.middleware,

    // Ticket
    ticketApi.middleware,
    adminRoleApi.middleware,
    dashBoardReportApi.middleware,
    dashBoardApplyApi.middleware,

    // User
    categoryBlogApi.middleware,
    contractUserApi.middleware,
];
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            immutableCheck: false,
            serializableCheck: false,
        }).concat(middlewares),
});

export const wrapperPersistStore = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
