import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useCreateApplyStepMutation, useUpdateApplyStepMutation } from "@redux/queries/admin/admin.apply.step";
import { validateNoSpaces } from "@utils/common";
import { Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";
import { listApplyStepStatus } from "../apply-step.const";
import { useGetListStepQuery } from "@redux/queries/admin/admin.step";
import { useGetListApplyQuery } from "@redux/queries/admin/admin.apply";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createApplyStep, isLoadingCreate] = useCreateApplyStepMutation();
    const [updateApplyStep, isLoadingUpdate] = useUpdateApplyStepMutation();
    const { showToast } = useToast();

    const { data: list_step } = useGetListStepQuery();
    const { data: list_apply } = useGetListApplyQuery();

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue(dataEdit);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const result = dataEdit
            ? await updateApplyStep({
                  id: dataEdit.id,
                  ...values,
              })
            : await createApplyStep({
                  ...values,
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Apply Step" : "Add Apply Step"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="applyId"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {list_apply &&
                                        list_apply.map((o: any, i: any) => {
                                            return (
                                                <Select.Option
                                                    key={o.id}
                                                    value={o.id}
                                                >
                                                    {o.id}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <label>Đơn đăng ký</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="stepId"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {list_step &&
                                        list_step.map((o: any, i: any) => {
                                            return (
                                                <Select.Option
                                                    key={o.id}
                                                    value={o.id}
                                                >
                                                    {`${o.name} - ${o.order}`}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <label>Bước (Tên - Thứ tự)</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="status"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    className="form-control form-floating"
                                    bordered={false}
                                >
                                    {listApplyStepStatus?.map((item) => (
                                        <Select.Option
                                            value={item.value}
                                            key={item.value}
                                        >
                                            {item.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <label>Trạng thái</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="order"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                    {
                                        pattern: /^[0-9]*$/,
                                        message: "Phải nhập số",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Thứ tự</label>
                        </div>
                    </div>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
