import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import BlogContent from "./components/BlogContent";
import RelateBlog from "./components/RelateBlog";
import ReviewPost from "./components/ReviewPost";
import Categories from "./components/Categories";
import RecentPost from "./components/RecentPost";
import { useGetSchoolNewsByIdQuery } from "@redux/queries/admin/admin.school.news";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useBlogDetailsQuery } from "@redux/queries/user/blog.api";
import { Spin } from "antd";

const DetailBlog: FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data, isLoading } = useBlogDetailsQuery(id ? { id } : skipToken);

    return (
        <section id="content-main">
            <hgroup className="title-page">
                <h2>Chi tiết bài viết của người dùng</h2>
            </hgroup>

            <Spin spinning={isLoading}>
                <div className="row">
                    <div className="col-12 col-sm-8">
                        <BlogContent data={data} />

                        {/* <RelateBlog categoryId={data?.category?.id} /> */}

                        {/* <ReviewPost /> */}
                    </div>
                    <div className="col-12 col-sm-4">
                        <aside>
                            {/* <Categories /> */}

                            <RecentPost />
                        </aside>
                    </div>
                </div>
            </Spin>
        </section>
    );
};

export default DetailBlog;
