import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { authSelector } from "@redux/slices/auth.slice";
import { useConsultationMutation } from "@redux/queries/user/authaction.api";
import { message } from "antd";

export interface Advice {
    name: string;
    email: string;
    phone: string;
    area: string;
    scholarship: string;
}

const AdvideForm: React.FunctionComponent = () => {
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const [doConsultation, resultCosultation] = useConsultationMutation();

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            area: "",
            scholarship: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Không được bỏ trống Họ và tên"),
            email: Yup.string().email("Email không đúng định dạng").required("Không được bỏ trống Email"),
            phone: Yup.string()
                .matches(phoneRegExp, "Số điện thoại không đúng định dạng!")
                .required("Không được bỏ trống Họ và tên"),
            area: Yup.string().required("Không được bỏ trống Khu vực"),
            scholarship: Yup.string().required("Không được bỏ trống Học bổng"),
        }),
        onSubmit: async (values: Advice) => {
            const result: any = await doConsultation(values);
            if ("data" in result) {
                message.success("Send info success!");
                formik.resetForm();
            }
        },
    });

    return (
        <div className="box-item-element infor-school bg-profile">
            <div className="title-element">
                <h5>Đăng ký tư vấn</h5>
                <hr />
            </div>
            <div className="body-element">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control form-control-no-icon"
                                placeholder=""
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            <label>Họ và tên</label>
                            <i className="bi bi-person"></i>
                        </div>
                        {formik.touched.name && formik.errors.name ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.name}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="email"
                                className="form-control form-control-no-icon"
                                placeholder=""
                                id="email"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            <label>Địa chỉ email</label>
                            <i className="bi bi-envelope-at"></i>
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.email}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control form-control-no-icon"
                                placeholder=""
                                id="phone"
                                name="phone"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                            />
                            <label>Số điện thoại</label>
                            <i className="bi bi-telephone-inbound"></i>
                        </div>
                        {formik.touched.phone && formik.errors.phone ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.phone}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                className="form-control form-control-no-icon"
                                placeholder=""
                                id="area"
                                name="area"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.area}
                            />
                            <label>Khu vực muốn đi</label>
                            <i className="bi bi-geo-alt"></i>
                        </div>
                        {formik.touched.area && formik.errors.area ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.area}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control form-control-no-icon"
                                placeholder=""
                                id="scholarship"
                                name="scholarship"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.scholarship}
                            />
                            <label>Học bổng muốn Apply</label>
                            <i className="bi bi-bookmark-plus"></i>
                        </div>
                        {formik.touched.scholarship && formik.errors.scholarship ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.scholarship}</p>
                        ) : null}
                    </div>
                    <button
                        disabled={!isLogin}
                        type="submit"
                        className={isLogin ? "btn-full btn-blue" : "btn-full btn-disabled"}
                    >
                        {resultCosultation.isLoading && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <span className="ps-2">Đăng ký tư vấn</span>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AdvideForm;
