import NumberFormat from "@components/NumberFormat";
import Img01 from "@assets/images/item-01.jpg";
import { linkCDN } from "@utils/common";
import { format } from "date-fns";
import { FC } from "react";
import renderHTML from "react-render-html";
import { Col, Row } from "antd";

interface IServiceContent {
    data: ShortService | undefined;
}

const ServiceContent: FC<IServiceContent> = ({ data }) => {
    const {
        id,
        createdAt,
        schoolId,
        scholarshipId,
        type,
        trainingId,
        trainingLanguage,
        languageSupports,
        majorSpecializationIds,
        amount,
        required,
        language,
        supported,
        expireApply,
        total,
        apply,
        year,
        view,
        feeApply,
        school,
        scholarship,
        training,
        specializeds,
    } = data || {};

    return (
        <div className="box-component">
            <div className="body-component">
                <article className="detail-content">
                    <img
                        className="img-fluid rounded-3"
                        src={linkCDN(school?.background, Img01)}
                        alt="img-item"
                    />
                    <ul className="infor-out-detail">
                        <li>
                            <i className="bi-calendar2-minus"></i>{" "}
                            {createdAt && format(new Date(createdAt), "MMMM dd, yyyy")}
                        </li>
                        <li>
                            <i className="bi-award"></i> Hệ: {training?.language?.[0]?.title}
                        </li>
                        <li>
                            <i className="bi-eye-fill"></i>{" "}
                            <b>
                                <NumberFormat value={view} />
                            </b>{" "}
                            Views
                        </li>
                    </ul>

                    <h5 className="my-2">
                        {school?.language?.[0]?.name} - {scholarship?.language?.[0]?.title}
                    </h5>

                    <h1 className="my-2">{language?.[0]?.title}</h1>
                    <p className="summary">{language?.[0]?.shortContent}</p>

                    <p className="content pe-1 render-content-html">
                        {language?.[0]?.content && renderHTML(language?.[0]?.content)}
                    </p>
                </article>
            </div>
        </div>
    );
};

export default ServiceContent;
