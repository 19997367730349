import { useFormik } from "formik";
import React from "react";

export enum SORT_BY {
    createdAt = "createdAt",
    rank = "rank",
    like = "like",
    favorite = "favorite",
    view = "view",
}

interface SearchSchoolFormProps {
    onSearch: (value: { searchInput: string; sortBy: SORT_BY }) => void;
}

const SearchSchoolForm: React.FunctionComponent<SearchSchoolFormProps> = (props) => {
    const formik = useFormik({
        initialValues: {
            searchInput: "",
            sortBy: SORT_BY.rank,
        },
        onSubmit: async (values) => {
            props.onSearch(values);
        },
    });

    return (
        <form
            className="d-flex"
            onSubmit={formik.handleSubmit}
        >
            <div className="rel-icon me-2">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Tìm kiếm..."
                    id="searchInput"
                    name="searchInput"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.searchInput}
                />
                <i className="bi-search" />
            </div>
            <div className="rel-icon me-2">
                <select
                    className="form-select no-background"
                    id="sortBy"
                    name="sortBy"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sortBy}
                >
                    <option value={SORT_BY.rank}>Xếp hạng</option>
                    <option value={SORT_BY.view}>Lượt xem</option>
                    <option value={SORT_BY.like}>Lượt thích</option>
                    <option value={SORT_BY.favorite}>Lượt quan tâm</option>
                </select>
                <i className="bi bi-sort-up" />
            </div>
            <button
                className="btn-blue"
                type="submit"
            >
                Tìm trường
            </button>
        </form>
    );
};

export default SearchSchoolForm;
