import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "./customFetchBase";

const BASE = "/api/user";

export const userApi = createApi({
    reducerPath: "userApi",
    keepUnusedDataFor: 30 * 60,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getUserProfile: builder.query<User, void>({
                query: (params) => BASE + `/profile`,
            }),
        };
    },
});

export const { useLazyGetUserProfileQuery } = userApi;
