import { useGetAllStatisticsRevenueQuery } from "@redux/queries/dashboard/dashboard.apply";
import { Row, Space } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface IProps {
    query?: any;
}

const ApexChart = (props: IProps) => {
    const { data } = useGetAllStatisticsRevenueQuery(props.query ? props.query : {});

    useEffect(() => {
        if (data) {
            const uniqueFund = _.uniqBy(data, "fund").map((item: any) => item.fund);
            const uniqueSource = _.uniqBy(data, "source").map((item: any) => item.source);

            const inputSourceChartData = {
                series: uniqueSource.map((source: string) => {
                    return data
                        .filter((item: any) => item.source === source)
                        .reduce((acc: any, cur: any) => acc + cur.income, 0);
                }),
                options: {
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return Number(val).toLocaleString("en-US");
                            },
                        },
                    },
                    chart: {
                        width: 380,
                        type: "pie",
                    },
                    title: {
                        text: "Thống kê nguồn thu",
                    },
                    labels: uniqueSource,
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
            };

            const outSourceChartData = {
                series: uniqueSource.map((source: string) => {
                    return data
                        .filter((item: any) => item.source === source)
                        .reduce((acc: any, cur: any) => acc + cur.expense, 0);
                }),
                options: {
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return Number(val).toLocaleString("en-US");
                            },
                        },
                    },
                    chart: {
                        width: 380,
                        type: "pie",
                    },
                    title: {
                        text: "Thống kê nguồn chi",
                    },
                    labels: uniqueSource,
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
            };

            const inputFundChartData = {
                series: uniqueFund.map((fund: string) => {
                    return data
                        .filter((item: any) => item.fund === fund)
                        .reduce((acc: any, cur: any) => acc + cur.income, 0);
                }),
                options: {
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return Number(val).toLocaleString("en-US");
                            },
                        },
                    },
                    chart: {
                        width: 380,
                        type: "pie",
                    },
                    title: {
                        text: "Thống kê phương thức thu",
                    },
                    labels: uniqueFund,
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
            };

            const outputFundChartData = {
                series: uniqueFund.map((fund: string) => {
                    return data
                        .filter((item: any) => item.fund === fund)
                        .reduce((acc: any, cur: any) => acc + cur.expense, 0);
                }),
                options: {
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return Number(val).toLocaleString("en-US");
                            },
                        },
                    },
                    chart: {
                        width: 380,
                        type: "pie",
                    },
                    title: {
                        text: "Thống kê phương thức chi",
                    },
                    labels: uniqueFund,
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
            };

            setInputSourceChartData(inputSourceChartData);
            setOutSourceChartData(outSourceChartData);
            setInputFundChartData(inputFundChartData);
            setOutputFundChartData(outputFundChartData);
        }
    }, [data]);

    const [inputSourceChartData, setInputSourceChartData] = useState<any>({});

    const [outSourceChartData, setOutSourceChartData] = useState<any>({});

    const [inputFundChartData, setInputFundChartData] = useState<any>({});

    const [outputFundChartData, setOutputFundChartData] = useState<any>({});

    return (
        <Row gutter={20}>
            <div id="chart">
                {inputSourceChartData?.series && (
                    <ReactApexChart
                        options={inputSourceChartData.options as any}
                        series={inputSourceChartData.series}
                        type="pie"
                        width={380}
                    />
                )}
            </div>
            <div id="chart">
                {outSourceChartData.series && (
                    <ReactApexChart
                        options={outSourceChartData.options as any}
                        series={outSourceChartData.series}
                        type="pie"
                        width={380}
                    />
                )}
            </div>
            <div id="chart">
                {inputFundChartData.series && (
                    <ReactApexChart
                        options={inputFundChartData.options as any}
                        series={inputFundChartData.series}
                        type="pie"
                        width={380}
                    />
                )}
            </div>
            <div id="chart">
                {outputFundChartData.series && (
                    <ReactApexChart
                        options={outputFundChartData.options as any}
                        series={outputFundChartData.series}
                        type="pie"
                        width={380}
                    />
                )}
            </div>
            <div id="html-dist"></div>
        </Row>
    );
};

export default ApexChart;
