import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

const BASE = "/api/school/type";

export const adminSchoolTypeApi = createApi({
    reducerPath: "adminSchoolTypeApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["SchoolType"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolType: builder.query<Array<IAdminSchoolType>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
        };
    },
});

export const { useGetListSchoolTypeQuery } = adminSchoolTypeApi;
