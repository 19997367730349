import { useGetAllStatisticsRevenueQuery } from "@redux/queries/dashboard/dashboard.apply";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface IProps {
    query?: any;
}

const CashFlowChart = (props: IProps) => {
    const { data } = useGetAllStatisticsRevenueQuery(props.query ? props.query : {});

    const [dataChart, setDataChart] = useState<any>({});

    useEffect(() => {
        if (data) {
            const uniqueSource = _.uniqBy(data, "source").map((item: any) => item.source);
            const minDate = _.minBy(data, (item: any) => moment(item.date, "YYYY-MM-DD").valueOf());
            const maxDate = _.maxBy(data, (item: any) => moment(item.date, "YYYY-MM-DD").valueOf());
            const listDate = [] as any[];
            for (
                let i = moment(minDate?.date, "YYYY-MM-DD");
                i <= moment(maxDate?.date, "YYYY-MM-DD");
                i = moment(i).add(1, "days")
            ) {
                listDate.push(i.format("YYYY-MM-DD"));
            }
            const series = uniqueSource.map((source: string) => {
                return {
                    name: source,
                    data: listDate.map((date: string) => {
                        const filterItem = data.filter((item: any) => item.date === date && item.source === source);
                        return filterItem.reduce((acc: any, cur: any) => acc + cur.income - cur.expense, 0);
                    }),
                };
            });
            setDataChart({
                series: series as any,
                chart: {
                    type: "bar",
                    stacked: true,
                    height: 850,
                },
                plotOptions: {
                    bar: {
                        columnWidth: "80%",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                yaxis: {
                    labels: {
                        formatter: (value: any) => {
                            return Number(value).toLocaleString("en-US");
                        },
                    },
                },
                xaxis: {
                    type: "datetime",
                    categories: listDate.map((date: string) => moment(date, "YYYY-MM-DD").add(7, "hours").valueOf()),
                },
            });
        }
    }, [data]);

    return (
        <div id="chart">
            {dataChart?.series && (
                <ReactApexChart
                    options={dataChart}
                    series={dataChart?.series}
                    type="bar"
                    height={850}
                />
            )}
        </div>
    );
};

export default CashFlowChart;
