import blankImage from "@assets/images/blank.png";
import { useToast } from "@components/toast/ToastProvider";
import {
    TOAST_CREATE_ERROR,
    TOAST_CREATE_SUCCESS,
    TOAST_DELETE_SUCCESS,
    TOAST_UPDATE_ERROR,
    TOAST_UPDATE_SUCCESS,
    TOAST_UPLOAD_ERROR,
    TOAST_UPLOAD_SUCCESS,
} from "@consts";
import { useCreateUserMutation, useUpdateUserMutation } from "@redux/queries/admin/admin.user";
import store from "@redux/store";
import { Col, DatePicker, Form, Input, Modal, Row, Select, Typography, message } from "antd";
import Upload, { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import moment from "moment";
import { useEffect, useState } from "react";
import { listUserRole } from "../user.const";
import ReactQuill from "react-quill";
import { HSKKOptions, HSKOptions } from "@pages/intro/constants";
import { pickBy, identity } from "lodash";
import dayjs from "dayjs";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const { Title } = Typography;

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createUser, isLoadingCreate] = useCreateUserMutation();
    const [updateUser, isLoadingUpdate] = useUpdateUserMutation();
    const { showToast } = useToast();

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [avatar, setAvatar] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue({
                ...dataEdit,
                birthday: dataEdit.birthday ? dayjs(dataEdit.birthday) : undefined,
            });
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const valueSubmit = pickBy(
            {
                ...values,
                birthday: values.birthday ? dayjs(values.birthday).toISOString() : undefined,
                avatar,
            },
            identity
        ) as any;
        const result = dataEdit
            ? await updateUser({
                  id: dataEdit.id,
                  ...valueSubmit,
                  status: valueSubmit.status ? 1 : 0,
              })
            : await createUser({
                  ...valueSubmit,
                  status: valueSubmit.status ? 1 : 0,
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    const listGender = [
        { label: "Nam", value: "Male" },
        { label: "Nữ", value: "Female" },
        { label: "Khác", value: "Other" },
    ];

    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;

    const uploadProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPngOrPdf) {
                message.error("Không đúng định dạng file yêu cầu");
            }
            const avatar = document.getElementById("avatar") as HTMLImageElement;
            avatar.src = URL.createObjectURL(file);
            return isJpgOrPngOrPdf;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileList([info.file]);
                setAvatar && setAvatar(info.file.response && info.file.response[0].url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileList([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
    ];

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link", "image"],
            ["clean"],
        ],
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update User" : "Add User"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1200}
            >
                <Row>
                    <Col span={5}>
                        <div className="body-component">
                            <div className="form-group">
                                <div className="frame-upload">
                                    <img
                                        className="img-fluid"
                                        src={blankImage}
                                        alt=""
                                        id="avatar"
                                    />
                                </div>
                                <Upload.Dragger {...uploadProps}>
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">Tải ảnh avatar</button>
                                    </div>
                                </Upload.Dragger>
                            </div>
                        </div>
                    </Col>

                    <Col span={12}>
                        {" "}
                        <div className="body-component">
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="username"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Tên người dùng</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="firstName"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Họ và tên đệm</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="lastName"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Tên chính</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="birthday"
                                                className="form-floating"
                                            >
                                                <DatePicker
                                                    className="no-icon form-floating"
                                                    style={{ width: "100%", height: 45 }}
                                                    format="DD/MM/YYYY"
                                                />
                                            </Form.Item>
                                            <label>Ngày sinh</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="gender"
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    options={listGender}
                                                >
                                                    {listGender.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.value}
                                                                value={o.value}
                                                            >
                                                                {o.label}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <label>Giới tính</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="email"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        type: "email",
                                                        message: "Email không đúng định dạng",
                                                    },
                                                    {
                                                        required: true,
                                                        message: "Không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Email</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="phone"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        pattern: /^[0-9]*$/,
                                                        message: "Phải nhập số",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Số điện thoại</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="coin"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Không được để trống",
                                                    },
                                                    {
                                                        pattern: /^[0-9]*$/,
                                                        message: "Phải nhập số",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Số coin</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="password"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    type="password"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Mật khẩu đăng nhập</label>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="role"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    options={listUserRole}
                                                />
                                            </Form.Item>
                                            <label>Role</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="status"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    options={[
                                                        {
                                                            label: "Hoạt động",
                                                            value: 1,
                                                        },
                                                        {
                                                            label: "Khóa",
                                                            value: 0,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <label>Trạng thái</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="isAdmin"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Không được để trống",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    options={[
                                                        {
                                                            label: "Có",
                                                            value: true,
                                                        },
                                                        {
                                                            label: "Không",
                                                            value: false,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <label>Quyền admin</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="refId"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Mã giới thiệu</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="address"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Địa chỉ</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="cardId"
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Chứng minh thư/Căn cước</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}></Col>
                            </Row>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="introduceVi"
                                        className="form-floating"
                                    >
                                        <ReactQuill
                                            className="text-editor"
                                            // value={introduceVi}
                                            // onChange={(value: any) => {
                                            //     setIntroduceVi(value);
                                            // }}
                                            formats={formats}
                                            modules={modules}
                                        />
                                    </Form.Item>
                                    <label>Lộ trình học tập</label>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={7}>
                        <Title level={4}>User Profile</Title>
                        <div className="body-component">
                            <Title level={5}>GPA</Title>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={["profile", "GPA", "coefficients"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        pattern: /^(4|10)$/,
                                                        message: "Chỉ được nhập 4 hoặc 10",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Hệ số</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={["profile", "GPA", "mark"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        pattern: /^(10(\.0+)?|[0-9](\.[0-9]+)?)$/,
                                                        message: "Chỉ được nhập số từ 0 đến 10",
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const profile = getFieldValue("profile");
                                                            const coefficients = profile?.GPA?.coefficients;
                                                            if ((value && coefficients) || !coefficients) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("Không được để trống");
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Điểm</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Title level={5}>HSK</Title>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={["profile", "HSK", "level"]}
                                                className="form-floating"
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const profile = getFieldValue("profile");
                                                            const level = profile?.HSK?.level;
                                                            if ((value && level) || !level) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("Không được để trống");
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    placeholder=""
                                                    options={HSKOptions}
                                                />
                                            </Form.Item>
                                            <label>Cấp độ</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={["profile", "HSK", "coefficients"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        pattern: /^(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-9]{2}|300)$/,
                                                        message: "Chỉ được nhập số từ 0 đến 10",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Điểm</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Title level={5}>HSKK</Title>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={["profile", "HSKK", "level"]}
                                                className="form-floating"
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const profile = getFieldValue("profile");
                                                            const level = profile?.HSKK?.level;
                                                            if ((value && level) || !level) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("Không được để trống");
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    placeholder=""
                                                    options={HSKKOptions}
                                                />
                                            </Form.Item>
                                            <label>Cấp độ</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={["profile", "HSKK", "coefficients"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        pattern: /^(?:[0-9]|[1-9][0-9]|100)$/,
                                                        message: "Chỉ được nhập số từ 0 đến 100",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Điểm</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name={["profile", "ielts"]}
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Chứng chỉ Ielts</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name={["profile", "certificate"]}
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Chứng chỉ</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name={["profile", "exCertificate"]}
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Chứng chỉ cũ</label>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default HandleForm;
