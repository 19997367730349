import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React from "react";
import { listPaymentMethodStatus, listTypePaymentMethod } from "../payment-method.const";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterPaymentMethod: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        {/* col 1 */}
                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="name"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Tên</label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="type"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                            >
                                                {listTypePaymentMethod.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.value}
                                                            value={o.value}
                                                        >
                                                            {o.label}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <label>Loại</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="status"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                            >
                                                {listPaymentMethodStatus.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.value}
                                                            value={o.value}
                                                        >
                                                            {o.label}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <label>Trạng thái</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterPaymentMethod;
