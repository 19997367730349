import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, FormInstance, Input, Row, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import Tab from "./Tab";

interface SchoolServiceTabProps {
    listTrain: any;
    list_specialized: any;
    listTrainingLanguage: any;
    listSchoolServiceType: any;
    form: FormInstance<any>;
    introduceVi: any;
    introduceEn: any;
    introduceCn: any;
    setIntroduceVi: (data: any) => void;
    setIntroduceEn: (data: any) => void;
    setIntroduceCn: (data: any) => void;
    setErrorContent: (data: any) => void;
    errorContent: any;
}

const SchoolServiceTab = (props: SchoolServiceTabProps) => {
    const {
        listTrain,
        list_specialized,
        listTrainingLanguage,
        listSchoolServiceType,
        form,
        introduceCn,
        introduceEn,
        introduceVi,
        setErrorContent,
        setIntroduceCn,
        setIntroduceEn,
        setIntroduceVi,
        errorContent,
    } = props;

    const serviceTabs = useWatch("serviceTab", form);

    return (
        <div
            className="tab-pane fade show"
            id="area-tab-pane"
            role="tabpanel"
            aria-labelledby="area-tab"
            tabIndex={0}
        >
            <Form.List name="serviceTab">
                {(fields, { add, remove }) => (
                    <div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
                        {fields.map((field, index) => (
                            <Card
                                size="small"
                                title={`Hệ đào tạo ${field.name + 1}`}
                                key={field.key}
                                extra={
                                    index > 0 ? (
                                        <CloseOutlined
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    ) : null
                                }
                            >
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={[field.name, "trainingId"]}
                                            className="form-floating"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin bắt buộc",
                                                },
                                            ]}
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                            >
                                                {listTrain &&
                                                    listTrain.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Hệ đào tạo</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "majorSpecializationIds"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Thông tin bắt buộc",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        allowClear
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                    >
                                                        {list_specialized &&
                                                            list_specialized.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Chuyên ngành</label>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "trainingLanguage"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Thông tin bắt buộc",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                    >
                                                        {listTrainingLanguage.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.value}
                                                                    value={o.value}
                                                                >
                                                                    {o.label}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Chương trình đào tạo</label>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "amount"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: "Phải nhập số",
                                                        },
                                                        {
                                                            required: true,
                                                            message: "Thông tin bắt buộc",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Phí dịch vụ</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={20}>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "total"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Thông tin bắt buộc",
                                                        },
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: "Phải nhập số",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Số chỉ tiêu</label>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "apply"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: "Phải nhập số",
                                                        },
                                                        {
                                                            required: true,
                                                            message: "Thông tin bắt buộc",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Đã tuyển</label>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "required", "gpa"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: "Phải nhập số",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Yêu cầu GPA</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={20}>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "required", "language"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: "Phải nhập số",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Yêu cầu ngoại ngữ</label>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "expireApply"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Thông tin bắt buộc",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        className="no-icon form-floating"
                                                        style={{ width: "100%", height: 45 }}
                                                        format="DD/MM/YYYY"
                                                    />
                                                </Form.Item>
                                                <label>Ngày hết hạn</label>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "supported"]}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                    >
                                                        <Select.Option value={true}>Có</Select.Option>
                                                        <Select.Option value={false}>Không</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <label>Hỗ trợ</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={20}>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "year"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: "Phải nhập số",
                                                        },
                                                        {
                                                            required: true,
                                                            message: "Thông tin bắt buộc",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="no-icon form-floating"
                                                    />
                                                </Form.Item>
                                                <label>Năm</label>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "type"]}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                    >
                                                        {listSchoolServiceType.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.value}
                                                                    value={o.value}
                                                                >
                                                                    {o.label}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Phân loại</label>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "languageSupports"]}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        mode="multiple"
                                                    >
                                                        {listTrainingLanguage.map((o: any, i: any) => {
                                                            return (
                                                                <Select.Option
                                                                    key={o.value}
                                                                    value={o.value}
                                                                >
                                                                    {o.label}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Ngôn ngữ hỗ trợ</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <Row gutter={20}>
                                        <Col span={8}>
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "feeApply"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: "Phải nhập số",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="no-icon form-floating"
                                                    />
                                                </Form.Item>
                                                <label>Phí Apply</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={[field.name, "note"]}
                                            className="form-floating"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin bắt buộc",
                                                },
                                            ]}
                                        >
                                            <Input
                                                type="text"
                                                className="no-icon form-floating"
                                            />
                                        </Form.Item>
                                        <label>Ghi chú</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Tab
                                            form={form}
                                            introduceVi={introduceVi}
                                            introduceEn={introduceEn}
                                            introduceCn={introduceCn}
                                            setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                            setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                            setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                            errorContent={errorContent}
                                            setErrorContent={(value: any) => setErrorContent(value)}
                                            fieldName={field.name.toString()}
                                        />
                                    </div>
                                </div>
                            </Card>
                        ))}

                        <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                        >
                            Thêm hệ đào tạo
                        </Button>
                    </div>
                )}
            </Form.List>
        </div>
    );
};

export default SchoolServiceTab;
