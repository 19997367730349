import { Button, Col, Form, Input, Row, Space } from "antd";
import React from "react";

interface FilterGrantEmployeeProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterGrantEmployee: React.FunctionComponent<FilterGrantEmployeeProps> = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <div className="body-component">
                        <Row gutter={16}>
                            <Col span={8}>
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="username"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Tên nhân viên</label>
                                    </div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="code"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Mã</label>
                                    </div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="email"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Email</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Space>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Search
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                Clear
                            </Button>
                        </Form.Item>
                    </Space>
                </Form>
            </div>
        </>
    );
};

export default FilterGrantEmployee;
