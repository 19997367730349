import "react-multi-date-picker/styles/colors/purple.css";

import { useUserCheckinQuery } from "@redux/queries/user/user.api";
import React from "react";
import { Calendar } from "react-multi-date-picker";

interface DailyCheckinProps {}

const DailyCheckin: React.FunctionComponent<DailyCheckinProps> = () => {
    const { data } = useUserCheckinQuery();
    const { items: dataCheckin } = data || { items: [] };

    const checkinList = dataCheckin.map((item) => new Date(item.createdAt));

    return (
        <div className="box-component user-box-component">
            <div className="body-component">
                {/* BEGIN: Check Daily */}
                <div className="box-check-daily">
                    <div className="title-element">
                        <h5>Điểm danh hằng ngày</h5>
                        <hr />
                    </div>
                    <div className="calendar-wrapper">
                        <Calendar
                            className="purple"
                            multiple
                            value={checkinList}
                            disableYearPicker
                            disableMonthPicker
                        />
                    </div>

                    {/* Bootstrap Modal */}
                    <div
                        className="modal fade"
                        id="notificationModal"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="notificationModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        
                                    </button>
                                    Điểm danh xong cho ngày: <span id="selectedDay" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END: Check Daily */}
            </div>
        </div>
    );
};

export default DailyCheckin;
