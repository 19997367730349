import { FC, memo } from "react";
import CompleteListBlogs from "./CompleteListBlogs";
import CompleteListShools from "./CompleteListShools";
import CompleteUserInfo from "./CompleteUserInfo";

interface ICompleteStep {
    dataComplete: ResponseWelcomeUser | null;
}

const CompleteStep: FC<ICompleteStep> = ({ dataComplete }) => {
    const { apply, news, rank, schools, user } = dataComplete || {};

    console.log("apply", apply);
    console.log("news", news);
    console.log("rank", rank);
    console.log("schools", schools);
    console.log("user", user);

    return (
        <section id="content-main">
            <CompleteUserInfo
                apply={apply}
                rank={rank}
                user={user}
            />

            <CompleteListBlogs news={news} />

            <div className="text-center">
                <a
                    className="btn-blue"
                    href="#"
                >
                    {/* //TODO link đến list news */}
                    Xem thêm
                </a>
            </div>

            <CompleteListShools schools={schools} />
        </section>
    );
};

export default memo(CompleteStep);
