import React, { useState } from "react";
import VideoItem from "./VideoItem";
import school_01 from "@assets/images/school-01.jpg";
import { useParams } from "react-router-dom";
import { usePaginateVideoBySchoolIdQuery } from "@redux/queries/user/public.api";
import { Pagination } from "@components/pagination";
import Nodata from "@components/Nodata";

const LIMIT = 12;

const TabVideo: React.FunctionComponent<{ id?: string }> = ({ id }) => {
    const [page, setPage] = useState(1);
    const { data } = usePaginateVideoBySchoolIdQuery({
        id: id as any,
        page,
        limit: LIMIT,
    });

    const [selectedVideo, setSelectedVideo] = useState<{
        thumbnail: string;
        url: string;
    }>({
        thumbnail: school_01,
        url: "https://www.youtube.com/embed/UHgsxKnhSKs",
    });

    return (
        <>
            {/* BEGIN: Content Image */}
            <div className="box-component">
                <div className="body-component">
                    <div className="title-element">
                        <h5>Video</h5>
                        <hr />
                    </div>
                    <div className="row list-video-item">
                        {data?.items?.length === 0 && <Nodata />}
                        {data?.items?.map((e) => (
                            <VideoItem
                                key={e.id}
                                item={{
                                    thumbnail: school_01,
                                    url: e.url,
                                }}
                                onClick={() =>
                                    setSelectedVideo({
                                        thumbnail: e.name,
                                        url: e.url,
                                    })
                                }
                            />
                        ))}
                    </div>
                    {(data?.meta?.totalItems || 0) > 0 && (
                        <div className="d-flex justify-content-center mt-4">
                            <Pagination
                                defaultSize={LIMIT}
                                onSizeChange={(size) => {}}
                                total={data?.meta.totalItems || 0}
                                showSize={false}
                                totalPage={data?.meta.totalPages || 0}
                                onChangePage={setPage}
                                defaultCurrentPage={1}
                                listSize={[12, 24, 50, 100]}
                            />
                        </div>
                    )}
                    {/* BEGIN: Modal */}
                    <div
                        className="modal fade"
                        id="modal-video"
                        tabIndex={-1}
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-xl modal-dialog-centered">
                            <div className="modal-content">
                                <div className="ratio ratio-16x9">
                                    <iframe
                                        src={selectedVideo.url}
                                        title="清原果耶 - 1st Single「今とあの頃の僕ら 」(Music Video)"
                                        frameBorder={0}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END: Modal */}
                </div>
            </div>
            {/* END: Content Image */}
        </>
    );
};

export default TabVideo;
