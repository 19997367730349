import { Form } from "antd";
import { Dispatch, FC, SetStateAction, memo } from "react";
import { purposeOfUse } from "../constants";

interface ISecondStep {
    dataWelcome: InputWelcomeUser;
    setDataWelcome: Dispatch<SetStateAction<InputWelcomeUser>>;
    setActiveTab: Dispatch<SetStateAction<number>>;
}

const SecondStep: FC<ISecondStep> = ({ dataWelcome, setDataWelcome, setActiveTab }) => {
    const [form] = Form.useForm();

    const onSubmit = (values: any) => {
        const arrayValues: number[] = [];

        Object.keys(values).forEach((key) => {
            if (values[key]) {
                arrayValues.push(parseInt(key, 10));
            }
        });

        setDataWelcome((dataWelcome) => ({
            ...dataWelcome,
            purposeOfUseId: arrayValues,
        }));

        handleNextStep();
    };

    const handleNextStep = () => {
        setActiveTab(3);
    };

    return (
        <section id="content-main">
            <div className="box-component">
                <div className="box-introduction">
                    <div className="content-li-step">
                        <ul className="li-step">
                            <li className="active">
                                <span>1</span>
                            </li>
                            <li className="active">
                                <span>2</span>
                            </li>
                            <li>
                                <span>3</span>
                            </li>
                            <li>
                                <span>4</span>
                            </li>
                            <li>
                                <span>5</span>
                            </li>
                        </ul>
                    </div>

                    <div className="content-introduction">
                        <div className="step-title">
                            <h6>Bước 1: Mục đích sử dụng StudyinChina.io</h6>
                            <h5>Bạn muốn sử dụng hệ thống StudyinChina.io vào việc gì?</h5>
                            <p>
                                Thông tin mà bạn cung cấp rất có ích cho chúng tôi. Điều này sẽ giúp chúng tôi có thể
                                nắm bắt được thông tin và mong muốn của bạn.
                            </p>
                        </div>

                        <Form
                            form={form}
                            onFinish={onSubmit}
                        >
                            <div className="row justify-content-center">
                                {purposeOfUse.map((item, index) => (
                                    <div
                                        className="col-6 col-sm-4"
                                        key={index}
                                    >
                                        <Form.Item
                                            name={item.id}
                                            valuePropName="checked"
                                            className="mb-0"
                                        >
                                            <label className="custom-checkbox">
                                                <input type="checkbox" />
                                                <span className="btnCheck">{item.language[0].content}</span>
                                            </label>
                                        </Form.Item>
                                    </div>
                                ))}
                            </div>

                            <hr className="hr-border" />

                            <div className="group-button-footer">
                                <button
                                    type="submit"
                                    className="continue btn btn-blue"
                                >
                                    Tiếp tục <i className="bi-arrow-right"></i>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default memo(SecondStep);
