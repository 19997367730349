import React, { createContext, useContext, useMemo, useState } from "react";
import ModalCustome from "./Modal";

interface IModalContext {
    showModal: (param?: { title: string | null; close?: string | null; button?: React.ReactElement }) => void;
    onSubmitModal: () => void;
}

interface IModalProps {
    children?: React.ReactElement;
}

const ModalContext = createContext<IModalContext>({
    showModal(param) {
        return false;
    },
    onSubmitModal() {
        return false;
    },
});

export const ModalProvider: React.FunctionComponent<IModalProps> = (props) => {
    const [data_moal, setDataModal] = useState<{
        title: string | null;
        close: string | null;
        button: React.ReactElement;
    }>({
        title: null,
        close: null,
        button: <></>,
    });
    const [open, setOpen] = useState<boolean>(false);

    let value_context = useMemo(() => {
        return {
            showModal: (params: any) => {
                setOpen(true);
                setDataModal(params);
            },
            onSubmitModal: () => {
                setOpen(false);
            },
        };
    }, [setDataModal]);

    const closeModal = () => {
        setOpen(false);
    };

    return (
        <ModalContext.Provider value={value_context}>
            {props.children}
            {
                <ModalCustome
                    open={open}
                    onClose={closeModal}
                    title={data_moal.title ? data_moal.title : "Warning"}
                    footer={[
                        <>
                            <>{data_moal.button}</>
                            <button
                                key="2"
                                type="button"
                                onClick={closeModal}
                                className="text-box-gray"
                            >
                                Close
                            </button>
                        </>,
                    ]}
                >
                    <p>Bạn có chắc chắn muốn xóa không?</p>
                </ModalCustome>
            }
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const Modal = useContext(ModalContext);
    return Modal;
};
