import { useGetAllMethodQuery } from "@redux/queries/user/deposit.api";
import React from "react";
import FormDeposit from "./FormDeposit";
import ListDeposit from "./ListDeposit";

interface TabCoinProps {}

const TabCoin: React.FunctionComponent<TabCoinProps> = () => {
    return (
        <div
            className={`tab-pane fade ${location.hash === "#history-deposit-tab" ? "show active" : ""}`}
            id="history-deposit"
            role="tabpanel"
            aria-labelledby="history-deposit-tab"
        >
            {/* BEGIN: Section Box Item Element */}
            <FormDeposit />
            {/* END: Section Box Item Element */}
            {/* BEGIN: Section Box Item Element */}
            <ListDeposit />
            {/* END: Section Box Item Element */}
        </div>
    );
};

export default TabCoin;
