/* eslint-disable react/prop-types */
import { authSelector } from "@redux/slices/auth.slice";
import { history } from "@utils/helper-router";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute: React.FunctionComponent<{ children: any }> = (props) => {
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);

    if (!isLogin) {
        return (
            <Navigate
                to={"/"}
                replace={true}
            />
        );
    }

    return props.children;
};

export { PrivateRoute };
