import Image01 from "@assets/images/item-01.jpg";
import { linkCDN } from "@utils/common";
import { Modal } from "antd";
import { format } from "date-fns";
import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDeleteBlogMutation } from "@redux/queries/user/blog.api";

interface PostProps {
    data: UserBlog | SchoolNews;
    title: string;
    shortContent: string;
    link?: string;
    displayEditDelete?: boolean;
    handleClickEditBlog?: (blog: UserBlog | SchoolNews) => void;
}

const Post: React.FunctionComponent<PostProps> = ({
    data,
    title,
    shortContent,
    link,
    displayEditDelete,
    handleClickEditBlog,
}) => {
    const { thumbnail, createdAt } = data || {};
    const [modal, contextHolder] = Modal.useModal();

    const [triggerDeleteBlog] = useDeleteBlogMutation();

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bài viết này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                console.log("id", id);
                triggerDeleteBlog({ id });
                // handleDelete(id);
            },
            centered: true,
        });
    };

    return (
        <>
            <div className="box-item">
                <div className="row">
                    <div className="col-5">
                        {link ? (
                            <Link to={link}>
                                <img
                                    className="img-fluid"
                                    src={linkCDN(thumbnail, Image01)}
                                    alt=""
                                />
                            </Link>
                        ) : (
                            <img
                                className="img-fluid"
                                src={linkCDN(thumbnail, Image01)}
                                alt=""
                            />
                        )}
                    </div>
                    <div className="col-7 position-relative">
                        <h5>{link ? <Link to={link}>{title}</Link> : title}</h5>
                        <time>{createdAt && format(new Date(createdAt), "MMMM dd, yyyy")}</time>
                        <p>{shortContent}</p>
                        {displayEditDelete && (
                            <div
                                className="position-absolute"
                                style={{ top: 0, right: 12 }}
                            >
                                <div className="d-flex gap-1 justify-content-end">
                                    <div className="group-icon-action">
                                        <button
                                            type="button"
                                            className="text-green"
                                            onClick={() => {
                                                console.log("5555", 5555);
                                                console.log("handleClickEditBlog", handleClickEditBlog);
                                                handleClickEditBlog && handleClickEditBlog(data);
                                            }}
                                        >
                                            <i className="bi-pencil-square"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="text-red"
                                            onClick={() => confirmDeleteRecord(data.id)}
                                        >
                                            <i className="bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {contextHolder}
        </>
    );
};

export default Post;
