import { listRoleAdmins } from "@consts";
import { useLoginMutation } from "@redux/queries/auth.api";
import { useLazyGetUserProfileQuery } from "@redux/queries/user.api";
import { authSelector, login, setUserProfile } from "@redux/slices/auth.slice";
import { history } from "@utils/helper-router";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Container from "./components/Container";

const LoginForm: React.FunctionComponent = (props) => {
    const { t } = useTranslation();
    const [doLogin, result] = useLoginMutation();
    const [getUserInfo, lazyUserInfoResult] = useLazyGetUserProfileQuery();
    const dispatch = useDispatch();

    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);
    const isAdmin = user?.isAdmin || (user?.role && listRoleAdmins.includes(user?.role));

    /* ******** BEGIN: Click to i class="bi-key", display password ******** */
    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDown = () => {
        setShowPassword(true);
    };
    const handleMouseUp = () => {
        setShowPassword(false);
    };
    /* ******** END: Click to i class="bi-key", display password ******** */

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Không được bỏ trống Email"),
            password: Yup.string().required("Không được bỏ trống Mật khẩu"),
        }),
        onSubmit: async (values) => {
            const result: any = await doLogin({
                ...values,
            });
            if ("data" in result) {
                dispatch(
                    login({
                        accessToken: result.data.accessToken,
                        type: result.data.type,
                    })
                );
                await handleGetUserInfo();
            }
        },
    });

    const handleGetUserInfo = async () => {
        const result = await getUserInfo();
        if ("data" in result) {
            const user = result.data;
            dispatch(setUserProfile(user as any));

            if (user?.startWelcome || isAdmin) {
                history.navigate("/");
                return;
            }

            history.navigate("/intro", { replace: true });
        }
    };

    useEffect(() => {
        if (isLogin) {
            if (user?.startWelcome || isAdmin) {
                history.navigate("/");
                return;
            }
            history.navigate("/intro", { replace: true });
        }
    }, [isLogin]);

    const errorMessage = () => {
        switch ((result?.error as any)?.errorCode) {
            case 404:
                return "Email chưa đăng ký tài khoản";
            case 1009:
                return "Sai mật khẩu";
            default:
                return "Vui lòng kiểm tra lại kết nối internet của bạn";
        }
    };

    return (
        <Container title={t("page_login_title", "Đăng nhập")}>
            <form
                className="pd-sign w-100"
                onSubmit={formik.handleSubmit}
            >
                <hgroup className="sign-title">
                    <h1>{t("page_login_tit2", "Đăng nhập")}</h1>
                </hgroup>
                <div className="login-with-social">
                    <ul>
                        <li>
                            <Link to={process.env.REACT_APP_HOST + "/api/auth/social/google"}>
                                <img
                                    src="images/social/google.png"
                                    alt="google"
                                />
                            </Link>
                        </li>
                    </ul>
                    <div className="title-center-hr">
                        <hr />
                        <h6>
                            <span>{t("page_login_or", "Hoặc đăng nhập")}</span>
                        </h6>
                        <hgroup className="sign-title">
                            <h3>{t("page_login_desc", "Vui lòng nhập thông tin tài khoản của bạn")}</h3>
                            {(result?.error as any)?.message && <h3 className=" text-red">{errorMessage()}</h3>}
                            <br />
                        </hgroup>
                    </div>
                </div>
                {/* END: Login with Social  */}
                <div className="form-group">
                    <div className="form-floating">
                        <input
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            type="text"
                            className="form-control"
                            placeholder="example@site.com"
                        />

                        <label>{t("page_login_email", "Email hoặc Tên đăng nhập")}</label>
                        <i className="bi-envelope" />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                        // @ts-ignore
                        <p className="text-red">{formik?.errors?.email}</p>
                    ) : null}
                </div>
                <div className="form-group">
                    <div className="form-floating mb-0">
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Tối thiểu 8 ký tự"
                            id="password"
                            name="password"
                            /* ***** use OnBlur to preserve the logged in user value *****  */
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        <label>{t("page_login_pass", "Mật khẩu")}</label>
                        <i
                            className="bi-key"
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onMouseOut={handleMouseUp} /* Catch the event when the mouse moves away from the button */
                            style={{ cursor: "pointer" }}
                        />
                    </div>

                    {formik.touched.password && formik.errors.password ? (
                        // @ts-ignore
                        <p className="text-red">{formik?.errors?.password}</p>
                    ) : null}
                </div>
                <div className="link-sub">
                    <div className="form-check">
                        {/* <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue="true"
                        />

                        <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                        >
                            Remember me
                        </label> */}
                    </div>
                    <button
                        type="button"
                        className="btn-forgot"
                        onClick={() => history.navigate("/forgot-pass")}
                    >
                        {t("page_login_forgotpass", "Bạn quên mật khẩu?")}
                    </button>
                    <div className="clearfix" />
                </div>
                <div className="mirror mirror-child">
                    <button
                        className="btn-submit btn-blue"
                        type="submit"
                        disabled={result.isLoading}
                    >
                        {result.isLoading && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <span className="ps-2">{t("page_login_btn", "Đăng nhập")}</span>
                    </button>
                </div>
                <p className="dont-account">
                    {t("page_login_has_acc", "Bạn chưa có tài khoản?")}{" "}
                    <b>
                        <Link
                            to="/register"
                            className="cursor-pointer"
                        >
                            {t("page_login_register", "Đăng ký ngay!")}
                        </Link>
                    </b>
                </p>
            </form>
        </Container>
    );
};

export default LoginForm;
