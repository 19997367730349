import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/apply/process";

export const adminApplyProcessApi = createApi({
    reducerPath: "adminApplyProcessApi",
    keepUnusedDataFor: 30,
    tagTypes: ["ApplyProcess"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListApplyProcess: builder.query<Array<IApplyProcess>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "ApplyProcess" as const, id })),
                              { type: "ApplyProcess", id: "ApplyProcess-LIST" },
                          ]
                        : [{ type: "ApplyProcess", id: "ApplyProcess-LIST" }],
            }),
            getListApplyProcessPaginate: builder.query<
                IResponseDataAdmin<IApplyProcess>,
                any
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "ApplyProcess" as const, id })),
                              { type: "ApplyProcess", id: "ApplyProcess-LIST" },
                          ]
                        : [{ type: "ApplyProcess", id: "ApplyProcess-LIST" }],
            }),
            createApplyProcess: builder.mutation<any, IApplyProcess>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyProcess"],
            }),
            updateApplyProcess: builder.mutation<any, IApplyProcess>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyProcess"],
            }),
            updateApplyProcessStatus: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/status/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyProcess"],
            }),
            deleteApplyProcess: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyProcess"],
            }),
            deleteMultiApplyProcess: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyProcess"],
            }),
        };
    },
});

export const {
    useGetListApplyProcessQuery,
    useGetListApplyProcessPaginateQuery,
    useLazyGetListApplyProcessPaginateQuery,
    useCreateApplyProcessMutation,
    useUpdateApplyProcessMutation,
    useDeleteApplyProcessMutation,
    useDeleteMultiApplyProcessMutation,
    useUpdateApplyProcessStatusMutation,
} = adminApplyProcessApi;
