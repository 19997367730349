import React, { useImperativeHandle } from "react";

export interface FormModalImageRef {
    openModal: () => void;
    closeModal: () => void;
}

interface FormProposalProps {
    image: string;
}

const ModalImage: React.ForwardRefRenderFunction<FormModalImageRef, FormProposalProps> = (props, ref) => {
    var slideIndex = 1;
    showSlides(slideIndex);

    function plusSlides(n: any) {
        showSlides((slideIndex += n));
    }

    function currentSlide(n: any) {
        showSlides((slideIndex = n));
    }

    function showSlides(n: any) {
        let i;
        let slides: any = document.getElementsByClassName("mySlides");
        let dots: any = document.getElementsByClassName("demo");
        let captionText = document.getElementById("caption");

        if (n > slides.length) {
            slideIndex = 1;
        }
        if (n < 1) {
            slideIndex = slides.length;
        }
        for (i = 0; i < slides.length; i++) {
            if (slides.length > 0) {
                // @ts-ignore
                slides[i].style.display = "none";
            }
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        if (slides.length > 0) {
            slides[slideIndex - 1].style.display = "block";
        }

        // dots[slideIndex - 1].className += " active";
        // captionText.innerHTML = dots.[slideIndex - 1]?.alt;
    }

    function openModal() {
        if (document) {
            (document as any).getElementById("myModal").style.display = "block";
        }
    }

    function closeModal() {
        if (document) {
            (document as any).getElementById("myModal").style.display = "none";
        }
    }

    useImperativeHandle(ref, () => ({
        openModal: () => {
            openModal();
            currentSlide(1);
        },
        closeModal: () => {
            closeModal();
        },
    }));

    return (
        <div
            id="myModal"
            className="modal modal-libaries-img"
        >
            <div className="modal-content modal-dialog-centered">
                <span
                    className="close cursor"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    ×
                </span>
                <div className="mySlides">
                    {/* <div className="numbertext">1 / 7</div> */}
                    <img
                        src={props.image ? `${process.env.REACT_APP_CDN}${props.image}` : ""}
                        style={{ width: "100%" }}
                    />
                </div>

                <div className="caption-container">
                    <p id="caption" />
                </div>
            </div>
        </div>
    );
};

export default React.forwardRef(ModalImage);
