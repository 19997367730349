import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

export const introApi = createApi({
    reducerPath: "intro",
    tagTypes: ["User-intro"],
    keepUnusedDataFor: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getStatisticUserDashbard: builder.query<StatisticsUserDashboard, void>({
                query: () => {
                    return {
                        url: `api/statistics`,
                    };
                },
            }),

            getSchoolNewsHome: builder.query<ResponseWithPaginateFormat<SchoolNews>, any>({
                query: (params) => ({
                    url: "/api/school/news/home/paginate",
                    params,
                }),
            }),

            schoolNewsHome: builder.mutation<ResponseWithPaginateFormat<SchoolNews>, any>({
                query: (params) => ({
                    url: "/api/school/news/home/paginate",
                    params,
                }),
            }),

            startWelcome: builder.mutation<Response<null>, void>({
                query: () => ({
                    url: "/api/user/start-welcome",
                    method: "POST",
                }),
            }),

            welcome: builder.mutation<Response<ResponseWelcomeUser>, InputWelcomeUser>({
                query: (args) => ({
                    url: "/api/user/welcome",
                    method: "PATCH",
                    body: args,
                }),
            }),
        };
    },
});

export const {
    useGetStatisticUserDashbardQuery,
    useSchoolNewsHomeMutation,
    useStartWelcomeMutation,
    useWelcomeMutation,
    useGetSchoolNewsHomeQuery,
} = introApi;
