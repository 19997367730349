import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/notification";

export const adminNotificationApi = createApi({
    reducerPath: "adminNotificationApi",
    keepUnusedDataFor: 3,
    tagTypes: ["Notification"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListNotification: builder.query<Array<any>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "Notification" as const, id })),
                              { type: "Notification", id: "Notification-LIST" },
                          ]
                        : [{ type: "Notification", id: "Notification-LIST" }],
            }),
            getListNotificationPaginate: builder.query<IResponseDataAdmin<any>, { page: number; limit: number; name?: string }>(
                {
                    query: (query) => ({
                        url: `${BASE}/paginate`,
                        method: "GET",
                        params: {
                            ...query
                        },
                    }),
                    transformErrorResponse(response, meta, arg) {
                        return response.data;
                    },
                    providesTags: (result, error, page) =>
                        result
                            ? [
                                  ...result.items.map(({ id }) => ({ type: "Notification" as const, id })),
                                  { type: "Notification", id: "Notification-LIST" },
                              ]
                            : [{ type: "Notification", id: "Notification-LIST" }],
                }
            ),
            createNotification: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Notification"],
            }),
            updateNotification: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Notification"],
            }),
            deleteNotification: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Notification"],
            }),
            deleteMultiNotification: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Notification"],
            }),
            sendNotification: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/sent/${params.id}`,
                    method: "PATCH",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
        };
    },
});

export const {
    useGetListNotificationQuery,
    useGetListNotificationPaginateQuery,
    useLazyGetListNotificationPaginateQuery,
    useCreateNotificationMutation,
    useUpdateNotificationMutation,
    useDeleteNotificationMutation,
    useDeleteMultiNotificationMutation,
    useSendNotificationMutation,
} = adminNotificationApi;
