export enum UserStatus {
    ACTIVE = 1,
    INACTIVE = 0,
}

export enum UserRole {
    ADMIN = 1,
    CUSTOMER = 2,
    MEMBER = 3,
    SALE_MANAGER = 4,
    MARKETING_MANAGER = 5,
    HEAD_OF_TRANSLATION = 6,
    HEAD_OF_ACADEMIC = 7,
    HEAD_OF_DOCUMENT = 8,
    CHIEF_ACCOUNTANT = 9,
    BUSINESS_STAFF = 10,
    MARKETING_STAFF = 11,
    TRANSLATOR = 12,
    ACADEMIC_STAFF = 13,
    DOCUMENT_STAFF = 14,
    ACCOUNTANT = 15,
    INTERPRETER = 16,
}

export const UserRoleAccessGrant = [UserRole.ADMIN, UserRole.SALE_MANAGER, UserRole.MARKETING_MANAGER];
export const UserRoleGrant = [
    UserRole.SALE_MANAGER,
    UserRole.MARKETING_MANAGER,
    UserRole.HEAD_OF_TRANSLATION,
    UserRole.HEAD_OF_ACADEMIC,
    UserRole.HEAD_OF_DOCUMENT,
    UserRole.CHIEF_ACCOUNTANT,
    UserRole.BUSINESS_STAFF,
    UserRole.MARKETING_STAFF,
    UserRole.TRANSLATOR,
    UserRole.ACADEMIC_STAFF,
    UserRole.DOCUMENT_STAFF,
    UserRole.ACCOUNTANT,
    UserRole.INTERPRETER,
];

export enum UserGender {
    MALE = "Male",
    FEMALE = "Female",
    OTHER = "Other",
}

export enum UserAttributeName {
    GPA = "GPA",
    HSK = "HSK",
    LEVEL_HSK = "LEVEL_HSK",
    HSKK = "HSKK",
    LEVEL_HSKK = "LEVEL_HSKK",
    IELTS = "IELTS",
    MERIT = "MERIT",
    MERIT_RELATED_MAJORS = "MERIT_RELATED_MAJORS",
}

export enum UserConfigName {
    ENABLE_NOTIFICATION = "ENABLE_NOTIFICATION",
    ENABLE_NOTIFICATION_EMAIL = "ENABLE_NOTIFICATION_EMAIL",
}

export enum UserGrantProcessType {
    PROCESSING = "PROCESSING",
    FINISHED = "FINISHED",
    CANCELED = "CANCELED",
}

export enum HSK {
    HSK1 = "HSK1",
    HSK2 = "HSK2",
    HSK3 = "HSK3",
    HSK4 = "HSK4",
    HSK5 = "HSK5",
    HSK6 = "HSK6",
}

export enum HSKK {
    HSKK_BEGINNER = "HSKK_BEGINNER",
    HSKK_INTERMEDIATE = "HSKK_INTERMEDIATE",
    HSKK_ADVANCED = "HSKK_ADVANCED",
}

export enum UserContractService {
    BASIC_PACKAGE = "BASIC_PACKAGE",
    PREMIUM_PACKAGE = "PREMIUM_PACKAGE",
    CSC_PACKAGE = "CSC_PACKAGE",
    CONSULTING_PACKAGE = "CONSULTING_PACKAGE",
}

export enum UserContractMethod {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE",
}

export enum UserContractPaymentStatus {
    PENDING = "PENDING",
    PAID = "PAID",
}

export const listUserRole = [
    { value: UserRole.ADMIN, label: "Admin", color: "red" },
    { value: UserRole.CUSTOMER, label: "Khách hàng", color: "blue" },
    { value: UserRole.MEMBER, label: "Thành viên", color: "green" },
    { value: UserRole.SALE_MANAGER, label: "Quản lý bán hàng", color: "orange" },
    { value: UserRole.MARKETING_MANAGER, label: "Quản lý Marketing", color: "purple" },
    { value: UserRole.HEAD_OF_TRANSLATION, label: "Trưởng bộ phận Dịch thuật", color: "pink" },
    { value: UserRole.HEAD_OF_ACADEMIC, label: "Trưởng bộ phận Học thuật", color: "yellow" },
    { value: UserRole.HEAD_OF_DOCUMENT, label: "Trưởng bộ phận Hồ sơ", color: "brown" },
    { value: UserRole.CHIEF_ACCOUNTANT, label: "Kế toán trưởng", color: "cyan" },
    { value: UserRole.BUSINESS_STAFF, label: "Nhân viên kinh doanh", color: "teal" },
    { value: UserRole.MARKETING_STAFF, label: "Nhân viên Marketing", color: "indigo" },
    { value: UserRole.TRANSLATOR, label: "Biên dịch viên", color: "violet" },
    { value: UserRole.ACADEMIC_STAFF, label: "Nhân viên Học thuật", color: "grey" },
    { value: UserRole.DOCUMENT_STAFF, label: "Nhân viên Hồ sơ", color: "black" },
    { value: UserRole.ACCOUNTANT, label: "Nhân viên kế toán", color: "olive" },
    { value: UserRole.INTERPRETER, label: "Phiên dịch viên", color: "brown" },
];

export const HSK_MAX = 300;
export const HSKK_MAX = 100;
