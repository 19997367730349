import { CloseOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useToast } from "@components/toast/ToastProvider";
import { useGetListApplyTaskQuery } from "@redux/queries/admin/admin.apply.task";
import { useGetListUserGrantQuery } from "@redux/queries/admin/admin.user";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space, Tag, Typography } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import UpdateProcessStatus from "./UpdateProcessStatus";

const { Text } = Typography;
interface IProps {
    form: any;
    data?: any;
}

const StepTab: React.FunctionComponent<IProps> = (props: IProps) => {
    const { showToast } = useToast();

    const [selectedSchoolService, setSelectedSchoolService] = useState<any>(null);
    const { data: list_user_grant } = useGetListUserGrantQuery();
    const [sortedApplySteps, setSortedApplySteps] = useState<any>([]);

    useEffect(() => {
        if (props.data) {
            const clone = [...(props?.data || [])];
            const sorted = clone.sort((a: any, b: any) => a.order - b.order);
            setSortedApplySteps(sorted);
        }
    }, [props.data]);

    useEffect(() => {
        if (selectedSchoolService) {
            props.form.setFieldsValue({ schoolServiceId: selectedSchoolService });
        }
    }, [selectedSchoolService]);

    const { data: list_apply_task } = useGetListApplyTaskQuery();

    const applyStepStatusColor = {
        PROCESSING: "blue",
        DONE: "green",
    };

    const applyProcessStatusColor = {
        PENDING: "yellow",
        PROCESSING: "blue",
        DONE: "green",
    };

    const applySteps = props.data;

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                {sortedApplySteps &&
                    sortedApplySteps.map((item: any) => (
                        <Card
                            title={item?.step.name}
                            key={item.id}
                            extra={<Tag color={_.get(applyStepStatusColor, item?.status)}>{item?.status}</Tag>}
                        >
                            <Form.List
                                name={["steps", item.id, "processes"]}
                                initialValue={
                                    item?.applyProcess?.length > 0
                                        ? item?.applyProcess.map((item: any) => {
                                              return {
                                                  ...item,
                                                  expiredAt: dayjs(item.expiredAt),
                                              };
                                          })
                                        : [{}]
                                }
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
                                        <Row>
                                            {fields
                                                .sort((a: any, b: any) => {
                                                    const aId = _.get(item?.applyProcess, [a.fieldKey, "id"]);
                                                    const bId = _.get(item?.applyProcess, [b.fieldKey, "id"]);
                                                    if (aId <= bId) {
                                                        return -1;
                                                    }
                                                    return 1;
                                                })
                                                .map((field, indexProcess) => (
                                                    <Col
                                                        span={6}
                                                        key={indexProcess}
                                                    >
                                                        <Card
                                                            size="small"
                                                            title={
                                                                <Space>
                                                                    <Text>Process {indexProcess + 1}</Text>
                                                                    {_.get(item?.applyProcess, [
                                                                        indexProcess,
                                                                        "status",
                                                                    ]) && (
                                                                        <Tag
                                                                            color={_.get(
                                                                                applyProcessStatusColor,
                                                                                _.get(item?.applyProcess, [
                                                                                    indexProcess,
                                                                                    "status",
                                                                                ])
                                                                            )}
                                                                        >
                                                                            {_.get(item?.applyProcess, [
                                                                                indexProcess,
                                                                                "status",
                                                                            ])}
                                                                        </Tag>
                                                                    )}
                                                                </Space>
                                                            }
                                                            extra={
                                                                indexProcess > 0 ? (
                                                                    <CloseOutlined
                                                                        onClick={() => {
                                                                            remove(field.name);
                                                                        }}
                                                                    />
                                                                ) : null
                                                            }
                                                        >
                                                            <div className="form-group">
                                                                <div className="form-floating">
                                                                    <Form.Item
                                                                        name={[field.name, "applyTaskId"]}
                                                                        className="form-floating"
                                                                    >
                                                                        <Select
                                                                            className="form-control form-floating"
                                                                            bordered={false}
                                                                            showSearch
                                                                            filterOption={(input, option) =>
                                                                                (option?.children as any)
                                                                                    ?.toLowerCase()
                                                                                    .indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {list_apply_task &&
                                                                                list_apply_task.map(
                                                                                    (o: any, i: any) => {
                                                                                        return (
                                                                                            <Select.Option
                                                                                                key={o.id}
                                                                                                value={o.id}
                                                                                            >
                                                                                                {o.name}
                                                                                            </Select.Option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <label>Công việc</label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="form-floating">
                                                                    <Form.Item
                                                                        name={[field.name, "title"]}
                                                                        className="form-floating"
                                                                    >
                                                                        <Input
                                                                            type="text"
                                                                            className="form-floating no-icon"
                                                                            placeholder=""
                                                                        />
                                                                    </Form.Item>
                                                                    <label>Tiêu đề</label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="form-floating">
                                                                    <Form.Item
                                                                        name={[field.name, "description"]}
                                                                        className="form-floating"
                                                                    >
                                                                        <Input.TextArea
                                                                            style={{ height: 100 }}
                                                                            placeholder=""
                                                                        />
                                                                    </Form.Item>
                                                                    <label>Mô tả</label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="form-floating">
                                                                    <Form.Item
                                                                        name={[field.name, "expiredAt"]}
                                                                        className="form-floating"
                                                                    >
                                                                        <DatePicker
                                                                            className="no-icon form-floating"
                                                                            style={{ width: "100%", height: 45 }}
                                                                            format="DD/MM/YYYY"
                                                                        />
                                                                    </Form.Item>
                                                                    <label>Ngày hết hạn</label>
                                                                </div>
                                                            </div>
                                                            <Space
                                                                direction="vertical"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <Text> Gán quyền </Text>

                                                                <Form.List
                                                                    name={[field.name, "grants"]}
                                                                    initialValue={
                                                                        _.get(item?.applyProcess, [
                                                                            indexProcess,
                                                                            "applyGrant",
                                                                        ])
                                                                            ? _.get(item?.applyProcess, [
                                                                                  indexProcess,
                                                                                  "applyGrant",
                                                                              ]).map((item: any) => {
                                                                                  return {
                                                                                      ...item,
                                                                                      expiredAt: dayjs(item.expiredAt),
                                                                                  };
                                                                              })
                                                                            : [{}]
                                                                    }
                                                                >
                                                                    {(subFields, subOpt, { errors }) => (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                rowGap: 16,
                                                                            }}
                                                                        >
                                                                            {subFields.map((subField, index) => (
                                                                                <div
                                                                                    key={subField.key}
                                                                                    className="space-medias-tab"
                                                                                    style={{ marginBottom: -20 }}
                                                                                >
                                                                                    <Space
                                                                                        direction="vertical"
                                                                                        className="form-group"
                                                                                        style={{
                                                                                            width: "90%",
                                                                                            display: "inline-block",
                                                                                        }}
                                                                                    >
                                                                                        <div className="form-floating">
                                                                                            <Form.Item
                                                                                                {...subField}
                                                                                                name={[
                                                                                                    subField.name,
                                                                                                    "userId",
                                                                                                ]}
                                                                                                className="form-floating"
                                                                                            >
                                                                                                <Select
                                                                                                    className="form-control form-floating"
                                                                                                    bordered={false}
                                                                                                    placeholder=""
                                                                                                    showSearch
                                                                                                    filterOption={(
                                                                                                        input,
                                                                                                        option
                                                                                                    ) =>
                                                                                                        (
                                                                                                            option?.children as any
                                                                                                        )
                                                                                                            ?.toLowerCase()
                                                                                                            .indexOf(
                                                                                                                input.toLowerCase()
                                                                                                            ) >= 0
                                                                                                    }
                                                                                                >
                                                                                                    {list_user_grant &&
                                                                                                        list_user_grant.map(
                                                                                                            (
                                                                                                                o: any,
                                                                                                                i: any
                                                                                                            ) => {
                                                                                                                return (
                                                                                                                    <Select.Option
                                                                                                                        key={
                                                                                                                            o.id
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            o.id
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {`${
                                                                                                                            o.firstName ||
                                                                                                                            ""
                                                                                                                        } ${
                                                                                                                            o.lastName ||
                                                                                                                            ""
                                                                                                                        } - ${
                                                                                                                            o.code ||
                                                                                                                            ""
                                                                                                                        }`}
                                                                                                                    </Select.Option>
                                                                                                                );
                                                                                                            }
                                                                                                        )}
                                                                                                </Select>
                                                                                            </Form.Item>
                                                                                            <label>Người xử lý</label>
                                                                                        </div>
                                                                                        <div className="form-floating">
                                                                                            <Form.Item
                                                                                                {...subField}
                                                                                                name={[
                                                                                                    subField.name,
                                                                                                    "expiredAt",
                                                                                                ]}
                                                                                                className="form-floating"
                                                                                            >
                                                                                                <DatePicker
                                                                                                    className="no-icon form-floating"
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        height: 45,
                                                                                                    }}
                                                                                                    format="DD/MM/YYYY"
                                                                                                />
                                                                                            </Form.Item>
                                                                                            <label>Ngày hết hạn</label>
                                                                                        </div>
                                                                                    </Space>
                                                                                    {index > 0 ? (
                                                                                        <MinusCircleOutlined
                                                                                            className="dynamic-delete-button"
                                                                                            style={{
                                                                                                width: "10%",
                                                                                                display: "inline-block",
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                subOpt.remove(
                                                                                                    subField.name
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    ) : null}
                                                                                </div>
                                                                            ))}
                                                                            <Form.Item>
                                                                                <Form.ErrorList errors={errors} />
                                                                            </Form.Item>
                                                                            <Button
                                                                                type="dashed"
                                                                                onClick={() => subOpt.add()}
                                                                                block
                                                                                style={{ marginTop: -40 }}
                                                                            >
                                                                                + Thêm gán quyền
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </Form.List>
                                                            </Space>
                                                            {_.get(item?.applyProcess, [indexProcess, "status"]) && (
                                                                <UpdateProcessStatus
                                                                    item={_.get(item?.applyProcess, [indexProcess])}
                                                                />
                                                            )}
                                                        </Card>
                                                    </Col>
                                                ))}
                                        </Row>

                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                        >
                                            + Thêm process
                                        </Button>
                                    </div>
                                )}
                            </Form.List>
                        </Card>
                    ))}
            </div>
        </>
    );
};

const MemoizedStepTab = React.memo(StepTab);

export default MemoizedStepTab;
