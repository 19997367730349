import {
    useLazyAreasQuery,
    useLazyCitiesQuery,
    useLazyMajorsQuery,
    useLazyProjectsQuery,
    useLazySchoolTypesQuery,
    useLazySpecializedsQuery,
} from "@redux/queries/user/public.api";
import { Field } from "formik";
import { cloneDeep } from "lodash";
import React, { useEffect, useState, useRef, useMemo } from "react";
import ClearFilter from "./ClearFilter";
import ListCheckboxFilter, { FormRefListBox } from "./FilterListCheckbox";
import FilterRank, { FormRef, RANK_MAX, RANK_MIN } from "./FilterRank";

interface FilterSchoolsProps {
    onChange: (filter: SchoolFilter) => void;
}

const FilterSchools: React.FunctionComponent<FilterSchoolsProps> = (props) => {
    const defaultFilter = {
        area: [],
        city: [],
        school_type: [],
        school_project: [],
        major: [],
        specialized: [],
        rank_from: RANK_MIN,
        rank_to: RANK_MAX,
    };
    const { onChange } = props;

    const [filter, setFilter] = useState<SchoolFilter>(defaultFilter);

    const [triggerAreas, resultAreas] = useLazyAreasQuery();
    const [triggerCities, resultCities] = useLazyCitiesQuery();
    const [triggerMajors, resultMajors] = useLazyMajorsQuery();
    const [triggerProjects, resultProjects] = useLazyProjectsQuery();
    const [triggerSchoolTypes, resultSchoolTypes] = useLazySchoolTypesQuery();
    const [triggerSpecializeds, resultSpecializeds] = useLazySpecializedsQuery();

    const refRank = useRef<FormRef>(null);
    const refArea = useRef<FormRefListBox>(null);
    const refCity = useRef<FormRefListBox>(null);
    const refSchoolType = useRef<FormRefListBox>(null);
    const refSchoolProject = useRef<FormRefListBox>(null);
    const refMajor = useRef<FormRefListBox>(null);
    const refSpecilized = useRef<FormRefListBox>(null);

    const areas = (resultAreas?.data?.map((e) => ({
        id: e.id,
        name: e?.language?.find((e) => e.lang === "vi")?.title,
    })) || []) as any;

    const cities = useMemo(() => {
        let result = [];
        if (filter.area.length !== 0) {
            result = (resultCities?.data
                ?.filter((e) => filter.area.includes(e.area.id))
                .map((e) => ({
                    id: e.id,
                    name: e?.language?.find((e) => e.lang === "vi")?.title,
                })) || []) as any;
        }
        return result;
    }, [resultAreas, filter]);

    const schoolTypes = (resultSchoolTypes?.data?.map((e) => ({
        id: e.id,
        name: e?.language?.find((e) => e.lang === "vi")?.title,
    })) || []) as any;

    const schoolProjects = (resultProjects?.data?.map((e) => ({
        id: e.id,
        name: e.name,
    })) || []) as any;

    const majors = (resultMajors?.data?.map((e) => ({
        id: e.id,
        name: e?.language?.find((e) => e.lang === "vi")?.title,
    })) || []) as any;

    const specilizeds = useMemo(() => {
        let result = [];
        if (filter?.major?.length !== 0) {
            result = (resultSpecializeds?.data
                ?.filter((e) => {
                    return filter?.major?.includes(e?.majorId);
                })
                ?.map((e) => ({
                    id: e?.id,
                    name: e?.language?.find((e) => e?.lang === "vi")?.title,
                })) || []) as any;
        }

        return result;
    }, [resultSpecializeds, filter]);

    const handleChangeFilter = (key: string, values: Array<string>) => {
        const cloneFilter = cloneDeep(filter);
        const newFilter = {
            ...cloneFilter,
            [key]: values,
        };

        if (key === "area") {
            const selectedAreaIds = values;
            const selectedCityIds = filter.city;
            const newSelectedCityIds = selectedCityIds.filter((e) => {
                const isCityInSelectedArea = resultCities?.data?.find(
                    (ele) => e === ele.id && selectedAreaIds.includes(ele.area.id)
                );
                return !!isCityInSelectedArea;
            });
            newFilter.city = newSelectedCityIds;
            refCity?.current?.setSelectedList?.(newSelectedCityIds);
        }

        if (key === "major") {
            const selectedMajorIds = values;
            const selectedSpecializedIds = filter.specialized;
            const newSelectedSpecializedIds = selectedSpecializedIds.filter((e) => {
                const isSpecInSelectedMajor = resultSpecializeds?.data?.find(
                    (ele) => e === ele.id && selectedMajorIds.includes(ele.major.id)
                );
                return !!isSpecInSelectedMajor;
            });
            newFilter.specialized = newSelectedSpecializedIds;
            refSpecilized?.current?.setSelectedList?.(newSelectedSpecializedIds);
        }

        setFilter(newFilter);
        onChange(newFilter);
    };

    const handleChangeRank = (values: { rankFrom: number; rankTo: number }) => {
        const cloneFilter = cloneDeep(filter);
        const newFilter = {
            ...cloneFilter,
            rank_from: values.rankFrom,
            rank_to: values.rankTo,
        };
        setFilter(newFilter);
        onChange(newFilter);
    };

    const handleResetFilter = () => {
        refRank?.current?.resetFields?.();
        refArea?.current?.resetFields?.();
        refCity?.current?.resetFields?.();
        refSchoolType?.current?.resetFields?.();
        refSchoolProject?.current?.resetFields?.();
        refMajor?.current?.resetFields?.();
        refSpecilized?.current?.resetFields?.();
        setFilter(defaultFilter);
        onChange(defaultFilter);
    };

    useEffect(() => {
        triggerAreas();
        triggerCities();
        triggerMajors();
        triggerProjects();
        triggerSchoolTypes();
        triggerSpecializeds();
    }, []);

    return (
        <aside className="group-filter-box">
            <ClearFilter onClear={handleResetFilter} />
            <Field name="rank">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <FilterRank
                        title={"Xếp hạng"}
                        ref={refRank}
                        onChange={(values: { rankFrom: number; rankTo: number }) => handleChangeRank(values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
            <Field name="area">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <ListCheckboxFilter
                        ref={refArea}
                        title={"Khu vực"}
                        listData={areas}
                        onChange={(values: Array<string>) => handleChangeFilter("area", values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
            {cities.length !== 0 && (
                <Field name="city">
                    {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                        <ListCheckboxFilter
                            ref={refCity}
                            title="Thành phố"
                            listData={cities}
                            onChange={(values: Array<string>) => handleChangeFilter("city", values)}
                            field={field}
                            form={form}
                        />
                    )}
                </Field>
            )}
            <Field name="shool_type">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <ListCheckboxFilter
                        ref={refSchoolType}
                        title="Loại hình trường"
                        listData={schoolTypes}
                        onChange={(values: Array<string>) => handleChangeFilter("school_type", values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
            <Field name="school_project">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <ListCheckboxFilter
                        ref={refSchoolProject}
                        title="Dự án"
                        listData={schoolProjects}
                        onChange={(values: Array<string>) => handleChangeFilter("school_project", values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
            <Field name="major">
                {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                    <ListCheckboxFilter
                        ref={refMajor}
                        title="Ngành học"
                        listData={majors}
                        onChange={(values: Array<string>) => handleChangeFilter("major", values)}
                        field={field}
                        form={form}
                    />
                )}
            </Field>
            {specilizeds.length !== 0 && (
                <Field name="specialized">
                    {({ field, form, meta }: { field: any; form: any; meta: any }) => (
                        <ListCheckboxFilter
                            ref={refSpecilized}
                            title="Chuyên ngành"
                            listData={specilizeds}
                            onChange={(values: Array<string>) => handleChangeFilter("specialized", values)}
                            field={field}
                            form={form}
                        />
                    )}
                </Field>
            )}
        </aside>
    );
};

export default FilterSchools;
