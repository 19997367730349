import moment from "moment";
import React from "react";
import defaultAvatar from "@assets/images/avatar-lg.jpg";
import { linkCDN } from "@utils/common";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "@redux/slices/auth.slice";

interface TicketItemProps {
    id: string;
    title: string;
    avatar: string;
    time: string;
    tag: Array<string>;
    question: string;
    rate: number;
}

const TicketItem: React.FunctionComponent<TicketItemProps> = (props) => {
    const colors = ["text-box-green", "text-box-red", "text-box-blue"];

    const { user } = useSelector(authSelector);

    return (
        <Link to={`/ticket/${props.id}#ticket-tab`}>
            <div className="item-post-review hover-effect">
                <div className="row">
                    <div>
                        <img
                            className="avatar-user-circle object-fit-cover"
                            src={linkCDN(user?.avatar, defaultAvatar)}
                            alt=""
                        />
                        <div className="content-user-review">
                            <div className="group-header-review">
                                <h6>{props.title}</h6>
                                <time className="color-text">{moment(props.time).format("DD/MM/YYYY HH:mm")}</time>

                                <div className="my-2">
                                    {props.tag.map((e, i) => {
                                        const index = i % 3;
                                        return (
                                            <span
                                                key={i}
                                                className={colors[index] + " me-1"}
                                            >
                                                {e}
                                            </span>
                                        );
                                    })}
                                </div>

                                <div className="clearfix" />
                            </div>
                            <p className="color-text">{props.question}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default TicketItem;
