import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";
import queryString from "query-string";

const BASE = "/api/user/blog/category";

export const categoryBlogApi = createApi({
    reducerPath: "categoryBlogApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["CategoryBlog"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListBlogCategory: builder.query<Array<IBlogCategory>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, mete, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "CategoryBlog" as const, id })),
                              { type: "CategoryBlog", id: "CategoryBlog-LIST" },
                          ]
                        : [{ type: "CategoryBlog", id: "CategoryBlog-LIST" }],
            }),
            getBlogCategoryById: builder.query<IBlogCategory, { id: string }>({
                query: (query) => ({
                    url: `${BASE}/${query.id}`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getBlogCategoryPaginate: builder.query<IResponseDataAdmin<IBlogCategory>, { page: number; limit: number }>({
                query: (query) => ({
                    url: `${BASE}/paginate?` + queryString.stringify(query, { arrayFormat: "bracket" }),
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "CategoryBlog" as const, id })),
                              { type: "CategoryBlog", id: "CategoryBlog-LIST" },
                          ]
                        : [{ type: "CategoryBlog", id: "CategoryBlog-LIST" }],
            }),
            createBlogCategpory: builder.mutation<any, IBlogCategory>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["CategoryBlog"],
            }),
            updateBlogCategory: builder.mutation<any, IBlogCategory>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        language: params.language,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["CategoryBlog"],
            }),
            deleteBlogCategory: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["CategoryBlog"],
            }),
            deleteMultiBlogCategory: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["CategoryBlog"],
            }),
        };
    },
});

export const {
    useCreateBlogCategporyMutation,
    useDeleteBlogCategoryMutation,
    useDeleteMultiBlogCategoryMutation,
    useGetBlogCategoryByIdQuery,
    useGetBlogCategoryPaginateQuery,
    useGetListBlogCategoryQuery,
    useLazyGetBlogCategoryByIdQuery,
    useLazyGetBlogCategoryPaginateQuery,
    useLazyGetListBlogCategoryQuery,
    useUpdateBlogCategoryMutation,
} = categoryBlogApi;
