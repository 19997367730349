import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
interface ContainerProps {
    title: string;
}

const Container: React.FunctionComponent<React.PropsWithChildren<ContainerProps>> = (props) => {
    const { t } = useTranslation();

    return (
        <div className="login-auth">
            {/* BEGIN: Header Logo + Sign Up */}
            <div className="header-login">
                <Link
                    className="logo-header"
                    to="/"
                >
                    <img
                        src="images/logo.png"
                        alt="logo-riba"
                    />
                </Link>
            </div>
            <section className="inline-block">
                <div
                    id="signin"
                    className="other-sign"
                >
                    <div className="row">
                        <div className="col-12 col-lg-6 d-flex pdr-0">
                            <div className="area-introduction">
                                <div className="group-btn-action">
                                    <Link
                                        to="/register"
                                        className="btn-green"
                                    >
                                        Đăng ký
                                    </Link>
                                </div>
                                <div className="welcome">
                                    <h6>{t("cmp_container_welcome")}</h6>
                                    <p>{t("cmp_container_desc")}</p>
                                    <p>Trân trọng!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pdl-0">{props.children}</div>
                    </div>
                </div>
                <p className="copyright-sign">{t("cmp_container_inc", "Bản quyền © 2023 thuộc Studychina, Inc.")}</p>
            </section>
        </div>
    );
};

export default Container;

// Bạn thân mến!

// Riba rất vui khi bạn đã quan tâm đến hệ thống tra cứu học bổng Trung Quốc của chúng tôi. Chúng tôi hiểu rằng du học là một bước ngoặt quan trọng trong cuộc đời của mỗi người, và chúng tôi mong muốn được đồng hành cùng bạn trên hành trình này.

// Trân trọng!
