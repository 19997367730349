import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useCreateRoleMutation,
    useGetListPermissionQuery,
    useUpdateRoleMutation,
} from "@redux/queries/admin/admin.role";
import { useGetListEmployeePaginateQuery } from "@redux/queries/admin/admin.user";
import { Col, Form, Input, message, Modal, Row, Select, Tree } from "antd";
import { uniq } from "lodash";
import { useEffect, useState } from "react";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createRole, isLoadingCreate] = useCreateRoleMutation();
    const [updateRole, isLoadingUpdate] = useUpdateRoleMutation();
    const { showToast } = useToast();

    const {
        data: list_employee,
        isLoading,
        isFetching,
    } = useGetListEmployeePaginateQuery({
        page: 1,
        limit: 1000,
    });

    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue: React.Key[]) => {
        setCheckedKeys(checkedKeysValue);
    };

    const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        setSelectedKeys(selectedKeysValue);
    };

    const [expandedKeysTwo, setExpandedKeysTwo] = useState<React.Key[]>([]);
    const [checkedKeysTwo, setCheckedKeysTwo] = useState<React.Key[]>([]);
    const [selectedKeysTwo, setSelectedKeysTwo] = useState<React.Key[]>([]);
    const [autoExpandParentTwo, setAutoExpandParentTwo] = useState<boolean>(true);

    const onExpandTwo = (expandedKeysValue: React.Key[]) => {
        setExpandedKeysTwo(expandedKeysValue);
        setAutoExpandParentTwo(false);
    };

    const onCheckTwo = (checkedKeysValue: React.Key[]) => {
        setCheckedKeysTwo(checkedKeysValue);
    };

    const onSelectTwo = (selectedKeysValue: React.Key[], info: any) => {
        setSelectedKeysTwo(selectedKeysValue);
    };

    const [expandedKeysThree, setExpandedKeysThree] = useState<React.Key[]>([]);
    const [checkedKeysThree, setCheckedKeysThree] = useState<React.Key[]>([]);
    const [selectedKeysThree, setSelectedKeysThree] = useState<React.Key[]>([]);
    const [autoExpandParentThree, setAutoExpandParentThree] = useState<boolean>(true);

    const onExpandThree = (expandedKeysValue: React.Key[]) => {
        setExpandedKeysThree(expandedKeysValue);
        setAutoExpandParentThree(false);
    };

    const onCheckThree = (checkedKeysValue: React.Key[]) => {
        setCheckedKeysThree(checkedKeysValue);
    };

    const onSelectThree = (selectedKeysValue: React.Key[], info: any) => {
        setSelectedKeysThree(selectedKeysValue);
    };

    const { data: dataPermission } = useGetListPermissionQuery();
    const listResource = uniq(dataPermission?.map((item: any) => item.resource) || []).sort((a: string, b: string) =>
        a.localeCompare(b)
    );
    const treeData = listResource?.map((item: any) => {
        return {
            title: item,
            value: item,
            children: dataPermission
                ?.filter((i: any) => i.resource === item)
                .map((i: any) => ({ title: i.description, key: i.id })),
        };
    });

    useEffect(() => {
        if (dataEdit) {
            form.setFieldsValue({
                ...dataEdit,
                userIds: dataEdit.users.map((item: any) => item.id),
            });
            const permissionIds = dataEdit.permissions.map((item: any) => item.id);
            const checkedKeys = [] as any[];
            const checkedKeysTwo = [] as any[];
            const checkedKeysThree = [] as any[];
            permissionIds.forEach((item: any) => {
                const itemData = dataPermission?.find((i: any) => i.id === item);
                const listOne = treeData.slice(0, treeData.length / 3);
                const listTwo = treeData.slice(treeData.length / 3, (treeData.length / 3) * 2);
                const listThree = treeData.slice((treeData.length / 3) * 2);
                if (listOne.find((i: any) => i.children?.find((j: any) => j.key === itemData?.id))) {
                    checkedKeys.push(itemData?.id);
                }
                if (listTwo.find((i: any) => i.children?.find((j: any) => j.key === itemData?.id))) {
                    checkedKeysTwo.push(itemData?.id);
                }
                if (listThree.find((i: any) => i.children?.find((j: any) => j.key === itemData?.id))) {
                    checkedKeysThree.push(itemData?.id);
                }
            });
            setCheckedKeys(checkedKeys);
            setCheckedKeysTwo(checkedKeysTwo);
            setCheckedKeysThree(checkedKeysThree);
        } else {
            form.resetFields();
            setCheckedKeys([]);
            setCheckedKeysTwo([]);
            setCheckedKeysThree([]);
        }
    }, [dataEdit]);

    const checkUUIDFormat = (uuid: string) => {
        return uuid.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);
    };

    const onFinish = async (values: any) => {
        const permissionIds = [] as any[];
        [...checkedKeys, ...checkedKeysTwo, ...checkedKeysThree].forEach((item: any) => {
            if (checkUUIDFormat(item)) {
                permissionIds.push(item);
            }
        });
        if (permissionIds.length === 0) {
            message.error("Chưa chọn quyền");
            return;
        }

        const result = dataEdit
            ? await updateRole({
                  id: dataEdit.id,
                  ...values,
                  permissionIds,
              })
            : await createRole({
                  ...values,
                  permissionIds,
              });
        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Role" : "Add Role"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1200}
            >
                <div className="body-component">
                    <Row gutter={16}>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="name"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Không được để trống",
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Tên</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="description"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Không được để trống",
                                            },
                                        ]}
                                    >
                                        <Input.TextArea style={{ height: 100 }} />
                                    </Form.Item>
                                    <label>Mô tả</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="userIds"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin bắt buộc",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            placeholder=""
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                            mode="multiple"
                                        >
                                            {list_employee?.items &&
                                                list_employee?.items.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {`${o.firstName || ""} ${o.lastName || ""} - ${
                                                                o.code || ""
                                                            }`}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Nhân viên</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <div className="form-floating">
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Tree
                                        checkable
                                        onExpand={onExpand}
                                        expandedKeys={expandedKeys}
                                        autoExpandParent={autoExpandParent}
                                        onCheck={onCheck as any}
                                        checkedKeys={checkedKeys}
                                        onSelect={onSelect}
                                        selectedKeys={selectedKeys}
                                        treeData={
                                            treeData.filter(
                                                (item: any, index: number) => index < treeData.length / 3
                                            ) as any
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <Tree
                                        checkable
                                        onExpand={onExpandTwo}
                                        expandedKeys={expandedKeysTwo}
                                        autoExpandParent={autoExpandParentTwo}
                                        onCheck={onCheckTwo as any}
                                        checkedKeys={checkedKeysTwo}
                                        onSelect={onSelectTwo}
                                        selectedKeys={selectedKeysTwo}
                                        treeData={
                                            treeData.filter(
                                                (item: any, index: number) =>
                                                    index >= treeData.length / 3 && index < (treeData.length / 3) * 2
                                            ) as any
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <Tree
                                        checkable
                                        onExpand={onExpandThree}
                                        expandedKeys={expandedKeysThree}
                                        autoExpandParent={autoExpandParentThree}
                                        onCheck={onCheckThree as any}
                                        checkedKeys={checkedKeysThree}
                                        onSelect={onSelectThree}
                                        selectedKeys={selectedKeysThree}
                                        treeData={
                                            treeData.filter(
                                                (item: any, index: number) => index >= (treeData.length / 3) * 2
                                            ) as any
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
