import { listTrainingLanguage } from "@consts";
import { useGetListProjectQuery } from "@redux/queries/admin/admin.project";
import { useGetListScholarshipQuery } from "@redux/queries/admin/admin.scholarship";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { Button, Col, Form, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { listSchoolServiceType } from "../school-service.const";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterSchoolService: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [listScholarshipSelected, setListScholarshipSelected] = useState<any[]>([]);
    const [listSpecialized, setListSpecialized] = useState<any[]>([]);
    const { data: list_train, isLoading: isLoadingTrain, error: error } = useGetListTrainingQuery();

    const {
        data: list_scholarship,
        isLoading: isLoadingScholarship,
        error: errorScholarship,
    } = useGetListScholarshipQuery();

    const {
        data: list_specialized,
        isLoading: isLoadingSpecialized,
        error: errorSpecialized,
    } = useGetListSpecializedQuery();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    useEffect(() => {
        setListScholarshipSelected(list_scholarship || []);
    }, [list_scholarship]);

    useEffect(() => {
        setListSpecialized(list_specialized || []);
    }, [list_specialized]);

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        {/* col 1 */}
                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={"scholarshipId"}
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                                showSearch
                                            >
                                                {listScholarshipSelected &&
                                                    listScholarshipSelected.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Học bổng</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        {/* col 2 */}
                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="trainingId"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                            >
                                                {list_train &&
                                                    list_train.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Hệ đào tạo</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="type"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                            >
                                                {listSchoolServiceType &&
                                                    listSchoolServiceType.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.value}
                                                                value={o.value}
                                                            >
                                                                {o.label}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Loại</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={6}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="trainingLanguage"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                            >
                                                {listTrainingLanguage.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.value}
                                                            value={o.value}
                                                        >
                                                            {o.label}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <label>Chương trình đào tạo</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterSchoolService;
