import NumberFormat from "@components/NumberFormat";
import { Divider } from "antd";
import { FC, memo } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface IStatisticsChart {
    chartParams: SchoolStatistic[];
}

const StatisticsChart: FC<IStatisticsChart> = ({ chartParams }) => {
    const dataChart = chartParams
        .map(({ total, year }) => ({
            total,
            year,
        }))
        .reverse();

    return (
        <div className="wrapper chart">
            <Divider />

            <ResponsiveContainer
                width="100%"
                height="100%"
            >
                <LineChart
                    data={dataChart}
                    margin={{
                        top: 12,
                        right: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        tickCount={3}
                        interval="preserveStartEnd"
                        dataKey="year"
                        height={20}
                        axisLine={false}
                        tickLine={false}
                        tickMargin={0}
                        minTickGap={70}
                    />
                    <YAxis
                        tickCount={3}
                        type="number"
                        stroke="#697589"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={24}
                    />
                    <Tooltip
                        content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div className="custom-tooltip">
                                        <p className="label">{payload[0].payload?.year}</p>
                                        <p className="desc">
                                            Tổng số Apply: <NumberFormat value={payload[0].value as number} />
                                        </p>
                                    </div>
                                );
                            }

                            return null;
                        }}
                    />
                    <Line
                        type="monotone"
                        dataKey="total"
                        stroke="#272829"
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default memo(StatisticsChart);
