import { useGetListAreaQuery } from "@redux/queries/admin/admin.area";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React from "react";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterCity: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    const { data: list_area, isLoading: isLoadingArea, error: errorArea } = useGetListAreaQuery();

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="areas"
                                            className="form-floating"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin bắt buộc",
                                                },
                                            ]}
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                                mode="multiple"
                                            >
                                                {list_area &&
                                                    list_area.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language && o.language[0].title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Khu vực</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="title"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Tiêu đề</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterCity;
