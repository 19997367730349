import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/payment";

export const adminPaymentApi = createApi({
    reducerPath: "adminPaymentApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Payment"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListPayment: builder.query<Array<any>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "Payment" as const, id })),
                              { type: "Payment", id: "Payment-LIST" },
                          ]
                        : [{ type: "Payment", id: "Payment-LIST" }],
            }),
            getListPaymentPaginate: builder.query<
                IResponseDataAdmin<any>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Payment" as const, id })),
                              { type: "Payment", id: "Payment-LIST" },
                          ]
                        : [{ type: "Payment", id: "Payment-LIST" }],
            }),
            createPayment: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Payment"],
            }),
            updatePayment: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"])
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Payment"],
            }),
            deletePayment: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Payment"],
            }),
            deleteMultiPayment: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Payment"],
            }),
            approvePayment: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/approve/${params.id}`,
                    method: "POST",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Payment"],
            }),
        };
    },
});

export const {
    useGetListPaymentQuery,
    useGetListPaymentPaginateQuery,
    useLazyGetListPaymentPaginateQuery,
    useCreatePaymentMutation,
    useUpdatePaymentMutation,
    useDeletePaymentMutation,
    useDeleteMultiPaymentMutation,
    useApprovePaymentMutation,
} = adminPaymentApi;
