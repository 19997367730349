import Nodata from "@components/Nodata";
import { useSpecializedBySchoolIdQuery } from "@redux/queries/user/public.api";
import React, { useState } from "react";
import MajorChart from "./MajorChart";

const TabMajor: React.FunctionComponent<{ id?: string }> = ({ id }) => {
    const { data: data } = useSpecializedBySchoolIdQuery(
        {
            id: id || "",
        },
        {
            skip: !id,
        }
    );

    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            {/* BEGIN: Content Majors */}
            <div className="box-component">
                <div className="body-component">
                    <div className="title-element mb-0">
                        <h5>Chuyên ngành đào tạo</h5>
                        <hr />
                    </div>
                    <div className="table-responsive">
                        {data && data?.length > 0 ? (
                            data?.map((e, index) => {
                                const isLastIndex = index === data.length - 1;

                                return (
                                    <div
                                        className="col-md-12"
                                        key={index}
                                    >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h4 className="text-uppercase my-3">{e?.training?.language[0].title}</h4>
                                            <div className="search-bar">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Nhập mã ngành"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </div>
                                        {e?.statistics?.length > 0 && <MajorChart chartParams={e?.statistics} />}
                                        <div className="scroll-table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>STT</th>
                                                        <th>Mã ngành</th>
                                                        <th className="text-start">Tên ngành</th>
                                                        <th>Xếp loại</th>
                                                        <th>Xếp hạng</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {e?.specializeds
                                                        ?.filter(
                                                            (specialized) =>
                                                                specialized?.code
                                                                    ?.toLowerCase()
                                                                    .includes(searchTerm.toLowerCase())
                                                        )
                                                        .map((specialized, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}.</td>
                                                                <td>{specialized?.code}</td>
                                                                <td className="text-start">
                                                                    {
                                                                        specialized?.language?.find(
                                                                            (lang) => lang?.lang === "vi"
                                                                        )?.title
                                                                    }
                                                                </td>
                                                                <td>{specialized?.rankAlphabet}</td>
                                                                <td>{specialized?.rank}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {!isLastIndex && <hr className="mt-5 hr-table" />}
                                    </div>
                                );
                            })
                        ) : (
                            <Nodata />
                        )}
                    </div>
                </div>
            </div>
            {/* END: Content Majors */}
        </>
    );
};

export default TabMajor;
