import { useGetListMajorQuery } from "@redux/queries/admin/admin.majors";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React from "react";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterSchoolSpecialized: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const { data: list_school, isLoading, isFetching } = useGetListSchoolQuery();

    const { data: list_major, isLoading: isLoadingMajor, error: errorMajor } = useGetListMajorQuery({});
    const {
        data: list_specialized,
        isLoading: isLoadingSpecialized,
        error: errorSpecialized,
    } = useGetListSpecializedQuery();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        {/* col 2 */}
                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="schoolId"
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                showSearch
                                                allowClear
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {list_school &&
                                                    list_school.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Trường</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={"majorId"}
                                            className="form-floating"
                                        >
                                            <Select
                                                className="form-control form-floating"
                                                bordered={false}
                                                allowClear
                                            >
                                                {list_major &&
                                                    list_major.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Ngành học</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="specializedId"
                                            className="form-floating"
                                        >
                                            <Select
                                                allowClear
                                                className="form-control form-floating"
                                                bordered={false}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    (option?.children as any)
                                                        ?.toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {list_specialized &&
                                                    list_specialized.map((o: any, i: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={o.id}
                                                                value={o.id}
                                                            >
                                                                {o.language[0].title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <label>Chuyên ngành</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                onClick={() => {
                                                    form.resetFields();
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterSchoolSpecialized;
