import React, { useEffect } from "react";
import searchIcon from "@assets/images/icons/search.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

interface SearchInput {
    name?: string;
}

interface IProps {
    data: SearchInput;
    onSearch: (data: SearchInput) => void;
}

const FilterProject: React.FunctionComponent<IProps> = (props) => {
    const formik = useFormik({
        initialValues: {
            name: props.data.name,
        },
        validationSchema: Yup.object({
            name: Yup.string().optional(),
        }),
        onSubmit: async (values) => {
            console.log(values);
            props.onSearch(values);
        },
    });

    return (
        <>
            <form
                className="search-form"
                method="POST"
                onSubmit={formik.handleSubmit}
            >
                <input
                    name="name"
                    type="text"
                    placeholder="Tìm kiếm tên..."
                    onChange={formik.handleChange}
                />
                <img
                    src={searchIcon}
                    alt="icon-search"
                />
            </form>
        </>
    );
};

export default FilterProject;
