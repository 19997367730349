import { ApplyGrantStatus } from "@pages/admin/apply-grant/apply-grant.const";
import { ApplyStepStatus } from "@pages/admin/apply-step/apply-step.const";
import { linkCDN } from "@utils/common";
import { Modal, message } from "antd";
import { format } from "date-fns";
import { Dispatch, FC, SetStateAction } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCancelApplyMutation } from "@redux/queries/user/user.api";

const listIconStep = [
    "bi-file-break",
    "bi-file-earmark-text-fill",
    "bi-file-ruled-fill",
    "bi-file-earmark-break-fill",
    "bi-file-earmark-check-fill",
];

export const applyStatusOptions: Record<string, Record<string, string>> = {
    [ApplyGrantStatus.PENDING]: {
        label: "Tạm hoãn",
        className: "pending",
        color: "warning",
        icon: " bi-pause-circle-fill",
    },
    [ApplyGrantStatus.PROCESSING]: {
        label: "Đang xử lý",
        className: "processing",
        color: "primary",
        icon: "bi-dash-circle-fill",
    },
    [ApplyGrantStatus.DONE]: {
        label: "Hoàn thành",
        className: "done",
        color: "success",
        icon: "bi-check-circle-fill",
    },
};

interface TabApplySchoolProps {
    apply: ApplyProcess;
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setApplySelected: Dispatch<SetStateAction<ApplyProcess | null>>;
    refetchListApplies: () => void;
}

const TabApplySchool: FC<TabApplySchoolProps> = ({
    apply,
    showModal,
    setShowModal,
    setApplySelected,
    refetchListApplies,
}) => {
    const {
        id,
        createdAt,
        year,
        period,
        majorId,
        specializeds,
        passPoint,
        status,
        storageUri,
        applySteps,
        contract,
        schoolScholarshipTraining,
        school,
        major,
        user,
        applySpecializeds,
        totalDiscuss,
    } = apply || {};

    const [modal, contextHolder] = Modal.useModal();

    const [triggerCancelApply] = useCancelApplyMutation();

    const checkStatusApply = (status: string, isCurrentStep: boolean) => {
        switch (status) {
            case ApplyStepStatus.PROCESSING:
                return isCurrentStep ? "processing" : "";
            case ApplyStepStatus.DONE:
                return "active";
            default:
                return "";
        }
    };

    const handleClickScholarshipOffer = () => {
        setShowModal(true);
        setApplySelected(apply);
    };

    const hadleCancelApply = async (id: string) => {
        try {
            const response = await triggerCancelApply({ id });

            refetchListApplies();
            message.success(`Rút hồ sơ thành công!`);
        } catch (error) {
            console.log(error);
            message.error(`Rút hồ sơ thất bại!`);
        }
    };

    const confirmCancelApply = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    Bạn có chắc chắn muốn rút hồ sơ này khỏi <b>{school?.language?.[0]?.name}?</b>
                </>
            ),
            okText: "Rút hồ sơ",
            cancelText: "Hủy",
            onOk() {
                hadleCancelApply(id);
            },
            centered: true,
        });
    };

    return (
        <>
            {/* <p>It is very easy to customize and it uses in your website application.</p> */}

            <ul className="progressbar">
                {applySteps?.map((step, index) => {
                    const isCurrentStep =
                        index === applySteps?.findIndex((step) => step?.status === ApplyStepStatus.PROCESSING);

                    return (
                        <li
                            key={index}
                            style={{ width: `calc(100% / ${applySteps?.length || 3}` }}
                            className={checkStatusApply(step?.status, isCurrentStep)}
                        >
                            {step?.step?.name}
                        </li>
                    );
                })}
                <div className="clearfix" />
            </ul>

            <h5 className="d-flex align-items-center gap-2">
                <img
                    className="w-32px h-32px flex-shrink-0 object-fit-cover rounded-full bg-gray-200"
                    src={linkCDN(school?.logo)}
                />
                Thông tin Apply {school?.language?.[0]?.name}
                <div className=" d-flex align-items-center justify-content-center flex-shink-0 ms-auto gap-2">
                    {status !== ApplyGrantStatus.CANCELLED && (
                        <button
                            disabled={status === ApplyGrantStatus.PASSED_CHECKING}
                            className="btn btn-sm btn-outline-primary"
                            onClick={handleClickScholarshipOffer}
                        >
                            {status === ApplyGrantStatus.PASSED_CHECKING ? "Đã báo đỗ" : "Báo đỗ"}
                        </button>
                    )}
                    {status !== ApplyGrantStatus.PASSED_CHECKING && (
                        <button
                            disabled={status === ApplyGrantStatus.CANCELLED}
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => confirmCancelApply(id)}
                        >
                            {status === ApplyGrantStatus.CANCELLED ? "Đã rút hồ sơ" : "Rút hồ sơ"}
                        </button>
                    )}
                </div>
            </h5>
            <div className="d-flex align-items-center flex-column mt-4 gap-2">
                <div className="w-100 row row-attributes bg-transparent">
                    <div className="col-6 list-attributes bg-transparent">
                        Khu vực: <b>{school?.area?.language?.[0]?.title}</b>
                    </div>
                    <div className="col-6 list-attributes bg-transparent">
                        Email: <b>{school?.contact?.email}</b>
                    </div>
                    <div className="col-6 list-attributes bg-transparent">
                        Thành phố: <b>{school?.city?.language?.[0]?.title}</b>
                    </div>
                    <div className="col-6 list-attributes bg-transparent">
                        Liên hệ: <b>{school?.contact?.phone}</b>
                    </div>
                    <div className="col-6 list-attributes bg-transparent">
                        Ngành: <b>{major?.language?.[0]?.title}</b>
                    </div>
                    <div className="col-6 list-attributes bg-transparent">
                        Loại Học bổng:{" "}
                        <b>{schoolScholarshipTraining?.schoolScholarship?.scholarshipType?.language?.[0]?.title}</b>
                    </div>
                    <div className="col-6 list-attributes bg-transparent">
                        Chuyên ngành: <b>{applySpecializeds?.[0]?.language?.[0]?.title}</b>
                    </div>

                    <div className="col-6 list-attributes bg-transparent">
                        Học bổng:{" "}
                        <b>{schoolScholarshipTraining?.schoolScholarship?.scholarship?.language?.[0]?.title}</b>
                    </div>
                    <div className="col-6 list-attributes bg-transparent">
                        Năm học: <b>{year}</b>
                    </div>
                    <div className="col-6 list-attributes bg-transparent">
                        Kỳ học: <b>{period}</b>
                    </div>
                </div>
            </div>

            <ul className="box-step-follow">
                {applySteps?.map(({ applyProcess = [], step, status }, index) => {
                    const totalSuccess = applyProcess?.filter(
                        (process: any) => process?.status === ApplyStepStatus.DONE
                    )?.length;

                    return (
                        <li
                            key={index}
                            className={applyStatusOptions[status]?.className}
                        >
                            <i className={`box-i ${listIconStep[index % 5]}`}></i>
                            <span>
                                <strong>
                                    {step?.name}{" "}
                                    {applyProcess?.length ? `(${totalSuccess}/${applyProcess?.length})` : ""}{" "}
                                    <i
                                        className={`text-${applyStatusOptions[status]?.color} ${applyStatusOptions[status]?.icon}`}
                                    ></i>
                                </strong>
                                <div className="text-truncate-container">
                                    {applyProcess?.map((process: any, index: number) => {
                                        return (
                                            <div key={index}>
                                                <div className="d-flex align-items-center fw-bold gap-1">
                                                    <i
                                                        className={`mt-0 text-${applyStatusOptions[process?.status]
                                                            ?.color} ${applyStatusOptions[process?.status]?.icon}`}
                                                    ></i>
                                                    Bước {index + 1} - {process?.title}
                                                </div>

                                                <div className="fst-italic small">
                                                    Thời hạn xử lý:{" "}
                                                    <b>
                                                        {process?.expiredAt &&
                                                            format(new Date(process?.expiredAt), "dd/MM/yyyy")}
                                                        .
                                                    </b>
                                                </div>

                                                <div className="truncate-3">{process?.description}</div>

                                                <hr className="my-1 text-muted" />
                                            </div>
                                        );
                                    })}
                                    <div className="truncate-3">{step?.description}</div>
                                </div>
                                <br />
                            </span>
                        </li>
                    );
                })}
                {/* <li>
                    <i className="box-i bi-file-break"></i>
                    <span>
                        <strong>
                            Kiểm tra <i className="text-green bi-check-circle-fill"></i>
                        </strong>
                        <div className="text-truncate-container">
                            <div className="truncate-3">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book. It has
                                survived not only five centuries, but also the leap into electronic.
                            </div>
                        </div>
                        <br />
                        <i>
                            Thời hạn: từ <b>24/02/2024</b> đến <b>27/02/2024</b>.
                        </i>
                    </span>
                </li> */}
            </ul>
            {contextHolder}
        </>
    );
};

export default TabApplySchool;
