import { useState } from "react";
import FifthStep from "./components/FifthStep";
import FirstStep from "./components/FirstStep";
import FourthStep from "./components/FourthStep";
import SecondStep from "./components/SecondStep";
import ThirdStep from "./components/ThirdStep";
import { initWelcomeUser } from "./constants";
import CompleteStep from "./components/CompleteStep";

const Intro = () => {
    const [dataWelcome, setDataWelcome] = useState<InputWelcomeUser>(initWelcomeUser);
    const [dataComplete, setDataComplete] = useState<ResponseWelcomeUser | null>(null);

    const [activeTab, setActiveTab] = useState(1);

    const listTabs = [
        {
            key: 1,
            children: (
                <FirstStep
                    setActiveTab={setActiveTab}
                    dataWelcome={dataWelcome}
                    setDataWelcome={setDataWelcome}
                />
            ),
        },
        {
            key: 2,
            children: (
                <SecondStep
                    setActiveTab={setActiveTab}
                    dataWelcome={dataWelcome}
                    setDataWelcome={setDataWelcome}
                />
            ),
        },
        {
            key: 3,
            children: (
                <ThirdStep
                    setActiveTab={setActiveTab}
                    dataWelcome={dataWelcome}
                    setDataWelcome={setDataWelcome}
                />
            ),
        },
        {
            key: 4,
            children: (
                <FourthStep
                    setActiveTab={setActiveTab}
                    dataWelcome={dataWelcome}
                    setDataWelcome={setDataWelcome}
                />
            ),
        },
        {
            key: 5,
            children: (
                <FifthStep
                    setActiveTab={setActiveTab}
                    dataWelcome={dataWelcome}
                    setDataWelcome={setDataWelcome}
                    setDataComplete={setDataComplete}
                />
            ),
        },
        {
            key: 6,
            children: <CompleteStep dataComplete={dataComplete} />,
        },
    ];

    return (
        <>
            {listTabs?.map(({ key, children }, index) => (
                <div
                    className={activeTab === key ? "active" : "d-none"}
                    key={index}
                >
                    {children}
                </div>
            ))}
        </>
    );
};

export default Intro;
