import logoSchool from "@assets/images/logo-school.png";
import Show01 from "@assets/images/show01.png";
import NumberFormat from "@components/NumberFormat";
import ModalApply from "@pages/pro-tool/schools/components/ModalApply";
import { authSelector } from "@redux/slices/auth.slice";
import { linkCDN } from "@utils/common";
import { FC, memo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface ICompleteListShools {
    schools: ResponseWithPaginateFormat<ShortSchool> | undefined;
}

const colors = ["text-box-green", "text-box-red", "text-box-blue"];

const CompleteListShools: FC<ICompleteListShools> = ({ schools }) => {
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);

    const [openApply, setOpenApply] = useState<boolean>(false);
    const [schoolSelected, setschoolSelected] = useState("");
    const [scholarshipApply, setScholarshipApply] = useState<IApplyScholarShip>();

    const handleApplySchool = (id: string) => (e: any) => {
        e.preventDefault();

        if (!isLogin) {
            return;
        }

        setschoolSelected(id);
        setOpenApply(true);
    };

    const handleApplyScholarship = (data: IApplyScholarShip) => () => {
        if (!isLogin) {
            return;
        }

        if (data?.id) {
            setScholarshipApply(data);
            setschoolSelected(data?.id);
            setOpenApply(true);
        }
    };

    return (
        <div className="four-blog">
            <div className="title-element">
                <h5>Đề xuất Trường</h5>
                <hr />
            </div>

            {schools?.items &&
                schools?.items?.length > 0 &&
                schools?.items?.map((school, index) => {
                    return (
                        <div
                            className="box-filter-item suggest-item bg-suggest"
                            key={index}
                        >
                            <div className="row align-items">
                                <div className="col-2 col-sm-1">
                                    <div className="box-stt">
                                        <h6>{index + 1}</h6>
                                        <img
                                            className="abs-icon-stt"
                                            src={Show01}
                                            alt="icon"
                                        />
                                    </div>
                                </div>
                                <div className="col-10 col-sm-9">
                                    <div className="row">
                                        <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                                            <div className="box-apply">
                                                <img
                                                    src={linkCDN(school?.logo, logoSchool)}
                                                    alt="icon-default"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-8 col-md-9 col-lg-10">
                                            <h5>{school?.language[0].name}</h5>
                                            <ul>
                                                <li>{school?.area?.language[0].title}</li>
                                                <li>{school?.level}</li>
                                                <li>
                                                    Mã trường: <b>{school?.code}</b>
                                                </li>
                                            </ul>

                                            <div className="group-tags">
                                                {school?.projects?.map((project, index) => {
                                                    const colorIndex = index % 3;

                                                    return (
                                                        <span
                                                            className={colors[colorIndex]}
                                                            key={index}
                                                        >
                                                            {project?.name}
                                                        </span>
                                                    );
                                                })}
                                            </div>

                                            <p className="p-apply">
                                                Số người đang apply:{" "}
                                                <b>
                                                    <NumberFormat value={school?.totalApply} />
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-2">
                                    <div className="body-filter-item">
                                        <ul className="suggest-li">
                                            <li>
                                                <a href={`/schools/${school?.slug}`}>Chi tiết</a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={handleApplySchool(school?.id)}
                                                >
                                                    Apply Now
                                                </a>
                                            </li>
                                            {school?.supportApply && (
                                                <li>
                                                    <a href="#">Hỗ trợ Apply</a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="info-body-star">
                                <div className="group-star">
                                    {new Array(5).fill(0).map((_, index) => {
                                        return (
                                            <i
                                                className={`bi-star-fill ${index < school?.rate ? "active" : ""}`}
                                                key={index}
                                            ></i>
                                        );
                                    })}
                                </div>
                                <p>
                                    <NumberFormat
                                        value={school?.totalReview}
                                        suffix=" Reviews"
                                    />
                                </p>
                            </div>
                            <hr />

                            <div className="table-responsive table-suggest">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Loại học bổng</th>
                                            <th>Học bổng</th>
                                            <th>Hệ đào tạo</th>
                                            <th>Điểm đỗ mùa trước</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {school?.scholarships?.items &&
                                            school?.scholarships?.items?.length > 0 &&
                                            school?.scholarships?.items?.map((scholarship, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {scholarship?.scholarship?.link ? (
                                                                <Link
                                                                    target="_blank"
                                                                    className="text-underline"
                                                                    to={scholarship?.scholarship?.link}
                                                                >
                                                                    {scholarship?.scholarship?.language[0].title}
                                                                </Link>
                                                            ) : (
                                                                scholarship?.scholarship?.language[0].title
                                                            )}
                                                        </td>
                                                        <td>{scholarship?.scholarshipType?.language[0].title}</td>
                                                        <td>
                                                            {scholarship?.trainings?.map((training, index) => (
                                                                <span key={index}>
                                                                    {training?.training?.language[0].title}
                                                                    <br />
                                                                </span>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            <NumberFormat
                                                                value={scholarship?.scholarshipType?.lastPointPass}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn-green"
                                                                onClick={handleApplyScholarship({
                                                                    id: school?.id,
                                                                    scholarshipTypeId: scholarship?.scholarshipTypeId,
                                                                    scholarshipId: scholarship?.scholarshipId,
                                                                    trainingId:
                                                                        scholarship?.trainings?.[0]?.training?.id,
                                                                })}
                                                            >
                                                                Apply Now
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })}
            {/* <!-- END: Item Box Filter --> */}

            {
                <ModalApply
                    open={openApply}
                    schoolId={schoolSelected}
                    onClose={() => setOpenApply(false)}
                    trainingId={scholarshipApply?.trainingId}
                    scholarshipId={scholarshipApply?.scholarshipId}
                    scholarshipTypeId={scholarshipApply?.scholarshipTypeId}
                />
            }
        </div>
    );
};

export default memo(CompleteListShools);
