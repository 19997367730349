import { useCreateDepositMutation, useGetAllMethodQuery } from "@redux/queries/user/deposit.api";
import { message } from "antd";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import * as Yup from "yup";
import ModalConfirmDeposit from "./ModalConfirmDeposit";
import ReCAPTCHA from "react-google-recaptcha";

const FormDeposit = () => {
    const recaptchaRef = useRef<any>(null);

    const { data, isLoading } = useGetAllMethodQuery({});
    const [triggerAddDeposit, { isLoading: isLoadingCreateDeposit }] = useCreateDepositMutation();
    const [selectedMethod, setSelectedMethod] = useState<Method>();
    const [open, setOpen] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            amount: 0,
            note: "",
            methodId: "",
        },
        validationSchema: Yup.object({
            amount: Yup.number().min(1, "Số tiền không được bỏ trống").required("Số tiền không được bỏ trống"),
            methodId: Yup.string().required("Phương thức nạp tiền không được bỏ trống"),
        }),
        onSubmit: async (values) => {
            try {
                await recaptchaRef.current.execute();
                const recaptchaValue = recaptchaRef && recaptchaRef.current && recaptchaRef.current.getValue();

                const result = await triggerAddDeposit({
                    ...values,
                    recaptcha: recaptchaValue,
                }).unwrap();
                formik.resetForm();
                setOpen(false);
                message.success("Tạo giao dịch nạp tiền thành công!");
            } catch (e: any) {
                console.log(e);
                if (e?.data?.errorCode === 1017) {
                    return message.error(
                        "Số tiền nạp phải là một trong các giá trị sau: 100.000, 200.000, 500.000, 1.000.000, 1.500.000, 2.000.000"
                    );
                }

                message.error(e?.data?.message || "Tạo giao dịch nạp tiền thất bại!");
            }
        },
    });

    return (
        <div className="box-item-element">
            <div className="title-element">
                <h5>Nạp tiền</h5>
                <hr />
            </div>
            <div className="body-element">
                <form
                    action=""
                    onSubmit={formik.handleSubmit}
                >
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group form-style">
                                <label>Phương thức nạp tiền</label>
                                <div className="row">
                                    {data?.map((e, i) => (
                                        <div
                                            className="col-6"
                                            key={i}
                                        >
                                            <div className="form-check radio-bank">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    checked={formik.values.methodId === e.id}
                                                    name="chooseBank"
                                                    id={e.id}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            formik.setFieldValue("methodId", e.id, true);
                                                            setSelectedMethod(e);
                                                        }
                                                    }}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={e.id}
                                                >
                                                    <img
                                                        src={`${process.env.REACT_APP_CDN}${e.thumbnail}`}
                                                        alt={e.id}
                                                        width="150"
                                                        height="90"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {formik.touched.methodId && formik.errors.methodId ? (
                                    // @ts-ignore
                                    <p className="text-red">{formik?.errors?.methodId}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <div className="form-group form-style">
                                        <label>Số tiền</label>

                                        <NumericFormat
                                            thousandSeparator
                                            className="form-control"
                                            id="amount"
                                            name="amount"
                                            onValueChange={(e) => {
                                                formik.setFieldValue("amount", e.floatValue);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.amount}
                                        />

                                        {formik.touched.amount && formik.errors.amount ? (
                                            // @ts-ignore
                                            <p className="text-red">{formik?.errors?.amount}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group form-style">
                                        <label>Ghi chú</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="note"
                                            name="note"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.note}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Button trigger modal */}
                            <button
                                type="button"
                                className="btn-blue"
                                onClick={() => {
                                    if (formik.errors.amount || formik.errors.methodId) {
                                        return message.error("Vui lòng điền đầy đủ thông tin");
                                    }

                                    setOpen(true);
                                }}
                            >
                                Thực hiện giao dịch
                            </button>
                            {/* Modal */}
                            <ModalConfirmDeposit
                                isLoading={isLoadingCreateDeposit}
                                methods={data || []}
                                open={open}
                                formik={formik}
                                selectedMethod={selectedMethod}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                onCancel={() => {
                                    formik.resetForm();
                                    setOpen(false);
                                }}
                                onSubmit={() => {
                                    formik.submitForm();
                                }}
                            />
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY as any}
                                ref={recaptchaRef}
                                size="invisible"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormDeposit;
