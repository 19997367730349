import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useDeleteMultiSchoolServiceMutation,
    useDeleteSchoolServiceMutation,
    useGetListSchoolServicePaginateQuery,
} from "@redux/queries/admin/admin.school.service";
import { Button, Modal, Empty, Typography, Spin } from "antd";
import React, { useEffect, useState } from "react";
import FilterSchoolService from "./components/FilterSchoolService";
import HandleForm from "./components/HanleForm";
import SchoolServiceItem from "./components/SchoolServiceItem";
import _ from "lodash";

const { Text } = Typography;

const SchoolService: React.FunctionComponent = () => {
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});
    const [listSelected, setListSelected] = useState<any>(Array.from(Array(10).keys()).map((item) => null));

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching } = useGetListSchoolServicePaginateQuery(query as any);

    useEffect(() => {
        setListSelected(Array.from(Array(10).keys()).map((item) => null));
    }, [data]);

    const [deleteMultiSchoolService, isLoadingMultiDelete] = useDeleteMultiSchoolServiceMutation();

    const handleMultiDelete = async () => {
        const result = await deleteMultiSchoolService({
            ids: listSelected.filter((item: any) => item) as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${
                _.flatten(listSelected).filter((item: any) => item).length
            } bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterSchoolService
                                        data={filter}
                                        onSearch={(value: any) => setFilter(value)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={
                                                    _.flatten(listSelected).filter((item: any) => item).length === 0
                                                }
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                {data?.items && data?.items.length > 0 ? (
                                    data?.items?.map((item: any, index) => {
                                        return (
                                            <SchoolServiceItem
                                                item={item}
                                                key={item.id}
                                                selectedKeys={listSelected[index] || []}
                                                setSelectedKeys={(value: any) => {
                                                    const newList = [...listSelected];
                                                    newList[index] = value;
                                                    setListSelected(newList);
                                                }}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className="d-flex justify-content-center">
                                        <Spin
                                            size="large"
                                            spinning={isLoading}
                                        />
                                    </div>
                                )}
                                <br />
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default SchoolService;
