import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/school/news";

export const adminSchoolNewsApi = createApi({
    reducerPath: "adminSchoolNewsApi",
    keepUnusedDataFor: 30,
    tagTypes: ["SchoolNews"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolNews: builder.query<Array<ISchoolNews>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "SchoolNews" as const, id })),
                              { type: "SchoolNews", id: "SchoolNews-LIST" },
                          ]
                        : [{ type: "SchoolNews", id: "SchoolNews-LIST" }],
            }),
            getListSchoolNewsPaginate: builder.query<
                IResponseDataAdmin<ISchoolNews>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "SchoolNews" as const, id })),
                              { type: "SchoolNews", id: "SchoolNews-LIST" },
                          ]
                        : [{ type: "SchoolNews", id: "SchoolNews-LIST" }],
            }),
            createSchoolNews: builder.mutation<any, ISchoolNews>({
                query: (params) => ({
                    url: params.schoolId ? `${BASE}/${params.schoolId}` : `${BASE}` ,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolNews"],
            }),
            updateSchoolNews: builder.mutation<any, ISchoolNews>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"])
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolNews"],
            }),
            deleteSchoolNews: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolNews"],
            }),
            deleteMultiSchoolNews: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolNews"],
            }),
            getSchoolNewsById: builder.query<ISchoolNews, { id: string }>({
                query: (params) => {
                    if (params.id) {
                        return {
                            url: `${BASE}/${params.id}`,
                            method: "GET",
                        };
                    }
                    return null as any;
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
        };
    },
});

export const {
    useGetListSchoolNewsQuery,
    useGetListSchoolNewsPaginateQuery,
    useLazyGetListSchoolNewsPaginateQuery,
    useCreateSchoolNewsMutation,
    useUpdateSchoolNewsMutation,
    useDeleteSchoolNewsMutation,
    useDeleteMultiSchoolNewsMutation,
    useGetSchoolNewsByIdQuery,
} = adminSchoolNewsApi;
