import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import qs from "qs";
import queryString from "query-string";
import _ from "lodash";

const BASE = "/api/school";

export const adminSchoolApi = createApi({
    reducerPath: "adminSchoolApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["School"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchool: builder.query<Array<IAdminSchool>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            listSchool: builder.mutation<Array<IAdminSchool>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListSchoolPaginate: builder.query<IResponseDataAdmin<IAdminSchool>, any>({
                query: (query) => {
                    const queryObject: any = {
                        url: `${BASE}/paginate?` + queryString.stringify(query, { arrayFormat: "bracket" }),
                        method: "GET",
                    };
                    return queryObject;
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "School" as const, id })),
                              { type: "School", id: "School-LIST" },
                          ]
                        : [{ type: "School", id: "School-LIST" }],
            }),
            getSchoolById: builder.query<IAdminSchool, { id: string }>({
                query: (params) => {
                    return {
                        url: `${BASE}/${params.id}`,
                        method: "GET",
                    };
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getSchoolDetailById: builder.query<IAdminSchool, { id: string }>({
                query: (params) => {
                    return {
                        url: `${BASE}/detail/${params.id}`,
                        method: "GET",
                    };
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            createSchool: builder.mutation<any, IAdminSchoolInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["School"],
            }),
            updateSchool: builder.mutation<any, IAdminSchoolInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["School"],
            }),
            deleteSchool: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["School"],
            }),
            deleteMultiSchool: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["School"],
            }),
        };
    },
});

export const {
    useGetListSchoolQuery,
    useListSchoolMutation,
    useGetListSchoolPaginateQuery,
    useLazyGetListSchoolPaginateQuery,
    useGetSchoolByIdQuery,
    useGetSchoolDetailByIdQuery,
    useLazyGetSchoolDetailByIdQuery,
    useCreateSchoolMutation,
    useUpdateSchoolMutation,
    useDeleteSchoolMutation,
    useDeleteMultiSchoolMutation,
} = adminSchoolApi;
