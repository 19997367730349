import store from "@redux/store";
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { logout } from "../slices/auth.slice";
import { message } from "antd";

const baseQuery = (token: string, type: string) =>
    fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST,
        prepareHeaders(headers, api) {
            headers.set("Authorization", `${type} ${token}`);
            return headers;
        },
    });

export const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    let result = await baseQuery(token, type)(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        api.dispatch(logout());
    }
    if (result.error && result.error.status === 403) {
        // return result;
        message.error("Bạn không có quyền truy cập");
    }

    // if (result.error && result.error.status === 401) {
    //     const refreshResult = awaait fetchBaseQuery({
    //         baseUrl: process.env.REACT_APP_HOST + "/api/v1",
    //         headers: {
    //             Authorization: `Bearer ${refreshtk}`
    //         }
    //     })("/auth/refresh-token", api, extraOptions);

    //     if (refreshResult.data) {
    //         api.dispatch(
    //             refreshToken({
    //                 refreshToken: (refreshResult.data as any).refreshToken,
    //                 accessToken: (refreshResult.data as any).accessToken,
    //             })
    //         );
    //         // retry the initial query
    //         result = await baseQuery((refreshResult.data as any).accessToken)(args, api, extraOptions);
    //     } else {
    //         api.dispatch(logout());
    //     }
    // }
    return result;
};
