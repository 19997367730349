import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FC } from "react";
import { useParams } from "react-router-dom";

import Img01 from "@assets/images/item-01.jpg";
import Nodata from "@components/Nodata";
import { useGetPaginateSpecializedsQuery, useGetSpecializedDetailsQuery } from "@redux/queries/user/public.api";
import { linkCDN } from "@utils/common";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";

const PAGE = 1;
const LIMIT = 5;

const SpecializedDetails: FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data } = useGetSpecializedDetailsQuery(id ? { id } : skipToken);
    const { createdAt, thumbnail, language, level, rank, rating, major, training } = data || {};

    const { data: dataPaginateSpecialized } = useGetPaginateSpecializedsQuery(
        training?.id
            ? {
                  page: PAGE,
                  limit: LIMIT,
                  trainings: training?.id,
              }
            : skipToken
    );
    const { items: listSpecializeds } = dataPaginateSpecialized || {};

    return (
        <section id="content-main">
            <hgroup className="title-page">
                <h2>Chi tiết chuyên ngành</h2>
            </hgroup>

            <div className="row">
                <div className="col-12 col-sm-8">
                    <div className="box-component">
                        <div className="body-component">
                            <article className="detail-content">
                                <img
                                    className="img-fluid"
                                    src={linkCDN(thumbnail, Img01)}
                                    alt="img-item"
                                />
                                <ul className="infor-out-detail">
                                    <li>
                                        <i className="bi-award"></i> Hệ: {training?.language?.[0]?.title}
                                    </li>
                                    <li>
                                        <i className="bi-list-stars"></i> Xếp hạng: {rank}
                                    </li>
                                    <li>
                                        <i className="bi-bar-chart-fill"></i> Cấp độ: {level}
                                    </li>
                                    <li>
                                        <i className="bi-star-fill"></i> Đánh giá: {rating}
                                    </li>
                                </ul>
                                <h1>{language?.[0]?.title}</h1>
                                <p className="summary">{language?.[0]?.shortContent}</p>

                                <p className="content pe-1 render-content-html">
                                    {language?.[0]?.content && renderHTML(language?.[0]?.content)}
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-4">
                    <aside>
                        <div className="box-item-element">
                            <div className="title-element">
                                <h5>Chuyên ngành liên quan</h5>
                                <hr />
                            </div>
                            <div className="body-element">
                                {listSpecializeds && listSpecializeds?.length > 0 ? (
                                    listSpecializeds?.map((item, index) => {
                                        return (
                                            <div
                                                className="box-item"
                                                key={index}
                                            >
                                                <div className="row">
                                                    <div className="col-12 col-sm-5">
                                                        <Link to={`/specialized/${item?.id}`}>
                                                            <img
                                                                className="img-fluid"
                                                                src={linkCDN(item?.thumbnail, Img01)}
                                                                alt=""
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-sm-7">
                                                        <h5>
                                                            <Link to={`/specialized/${item?.id}`}>
                                                                {item?.language?.[0]?.title}
                                                            </Link>
                                                        </h5>
                                                        <p>{item?.language?.[0]?.shortContent}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Nodata />
                                )}
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </section>
    );
};

export default SpecializedDetails;
