import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/role";

export const adminRoleApi = createApi({
    reducerPath: "adminRoleApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Role", "Permission"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListRole: builder.query<Array<any>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "Role" as const, id })),
                              { type: "Role", id: "Role-LIST" },
                          ]
                        : [{ type: "Role", id: "Role-LIST" }],
            }),
            getListPermission: builder.query<Array<any>, void>({
              query: (query) => ({
                  url: `api/permission/`,
                  method: "GET",
              }),
              transformErrorResponse(response, meta, arg) {
                  return response.data;
              },
              providesTags: (result, error, page) =>
                  result
                      ? [
                            ...result.map(({ id }) => ({ type: "Permission" as const, id })),
                            { type: "Permission", id: "Permission-LIST" },
                        ]
                      : [{ type: "Permission", id: "Permission-LIST" }],
          }),
            getListRolePaginate: builder.query<IResponseDataAdmin<any>, { page: number; limit: number; name?: string }>(
                {
                    query: (query) => ({
                        url: `${BASE}/paginate`,
                        method: "GET",
                        params: {
                            page: query.page,
                            limit: query.limit,
                            name: query.name,
                        },
                    }),
                    transformErrorResponse(response, meta, arg) {
                        return response.data;
                    },
                    providesTags: (result, error, page) =>
                        result
                            ? [
                                  ...result.items.map(({ id }) => ({ type: "Role" as const, id })),
                                  { type: "Role", id: "Role-LIST" },
                              ]
                            : [{ type: "Role", id: "Role-LIST" }],
                }
            ),
            createRole: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Role"],
            }),
            updateRole: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Role"],
            }),
            deleteRole: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Role"],
            }),
            deleteMultiRole: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Role"],
            }),
        };
    },
});

export const {
    useGetListRoleQuery,
    useGetListPermissionQuery,
    useGetListRolePaginateQuery,
    useLazyGetListRolePaginateQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useDeleteMultiRoleMutation,
} = adminRoleApi;
