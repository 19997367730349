import { ExclamationCircleOutlined, EditOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useDeleteMultiSchoolReviewMutation,
    useDeleteSchoolReviewMutation,
    useGetListSchoolReviewPaginateQuery,
    useRejectSchoolReviewMutation,
} from "@redux/queries/admin/admin.school.review";
import { Button, Form, FormInstance, Modal, Select, Table, Tag, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import FilterSchoolFAQ from "./components/FilterSchoolReview";
import HandleForm from "./components/HanleForm";
import { useToast } from "@components/toast/ToastProvider";
import { schoolLogo, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useApproveSchoolReviewMutation } from "@redux/queries/admin/admin.school.review";

const { Text } = Typography;

const listStatus = [
    {
        value: 1,
        label: "Active",
        color: "green",
    },
    {
        value: 0,
        label: "Inactive",
        color: "orange",
    },
    {
        value: -1,
        label: "Reject",
        color: "red",
    },
];

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form
            form={form}
            component={false}
        >
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: any;
    record: any;
    handleSave: () => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const form = useContext(EditableContext)!;

    const [approveSchoolReview] = useApproveSchoolReviewMutation();
    const [rejectSchoolReview] = useRejectSchoolReviewMutation();

    const { showToast } = useToast();

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            const status = values.status;
            let result = {} as any;
            if (status === 1) {
                result = await approveSchoolReview({
                    id: record.id,
                });
            }
            if (status === -1) {
                result = await rejectSchoolReview({
                    id: record.id,
                });
            }
            if ("error" in result) {
                showToast(TOAST_UPDATE_ERROR);
            }
            if ("data" in result) {
                showToast(TOAST_UPDATE_SUCCESS);
            }
            handleSave();
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Select
                    onChange={save}
                    onBlur={save}
                >
                    {listStatus.map((item) => (
                        <Select.Option
                            key={item.value}
                            value={item.value}
                            disabled={item.value === 0}
                        >
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const SchoolReview: React.FunctionComponent = () => {
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching, refetch } = useGetListSchoolReviewPaginateQuery(query as any);

    const [deleteSchoolReview, isLoadingDelete] = useDeleteSchoolReviewMutation();
    const [deleteMultiSchoolReview, isLoadingMultiDelete] = useDeleteMultiSchoolReviewMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteSchoolReview({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiSchoolReview({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (id: string) => {
        setDetailID(id);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const defaultColumns = [
        {
            title: <div className="text-title-table">Trường</div>,
            key: "schoolId",
            dataIndex: "school",
            render: (school: IAdminSchool, record: any) => {
                return school?.language[0]?.name || "";
            },
        },
        {
            title: <div className="text-title-table">Người tạo</div>,
            key: "user",
            dataIndex: "user",
            render: (user: any, record: any) => {
                return record?.user?.userName || "";
            },
        },
        {
            title: <div className="text-title-table">Nội dung</div>,
            key: "content",
            dataIndex: "content",
        },
        {
            title: <div className="text-title-table">Hình ảnh</div>,
            key: "images",
            dataIndex: "images",
            render: (file: any) => {
                return file?.map((url: string, index: number) => (
                    <img
                        key={index}
                        src={"" + process.env.REACT_APP_CDN + url}
                        width={140}
                    />
                ));
            },
        },
        {
            title: <div className="text-title-table">Đánh giá (số)</div>,
            key: "rating",
            dataIndex: "rating",
        },
        {
            title: <div className="text-title-table">Tags</div>,
            key: "tags",
            dataIndex: "tags",
            render: (tags: any, record: any) => {
                return tags?.map((o: any, i: any) => {
                    return (
                        <Tag
                            key={i}
                            color="blue"
                        >
                            {o}
                        </Tag>
                    );
                });
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (status: number, record: any) => {
                const statusRecord = listStatus.find((o) => o.value === status);
                return (
                    <Tag color={statusRecord?.color}>
                        {statusRecord?.label} {!status && <EditOutlined />}
                    </Tag>
                );
            },
            editable: true,
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        {/* <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button> */}
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: () => {
                    refetch();
                },
            }),
        };
    });

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterSchoolFAQ
                                        data={filter}
                                        onSearch={(v: any) => setFilter(v)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    components={components}
                                    rowSelection={rowSelection}
                                    columns={columns as any}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default SchoolReview;
