import { useBlogDetailsQuery } from "@redux/queries/user/blog.api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FC } from "react";
import { useParams } from "react-router-dom";
import RecentService from "./components/RecentService";
import ServiceContent from "./components/ServiceContent";
import { useGetServiceDetailsQuery } from "@redux/queries/user/public.api";
import { Spin } from "antd";

const ServiceDetails: FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data: dataService, isLoading } = useGetServiceDetailsQuery(id ? { id } : skipToken);

    return (
        <section id="content-main">
            <Spin spinning={isLoading}>
                <div className="row">
                    <div className="col-12 col-sm-8">
                        <ServiceContent data={dataService?.[0]} />
                    </div>
                    <div className="col-12 col-sm-4">
                        <aside>
                            <RecentService schoolId={dataService?.[0]?.schoolId} />
                        </aside>
                    </div>
                </div>
            </Spin>
        </section>
    );
};

export default ServiceDetails;
