import defaultAvatar from "@assets/images/default.png";
import item01 from "@assets/images/item-01.jpg";
import item02 from "@assets/images/item-02.jpg";
import item03 from "@assets/images/item-03.jpg";
import partner01 from "@assets/images/partner/logo-1.jpg";
import partner02 from "@assets/images/partner/logo-2.jpg";
import partner03 from "@assets/images/partner/logo-3.jpg";
import partner04 from "@assets/images/partner/logo-4.jpg";
import partner05 from "@assets/images/partner/logo-5.jpg";
import partner06 from "@assets/images/partner/logo-6.jpg";
import img01 from "@assets/images/img1.png";
import lead01 from "@assets/images/lead.svg";

import scholar01 from "@assets/images/scholar01.svg";
import scholar02 from "@assets/images/scholar02.svg";
import scholar03 from "@assets/images/scholar03.svg";
import scholar04 from "@assets/images/scholar04.svg";

import imgStudy from "@assets/images/img2.svg";
import { useGetStatisticUserDashbardQuery, useSchoolNewsHomeMutation } from "@redux/queries/user/intro.api";
import NumberFormat from "@components/NumberFormat";
import { useGetPopularMajorsQuery, useGetSchoolAreaQuery } from "@redux/queries/user/public.api";
import { useEffect } from "react";
import { Spin } from "antd";
import Blog from "../UserDashboard/components/Blog";
import { linkCDN } from "@utils/common";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    const { data } = useGetStatisticUserDashbardQuery();
    const { totalUsers = 0, totalSchools = 0, totalApplies = 0, totalScholarships = 0 } = data || {};

    // school area
    const { data: dataSchoolArea } = useGetSchoolAreaQuery({
        page: 1,
        limit: 15,
    });
    const { items: schoolArea = [] } = dataSchoolArea || { items: [] };

    // popular majors
    const { data: dataPopularMajors } = useGetPopularMajorsQuery({
        page: 1,
        limit: 6,
    });
    const { items: popularMajors = [] } = dataPopularMajors || { items: [] };

    // school news
    const [fetch, { data: dataSchoolNews, isLoading }] = useSchoolNewsHomeMutation();

    useEffect(() => {
        fetch({ page: 1, limit: 8 });
    }, []);

    const { items: listSchoolNews = [] } = dataSchoolNews || {};

    return (
        <main>
            <section id="content-main">
                <div className="box-introduction-home">
                    <div className="content-frame">
                        <div className="row">
                            <div className="col-12 col-md-6 order-a">
                                <article className="item-intro">
                                    <h2>
                                        <strong>Studyinchina.io</strong>
                                        Hệ thống thông tin Du học Trung Quốc
                                    </h2>
                                    <p>Vô vàn thông tin đang chờ bạn khám phá.</p>
                                </article>
                            </div>
                            <div className="col-12 col-md-6 order-b">
                                <div className="box-lamp-animation">
                                    <img
                                        src={img01}
                                        alt="img"
                                    />
                                    <div className="civi-style-lamp-swing">
                                        <div className="elementor-widget-container">
                                            <div className="civi-image-animation style-lamp-swing">
                                                <div className="civi-image image">
                                                    <img
                                                        width="104"
                                                        height="169"
                                                        src={lead01}
                                                        alt="lead"
                                                        className="entered lazyloaded"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-frame">
                    <div className="focus-frame bg-white">
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <p>
                                    <strong>
                                        <NumberFormat value={totalUsers} />
                                    </strong>
                                    Người dùng
                                </p>
                            </div>
                            <div className="col-6 col-md-3">
                                <p>
                                    <strong>
                                        <NumberFormat value={totalApplies} />
                                    </strong>
                                    Apply
                                </p>
                            </div>
                            <div className="col-6 col-md-3">
                                <p>
                                    <strong>
                                        <NumberFormat value={totalSchools} />
                                    </strong>
                                    Trường Đại học
                                </p>
                            </div>
                            <div className="col-6 col-md-3">
                                <p>
                                    <strong>
                                        <NumberFormat value={totalScholarships} />
                                    </strong>
                                    Học bổng
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white-trans">
                    <div className="list-suggest space_section">
                        <div className="content-frame">
                            <hgroup className="title-section">
                                <h2>Danh sách Trường</h2>
                                <h6>Khám phá các trường Đại học Trung Quốc theo khu vực.</h6>
                            </hgroup>
                            <div className="row px-4 overflow-hidden">
                                <Swiper
                                    loop
                                    navigation
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },

                                        768: {
                                            slidesPerView: 2,
                                        },

                                        992: {
                                            slidesPerView: 3,
                                        },
                                        1400: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                    modules={[Navigation, Autoplay]}
                                    autoplay={{
                                        delay: 3000,
                                    }}
                                    className="mySwiper overflow-visible"
                                >
                                    {schoolArea?.map((item: any, index: number) => (
                                        <SwiperSlide key={index}>
                                            <article
                                                className="item-article cursor-pointer"
                                                onClick={() => navigate(`/pro-tool/schools?area=${item?.id}`)}
                                            >
                                                <img
                                                    className="img-fluid"
                                                    src={linkCDN(item?.thumbnail, item01)}
                                                    alt="school"
                                                />
                                                <div className="pd-content">
                                                    <h5>{item?.language?.[0]?.title}</h5>
                                                    <p>{item?.countSchools} trường</p>
                                                </div>
                                            </article>
                                            {/* </div> */}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>

                    {/* <div className="list-logo space_section">
                        <div className="content-frame">
                            <p>Trusted by 5,000 companies around the world</p>
                            <div className="row">
                                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                    <img
                                        className="img-fluid"
                                        src={partner01}
                                        alt="logo-partner"
                                    />
                                </div>
                                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                    <img
                                        className="img-fluid"
                                        src={partner02}
                                        alt="logo-partner"
                                    />
                                </div>
                                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                    <img
                                        className="img-fluid"
                                        src={partner03}
                                        alt="logo-partner"
                                    />
                                </div>
                                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                    <img
                                        className="img-fluid"
                                        src={partner04}
                                        alt="logo-partner"
                                    />
                                </div>
                                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                    <img
                                        className="img-fluid"
                                        src={partner05}
                                        alt="logo-partner"
                                    />
                                </div>
                                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                    <img
                                        className="img-fluid"
                                        src={partner06}
                                        alt="logo-partner"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* BEGIN: List Major */}
                    <div className="major-list space_section">
                        <div className="content-frame">
                            <hgroup className="title-section">
                                <h2>Ngành học phổ biến</h2>
                                <h6>Tìm hiểu thông tin về các ngành học phổ biến nhất khi đi du học Trung Quốc.</h6>
                            </hgroup>
                            <div className="row">
                                {popularMajors?.map((item: any, index: number) => (
                                    <div
                                        key={index}
                                        className="col-12 col-sm-4 cursor-pointer"
                                        onClick={() => navigate(`/pro-tool/schools?major=${item?.id}`)}
                                    >
                                        <article>
                                            <i className="bi-journal-bookmark-fill"></i>
                                            <h6>
                                                <strong className="text-truncate">{item?.language?.[0]?.title}</strong>
                                                {item?.countSpecializeds} Chuyên ngành
                                            </h6>
                                        </article>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* END: List Major */}

                    {/* BEGIN: List Scholarship */}
                    <div className="scholarship space_section">
                        <div className="content-frame">
                            <hgroup className="title-section">
                                <h2>Danh sách học bổng</h2>
                                <h6>Easily find the right service from over 2000+ skills</h6>
                            </hgroup>
                            <div className="row">
                                <div className="col-6 col-lg-3">
                                    <article>
                                        <img
                                            src={scholar01}
                                            alt="schoolar"
                                        />
                                        <p>Học bổng Chính chủ</p>
                                    </article>
                                </div>
                                <div className="col-6 col-lg-3">
                                    <article>
                                        <img
                                            src={scholar02}
                                            alt="schoolar"
                                        />
                                        <p>Học bổng Tỉnh</p>
                                    </article>
                                </div>
                                <div className="col-6 col-lg-3">
                                    <article>
                                        <img
                                            src={scholar03}
                                            alt="schoolar"
                                        />
                                        <p>Học bổng Thành phố</p>
                                    </article>
                                </div>
                                <div className="col-6 col-lg-3">
                                    <article>
                                        <img
                                            src={scholar04}
                                            alt="schoolar"
                                        />
                                        <p>Học bổng Trường</p>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END: List Scholarship */}

                    {/* BEGIN: List Introduction 2 */}
                    <div className="bg-blue-intro intro-section-frame">
                        <div className="content-frame">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <img
                                        src={imgStudy}
                                        alt="study"
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <article>
                                        <h4>Cả thế giới học bổng đều có tại studyinchina.io</h4>
                                        <ul>
                                            <li>
                                                Tra cứu học bổng
                                                <small>
                                                    Dễ dàng tìm kiếm thông tin của hơn 100 loại học bổng Trung Quốc.
                                                </small>
                                            </li>
                                            <li>
                                                Tra cứu Trường
                                                <small>Chưa bao giờ việc check trường lại chân thật đến vậy!</small>
                                            </li>
                                            <li>
                                                Chuyên ngành Học
                                                <small>
                                                    Chúng tôi cung cấp kho thông tin đầy đủ về tất cả chuyên ngành học.
                                                </small>
                                            </li>
                                            <li>
                                                24/7 Support
                                                <small>Chúng tôi ở đây và sẵn sàng hỗ trợ bạn 24/7.</small>
                                            </li>
                                        </ul>
                                        <div className="link-to">
                                            <a
                                                className="btn-lg btn-blue"
                                                href="/"
                                            >
                                                Bắt đầu ngay
                                            </a>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* END: List Introduction 2 */}

                    {/* BEGIN: 3 News Lastest */}
                    <div className="content-frame space_section">
                        <h5 className="title-section">Bài viết mới nhất</h5>
                        <Spin spinning={isLoading}>
                            <div className="row">
                                {listSchoolNews && listSchoolNews?.length > 0 ? (
                                    listSchoolNews?.map(
                                        ({ id, createdAt, language, slug, thumbnail, view, like, creator }, index) => (
                                            <div
                                                className="col-12 col-sm-6 col-md-4 d-flex"
                                                key={index}
                                            >
                                                <Blog
                                                    linkType="news"
                                                    id={id}
                                                    createdAt={createdAt}
                                                    title={language?.[0]?.title}
                                                    shortContent={language?.[0]?.shortContent}
                                                    slug={slug}
                                                    thumbnail={thumbnail}
                                                    view={view}
                                                    like={like}
                                                    userName={
                                                        creator?.firstName
                                                            ? creator?.firstName + " " + creator?.lastName
                                                            : creator?.userName
                                                    }
                                                    avatar={creator?.avatar}
                                                />
                                            </div>
                                        )
                                    )
                                ) : (
                                    <div className="min-h-400px"></div>
                                )}
                            </div>
                        </Spin>
                    </div>
                    {/* END: 3 News Lastest */}

                    <footer>
                        <p className="copyright-home">
                            <b>studyinchina.io</b> được phát triển bằng cả trái tim của <b>Riba Team</b>
                        </p>
                    </footer>
                </div>
            </section>
        </main>
    );
};

export default Home;
