import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useCreatePaymentMethodMutation,
    useUpdatePaymentMethodMutation,
} from "@redux/queries/admin/admin.payment.method";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import Tab from "./Tab";
import { pickBy, identity } from "lodash";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { listPaymentMethodStatus, listTypePaymentMethod } from "../payment-method.const";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createPaymentMethod, isLoadingCreate] = useCreatePaymentMethodMutation();
    const [updatePaymentMethod, isLoadingUpdate] = useUpdatePaymentMethodMutation();
    const { showToast } = useToast();

    const [thumbnail, setThumbnail] = useState<any>(null);
    const [editThumbnail, setEditThumbnail] = useState<any>(null);

    const [qrUrl, setQrUrl] = useState<any>("");
    const [editQrUrl, setEditQrUrl] = useState<any>("");

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");
    const [errorContent, setErrorContent] = useState<string>();

    useEffect(() => {
        if (dataEdit) {
            const introduceVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.content;
            const introduceEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.content;
            const introduceCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.content;
            setIntroduceVi(introduceVi || "");
            setIntroduceEn(introduceEn || "");
            setIntroduceCn(introduceCn || "");
            form.setFieldsValue({
                ...dataEdit,
                titleVi: dataEdit?.language[0].name,
                titleEn: dataEdit?.language[1].name,
                titleCn: dataEdit?.language[2].name,
                introduceVi,
                introduceEn,
                introduceCn,
                accountNumber: dataEdit?.info?.accountNumber,
                owner: dataEdit?.info?.owner,
            });
            setEditThumbnail(dataEdit?.thumbnail);
            setEditQrUrl(dataEdit?.qrUrl);
        } else {
            form.resetFields();
            setEditThumbnail(null);
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        let value;
        try {
            value = await form.validateFields();
            if (!introduceVi) {
                setErrorContent("introduceVi");
                return;
            }
            if (!introduceEn) {
                setErrorContent("introduceEn");
                return;
            }
            if (!introduceCn) {
                setErrorContent("introduceCn");
                return;
            }
        } catch (errorInfo) {
            const fieldError = (errorInfo as any)?.errorFields[0].name[0];
            if (fieldError === "titleVi" || fieldError === "titleEn" || fieldError === "titleCn") {
                setErrorContent(fieldError);
            }
            return;
        }
        const language = [
            {
                lang: "vi",
                name: values.titleVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.name,
                content: values.introduceVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.content,
            },
            {
                lang: "en",
                name: values.titleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.name,
                content: values.introduceEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.content,
            },
            {
                lang: "cn",
                name: values.titleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.name,
                content: values.introduceCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.content,
            },
        ];

        const data = pickBy(
            {
                ...values,
                language,
                thumbnail: thumbnail || editThumbnail,
                qrUrl: qrUrl || editQrUrl,
                info: {
                    accountNumber: values.accountNumber,
                    owner: values.owner,
                },
            },
            identity
        ) as any;
        const result = dataEdit
            ? await updatePaymentMethod({
                  id: dataEdit.id,
                  ...data,
                  status: data.status || 0,
              })
            : await createPaymentMethod({
                  ...data,
                  status: data.status || 0,
              });
        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setEditThumbnail(null);
            setThumbnail(null);
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Payment Method" : "Add Payment Method"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1000}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Tab
                                form={form}
                                thumbnail={thumbnail}
                                setThumbnail={(value: any) => setThumbnail(value)}
                                editThumbnail={editThumbnail}

                                qrUrl={qrUrl}
                                setQrUrl={(value: any) => setQrUrl(value)}
                                editQrUrl={editQrUrl}
                                introduceVi={introduceVi}
                                introduceEn={introduceEn}
                                introduceCn={introduceCn}
                                setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                errorContent={errorContent}
                                setErrorContent={(value: any) => setErrorContent(value)}
                            />
                        </div>
                    </div>
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="status"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            allowClear
                                        >
                                            {listPaymentMethodStatus.map((o: any, i: any) => {
                                                return (
                                                    <Select.Option
                                                        key={o.value}
                                                        value={o.value}
                                                    >
                                                        {o.label}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <label>Trạng thái</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            {" "}
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="accountNumber"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Số tài khoản</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            {" "}
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="owner"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Chủ tài khoản</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="type"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            allowClear
                                        >
                                            {listTypePaymentMethod.map((o: any, i: any) => {
                                                return (
                                                    <Select.Option
                                                        key={o.value}
                                                        value={o.value}
                                                    >
                                                        {o.label}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <label>Loại</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
