import { useGetListScholarshipQuery } from "@redux/queries/admin/admin.scholarship";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import { MinusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetListScholarshipTypeQuery } from "@redux/queries/admin/admin.scholarship.type";
import { useEffect, useState } from "react";

const ScholarTab: React.FunctionComponent = () => {
    const {
        data: list_scholarship,
        isLoading: isLoadingScholarship,
        error: errorScholarship,
    } = useGetListScholarshipQuery();
    const {
        data: list_scholarship_type,
        isLoading: isLoadingScholarshipType,
        error: errorScholarshipType,
    } = useGetListScholarshipTypeQuery();
    const { data: list_train, isLoading: isLoadingTrain, error: errorCity } = useGetListTrainingQuery();

    const [listScholarship, setListScholarship] = useState<any[]>([]);

    const onChangeScholarshipType = (value: any, index: any) => {
        const listScholarshipFilter = list_scholarship?.filter((o: any) => o.scholarshipTypeId === value);
        const list = [...(listScholarship || [])];
        list[index] = listScholarshipFilter;
        setListScholarship(list);
    };
    return (
        <>
            <div
                className="tab-pane fade show"
                id="area-tab-pane"
                role="tabpanel"
                aria-labelledby="area-tab"
                tabIndex={0}
            >
                <Form.List
                    name="scholarships"
                    initialValue={[{}]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
                            <Row>
                                {fields.map((field, index) => (
                                    <Col
                                        span={12}
                                        key={field.key}
                                    >
                                        <Card
                                            size="small"
                                            title={`Học bổng ${field.name + 1}`}
                                            extra={
                                                index > 0 ? (
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                    />
                                                ) : null
                                            }
                                        >
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[field.name, "scholarshipTypeId"]}
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="form-control form-floating"
                                                            bordered={false}
                                                            allowClear
                                                            onChange={(value) => onChangeScholarshipType(value, index)}
                                                        >
                                                            {list_scholarship_type &&
                                                                list_scholarship_type.map((o: any, i: any) => {
                                                                    return (
                                                                        <Select.Option
                                                                            key={o.id}
                                                                            value={o.id}
                                                                        >
                                                                            {o.language[0].title}
                                                                        </Select.Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </Form.Item>
                                                    <label>Loại học bổng</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[field.name, "scholarshipId"]}
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    const scholarshipRecord = list_scholarship?.find(
                                                                        (o: any) => o.id === value
                                                                    );
                                                                    const scholarshipType =
                                                                        getFieldValue("scholarships")[index]
                                                                            .scholarshipTypeId;
                                                                    if (
                                                                        value &&
                                                                        scholarshipRecord?.scholarshipTypeId !==
                                                                            scholarshipType
                                                                    ) {
                                                                        return Promise.reject(
                                                                            "Học bổng không thuộc loại học bổng"
                                                                        );
                                                                    }
                                                                    const scholarships = getFieldValue("scholarships");
                                                                    const scholarshipIds = scholarships.map(
                                                                        (o: any) => o.scholarshipId
                                                                    );
                                                                    if (
                                                                        scholarshipIds.filter((o: any) => o === value)
                                                                            .length <= 1
                                                                    ) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject("Không được trùng học bổng");
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Select
                                                            className="form-control form-floating"
                                                            bordered={false}
                                                            allowClear
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                (option?.children as any)
                                                                    ?.toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {(listScholarship[index] || list_scholarship) &&
                                                                (listScholarship[index] || list_scholarship).map(
                                                                    (o: any, i: any) => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={o.id}
                                                                                value={o.id}
                                                                            >
                                                                                {o.language[0].title}
                                                                            </Select.Option>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </Form.Item>
                                                    <label>Học bổng</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[field.name, "trainIds"]}
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className="form-control form-floating"
                                                            bordered={false}
                                                            mode="multiple"
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                (option?.children as any)
                                                                    ?.toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {list_train &&
                                                                list_train.map((o: any, i: any) => {
                                                                    return (
                                                                        <Select.Option
                                                                            key={o.id}
                                                                            value={o.id}
                                                                        >
                                                                            {o.language[0].title}
                                                                        </Select.Option>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </Form.Item>
                                                    <label>Hệ đào tạo</label>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>

                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                            >
                                + Thêm học bổng
                            </Button>
                        </div>
                    )}
                </Form.List>
            </div>
        </>
    );
};

export default ScholarTab;
