import Nodata from "@components/Nodata";
import Post from "@pages/user/profile/components/TabMyPost/Post";
import { useListBlogQuery } from "@redux/queries/user/blog.api";
import { FC } from "react";

const PAGE = 1;
const LIMIT = 5;

const RecentPost: FC = () => {
    const { data, isLoading } = useListBlogQuery({
        page: PAGE,
        limit: LIMIT,
    });

    const { items: listBlogs } = data || {};

    return (
        <div className="box-item-element">
            <div className="title-element">
                <h5>Bài viết gần đây</h5>
                <hr />
            </div>
            <div className="body-element">
                {listBlogs && listBlogs?.length > 0 ? (
                    listBlogs?.map((item, index) => {
                        return (
                            <Post
                                key={index}
                                data={item}
                                title={item?.title}
                                shortContent={item?.shortContent}
                                link={`/blogs/${item?.id}`}
                            />
                        );
                    })
                ) : (
                    <Nodata />
                )}
            </div>
        </div>
    );
};

export default RecentPost;
