import ModalCustome from "@components/modal/Modal";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS } from "@consts";
import { useCreateScholarshipTypeMutation } from "@redux/queries/admin/admin.scholarship.type";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

const AddScholarshipType: React.FunctionComponent = () => {
    const [createScholarshipType, result] = useCreateScholarshipTypeMutation();
    const [open, setOpen] = useState<boolean>(false);
    const closeModal = () => setOpen(false);
    const openModal = () => {
        formik.resetForm();
        setOpen(true);
    };

    const { showToast } = useToast();
    const formik = useFormik({
        initialValues: {
            id: "",
            title_vi: "",
            title_en: "",
            title_cn: "",
            lastPointPass: 0,
        },
        validationSchema: Yup.object({
            title_vi: Yup.string().required("Không được để trống"),
            title_en: Yup.string().required("Không được để trống"),
            title_cn: Yup.string().required("Không được để trống"),
            lastPointPass: Yup.number()
                .required("Không được để trống")
                .max(99, "Điểm đỗ mặc định nằm trong khoảng 1-99")
                .min(1, "Điểm đỗ mặc định nằm trong khoảng 1-99"),
        }),
        onSubmit: async (values) => {
            let language = [
                {
                    lang: "vi",
                    title: values.title_vi,
                },
                {
                    lang: "en",
                    title: values.title_en,
                },
                {
                    lang: "cn",
                    title: values.title_cn,
                },
            ];

            if (values.id === "") {
                const result = await createScholarshipType({
                    language: language,
                    lastPointPass: +values.lastPointPass,
                });
                if ("error" in result) {
                    showToast({ ...TOAST_CREATE_ERROR });
                }

                if ("data" in result) {
                    formik.resetForm();
                    closeModal();
                    showToast({ ...TOAST_CREATE_SUCCESS });
                }
            }
        },
    });

    return (
        <>
            <button
                type="button"
                className="btn-blue"
                onClick={openModal}
            >
                Add New
            </button>
            <ModalCustome
                open={open}
                onClose={closeModal}
                title="Add ScholarshipType"
                footer={[
                    <button
                        key="1"
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                        className="btn-blue"
                    >
                        {result.isLoading && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <span className="ps-2">Submit</span>
                    </button>,
                    <button
                        key="2"
                        type="button"
                        onClick={closeModal}
                        className="text-box-gray"
                    >
                        Close
                    </button>,
                ]}
            >
                <form>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="title_vi"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title_vi}
                            />
                            <label>Tên loại học bổng</label>
                        </div>
                        {formik.touched.title_vi && formik.errors.title_vi ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.title_vi}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="title_en"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title_en}
                            />
                            <label>Tên tiếng anh</label>
                        </div>
                        {formik.touched.title_en && formik.errors.title_en ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.title_en}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="title_cn"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title_cn}
                            />
                            <label>Tên tiếng trung</label>
                        </div>
                        {formik.touched.title_cn && formik.errors.title_cn ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.title_cn}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="lastPointPass"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastPointPass}
                            />
                            <label>Điểm đỗ mặc định</label>
                        </div>
                        {formik.touched.lastPointPass && formik.errors.lastPointPass ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.lastPointPass}</p>
                        ) : null}
                    </div>
                </form>
            </ModalCustome>
        </>
    );
};

export default AddScholarshipType;
