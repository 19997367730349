export enum ApplyStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    CANCELLED = "CANCELLED",
    PASSED_CHECKING = "PASSED_CHECKING",
    PASSED = "PASSED",
}

export const listApplyStatus = [
    { value: ApplyStatus.PENDING, label: "Pending", color: "yellow" },
    { value: ApplyStatus.APPROVED, label: "Approved", color: "green" },
    { value: ApplyStatus.REJECTED, label: "Rejected", color: "red" },
    { value: ApplyStatus.CANCELLED, label: "Cancelled", color: "gray" },
    { value: ApplyStatus.PASSED_CHECKING, label: "Passed Checking", color: "blue" },
    { value: ApplyStatus.PASSED, label: "Passed", color: "green" },
];

export enum typeContractUser {
    NEWCONTRACT = 1,
    RENEWCONTRACT = 2,
}
