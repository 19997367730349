import { useCreateBlogMutation, useUpdateBlogByIdMutation } from "@redux/queries/user/blog.api";
import { useUploadThumbnailBlogMutation } from "@redux/queries/user/user.api";
import { Upload, message } from "antd";
import { RcFile } from "antd/es/upload";
import { useFormik } from "formik";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";
import { ACTIVE_TAB } from "../TabProfile";
import { linkCDN } from "@utils/common";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
    const isLt1M = file.size / 1024 / 1024 < 1;
    const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPngOrPdf) {
        message.error("Không đúng định dạng file yêu cầu");
        return isJpgOrPngOrPdf;
    }

    if (!isLt1M) {
        message.error("Kích thước ảnh phải nhỏ hơn 1MB!");
        return isLt1M;
    }
};

interface IFormAddPost {
    blogSelected?: any;
    activeTab?: ACTIVE_TAB;
    setBlogSelected?: Dispatch<SetStateAction<any>>;
    setActiveTab: Dispatch<SetStateAction<ACTIVE_TAB>>;
}

const FormAddPost: FC<IFormAddPost> = ({ activeTab, blogSelected, setBlogSelected, setActiveTab }) => {
    const [triggerAddPost, resultAddPost] = useCreateBlogMutation();
    const [triggerEditPost] = useUpdateBlogByIdMutation();
    const [uploadFile, resultUploadFile] = useUploadThumbnailBlogMutation();

    const [imageUrl, setImageUrl] = useState<string>();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: blogSelected?.title || "",
            shortContent: blogSelected?.shortContent || "",
            content: blogSelected?.content || "",
            thumbnail: "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Không được bỏ trống Tiêu đề"),
            shortContent: Yup.string().required("Không được bỏ trống Mô tả ngắn"),
            content: Yup.string().required("Không được bỏ trống Nội dung"),
            thumbnail: blogSelected?.id ? Yup.mixed() : Yup.string().required("Không được bỏ trống thumbnail"),
        }),
        onSubmit: async (values) => {
            try {
                if (blogSelected?.id) {
                    await triggerEditPost({
                        ...values,
                        id: blogSelected?.id,
                        thumbnail: values.thumbnail || blogSelected?.thumbnail,
                    });
                } else {
                    await triggerAddPost({
                        ...values,
                    });
                }

                formik.resetForm();
                setImageUrl("");
                setActiveTab(ACTIVE_TAB.LIST_POST);
                message.success(`${blogSelected?.id ? "Chỉnh sửa" : "Tạo"} bài viết thành công!`);
            } catch (e) {
                message.error(`${blogSelected?.id ? "Chỉnh sửa" : "Tạo"} bài viết thất bại!`);
            }
        },
    });

    useEffect(() => {
        if (activeTab !== ACTIVE_TAB.ADD_POST) {
            setBlogSelected && setBlogSelected(null);
            formik.resetForm();
            setImageUrl("");
        }
    }, [activeTab]);

    const handleUploadFile = async ({ file, onError, onSuccess }: any) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const result = await uploadFile(formData).unwrap();
            const url = result?.url || "";
            formik.setFieldValue("thumbnail", url);

            getBase64(file, (url) => {
                setImageUrl(url);
            });

            console.log("result", result);
            onSuccess();
        } catch (error) {
            console.log(error);
            message.error("Tải hình ảnh thất bại!");
            onError();
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group form-style">
                <h5>{blogSelected?.id ? "Chỉnh sửa" : "Tạo"} bài viết</h5>
                <hr />
                <label>Tiêu đề bài viết</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="title"
                    name="title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title ? (
                    // @ts-ignore
                    <p className="text-red">{formik?.errors?.title}</p>
                ) : null}
            </div>
            <div className="form-group form-style">
                <label>Hình ảnh bài viết</label>
                <Upload.Dragger
                    accept="image/png, image/jpeg"
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                    customRequest={handleUploadFile}
                >
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            className="image-blog"
                        />
                    ) : linkCDN(blogSelected?.thumbnail) ? (
                        <img
                            src={linkCDN(blogSelected.thumbnail)}
                            className="image-blog"
                        />
                    ) : (
                        <div className="upload-btn-wrapper upload-blog">
                            <button className="btn w-fit-content">Nhấp hoặc kéo tệp vào khu vực này để tải lên.</button>
                        </div>
                    )}
                </Upload.Dragger>
                <div className="text-muted my-1">
                    Vui lòng tải lên hình ảnh với tỉ lệ 16:9, ví dụ: 400x225, 800x450.
                </div>
                {formik.touched.thumbnail && formik.errors.thumbnail ? (
                    <p className="text-red">{formik?.errors?.thumbnail}</p>
                ) : null}
            </div>
            <div className="form-group form-style">
                <label>Mô tả ngắn</label>
                <textarea
                    className="form-control"
                    rows={4}
                    defaultValue={""}
                    id="shortContent"
                    name="shortContent"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.shortContent}
                />
                {formik.touched.shortContent && formik.errors.shortContent ? (
                    // @ts-ignore
                    <p className="text-red">{formik?.errors?.shortContent}</p>
                ) : null}
            </div>

            <div className="form-group form-style">
                <label>Nội dung chi tiết</label>
                <textarea
                    className="form-control"
                    rows={10}
                    defaultValue={""}
                    id="content"
                    name="content"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.content}
                />
                {formik.touched.content && formik.errors.content ? (
                    // @ts-ignore
                    <p className="text-red">{formik?.errors?.content}</p>
                ) : null}
            </div>
            <button
                type="submit"
                className="btn btn-blue"
            >
                {blogSelected?.id ? "Chỉnh sửa" : "Đăng bài"}
            </button>
        </form>
    );
};

export default FormAddPost;
