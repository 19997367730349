import React, { useEffect } from "react";
import searchIcon from "@assets/images/icons/search.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

interface SearchInput {
    title?: string;
    code?: string;
}

interface IProps {
    data: SearchInput;
    onSearch: (data: SearchInput) => void;
}

const FilterMajor: React.FunctionComponent<IProps> = (props) => {
    const formik = useFormik({
        initialValues: {
            title: props.data.title,
            code: props.data.code,
        },
        validationSchema: Yup.object({
            title: Yup.string().optional(),
            code: Yup.string().optional(),
        }),
        onSubmit: async (values) => {
            console.log(values);

            props.onSearch(values);
        },
    });

    return (
        <>
            <form
                className="search-form"
                method="POST"
                onSubmit={formik.handleSubmit}
            >
                <input
                    name="title"
                    type="text"
                    placeholder="Tìm kiếm ngành học..."
                    onChange={formik.handleChange}
                />
                <img
                    src={searchIcon}
                    alt="icon-search"
                />
            </form>
            <form
                className="search-form"
                method="POST"
                onSubmit={formik.handleSubmit}
            >
                <input
                    name="code"
                    type="text"
                    placeholder="Code..."
                    style={{ marginRight: "5px" }}
                    onChange={formik.handleChange}
                />
            </form>
        </>
    );
};

export default FilterMajor;
