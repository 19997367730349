import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

export const depositApi = createApi({
    reducerPath: "deposit",
    tagTypes: ["Deposit"],
    keepUnusedDataFor: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getAllDepositByUser: builder.query<
                ResponseWithPaginateFormat<Deposit>,
                InputGetAllDepositByUser & { page: number; limit: number }
            >({
                query: (params) => ({
                    url: "/api/user/deposit/paginate",
                    params,
                }),
                providesTags: ["Deposit"],
            }),
            createDeposit: builder.mutation<Response<Deposit>, InputCreateDeposit>({
                query: (body) => ({
                    url: "/api/user/deposit",
                    method: "POST",
                    body: {
                        amount: body.amount,
                        methodId: body.methodId,
                        note: body.note,
                    },
                    headers: {
                        recaptcha: body.recaptcha,
                    },
                }),
                invalidatesTags: ["Deposit"],
            }),
            cancelDeposit: builder.mutation<Response<Deposit>, InputCancelDeposit>({
                query: (body) => ({
                    url: `/api/user/deposit/cancel/${body.id}`,
                    method: "POST",
                    body: {},
                    headers: {
                        recaptcha: body.recaptcha,
                    },
                }),
                invalidatesTags: ["Deposit"],
            }),
            approveDeposit: builder.mutation<Response<Deposit>, InputApproveDeposit>({
                query: (body) => ({
                    url: `/api/user/deposit/approve/${body.id}`,
                    method: "PATCH",
                    body: {},
                }),
                invalidatesTags: ["Deposit"],
            }),
            rejectDeposit: builder.mutation<Response<Deposit>, InputApproveDeposit>({
                query: (body) => ({
                    url: `/api/user/deposit/reject/${body.id}`,
                    method: "PATCH",
                    body: {},
                }),
                invalidatesTags: ["Deposit"],
            }),
            updateDeposit: builder.mutation<Response<Deposit>, InputUpdateDeposit>({
                query: (body) => ({
                    url: `/api/user/deposit/${body.id}`,
                    method: "PATCH",
                    body: {},
                }),
                invalidatesTags: ["Deposit"],
            }),
            deleteDeposit: builder.mutation<Response<Deposit>, InputDeleteDeposit>({
                query: (body) => ({
                    url: `/api/user/deposit/${body.id}`,
                    method: "DELETE",
                    body: {},
                }),
                invalidatesTags: ["Deposit"],
            }),
            getAllMethod: builder.query<Array<Method>, { name?: string; status?: SharedStatus }>({
                query: (params) => ({
                    url: `/api/payment/method`,
                    params,
                }),
            }),
        };
    },
});

export const {
    useApproveDepositMutation,
    useCancelDepositMutation,
    useRejectDepositMutation,
    useCreateDepositMutation,
    useDeleteDepositMutation,
    useGetAllDepositByUserQuery,
    useLazyGetAllDepositByUserQuery,
    useUpdateDepositMutation,
    useGetAllMethodQuery,
} = depositApi;
