export const listApplyPaymentStatus = [
    {
        value: 'PAID',
        label: 'Đã thanh toán',
        color: 'green'
    },
    {
        value: 'UNPAID',
        label: 'Chưa thanh toán',
        color: 'red'
    } 
];