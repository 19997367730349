import { Link } from "react-router-dom";

interface IProps {
    title: string;
    total: number;
}

const ItemBox = (props: IProps) => {
    return (
        <div className="col-12 col-md-4">
            {/* BEGIN: Item Box */}
            <div className="box-component box-highlight">
                <div className="header-item-box">
                    <h6>
                        <strong className="countup">{props?.total}</strong>
                        {props?.title}
                    </h6>
                    <img
                        src={window?.location?.origin + "/images/chart.png"}
                        alt="chart"
                    />
                    <div className="clearfix" />
                </div>
                {/* <div className="footer-item-box">
                    <p className="percent-change">
                        <span className="text-red">
                            <img
                                src="images/down-arrow.svg"
                                alt="icon-down"
                            />{" "}
                            3.87%
                        </span>{" "}
                        so với tháng trước
                    </p>
                </div> */}
            </div>
            {/* END: Item Box */}
        </div>
    );
};

export default ItemBox;
