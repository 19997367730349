import React from "react";
import AlertPage from "./components/AlertPage";
import { useTranslation } from "react-i18next";

const ConfirmEmail: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <AlertPage
            title={t("page_confirm_email_tit", "Xác thực email thành công!")}
            description={t(
                "page_confirm_email_desc",
                "Chúc mừng bạn đăng ký tài khoản thành công! Vui lòng đăng nhập để thực hiện các thao tác khác!"
            )}
            button={{
                navigateTo: "/login",
                text: t("page_confirm_email_btn", "Đăng nhập"),
            }}
        />
    );
};

export default ConfirmEmail;
