import {
    useGetNotificationByUserQuery,
    useReadAllUserNotificationMutation,
    useReadUserNotificationMutation,
} from "@redux/queries/user/notification.api";
import { useUser } from "@redux/slices/auth.slice";
import { Spin, message } from "antd";
import React, { useState } from "react";
import { Pagination } from "@components/pagination";
import moment from "moment";
import Nodata from "@components/Nodata";
import renderHTML from "react-render-html";

interface TabNotificationProps {}

const TabNotification: React.FunctionComponent<TabNotificationProps> = () => {
    const [page, setPage] = useState(1);
    const user = useUser();
    const [triggerReadAll, resultReadAll] = useReadAllUserNotificationMutation();
    const [triggerReadNoti, resultReadNoti] = useReadUserNotificationMutation();
    const { data, isLoading } = useGetNotificationByUserQuery({
        page: page,
        limit: 10,
        userId: user?.id || "",
    });

    const handleReadAll = async () => {
        try {
            const result = await triggerReadAll().unwrap();

            if (result) {
                message.success("Read all success!");
            }
        } catch (error) {
            message.error("Read all notification error! Please try again");
        }
    };

    const handleReadNoti = async (noti: UserNotification) => {
        try {
            const result = await triggerReadNoti({
                id: noti.id,
            }).unwrap();
            if (result.status) {
                message.success("Read success!");
            }
        } catch (error) {
            message.error("Read notification error! Please try again");
        }
    };

    return (
        <div
            className={`tab-pane fade ${location.hash === "#notice-tab" ? "show active" : ""}`}
            id="notice"
            role="tabpanel"
            aria-labelledby="notice-tab"
        >
            <div className="box-item-element announcement">
                <div className="title-element">
                    <h5 className="d-flex justify-content-between">
                        <span>Thông báo</span>
                        <button
                            className="btn btn-blue"
                            onClick={handleReadAll}
                        >
                            Đánh dấu đã đọc
                        </button>
                    </h5>
                    <hr />
                </div>
                <Spin spinning={isLoading}>
                    <div className="body-element">
                        <ul className="list-item">
                            {data?.meta.totalItems === 0 && <Nodata />}
                            {data &&
                                data.items.map((e, i) => (
                                    <li
                                        className="d-flex px-2 cursor-pointer hover-effect"
                                        key={i}
                                        onClick={() => handleReadNoti(e)}
                                    >
                                        <i className="bi-megaphone-fill" />
                                        <h6 className="">
                                            <div className="d-flex align-items-center gap-1">
                                                {!e?.isRead && <span className="dot-icon"></span>}
                                                <b>{e?.language?.find((e) => e.lang === "vi")?.title}</b>
                                            </div>

                                            <time>{moment(e?.createdAt).format("DD/MM/YYYY - HH:mm")}</time>
                                            <p>{renderHTML(e?.language?.find((e) => e.lang === "vi")?.content)}</p>
                                        </h6>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="row">
                        <Pagination
                            total={data?.meta.totalItems || 0}
                            showSize={false}
                            totalPage={data?.meta.totalPages || 0}
                            onChangePage={setPage}
                            defaultCurrentPage={1}
                        />
                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default TabNotification;
