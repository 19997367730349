import { schoolBg } from "@consts";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface ScholarshipItemProps {
    data: IScholarship;
}

const ScholarshipItem: React.FunctionComponent<ScholarshipItemProps> = ({ data }) => {
    const { id, language, thumbnail, scholarshipType } = data || {};

    const navigate = useNavigate();

    const goToDetail = () => {
        navigate(`/scholarship/${id}`);
    };

    return (
        <div className="col-12 col-sm-6 col-md-4 col-xxl-3 d-flex">
            <div className="box-filter-item w-100">
                <Link to={`/scholarship/${id}`}>
                    <div
                        onClick={goToDetail}
                        className="bg-item"
                        style={{
                            background: `url("${
                                thumbnail ? `${process.env.REACT_APP_CDN}${thumbnail}` : schoolBg
                            }") center center / cover no-repeat`,
                        }}
                    ></div>

                    <div className="body-filter-item">
                        <h6
                            className="m-3"
                            onClick={goToDetail}
                        >
                            {language?.[0]?.title}
                        </h6>
                        <ul>
                            <li>Loại: {scholarshipType?.language?.[0]?.title}</li>
                        </ul>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default ScholarshipItem;
