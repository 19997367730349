import { GROUP_TAB } from "@consts";
import { actionSelector, updateActiveTab } from "@redux/slices/action.slice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Overview from "./components/Overview";
import AsideInfo from "./components/Overview/AsideInfo";
import TabFaq from "./components/TabFaq";
import TabGeneral from "./components/TabGeneral";
import TabImage from "./components/TabImage";
import TabMajor from "./components/TabMajor";
import TabRelatedPost from "./components/TabRelatedPost";
import TabReview from "./components/TabReview";
import TabScholarship from "./components/TabScholarship";
import TabVideo from "./components/TabVideo";
import {
    useGetDetailSchoolByIdQuery,
    usePaginateScholarshipBySchoolIdQuery,
    usePaginateSpecializedBySchoolIdQuery,
} from "@redux/queries/user/public.api";
import { Helmet } from "react-helmet";
import { linkCDN } from "@utils/common";

const DetailSchool: React.FunctionComponent = () => {
    const { activeTab } = useSelector(actionSelector);
    const { id } = useParams<{ id: string }>();
    const { data, refetch } = useGetDetailSchoolByIdQuery({
        id: id as any,
    });

    console.log("data", data);

    const { data: dataScholarship } = usePaginateScholarshipBySchoolIdQuery(
        {
            id: data?.id || "",
            page: 1,
            limit: 5,
        },
        { skip: !data?.id }
    );

    const { data: dataSpecialized } = usePaginateSpecializedBySchoolIdQuery(
        {
            id: data?.id || "",
            page: 1,
            limit: 5,
        },
        { skip: !data?.id }
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateActiveTab(GROUP_TAB.GENERAL));
    }, []);

    return (
        <section id="content-main">
            <Helmet>
                <meta
                    itemProp="description"
                    content={data?.language?.[0]?.name}
                />
                <meta
                    itemProp="image"
                    content={linkCDN(data?.background)}
                />

                <meta
                    property="og:url"
                    content="cms.studyinchina.io"
                />
                <meta
                    property="og:type"
                    content="website"
                />
                <meta
                    property="og:title"
                    content={data?.slug}
                />
                <meta
                    property="og:description"
                    content={data?.language?.[0]?.name}
                />
                <meta
                    property="og:image"
                    content={linkCDN(data?.background)}
                />

                <meta
                    name="twitter:card"
                    content="summary_large_image"
                />
                <meta
                    name="twitter:title"
                    content={data?.slug}
                />
                <meta
                    name="twitter:description"
                    content={data?.language?.[0]?.name}
                />
                <meta
                    name="twitter:image"
                    content={linkCDN(data?.background)}
                />
                <title>{data?.slug}</title>
            </Helmet>

            <Overview
                id={data?.id}
                logo={data?.logo || ""}
                background={data?.background || ""}
                name={data?.language?.find((e) => e.lang === "vi")?.name || ""}
                views={data?.view || 0}
                favourite={data?.favoriteTotal || 0}
                like={data?.likeTotal || 0}
                onRefresh={() => {
                    refetch();
                }}
            />
            <div className="body-infor">
                <div className="row">
                    <div className="col-12 col-xl-8">
                        {activeTab === GROUP_TAB.GENERAL && (
                            <TabGeneral
                                id={data?.id}
                                schoolInfo={data?.attributes?.map((e) => ({ key: e.name, value: e.value })) || []}
                                images={data?.medias?.filter((e) => e.type === "IMAGE") || []}
                                video={data?.medias?.filter((e) => e.type !== "IMAGE") || []}
                                scholarship={dataScholarship?.items || []}
                                introduce={data?.language?.find((e) => e.lang === "vi")?.introduce || ""}
                                specializeds={dataSpecialized?.items || []}
                                view720={data?.view720}
                                statistics={data?.statistics || []}
                            />
                        )}
                        {activeTab === GROUP_TAB.FAQ && <TabFaq id={data?.id} />}
                        {activeTab === GROUP_TAB.IMAGE && <TabImage id={data?.id} />}
                        {activeTab === GROUP_TAB.MAJOR && <TabMajor id={data?.id} />}
                        {activeTab === GROUP_TAB.RELATED_POST && <TabRelatedPost id={data?.id} />}
                        {activeTab === GROUP_TAB.REVIEW && <TabReview id={data?.id} />}
                        {activeTab === GROUP_TAB.SCHOLARSHIP && <TabScholarship id={data?.id} />}
                        {activeTab === GROUP_TAB.VIDEO && <TabVideo id={data?.id} />}
                    </div>
                    <div className="col-12 col-xl-4">
                        <AsideInfo
                            schoolName={data?.language?.find((e) => e.lang === "vi")?.name || ""}
                            address={data?.contact?.address || ""}
                            phoneNumber={data?.contact?.phone || ""}
                            website={data?.contact?.website || ""}
                            logo={data?.logo || ""}
                            email={data?.contact?.email || ""}
                            map={data?.map}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default DetailSchool;
