import { Modal } from "antd";
import React from "react";
import { formatNumber } from "@utils/common";
import "./style.css";

interface ModalConfirmDepositProps {
    methods: Array<Method>;
    formik: any;
    open: boolean;
    isLoading: boolean;
    selectedMethod?: Method;
    onClose: () => void;
    onSubmit: () => void;
    onCancel: () => void;
}
const ModalConfirmDeposit: React.FunctionComponent<ModalConfirmDepositProps> = (props) => {
    const { formik, open, methods, isLoading, selectedMethod, onClose, onSubmit } = props;

    return (
        <Modal
            style={{
                padding: 0,
            }}
            open={open}
            footer={null}
            closable={false}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5">Thông tin giao dịch</h1>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onClose}
                    ></button>
                </div>
                <div className="modal-body">
                    <ul className="infor-ul">
                        <li>
                            Phương thức nạp tiền{" "}
                            <b>
                                {
                                    methods
                                        ?.find((x) => x.id === formik.values.methodId)
                                        ?.language?.find((x) => x.lang === "vi")?.name
                                }
                            </b>
                        </li>
                        <li>
                            Số tài khoản <b>{selectedMethod?.info?.accountNumber}</b>
                        </li>
                        <li>
                            Tên người nhận <b>{selectedMethod?.info?.owner}</b>
                        </li>
                        <li>
                            Số tiền <b className="text-blue">{formatNumber(formik.values.amount)}</b>
                        </li>
                        <li>
                            Ghi chú <b>{formik.values.note || "(Không có)"}</b>
                        </li>
                    </ul>
                </div>
                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn-blue me-3"
                        onClick={props.onSubmit}
                        disabled={isLoading}
                    >
                        Đồng ý
                    </button>
                    <button
                        type="button"
                        className="btn-red"
                        onClick={props.onCancel}
                    >
                        Hủy giao dịch
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalConfirmDeposit;
