import blankImage from "@assets/images/blank.png";
import store from "@redux/store";
import { Button, Form, Input, Modal, Space, message } from "antd";
import Upload, { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { useEffect, useState } from "react";
import { useToast } from "@components/toast/ToastProvider";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { TOAST_DELETE_SUCCESS, TOAST_UPLOAD_ERROR, TOAST_UPLOAD_SUCCESS } from "@consts";
import Title from "antd/es/typography/Title";
import { getBase64 } from "@utils/common";

interface ImageTabProps {
    images?: any;
    setImages?: (images: any) => void;
    editImages?: any;
}

const MediasTab = ({ images, setImages, editImages }: ImageTabProps) => {
    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { showToast } = useToast();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    useEffect(() => {
        if (editImages) {
            const list = editImages.map((image: any) => {
                return {
                    uid: image.id,
                    name: image.id,
                    status: "done",
                    url: process.env.REACT_APP_CDN + image.url,
                    response: [
                        {
                            url: image.url,
                        },
                    ],
                };
            });
            setFileList(list);
        }
    }, [editImages]);

    useEffect(() => {
        const list = fileList && fileList.map((file: any) => {
            return file?.response && file?.response[0]?.url;
        });
        setImages && setImages(list);
    }, [fileList]);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file?.url || (file?.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        multiple: true,
        fileList,
        listType: "picture-card",
        onPreview: handlePreview,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPngOrPdf) {
                message.error("Không đúng định dạng file yêu cầu");
            }
            return isJpgOrPngOrPdf;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    const handleCancel = () => setPreviewOpen(false);

    return (
        <>
            <div
                className="tab-pane fade show"
                id="image-tab-pane"
                role="tabpanel"
                aria-labelledby="image-tab"
                tabIndex={0}
            >
                <div className="row">
                    {/* col 1 */}
                    <div className="col-12 col-sm-6 col-xl-4">
                        <div className="body-component">
                            <Title level={5}> Ảnh </Title>
                            <div className="form-group">
                                <Upload.Dragger {...uploadProps}>
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">
                                            <i className="bi-upload"></i>
                                            Tải ảnh media
                                        </button>
                                    </div>
                                </Upload.Dragger>
                            </div>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                            >
                                <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewImage}
                                />
                            </Modal>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-4">
                        <div className="body-component">
                            <Title level={5}> Videos </Title>
                            <Form.List
                                name="videos"
                                initialValue={[{}]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div
                                                key={field.key}
                                                className="space-medias-tab"
                                            >
                                                <div
                                                    className="form-group"
                                                    style={{ width: "90%", display: "inline-block" }}
                                                >
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name={[field.name, "link"]}
                                                            className="form-floating"
                                                            rules={[
                                                                {
                                                                    type: "url",
                                                                    message: "Sai định dạng url",
                                                                },
                                                                {
                                                                    pattern: /^https:\/\/www\.youtube\.com\/embed\/[A-Za-z0-9_-]+$/,
                                                                    message: "Link theo định dạng: https://www.youtube.com/embed/xxxxxxxxxxx",
                                                                }
                                                            ]}
                                                        >
                                                            <Input
                                                                type="text"
                                                                className="form-control form-floating"
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                        <label>Link video</label>
                                                    </div>
                                                </div>
                                                {fields?.length > 0 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        style={{ width: "10%", display: "inline-block" }}
                                                        onClick={() => remove(field.name)}
                                                    />
                                                ) : null}
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{ width: "100%" }}
                                                icon={<PlusOutlined />}
                                            >
                                                Thêm link video
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MediasTab;
