import { listRoleAdmins } from "@consts";
import { authSelector } from "@redux/slices/auth.slice";
import { FC } from "react";
import { useSelector } from "react-redux";
import AdminDashboard from "./AdminDashboard";
import UserDashboard from "./UserDashboard";
import Home from "./Home";

const Dashboard: FC = () => {
    const { accessToken, type, user } = useSelector(authSelector);
    const isLogin = !!(accessToken && type && user);
    const isAdmin = user?.isAdmin || (user?.role && listRoleAdmins.includes(user?.role));

    return !isLogin ? <Home /> : isAdmin ? <AdminDashboard /> : <UserDashboard />;
};

export default Dashboard;
