import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useCreateSchoolCategoryMutation,
    useUpdateSchoolCategoryMutation,
} from "@redux/queries/admin/admin.school.category";
import { validateNoSpaces } from "@utils/common";
import { Form, Input, Modal } from "antd";
import { useEffect } from "react";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createSchoolCategory, isLoadingCreate] = useCreateSchoolCategoryMutation();
    const [updateSchoolCategory, isLoadingUpdate] = useUpdateSchoolCategoryMutation();
    const { showToast } = useToast();

    useEffect(() => {
        if (dataEdit) {
            const titleVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.title;
            const titleEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.title;
            const titleCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.title;
            form.setFieldsValue({
                titleVi,
                titleEn,
                titleCn,
            });
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const language = [
            {
                lang: "vi",
                title: values.titleVi,
            },
            {
                lang: "en",
                title: values.titleEn,
            },
            {
                lang: "cn",
                title: values.titleCn,
            },
        ];
        const result = dataEdit
            ? await updateSchoolCategory({
                  id: dataEdit.id,
                  language,
              })
            : await createSchoolCategory({
                  language,
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }

        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Category" : "Add Category"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()} 
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="titleVi"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                    {
                                        validator: (rule, value, callback) =>
                                            validateNoSpaces(rule, value, callback, "cn"),
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Tên tiếng Việt</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="titleEn"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                    {
                                        validator: (rule, value, callback) =>
                                            validateNoSpaces(rule, value, callback, "cn"),
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Tên tiếng Anh</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="titleCn"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                    {
                                        validator: (rule, value, callback) =>
                                            validateNoSpaces(rule, value, callback, "cn"),
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="form-floating no-icon"
                                    placeholder=""
                                />
                            </Form.Item>
                            <label>Tên tiếng Trung</label>
                        </div>
                    </div>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
