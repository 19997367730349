import { useGetListUserPaginateQuery } from "@redux/queries/admin/admin.user";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { Pagination } from "@components/pagination";
import _ from "lodash";

interface SearchUserProps {
    openSearchUser: boolean;
    setOpenSearchUser: (visible: boolean) => void;
    selectedUser?: any;
    setSelectedUser?: (data: any) => void;
}

const SearchUser = ({ openSearchUser, setOpenSearchUser, selectedUser, setSelectedUser }: SearchUserProps) => {
    const [form] = Form.useForm();

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const { data, isLoading, isFetching } = useGetListUserPaginateQuery(query as any);

    useEffect(() => {
        if (selectedUser) {
            setSelectedRowKeys([selectedUser]);
        }
    }, [selectedUser]);

    const onFinish = async (values: any) => {
        const newQuery = _.pickBy(
            { ...query, ...values, page: 1 },
            (item: any) => item !== "" && item !== null && item !== undefined && item !== "-1" && item
        );
        setQuery(newQuery);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [query]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        type: "radio" as any,
    };

    const columns = [
        {
            title: <div className="text-title-table">Username</div>,
            key: "username",
            dataIndex: "username",
        },
        {
            title: <div className="text-title-table">Tên</div>,
            key: "firstName",
            dataIndex: "firstName",
            render: (text: string, record: any) => {
                return `${record.firstName || ""} ${record.lastName || ""}`;
            },
        },
        {
            title: <div className="text-title-table">Code</div>,
            key: "code",
            dataIndex: "code",
        },
    ];

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openSearchUser}
                onCancel={() => {
                    form.resetFields();
                    setOpenSearchUser(false);
                }}
                title={"Search User"}
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        disabled={selectedRowKeys.length === 0}
                        onClick={() => {
                            setSelectedUser && setSelectedUser(selectedRowKeys[0]);
                            form.resetFields();
                            setOpenSearchUser(false);
                        }}
                    >
                        Xác nhận
                    </Button>,
                ]}
                width={1000}
            >
                <Row>
                    <Col span={8}>
                        <div className="body-component">
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="username"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Username</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="firstName"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Họ</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="lastName"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Tên</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="gender"
                                        className="form-floating"
                                        initialValue={false}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            placeholder=""
                                            options={[
                                                {
                                                    label: "Nam",
                                                    value: "Male",
                                                },
                                                {
                                                    label: "Nữ",
                                                    value: "Female",
                                                },
                                                {
                                                    label: "Khác",
                                                    value: "Other",
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                    <label>Giới tính</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="email"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Email</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="phone"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Số điện thoại</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="code"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Code</label>
                                </div>
                            </div>
                            <Row justify="end">
                                <Space>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            onClick={() => {
                                                form.submit();
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            onClick={() => {
                                                form.resetFields();
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Row>
                        </div>
                    </Col>
                    <Col span={16}>
                        <Table
                            rowSelection={rowSelection}
                            rowKey="id"
                            columns={columns}
                            dataSource={(data?.items as any) || []}
                            pagination={false}
                            loading={isLoading || isFetching}
                        />
                        <Pagination
                            onSizeChange={(size) => setQuery({ ...query, limit: size })}
                            total={data?.meta.totalItems || 0}
                            showSize={true}
                            totalPage={data?.meta.totalPages || 0}
                            onChangePage={(page) => setQuery({ ...query, page: page })}
                            defaultCurrentPage={query.page}
                        />
                    </Col>
                </Row>
            </Modal>
        </Form>
    );
};

export default SearchUser;
