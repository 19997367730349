import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

export const userProfileApi = createApi({
    reducerPath: "userProfile",
    tagTypes: ["User-Profile"],
    keepUnusedDataFor: 30 * 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            userProfile: builder.query<UserProfile, void>({
                query: () => {
                    return {
                        url: `/api/user/profile`,
                    };
                },
                providesTags: ["User-Profile"],
            }),
            userCheckin: builder.query<ResponseWithPaginateFormat<ICheckinUser>, void>({
                query: () => {
                    return {
                        url: `/api/user/checkin/paginate`,
                        params: {
                            page: 1,
                            limit: 300,
                        },
                    };
                },
            }),
            updateUserProfile: builder.mutation<Response<UserProfile>, InputUpdateUserProfile>({
                query: (body) => {
                    return {
                        url: `/api/user/info`,
                        method: "PATCH",
                        body,
                    };
                },
                invalidatesTags: ["User-Profile"],
            }),
            uploadAvatar: builder.mutation<any, any>({
                query: (body) => {
                    return {
                        url: "/api/user/avatar",
                        method: "POST",
                        body,
                    };
                },
            }),
            uploadThumbnailBlog: builder.mutation<any, any>({
                query: (body) => {
                    return {
                        url: "/api/user/blog/upload",
                        method: "POST",
                        body,
                    };
                },
            }),
            userReviewHistory: builder.query<
                ResponseWithPaginateFormat<SchoolReview>,
                { userId: string; page: number; limit: number }
            >({
                query: (params) => {
                    return {
                        url: `/api/user/review/paginate`,
                        params: {
                            limit: params.limit,
                            page: params.page,
                        },
                    };
                },
            }),
            createReview: builder.mutation<Response<any>, InputCreateReview>({
                query: (body) => {
                    return {
                        url: `/api/school/review/${body.schoolId}`,
                        method: "POST",
                        body,
                    };
                },
            }),

            applySchool: builder.mutation<Response<any>, InputCreateUserApply>({
                query: (body) => ({
                    url: `/api/user/apply`,
                    method: "POST",
                    body,
                }),
            }),
            userApply: builder.query<ApplyProcess[], void>({
                query: () => ({
                    url: `/api/user/apply`,
                }),
            }),
            scholarshipOfferApply: builder.mutation<any, any>({
                query: (body) => {
                    const formData = new FormData();
                    body?.fileList.forEach((file: any) => {
                        formData.append("files", file.originFileObj as File);
                    });

                    return {
                        url: `/api/user/apply/passed/${body?.id}`,
                        method: "POST",
                        body: formData,
                    };
                },
            }),
            cancelApply: builder.mutation<any, any>({
                query: (body) => {
                    return {
                        url: `/api/user/apply/cancel/${body?.id}`,
                        method: "POST",
                    };
                },
            }),
            getSchoolNewsById: builder.query<ISchoolNews, { id: string }>({
                query: (params) => {
                    return {
                        url: `/api/school/news/${params.id}`,
                        method: "GET",
                    };
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            createTicket: builder.mutation<any, any>({
                query: (body) => {
                    return {
                        url: "/api/ticket",
                        method: "POST",
                        body,
                    };
                },
            }),
            getTicketDetail: builder.query<ITicketDetail, { id: string }>({
                query: (params) => {
                    return {
                        url: `/api/ticket/${params.id}`,
                        method: "GET",
                    };
                },
            }),
            getListTickets: builder.query<ITicket[], void>({
                query: () => {
                    return {
                        url: `/api/user/ticket`,
                    };
                },
            }),
            replyTicket: builder.mutation<any, any>({
                query: (body) => {
                    return {
                        url: `/api/ticket/answer/${body.id}/reply`,
                        method: "POST",
                        body: {
                            answer: body?.answer,
                            rate: body?.rate,
                        },
                    };
                },
            }),
        };
    },
});

export const {
    useUserProfileQuery,
    useUserCheckinQuery,
    useUpdateUserProfileMutation,
    useUploadThumbnailBlogMutation,
    useUploadAvatarMutation,
    useUserReviewHistoryQuery,
    useApplySchoolMutation,
    useUserApplyQuery,
    useGetSchoolNewsByIdQuery,
    useCreateReviewMutation,
    useScholarshipOfferApplyMutation,
    useCreateTicketMutation,
    useCancelApplyMutation,
    useGetTicketDetailQuery,
    useGetListTicketsQuery,
    useReplyTicketMutation,
} = userProfileApi;
