import React, { useMemo } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

interface MapProps {
    lat: any;
    lng: any;
}

const Map: React.FunctionComponent<MapProps> = (props) => {
    return (
        <div className="box-item-element infor-school">
            <div className="title-element">
                <h5>Bản đồ</h5>
                <hr />
            </div>
            <div className="body-element">
                <iframe
                    width="100%"
                    height="100%"
                    loading="lazy"
                    style={{ border: 0 }}
                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAP_KEY}&q=${props.lat},${props.lng}`}
                ></iframe>

                {/* {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={locations[0]}
                        zoom={10}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        {locations.map((location, index) => (
                            <Marker
                                key={index}
                                position={location}
                            />
                        ))}
                    </GoogleMap>
                ) : (
                    <></>
                )} */}
            </div>
        </div>
    );
};

export default Map;
