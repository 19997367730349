import moment from "moment";
import React from "react";
import defaultAvatar from "@assets/images/avatar-lg.jpg";
import { linkCDN } from "@utils/common";

interface ReviewItemProps {
    username: string;
    avatar: string;
    time: string;
    tag: Array<string>;
    content: string;
    rate: number;
}

const MAX_STAR = 5;
const ReviewItem: React.FunctionComponent<ReviewItemProps> = (props) => {
    const light = props.rate;
    const normal = MAX_STAR - props.rate;
    const colors = ["text-box-green", "text-box-red", "text-box-blue"];

    return (
        <div className="item-post-review">
            <div className="row">
                <div className="col-9">
                    <img
                        className="avatar-user-circle object-fit-cover"
                        src={linkCDN(props?.avatar, defaultAvatar)}
                        alt=""
                    />
                    <div className="content-user-review">
                        <div className="group-header-review">
                            <h6>{props.username}</h6>
                            <time>{moment(props.time).format("DD/MM/YYYY HH:mm")}</time>
                            <div className="group-review-status">
                                {props.tag.map((e, i) => {
                                    const index = i % 3;
                                    return (
                                        <span
                                            key={i}
                                            className={colors[index] + " me-1"}
                                        >
                                            {e}
                                        </span>
                                    );
                                })}
                            </div>
                            <div className="clearfix" />
                        </div>
                        <p>{props.content}</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="box-rate">
                        <p>{light.toFixed(1)}</p>
                        <ul className="list-star">
                            {Array(light)
                                .fill("star")
                                .map((e, id) => (
                                    <li
                                        key={id}
                                        className="active"
                                    >
                                        <i className="bi-star-fill" />
                                    </li>
                                ))}
                            {Array(normal)
                                .fill("normal")
                                .map((e, id) => (
                                    <li key={id}>
                                        <i className="bi-star-fill star-normal" />
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewItem;
