import NumberFormat from "@components/NumberFormat";
import { useGetStatisticUserDashbardQuery } from "@redux/queries/user/intro.api";
import { FC, memo } from "react";

const Statistics: FC = () => {
    const { data } = useGetStatisticUserDashbardQuery();
    const {
        totalUsers = 0,
        totalSchools = 0,
        totalApplies = 0,
        totalSpecialties = 0,
        totalMajors = 0,
        totalPosts = 0,
        totalUsersToday = 0, // Tổng số người dùng sử dụng hôm nay
    } = data || {};

    const listStatistics = [
        {
            id: 1,
            title: "Tổng số người dùng",
            number: totalUsers,
            color: "text-box-blue",
        },
        {
            id: 2,
            title: "Tổng số Trường",
            number: totalSchools,
            color: "text-box-red",
        },
        {
            id: 3,
            title: "Tổng số apply",
            number: totalApplies,
            color: "text-box-green",
        },
        {
            id: 4,
            title: "Tổng số chuyên ngành",
            number: totalSpecialties,
            color: "text-box-gold",
        },
        {
            id: 5,
            title: "Tổng số ngành",
            number: totalMajors,
            color: "text-box-blue",
        },
        {
            id: 6,
            title: "Tổng số bài viết",
            number: totalPosts,
            color: "text-box-red",
        },
    ];

    return (
        <div
            id="spec-01"
            className="row"
        >
            {listStatistics.map((item) => (
                <div
                    className="col-6 col-md-4 col-xxl-2"
                    key={item.id}
                >
                    <div className="box-component box-option box-style-02">
                        <span className={`abs_icon ${item.color}`}>
                            <i className="bi-person-add"></i>
                        </span>
                        <h6>{item.title}</h6>
                        <h4>
                            <NumberFormat value={item.number} />
                        </h4>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default memo(Statistics);
