import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

const BASE = "/api/training";

export const adminTrainingApi = createApi({
    reducerPath: "adminTrainingApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Training"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListTraining: builder.query<Array<ITraining>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListTrainingPaginate: builder.query<IResponseDataAdmin<ITraining>, { page: number; limit: number }>({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: {
                        page: query.page,
                        limit: query.limit,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Training" as const, id })),
                              { type: "Training", id: "Training-LIST" },
                          ]
                        : [{ type: "Training", id: "Training-LIST" }],
            }),
            createTraining: builder.mutation<any, IAdminTrainingInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: {
                        language: params.language,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Training"],
            }),
            updateTraining: builder.mutation<any, IAdminTrainingInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        language: params.language,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Training"],
            }),
            deleteTraining: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Training"],
            }),
            deleteMultiTraining: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Training"],
            }),
        };
    },
});

export const {
    useGetListTrainingQuery,
    useGetListTrainingPaginateQuery,
    useLazyGetListTrainingPaginateQuery,
    useCreateTrainingMutation,
    useUpdateTrainingMutation,
    useDeleteTrainingMutation,
    useDeleteMultiTrainingMutation,
} = adminTrainingApi;
