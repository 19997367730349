import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/school/service";

export const adminSchoolServiceApi = createApi({
    reducerPath: "adminSchoolServiceApi",
    keepUnusedDataFor: 30,
    tagTypes: ["SchoolService"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolService: builder.query<Array<ISchoolService>, any | void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "SchoolService" as const, id })),
                              { type: "SchoolService", id: "SchoolService-LIST" },
                          ]
                        : [{ type: "SchoolService", id: "SchoolService-LIST" }],
            }),
            getListSchoolServicePaginate: builder.query<
                IResponseDataAdmin<ISchoolService>,
                { page: number; limit: number }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "SchoolService" as const, id })),
                              { type: "SchoolService", id: "SchoolService-LIST" },
                          ]
                        : [{ type: "SchoolService", id: "SchoolService-LIST" }],
            }),
            createSchoolService: builder.mutation<any, ISchoolServiceInput>({
                query: (params) => ({
                    url: `${BASE}/${params.schoolId}`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolService"],
            }),
            updateSchoolService: builder.mutation<any, ISchoolServiceInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolService"],
            }),
            deleteSchoolService: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolService"],
            }),
            deleteSchoolServiceTraining: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/training/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolService"],
            }),
            deleteMultiSchoolService: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolService"],
            }),
        };
    },
});

export const {
    useGetListSchoolServiceQuery,
    useLazyGetListSchoolServiceQuery,
    useGetListSchoolServicePaginateQuery,
    useLazyGetListSchoolServicePaginateQuery,
    useCreateSchoolServiceMutation,
    useUpdateSchoolServiceMutation,
    useDeleteSchoolServiceMutation,
    useDeleteMultiSchoolServiceMutation,
    useDeleteSchoolServiceTrainingMutation,
} = adminSchoolServiceApi;
